import loglevel from 'loglevel';
const log = loglevel.getLogger('strategy-service');

import localforage from 'localforage';
import {combineLatest, from, Observable, of} from 'rxjs';
import {filter, flatMap, map, switchMap, withLatestFrom} from 'rxjs/operators';

import { compareKey, ObjectDefinition } from '@thinkalpha/table-client';

import { defineStrategy } from '../strategy/definer';
import { defineUniverse } from '../universe/definer';

import {ConcreteStrategy, KnownStrategyTemplate, MaterializedStrategy, StrategyType} from '../strategy/model';
import { randomString } from '../util/randomString';
import {client} from './table';

import { Universe, UniverseAspectType } from '../universe/model';
import { saveUniverse, UniverseRecord } from './universes';
import { captureEvent, Severity } from '@sentry/browser';

export interface StrategyRecord {
    id: string;
    name?: string;
    type: StrategyType;
    universe?: UniverseRecord;
    tableName?: string;
}

const itemMoniker = 'strategies';
function getHackSavedStrategies(): Observable<MaterializedStrategy[]> {
    return from(localforage.getItem<MaterializedStrategy[]>(itemMoniker)).pipe(
        map(xs => xs || [])
    );
}

export function getSavedStrategies(): Observable<StrategyRecord[]> {
    return getHackSavedStrategies().pipe(
        map(xs => xs.map<StrategyRecord>(x => ({
            name: x.strategy.name,
            id: x.id,
            type: x.strategy.type,
            tableName: x.tableName,
            universe: x.universe && {
                id: x.universe.id,
                tableName: x.universe.tableName,
                name: x.universe.name
            }
        })))
    );
}

export function getStrategyById(id: string): Observable<MaterializedStrategy | undefined> {
    return getHackSavedStrategies().pipe(
        map(xs => xs.find(x => x.id === id))
    );
}

export function saveStrategy(strategy: ConcreteStrategy) {
    return getHackSavedStrategies().pipe(
        switchMap(xs => from(localforage.setItem(itemMoniker, [...xs.filter(x => x.id !== strategy.id), {id: strategy.id || randomString(), ...strategy}])))
    );
}

export function playTransientStrategy(universe: Universe | undefined, template: KnownStrategyTemplate) {
    return playStrategy({strategy: template, universe});
}

export function playStrategy(strategyOrId: string | ConcreteStrategy | StrategyRecord): Observable<ConcreteStrategy | undefined> {
    if (typeof strategyOrId === 'object' && !('strategy' in strategyOrId)) {
        strategyOrId = strategyOrId.id;
    }

    const strategy$ = (typeof strategyOrId === 'string' ? getStrategyById(strategyOrId) as Observable<ConcreteStrategy> : of(strategyOrId)).pipe(
        withLatestFrom(client.keys$),
        flatMap(async ([strategy, keys]): Promise<ConcreteStrategy | undefined> => {
            log.info('Attempting to play strategy', strategy);
            if (!strategy) {
                log.warn('Not playing undefined strategy');
                return undefined;
            }
            let universe = strategy.universe;

            if (strategy.tableName) {
                // maybe it exists
                if (keys.find(k => compareKey(k, {sym: strategy.tableName!, ex: 'T'}))) {
                    log.info('Not playing strategy', strategy.strategy.name, 'because key', strategy.tableName, 'already exists in the key list.');
                    return strategy;
                } else {
                    strategy = {...strategy, tableName: undefined};
                }
            }

            const defs: ObjectDefinition[] = [];

            if (!universe) {
                universe = {aspects: [], inclusionList: [], exclusionList: []};
            }

            if (!universe.tableName || !keys.find(k => compareKey(k, {sym: universe!.tableName as string, ex: 'T'}))) {
                // need to define the universe, since its missing
                const universeDefinition = defineUniverse(universe);
                universe = {...universe, tableName: universeDefinition.name};
                defs.push(...universeDefinition.definitions);
            }

            const strategyDefinition = defineStrategy(universe!.tableName!, strategy);
            strategy = {...strategy, tableName: strategyDefinition.name};
            defs.push(...strategyDefinition.definitions);

            // for (const def of defs) {
            //     log.info('Defining object', def);
            if (defs.length) {
                log.info('Defining objects', defs);
                captureEvent({
                    message: 'Defining object bundle',
                    extra: { definitions: defs },
                    level: Severity.Debug
                });    
                const defineResult = await client.defineObject(false, defs).toPromise();
                log.info('Got define result', defineResult);
                if (!defineResult.success) {
                    log.error('Got define error', defineResult);
                    return;
                }
            } else {
                log.info('Not defining empty objects bundle');
            }
            // }

            if (universe.id) {
                // update universe master
                saveUniverse(universe);

                // update the universe record with the new table name across all strategies
                getHackSavedStrategies().pipe(
                    flatMap(xs => xs),
                    filter(x => !!x.universe && x.universe.id === universe!.id),
                    map(x => saveStrategy({...x, universe}))
                ).subscribe();
            }

            // update the strategy record with all updates (such as strategy table name, etc.)
            if (strategy.id) saveStrategy(strategy).subscribe();

            return strategy;
        })
    );

    return strategy$;
}

export function playStrategyById(id: string) {
    return playStrategy(id);
}