import { ConcreteStrategy, KnownStrategyTemplate, StrategyCreatorResult, StrategyType } from './model';

import { UniverseCreatorResult } from '../universe/model';
import {createIfThenStrategy} from './creators/if-then/definer';
import {createPriceChangeStrategy} from './creators/price-change/definer';

export type StrategyDefiner<T extends KnownStrategyTemplate> = (universeTableName: string, strategy: T) => StrategyCreatorResult;

export const defineStrategy = (universeTableName: string, strategy: ConcreteStrategy): StrategyCreatorResult => {
    // if (strategy.tableName) {
    //     return {name: strategy.tableName, definitions: []};
    // }

    if (!universeTableName) universeTableName = 'EquityUniverse';

    switch (strategy.strategy.type) {
        case StrategyType.priceChange:
            return createPriceChangeStrategy(universeTableName, strategy.strategy);
        case StrategyType.ifThen:
            return createIfThenStrategy(universeTableName, strategy.strategy);
        default:
            throw new Error('not implemented');
        // case StrategyType.priceRelativeChange:
        //     return createRelativePriceChangeStrategy(universe, strategy);
    }
};