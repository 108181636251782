import './group.scss';

import loglevel from 'loglevel';
const log = loglevel.getLogger('strategy-group');

import React, {useState, useEffect, useCallback, useRef, useMemo, useContext, RefObject} from 'react';

import { Menu, MenuItem, Select, TextField, IconButton, Button, ButtonGroup, Checkbox } from '@material-ui/core';

import classnames from 'classnames';

import { FieldDescriptor } from '@thinkalpha/table-client';

import {IfThenGroupModel, IfThenLineModel, IfThenLinePlaceholder, IfThenConditionModel, DragModel, DRAG_MODEL_TYPE, DragProps, DragPreview, DropPosition} from './model';
import IfThenLine from './line';
import { Field } from '../components/filter-editor/model';
import { useInputState, useCheckedInputState } from '../hooks/useInputState';
import { useToggleState } from '../hooks/useToggleState';
import { useDeepEffect } from '../hooks/useDeepEffect';
import { useModelCommitter } from '../hooks/useModelCommitter';
import { randomString } from '../util/randomString';
import _ from 'lodash';
import ActionsMenu from './actions-menu';
import { Clipboard } from '../state';
import { useDrag, useDrop } from 'react-dnd';
import {isEqual} from 'lodash';
import { LogicalOperator } from '@thinkalpha/language-services';


type Props<T extends IfThenGroupModel> = {
    model: T & {name?: string};
    committedModel?: T;
    fields: readonly Field[];
    depth: number;
    onModelChanged: (strategy: T & {name?: string}) => void;
    onValidityChanged?: (valid: boolean) => void;
    clipboard: Clipboard;
    setClipboard: (clipboard: Clipboard) => void;
    isOnly?: boolean;
    dragProps?: DragProps;
};

const IfThenGroup = <T extends IfThenGroupModel>({depth, fields, model, committedModel, onModelChanged, onValidityChanged, clipboard, setClipboard, dragProps}: Props<T>) => {
    const [pendingChanges, setPendingChanges] = useState(false);
    const [editingConditionName, setEditingConditionName] = useState(!model.name);
    const [pendingConditionName, setPendingConditionName, onPendingConditionNameChanged] = useInputState(model.name || '');
    const [savedName, setSavedName] = useState(model.name);
    const [lineValidity, setLineValidity] = useState<(boolean | undefined)[]>([]);

    const [collapsed, setCollapsed, collapse, expand] = useToggleState(model.collapsed);
    const [lines, setLines] = useState(model.lines);
    const [name, setName] = useState(model.name);
    const [id, setId] = useState(model.id);
    const [operator, setOperator, onOperatorChanged] = useInputState(model.operator || LogicalOperator.and);
    const [enabled, setEnabled, onEnabledChanged] = useCheckedInputState(model ? model.enabled : true);

    const [addMenuShown,, showAddMenu, hideAddMenu, toggleAddMenu] = useToggleState(false);
    const [addConditionMenuIndex, setAddConditionMenuIndex] = useState<number>();

    const addMenuAnchor = document.getElementById(`addMenuRef-${id}`);
    const addConditionMenuAnchor = document.getElementById(`conditionMenuRef-${id}-${addConditionMenuIndex}`);

    const [dragPreview, setDragPreview] = useState<DragPreview | null>();

    const dragHelperRef = useRef<HTMLDivElement>(null);
    const [draggedChildId, setDraggedChildId] = useState<string | null>(null);
    const dropPreviewRef = useRef<HTMLDivElement>(null);

    const validitySetters = useMemo(() => 
        lines.map((x, i) => (valid: boolean) => {
            setLineValidity(existing => {
                const validities = [...existing];
                validities[i] = valid;
                return validities;
            });
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [lines.length]);
    useEffect(() => {
        if (lines.length < lineValidity.length) {
            setLineValidity(lineValidity.splice(lines.length - 1, lineValidity.length - lines.length));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lines.length]);

    const save = () => {
        // savedConditionService.deleteSavedCondition(oldName || model.name);
        // setState({ oldName: undefined });

        // savedConditionService.addSavedCondition(model);
        // setState({ pendingChanges: false });
    };

    useModelCommitter(
        model,
        onModelChanged,
        { collapsed, lines, name, operator, id, enabled },
        ({ collapsed, lines, name, operator, id, enabled }) => { // committer
            // console.log('committer in strategy group', collapsed, lines, name, operator);
            const newModel: IfThenConditionModel = {
                ...model, // in case we are inheriting something other than the group model
                
                type: 'group',
                id,
                collapsed,
                lines,
                name,
                enabled,//: lines.length > 1 ? enabled : true,
                operator,
            };
            return newModel;
        },
        model => { // materializer
            // console.log('materializer in strategy group', model);
            if (!model) {
                setId(randomString());
                setName('');
                setCollapsed(false);
                setLines([]);
                setOperator(LogicalOperator.and);
                setEditingConditionName(true);
                setEnabled(true);
            } else {
                setId(model.id);
                setName(model.name);
                setCollapsed(model.collapsed || false);
                setLines(model.lines);
                setOperator(model.operator || LogicalOperator.and);
                setEnabled(model.enabled);//model.lines.length > 1 ? model.enabled : true);
                
                setEditingConditionName(!model.name);
            }
        }
    );

    const valid = useMemo(() => {
        const valid = lineValidity.map((x, i) => lines[i] && lines[i].type === 'line' && !('operator' in lines[i]) ? true : x).every(x => x);
        return valid;
    }, [lineValidity, lines]);

    useEffect(() => {
        onValidityChanged?.(valid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valid]);

    const clickedCondition = useCallback(() => {
        setEditingConditionName(true);
        setPendingConditionName(name || '');
    }, [name, setPendingConditionName]);

    const blurredCondition = useCallback(() => {
        if (!pendingConditionName || !pendingConditionName.trim()) return;

        setEditingConditionName(false);
        if (name === pendingConditionName) return;

        setPendingChanges(true);

        setName(pendingConditionName);
    }, [name, pendingConditionName]);

    const operators = [LogicalOperator.and, LogicalOperator.or].map(x => ({name: x.toUpperCase(), value: x}));

    const addItem = useCallback((newItem: IfThenGroupModel | IfThenLineModel | IfThenLinePlaceholder, i?: number) => {
        log.debug('adding item', newItem, 'at', i);

        const newLines = [...lines];
        if (i !== undefined) {
            newLines.splice(i, 0, newItem);
        } else {
            newLines.push(newItem);
        }

        setLines(newLines);
    }, [lines]);

    const pasteLine = useCallback((i: number) => {
        const newItem = {...clipboard, id: randomString()};;
        addItem(newItem, i);
    }, [clipboard, addItem]);

    const closeMenus = useCallback(() => {
        hideAddMenu();
        setAddConditionMenuIndex(undefined);
    }, [hideAddMenu]);

    const addIf = useCallback((i?: number) => {
        const newItem: IfThenLinePlaceholder = {type: 'line', id: randomString(), enabled: true};
        addItem(newItem, i);
        closeMenus();
    }, [addItem, closeMenus]);

    const addCondition = useCallback((i?: number, strategy?: IfThenGroupModel) => {
        const newItem: IfThenGroupModel = strategy || {
            type: 'group',
            enabled: true,
            operator: operator === LogicalOperator.and ? LogicalOperator.or : LogicalOperator.and,
            id: randomString(),
            collapsed: false,
            lines: [{
                type: 'line',
                enabled: true,
                id: randomString()
            }]
        };
        addItem(newItem, i);
        closeMenus();
    }, [addItem, closeMenus, operator]);

    const pasteCondition = useCallback((i: number) => {
        const newItem = clipboard as IfThenGroupModel;
        addItem({...newItem, id: randomString()}, i);
    }, [addItem, clipboard]);

    const removeLine = useCallback((idx: number) => {
        setLines(lines => lines.filter((x, i) => i !== idx));
    }, []);

    const removeLineById = useCallback((id: string) => {
        setLines(lines => lines.filter(x => x.id !== id));
    }, []);

    const onDropHandler = useCallback((item: DragModel, dropId: string, position: DropPosition) => {
        const itemId = item.model.id;
        const newLines = [...lines];
        const ids = lines.map(x => x.id);
        const itemIndex = ids.indexOf(itemId);
        const dropIndex = ids.indexOf(dropId);
        
        if (itemIndex === -1) {

            newLines.splice(dropIndex + (position === 'top' ? 0 : 1), 0, item.model);
            if (item.depth > depth) {
                setLines(newLines);
                item.onDragLeave();
            } else {
                item.onDragLeave();
                setLines(newLines);
            }
        } else {
            newLines.splice(dropIndex, 0 , ...newLines.splice(itemIndex, 1));
            setLines(newLines);
        }
    }, [lines, depth]);

    const onDragDoneHandler = useCallback(() => {
        setDraggedChildId(null);
    }, []);

    const onDragStartHandler = useCallback((preview: DragPreview) => {
        setDraggedChildId(preview.lineId);
        setDragPreview(preview);
    }, []);

    const addAnotherConditionCallbacks = useMemo(() => {
        return lines.map((_, index) => () => addCondition(index + 1));
    }, [lines, addCondition]);

    const onModelChangesCallbacks = useMemo(() => {
        return lines.map((_, index) => (line: IfThenGroupModel | IfThenLineModel | IfThenLinePlaceholder | undefined) => {
            // console.log('onmodelchanges', line, index, 'depth', depth);
            setLines(lines => lines.map((x, i) => i === index ? (line || {type: 'line', id: randomString(), enabled: true}) : x));
        });
    }, [lines, setLines]);

    const onCopyHandler = useCallback(() => {
        setClipboard(model);
    }, [setClipboard, model]);

    const onPasteHandler = useCallback(() => {
        pasteCondition(lines.length + 1);
    }, [lines, pasteCondition]);

    const committed = committedModel !== undefined
        && _.isEqual(committedModel, model);

    const pending = false;

    const onPasteLineHandlers = useMemo(() => lines.map((_, i) => () => pasteLine(i + 1)), [pasteLine, lines]);

    const onRemoveLineHandlers = useMemo(() => lines.map((x) => () => removeLineById(x.id)), [removeLineById, lines]);

    const clearDragPlaceholderHandler = useCallback(() => {
        setDragPreview(null);
    }, []);

    const [, drop] = useDrop({
        accept: DRAG_MODEL_TYPE,        
        collect: (monitor) => ({
            item: monitor.getItem(),
        }),
        hover: (item: DragModel, monitor) => {
            if (!monitor.isOver({shallow: true})) return;

            const clientOffset = monitor.getSourceClientOffset();
            if (!clientOffset) return;

            if (depth === 0) return;

            if (item.model.id === model.id) return;

            if (!item.dropZoneId) item.dropZoneId = model.id;

            // Switching between zones
            if (item.dropZoneId !== model.id) {
                item.clearDragPlaceholder();
                item.clearDragPlaceholder = clearDragPlaceholderHandler;
                item.dropZoneId = model.id;
            }

            const lineElements = dragHelperRef.current!.querySelectorAll(`.line-container[data-depth="${depth}"]`);

            let lineId: string | undefined;
            let position: DropPosition | undefined;

            lineElements.forEach((line, index) => {
                const rect = line.getBoundingClientRect() as DOMRect;
                if ((rect.y <= clientOffset.y) && ((rect.y + rect.height) >= clientOffset.y)) {
                    lineId = model.lines[index].id;
                    position = (rect.y + rect.height/2) > clientOffset.y ? 'top' : 'bottom';
                    const preview = {
                        lineId,
                        position,
                        height: item.height!
                    };
                    if (!isEqual(preview, dragPreview)) setDragPreview(preview);
                    return;
                }
            });

            if (!lineId) {
                const firstRect = lineElements[0].getBoundingClientRect() as DOMRect;
                const lastRect = lineElements[lineElements.length - 1].getBoundingClientRect() as DOMRect;
                // console.log(firstRect.y, clientOffset.y, firstRect.y > clientOffset.y, 'AAAAAAAAAAAAAAAaa')
                if (firstRect.y > clientOffset.y) {
                    const preview: DragPreview = {
                        lineId: model.lines[0].id,
                        position: 'top',
                        height: item.height!
                    };
                    if (!isEqual(preview, dragPreview)) setDragPreview(preview);
                    return;
                } else if (lastRect.y < clientOffset.y ) {
                    const preview: DragPreview = {
                        lineId: model.lines[lineElements.length - 1].id,
                        position: 'bottom',
                        height: item.height!
                    };
                    if (!isEqual(preview, dragPreview)) setDragPreview(preview);
                    return;
                }
            }
        },
        drop: (item) => {
            setDragPreview(null);

            if (item.dropZoneId === model.id) {
                onDropHandler(item, dragPreview!.lineId, dragPreview!.position);
            };
        },
        canDrop: (item) => {
            return item.model.id !== model.id;
        },
    });
    
    const [, drag, preview] = useDrag({
        item: { type: DRAG_MODEL_TYPE, model, height: 0, depth, ...dragProps },
        begin: () => {
            const height = dragHelperRef.current!.getBoundingClientRect().height;
            setTimeout(() => dragProps!.onDragStart({lineId: model.id, position: 'top', height}), 0);
            return { 
                type: DRAG_MODEL_TYPE, 
                model,
                height: dragHelperRef.current!.getBoundingClientRect().height,
                depth,
                ...dragProps
            };
        },
        end: (item: DragModel) => {
            item.clearDragPlaceholder();
            item.onDragDone();
        },
    });

    drop(dragHelperRef);
    drop(preview(dropPreviewRef));

    const previewHeight = dragPreview && `${dragPreview.height}px` as any;
    const style = dragPreview ? dragPreview.position === 'top' ? {paddingTop: previewHeight} : {paddingBottom: previewHeight} : {};

    return (<div data-depth={depth} ref={dragHelperRef} className={classnames('ifthen-container', {
        committed,
        uncommitted: !committed,
        pending: pending,
        valid: !pending && valid,
        invalid: !pending && !valid,
        collapsed,
    })}>
        {depth === 1 && <div className={classnames({'floating-buttons': true, editing: editingConditionName, collapsed})}>
            <IconButton hidden={collapsed} className="collapse" onClick={collapse}><i className="fa fa-minus-square"/></IconButton>
            <IconButton hidden={!collapsed} className="expand" onClick={expand}><i className="fa fa-plus-square"/></IconButton>
            <div hidden={!collapsed} id="if-prefix">
                If&nbsp;
            </div>
            <div className="name">
                <TextField variant="outlined" spellCheck={false} value={pendingConditionName} onChange={onPendingConditionNameChanged} label="Condition Name" onBlur={blurredCondition} />
            </div>
            <div hidden={!collapsed} id="is-true">
                &nbsp;is True
            </div>
        </div> }
        
        {depth > 1 && <div className={classnames('group-start', 'validity-box')}>
            <Checkbox checked={enabled} onChange={onEnabledChanged} color="default" />
            <div ref={drag}>
                <ActionsMenu  onCopy={onCopyHandler} onPaste={onPasteHandler}/>
            </div>
        </div> }
        <div ref={dropPreviewRef} className={classnames({
            even: depth % 2 === 0,
            odd: depth % 2 !== 0,
            'ifthen-group': true
        })} hidden={collapsed} data-depth={depth}>
            {depth > 0 && <div id="brace" className={classnames({'has-items': !!lines.length})}>
                { /* <img src="../../../assets/brace.svg" /> */ }
            </div> }
            <div id="lines">
                {lines.map((line, index) => <div 
                    key={line.id} 
                    className={classnames({'line-container' : true, 'dragged': draggedChildId === line.id})}
                    style={dragPreview && dragPreview.lineId === line.id ? style : {}} 
                    data-depth={depth}>
                    <div className={classnames({line: true, outer: depth === 0, strategy: line.type === 'group'})} data-depth={depth}>
                        <div className={classnames({'line-content': true, 'group': line.type === 'group'})} data-depth={depth}>
                            {line.type === 'group' && <div className="top-buttons" hidden={line.collapsed}>
                                {depth === 0 && <Button variant="contained" className="save" disabled={!pendingChanges || !name} onClick={save}>Save Condition</Button> }
                                {depth === 0 && <Button variant="contained" className="add" endIcon={<i className="fal fa-plus-circle"/>} onClick={addAnotherConditionCallbacks[index]}>Add another condition</Button>} 
                                {lines.length > 1 && depth !== 0 && <IconButton className="close" onClick={() => removeLine(index)}><i className="fas fa-times-circle"/></IconButton> }
                            </div>} 
                            {line.type === 'line' && <IfThenLine
                                committedModel={committedModel && committedModel.lines.find(x => x.id === line.id) as IfThenLineModel}
                                onValidityChange={validitySetters[index]}
                                onModelChange={onModelChangesCallbacks[index]}
                                fields={fields}
                                model={line}
                                onCopy={setClipboard}
                                onPaste={onPasteLineHandlers[index]}
                                isOnly={lines.length === 1}
                                depth={depth}
                                dragProps={{
                                    clearDragPlaceholder: clearDragPlaceholderHandler,
                                    onDragLeave: onRemoveLineHandlers[index],
                                    onDragDone: onDragDoneHandler,
                                    onDragStart: onDragStartHandler
                                }}
                            />}
                            {line.type === 'group' && <IfThenGroup
                                committedModel={committedModel && committedModel.lines.find(x => x.id === line.id) as IfThenGroupModel}
                                onValidityChanged={validitySetters[index]}
                                depth={depth + 1}
                                onModelChanged={onModelChangesCallbacks[index]}
                                clipboard={clipboard}
                                setClipboard={setClipboard}
                                fields={fields}
                                model={line}
                                isOnly={lines.length === 1}
                                dragProps={{
                                    clearDragPlaceholder: clearDragPlaceholderHandler,
                                    onDragLeave: onRemoveLineHandlers[index],
                                    onDragDone: onDragDoneHandler,
                                    onDragStart: onDragStartHandler
                                }}
                            /> }
                        </div>
                        {depth !== 0 && <>
                            {line.type === 'line' && <>
                                <IconButton className="close" size="small" onClick={() => removeLine(index)} hidden={lines.length <= 1}><i className="fas fa-times-circle"/></IconButton>
                            </>}
                        </>}
                        { depth >= 1 && index === 0 && lines.length > 1 && <TextField InputProps={{disableUnderline: true}} className="operator line-operator" hidden={lines.length === 1} select value={operator} onChange={onOperatorChanged}>
                            {operators.map(op => <MenuItem value={op.value} key={op.value}>{op.name}</MenuItem>)}
                        </TextField>}
                        { depth >= 1 && index === lines.length - 1 && <Button className="add" variant="text" id={`addMenuRef-${id}`} onClick={showAddMenu} size="large"><i className="fal fa-plus"/></Button> }
                    </div>
                    { depth === 0 && <div className="operator-line">
                        <Select variant="outlined" className="operator" value={operator} onChange={onOperatorChanged}>
                            <MenuItem value={LogicalOperator.and}>AND</MenuItem>
                            <MenuItem value={LogicalOperator.or}>OR</MenuItem>
                        </Select>
                    </div> }
                </div>)}
            </div>
        </div>

        <Menu onClose={closeMenus} anchorEl={addMenuAnchor} open={addMenuShown}> {/* addMenu */}
            {/* {depth !== 0 && <MenuItem onClick={() => addIf(addMenuIndex!)}>
                Add If
                &nbsp;<i className="fa fa-caret-up"/>
            </MenuItem>} */}
            {depth !== 0 && <MenuItem onClick={() => addIf()}>
                Add If
                {/* &nbsp;<i className="fa fa-caret-down"/> */}
            </MenuItem>}
            {/* <MenuItem onClick={() => addCondition(addMenuIndex!)}>
                Add {depth === 0 ? 'Condition' : 'Nested If'}
                &nbsp;<i className="fa fa-caret-up"/>
            </MenuItem> */}
            <MenuItem onClick={() => addCondition()}>
                Add {depth === 0 ? 'Condition' : 'Nested If'}
                {/* &nbsp;<i className="fa fa-caret-down"/> */}
            </MenuItem>
        </Menu>

        <Menu onClose={closeMenus} anchorEl={addConditionMenuAnchor} open={addConditionMenuIndex !== undefined}> {/* addConditionMenu */}
            <MenuItem onClick={() => addCondition(addConditionMenuIndex! + 1)}>
                Add Condition
                {/* &nbsp;<i className="fa fa-caret-down"/> */}
            </MenuItem>
        </Menu>
    </div>);
};

export default IfThenGroup;