import { FormControlLabel, FormGroup, Input, InputLabel, MenuItem, Radio, RadioGroup, Select, Checkbox } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { isEqual } from 'lodash';

import { useInputCallback, useNumberInputCallback, useNumberInputState } from '../../../hooks/useInputState';
import { Range, RangedUniverseAspect, SimpleRange, Universe } from '../../model';

import { NumberFormatMaterial } from '../../../components/formatted-number-input/formatted-number-input';

import Rheostat, {PublicState} from 'rheostat';
import { useModelCommitter } from '../../../hooks/useModelCommitter';

function validate(value: undefined | number | SimpleRange): value is number | SimpleRange {
    if (!value) return false;
    if (typeof value === 'number') {
        if (isNaN(value)) return false;
    } else {
        if (!validate(value.start)) return false;
        if (!validate(value.end)) return false;
    }
    return true;
}

export const RangeCreator: React.FunctionComponent<{value?: Range, onChange: (value?: Range) => void, max?: number, min?: number}> = ({value, onChange, max = 100, min = 0}) => {
    const [start, setStart, onStartChanged] = useNumberInputState(value ? ((typeof value === 'number') ? value : value.start) : null);
    const [end, setEnd, onEndChanged] = useNumberInputState(value ? ((typeof value === 'number') ? value : value.end) : null);

    useModelCommitter(
        value,
        onChange,
        {start, end},
        ({start, end}) => {
            if (typeof start !== 'number' || typeof end !== 'number') return false;
            if (start === end) return start;
            return {start, end};
        },
        model => {
            if (!validate(model)) {
                setStart(null);
                setEnd(null);
            } else if (typeof model === 'number') {
                setStart(model);
                setEnd(model);
            } else {
                const {start, end} = model;
                setStart(start);
                setEnd(end);
            }
        }
    );

    const onRheostatChanged = useCallback(({values: [start, end]}: PublicState) => {
        if (start !== min - 0.0001) setStart(start);
        if (end !== max + 0.0001) setEnd(end);
    }, [max, min, setEnd, setStart]);

    const rheostatValues = useMemo(() => [
        Math.max(typeof start === 'number' ? start : min, min - 0.0001), // don't let the values go out of bounds
        Math.min(typeof end === 'number' ? end : max, max + 0.0001)
    ], [start, min, end, max]);

    return <FormGroup>
        <Rheostat values={rheostatValues} onValuesUpdated={onRheostatChanged} min={min} max={max} />
        <FormControlLabel onChange={onStartChanged} value={start ? start.toString() : null} label="Smallest Value" control={<NumberFormatMaterial inputProps={{max: Math.min(max, end || -Infinity), min: min}} />} />
        <FormControlLabel onChange={onEndChanged} value={end ? end.toString() : null} label="Largest Value" control={<NumberFormatMaterial inputProps={{max: max, min: Math.max(min, start || Infinity)}} />} />
    </FormGroup>;
};