import React, {ChangeEvent, useCallback, useEffect, useState} from 'react';

import { FieldDescriptor, FieldTypeCategory, TypesInTypeCategory } from '@thinkalpha/table-client';
import {Subject, timer} from 'rxjs';
import {debounce, tap} from 'rxjs/operators';

import { useSubscription } from '../hooks/useSubscription';
import {lexer, parser, flattenAst} from '@thinkalpha/language-services';
import { descriptorToField } from './data-builder/data-builder';
import compile from '@thinkalpha/language-services/dist/compilers/table-server';
import { Field } from './filter-editor/model';
import FilterEditor from './filter-editor/filter-editor.new';
import { useModelCommitter } from '../hooks/useModelCommitter';
import { functionDefs } from '../syntax/functions';

export function smartFilterToTableFilter(text: string, fields: FieldDescriptor[] | undefined): string | undefined {
    if (!text) {
        return undefined;
    } else if (text.startsWith('=')) {
        // construct a "complex" filter: verbatum text without the =
        const parsed = parser(text.substr(1), (fields || []).map(descriptorToField), functionDefs);
        const valid = parsed.valid && flattenAst(parsed.root).every(x => x.valid);
        if (!valid || !parsed.root) return undefined;
        if (parsed.root.dataType !== FieldTypeCategory.Boolean) return undefined;

        const compiled = compile(parsed.root, {hoist: false}).result;

        return compiled;
    } else if (!fields) {
        return undefined;
    } else {
        // construct a "simple" filter: make a really complex filter to search the specified text
        const types = TypesInTypeCategory.get(FieldTypeCategory.String)!;
        const pieces = fields.filter(x => types.indexOf(x.type) !== -1).map(x => `(${x.name} like '%.*${text}.*')`);
        return pieces.join(' or ');
    }
}

// export default SmartFilterBox;

type Props = {
    placeholder?: string;
    fields: Field[];
    value?: string | null;
    onChange?: (value: string) => void;
};

export const SmartFilterBox: React.FC<Props> = ({
    placeholder = 'Filter',
    fields,
    value, onChange
}) => {
    const [filter, setFilter] = useState<string | null>(null);
    const [liveFilter, setLiveFilter] = useState<string | null>(null);
    
    const onFilterChanged = useCallback((value: string) => {
        setFilter(value); // todo: debounce
        if (!value.startsWith('=')) {
            setLiveFilter(value);
        } // else wait for parser result
    }, []);

    useModelCommitter(
        value,
        onChange,
        {filter, liveFilter},
        ({filter, liveFilter}) => {
            if (filter !== liveFilter) return false;
            return filter;
        },
        model => {
            if (model === undefined) {
            } else if (model === null) {
                setFilter(null);
                setLiveFilter(null);
            } else {
                onFilterChanged(model);
            }
        }
    );

    const onValidityChanged = useCallback((valid: boolean, value: string) => {
        if (valid) {
            setLiveFilter(value);
        }
    }, []);

    return <FilterEditor
        placeholder={placeholder}
        equalsMode
        dataTypeRequired={FieldTypeCategory.Boolean}
        fields={fields}
        value={filter}
        onValueChange={onFilterChanged}
        onValidityChange={onValidityChanged}
    />;
};