import {IfThenLineModel, IfThenGroupModel} from './model';
import { Field } from '../components/filter-editor/model';
import { AstNodeType, KnownAstNode, parser, lexer, categorizeOperator, OperatorType, LogicalOperator, StringComparisonOperator, BinaryLogicalOperator, ComparisonOperator } from '@thinkalpha/language-services';
import compiler, { HoistedColumn } from '@thinkalpha/language-services/dist/compilers/table-server';
import renderer from '../components/filter-editor/renderer';
import { randomString } from '../util/randomString';
import { Set } from 'immutable';
import { functionDefs } from '../syntax/functions';

export function renderLine(line: IfThenLineModel): string {
    const operand1 = line.operand1;
    const operand2 = line.operand2;

    const combined = `${operand1} ${StringComparisonOperator[line.operator]} ${operand2}`;
    return combined;
}

export function renderGroup(group: IfThenGroupModel): string | undefined {
    if (!group.lines.length) return undefined;
    if (!group.operator) return undefined;

    const pieces = group.lines
        .filter(x => x.enabled)
        .map(line => {
            if (line.type as any === 'strategy') line.type = 'group'; // hack to preserve saved legacy strategies
            if (line.type === 'group') {
                return renderGroup(line);
            } else if ('operator' in line) { // not a placeholder
                return renderLine(line);
            } else {
                // console.log('ignoring placeholder line', line);
            }
        })
        .filter(x => x)
        .map(x => `${x}`);

    return pieces.filter(x => x).join(` ${LogicalOperator[group.operator]} `);
}

export function groupToFilter(group: IfThenGroupModel, fields: readonly Field[], hoists: HoistedColumn[]): string | undefined {
    if (!group.lines.length) return undefined;
    if (!group.operator) return undefined;

    const uncompiled = renderGroup(group) ?? '';
    const parserResult = parser(uncompiled, fields, functionDefs);
    const compilerResult = compiler(parserResult.root, {hoist: true});
    hoists.push(...compilerResult.hoistedColumns);
    return compilerResult.result;
}

const defaultIfThenGroup = {
    collapsed: false,
    enabled: true,
    operator: LogicalOperator.and as BinaryLogicalOperator,
    type: 'group' as 'group'
};
Object.freeze(defaultIfThenGroup);

export function renderNodeToIfThen(node: KnownAstNode): IfThenGroupModel | IfThenLineModel {
    switch (node.type) {
        case AstNodeType.paren:
            return {
                ...defaultIfThenGroup,
                id: randomString(),
                lines: node.content ? [renderNodeToIfThen(node.content)] : [{type: 'line', enabled: true, id: randomString()}]
            };
        case AstNodeType.binaryOperation:
            switch (categorizeOperator(node.operator)) {
                case OperatorType.logical:
                    return {
                        ...defaultIfThenGroup,
                        id: randomString(),
                        lines: [
                            ...(node.operand1.type === AstNodeType.binaryOperation && categorizeOperator(node.operand1.operator) === OperatorType.logical
                                ? (renderNodeToIfThen(node.operand1) as IfThenGroupModel).lines
                                : [renderNodeToIfThen(node.operand1)]
                            ),
                            ...!node.operand2 ? [] : (node.operand2.type === AstNodeType.binaryOperation && categorizeOperator(node.operand2.operator) === OperatorType.logical
                                ? (renderNodeToIfThen(node.operand2) as IfThenGroupModel).lines
                                : [renderNodeToIfThen(node.operand2)]
                            ),
                        ]
                    };
                case OperatorType.comparison:
                    return {
                        id: randomString(),
                        type: 'line',
                        enabled: true,
                        operand1: renderer(node.operand1),
                        operand2: renderer(node.operand2),
                        operator: node.operator as ComparisonOperator
                    };
                default:
                    throw new Error('Cannot render binary node because it is not logical or comparison, and thus should have already been rendered.');
            }
        case AstNodeType.column:
        case AstNodeType.number:
        case AstNodeType.string:
        case AstNodeType.regex:
        case AstNodeType.functionCall:
        case AstNodeType.unaryOperation:
            throw new Error('Cannot render scalar nodes.');
    }
}