export enum RiskScreenType {
    platforms = 'platforms',
    joinedAccounts = 'joinedAccounts',
    accounts = 'accounts',
    symbols = 'symbols',
    traders = 'traders',
    positions = 'positions',
    joinedPositions = 'joinedPositions'
}

export const RiskScreenTypeNames: ReadonlyMap<RiskScreenType, string> = new Map([
    [RiskScreenType.platforms, 'Platforms'],
    [RiskScreenType.joinedAccounts, 'Joined Accounts'],
    [RiskScreenType.accounts, 'Accounts'],
    [RiskScreenType.symbols, 'Symbols'],
    [RiskScreenType.traders, 'Traders'],
    [RiskScreenType.positions, 'Positions'],
    [RiskScreenType.joinedPositions, 'Joined Positions']
]);
Object.freeze(RiskScreenTypeNames);