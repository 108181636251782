import localforage from 'localforage';
import {empty, from, Observable} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import {KnownUniverseAspect, Universe} from '../universe/model';
import { randomString } from '../util/randomString';

export interface UniverseRecord {
    id?: string;
    name?: string;
    tableName?: string;
}

const itemMoniker = 'universes';
function getHackSavedUniverses() {
    return from(localforage.getItem<Universe[]>(itemMoniker)).pipe(
        map(xs => xs || [])
    );
}

export function getSavedUniverses(): Observable<UniverseRecord[]> {
    return getHackSavedUniverses().pipe(
        map(xs => xs.map(x => ({name: x.name, id: x.id, tableName: x.tableName})))
    );
}

export function getUniverseById(id: string): Observable<Universe | undefined> {
    return getHackSavedUniverses().pipe(
        map(xs => xs.find(x => x.id === id))
    );
}

export function saveUniverse(universe: Universe | undefined) {
    if (!universe) return empty();
    return getHackSavedUniverses().pipe(
        switchMap(xs => from(localforage.setItem(itemMoniker, [...xs.filter(x => x.id !== universe.id), {id: universe.id || randomString(), ...universe}]))),
        // tap(() => console.log('saved'))
    );
}