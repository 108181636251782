import {PremadeUniverseAspect, UniverseAspectType} from './model';

export const PremadeUniverses: ReadonlyArray<PremadeUniverseAspect & {display: string}> = [
    {type: UniverseAspectType.premade, table: 'allUniverse', display: 'All Symbols'},
    {type: UniverseAspectType.premade, table: 'SP_100', display: 'S&P 100'},
    {type: UniverseAspectType.premade, table: 'SP_500', display: 'S&P 500'},
    {type: UniverseAspectType.premade, table: 'SP_400_MidCap', display: 'S&P 400 (Mid Cap)'},
    {type: UniverseAspectType.premade, table: 'SP_600_SmallCap', display: 'S&P 600 (Small Cap)'},
    {type: UniverseAspectType.premade, table: 'Russell_1000', display: 'Russell 1000'},
    {type: UniverseAspectType.premade, table: 'Russell_3000', display: 'Russell 3000'},
    {type: UniverseAspectType.premade, table: 'DJIA', display: 'DJIA'},
    {type: UniverseAspectType.premade, table: 'NYSE_Stocks', display: 'NYSE Stocks'},
    {type: UniverseAspectType.premade, table: 'NASDAQ_Stocks', display: 'NASDAQ Stocks'},
    {type: UniverseAspectType.premade, table: 'Energy_Stocks', display: 'Energy Stocks'},
    {type: UniverseAspectType.premade, table: 'Material_Stocks', display: 'Material Stocks'},
    {type: UniverseAspectType.premade, table: 'Industrial_Stocks', display: 'Industrial Stocks'},
    {type: UniverseAspectType.premade, table: 'Consumer_Discretionary_Stocks', display: 'Consumer Discretionary Stocks'},
    {type: UniverseAspectType.premade, table: 'Consumer_Staple_Stocks', display: 'Consumer Staple Stocks'},
    {type: UniverseAspectType.premade, table: 'Health_Care_Stocks', display: 'Health Care Stocks'},
    {type: UniverseAspectType.premade, table: 'Financial_Stocks', display: 'Financial Stocks'},
    {type: UniverseAspectType.premade, table: 'Technology_Stocks', display: 'Technology Stocks'},
    {type: UniverseAspectType.premade, table: 'Telecommunication_Stocks', display: 'Telecommunication Stocks'},
    {type: UniverseAspectType.premade, table: 'Utility_Stocks', display: 'Utility Stocks'},
    {type: UniverseAspectType.premade, table: 'REITS', display: 'REITS'},
    {type: UniverseAspectType.premade, table: 'ADRs', display: 'ADRs'},
    {type: UniverseAspectType.premade, table: 'ETFs', display: 'ETFs'},
    {type: UniverseAspectType.premade, table: 'Dividend_Stocks', display: 'Dividend Stocks'},
    {type: UniverseAspectType.premade, table: 'Stocks_With_Analyst_Coverage', display: 'Stocks With Analyst Coverage'},
    {type: UniverseAspectType.premade, table: 'Stocks_With_Options', display: 'Stocks With Options'}
];