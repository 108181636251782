export function isInView(elem: HTMLElement, outer?: HTMLElement) {
    const elemBounds = elem.getBoundingClientRect();
    const outerBounds = outer ? outer.getBoundingClientRect() : {
        top: 0,
        left: 0,
        bottom: window.innerHeight || document.documentElement.clientHeight,
        right: window.innerWidth || document.documentElement.clientWidth
    };
    return (
        elemBounds.top >= outerBounds.top &&
        elemBounds.left >= outerBounds.left &&
        elemBounds.bottom <= outerBounds.bottom &&
        elemBounds.right <= outerBounds.right
    );
};

export default isInView;