import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Store } from 'reactive-state';
import { connect, ActionMap } from 'reactive-state/react';

import { AppState, filterDictionaryOptions$, FilterDictionaryOptions } from '../../state';

import Draggable from 'react-draggable';

import './filter-dictionary.scss';
import classnames from 'classnames';
import { IconButton, Tooltip, Tabs, Tab, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Checkbox } from '@material-ui/core';
import { FunctionDef, functionDefs } from '../../syntax/functions';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import { IndicatorGrouping, Indicator, IndicatorCategory, IndicatorClassType, IndicatorPackage, IndicatorClass, IndicatorGroupingKind } from '../filter-editor/model';

import FunctionTooltip from './function-tooltip';
import IndicatorTooltip from './indicator-tooltip';
import { randomString } from '../../util/randomString';
import { useToggleState } from '../../hooks/useToggleState';

import Indicators from './indicators';
import Functions from './functions';


interface Props {
    show: boolean;
    disabled: boolean;
    // groupings: IndicatorGrouping[];
    // indicators: Indicator[];
    // categories: IndicatorCategory[];
    // classTypes: IndicatorClassType[];
    // packages: IndicatorPackage[];
    // classes: IndicatorClass[];

    setFilterDictionaryOptions: (options: FilterDictionaryOptions) => void;
}

const FilterDictionary: React.FC<Props> = ({
    show, disabled, setFilterDictionaryOptions, 
    // groupings, categories, packages, classTypes, classes, indicators
}) => {

    const close = useCallback(() => setFilterDictionaryOptions({show: false, disabled: false}), [setFilterDictionaryOptions]);
    const [tab, setTab] = useState<number>(1);

    const onSelect = useCallback(field => {
        setFilterDictionaryOptions({show: true, disabled: false, selected: field});
    }, [setFilterDictionaryOptions]);
 
    if (!show) return null;

    return <Draggable cancel=".cancel" handle=".handle" >
        <div tabIndex={0} className={classnames({
            'filter-dictionary': true,
            'filter-dictionary-marker': true,
            disabled
        })}>
            <div className="container">
                <div className="handle">
                    <i className="fas fa-arrows-alt handle"/>
                </div>
                <IconButton  className="cancel" onClick={close}>
                    <i className="fas fa-times"/>
                </IconButton>
                <div className={classnames({
                    'content': true,
                })} >
                    <div className="heading">
                        <div className="caption">Dictionary</div>
                        <Tabs value={tab} onChange={(_, i) => setTab(i)}>
                            <Tab label="Functions" />
                            <Tab label="Indicators" />
                        </Tabs>
                    </div>
                    {tab === 0  && <Functions setFilterDictionaryOptions={setFilterDictionaryOptions} />}
                    {tab === 1  && <Indicators setFilterDictionaryOptions={setFilterDictionaryOptions} />}
                </div>
            </div>
        </div>
    </Draggable>;
};

const connected = connect(FilterDictionary, (store: Store<AppState>) => {
    const props = store.watch(state => ({
        show: state.filterDictionaryOptions.show,
        disabled: state.filterDictionaryOptions.disabled
    }));

    const actionMap: ActionMap<typeof FilterDictionary> = {
        setFilterDictionaryOptions: filterDictionaryOptions$
    };

    return {
        props,
        actionMap
    };
});

export {connected as FilterDictionary};
export default connected;