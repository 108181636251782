import './data-builder.scss';

import _ from 'lodash';
import React, { useCallback, useState, useEffect, useMemo, useRef } from 'react';
import FilterEditor from '../../components/filter-editor/filter-editor.new';
import { ParserResult, flattenAst, AstNode, AstNodeType, ColumnNode, NodeWithDepth } from '@thinkalpha/language-services';
import { useInputState, useNumberInputState, useStringBooleanInputState, useCheckedInputState, useInputCallback, useNumberInputCallback } from '../../hooks/useInputState';
import { TextField, IconButton, MenuItem, Checkbox, FormControlLabel, Radio, Button, RadioGroup, Card, CardHeader, DialogActions, Dialog, DialogContent, CardContent, DialogTitle, Typography, Tooltip, Menu } from '@material-ui/core';
import {CustomFormula} from './model';
import {HistoryType, Timeframe} from '../timeframe/model';
import { HistoricType, Field } from '../../components/filter-editor/model';
import {FieldDescriptor, CategoryForFieldType, MarketSession} from '@thinkalpha/table-client';
import { allStrategyFields } from '../../if-then/strategy/fields.hardcoded';
import { TimeframeComponent } from '../timeframe/timeframe';
import {DraggablePaper} from '../../components/draggable-paper';
import classNames from 'classnames';
import { useToggleState } from '../../hooks/useToggleState';

const nonBarsFunctions: readonly string[] = ['at', 'change'];
Object.freeze(nonBarsFunctions);

const validFieldNameRegex = /^[a-zA-Z_%][0-9A-Za-z_%]*$/;

export const DataBuilder: React.FC<{
    name?: string;
    fields: readonly Field[];
    formulas?: readonly CustomFormula[];
    onClose?: (column?: CustomFormula) => void;
    onDelete?: (column?: CustomFormula) => void;
}> = ({name, fields, formulas = [], onClose, onDelete}) => {
    const [formula, setFormula] = useState<string>('');
    const [parserResult, onParserResult] = useState<ParserResult>();
    const [fieldName, setFieldName, onFieldNameChanged] = useInputState<string>(name);
    const [functionName, setFunctionName, onFunctionChanged] = useInputState<string>();

    const [applyToSpecificSymbol, setApplyToSpecificSymbol, onApplyToSpecificSymbolChanged] = useCheckedInputState(false);
    const [specificSymbol,, onSpecificSymbolChanged] = useInputState<string | null>(null);

    const [premarket, setPremarket, onPremarketChanged] = useCheckedInputState(false);
    const [postmarket, setPostmarket, onPostmarketChanged] = useCheckedInputState(false);
    const [timeframe, setTimeframe] = useState<Timeframe>();

    const [loadMenuShown,, showLoadMenu, hideLoadMenu, toggleLoadMenu] = useToggleState(false);
    const loadMenuRef = useRef<HTMLButtonElement>(null);
    const formulaLoaders = useMemo(() => new Map(formulas.map(f => [f.name, () => {
        setTimeframe(f.timeframe);
        setFieldName(f.name);
        setFormula(f.formula);
        setFunctionName(f.function ?? '');
        setPremarket([MarketSession.premarket, MarketSession.fullSession, MarketSession.noAftermarket].includes(f.marketSession));
        setPostmarket([MarketSession.aftermarket, MarketSession.fullSession, MarketSession.noPremarket].includes(f.marketSession));
        hideLoadMenu();
    }])), [formulas, hideLoadMenu, setFieldName, setFunctionName, setPostmarket, setPremarket]);

    useEffect(() => {
        if (onClose === undefined) {
            // since this has been closed, clear out the state back to initial

            setFormula('');
            onParserResult(undefined);
            setFieldName('');
            setFunctionName('');
            setApplyToSpecificSymbol(false);
            setPremarket(false);
            setPostmarket(false);
            setTimeframe(undefined);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onClose]);

    const anyHistoric = useMemo(() => !parserResult ? false : _(flattenAst(parserResult.root))
        .filter((x): x is NodeWithDepth<ColumnNode> => x.type === AstNodeType.column)
        .map(x => x.field)
        .some(x => !!x && x.historic !== HistoricType.none)
    , [parserResult]);

    const anyBars = useMemo(() => !parserResult ? false : _(flattenAst(parserResult.root))
        .filter((x): x is NodeWithDepth<ColumnNode> => x.type === AstNodeType.column)
        .map(x => x.field)
        .some(x => !!x && x.historic === HistoricType.bars)
    , [parserResult]);

    useEffect(() => {
        if (!anyHistoric) {
            setFunctionName('');
        } else if (!functionName) {
            setFunctionName('short_hist_change');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [anyHistoric]);

    useEffect(() => {
        if (!anyBars) {
            if (!nonBarsFunctions.includes(functionName)) {
                setFunctionName('');
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [anyBars]);

    const validFieldName = useMemo(() => {
        if (!fieldName) return false;
        if (!fieldName.match(validFieldNameRegex)) return false;

        return true;
    }, [fieldName]);

    const valid = useMemo(() => {
        if (!validFieldName) return false;
        
        if (!formula) return false;
        if (!parserResult) return false;
        if (!parserResult.valid) return false;
        if (flattenAst(parserResult.root).some(x => !x.valid)) return false;

        if (anyHistoric && !timeframe) return false;
        if (anyHistoric && !functionName) return false;

        return true;
    }, [anyHistoric, formula, functionName, parserResult, timeframe, validFieldName]);

    const cancel = useCallback(() => {
        if (onClose) onClose();
    }, [onClose]);

    let marketSession: MarketSession;
    if (premarket && postmarket)
        marketSession = MarketSession.fullSession;
    else if (premarket)
        marketSession = MarketSession.noAftermarket;
    else if (postmarket)
        marketSession = MarketSession.noPremarket;
    else
        marketSession = MarketSession.market;

    const save = useCallback(() => {
        if (!onClose) return;
        if (!parserResult) throw new Error('Tried to save, but no parser result.');
        if (!parserResult.root) throw new Error('Tried to save, but no parser result.');

        if (anyHistoric) {
            onClose({
                timeframe,
                name: fieldName,
                formula,
                function: functionName,
                type: parserResult.root.dataType!,
                marketSession,
                key: applyToSpecificSymbol ? (specificSymbol ?? undefined) : undefined,
                sourceTable: undefined,
                historic: anyBars ? HistoricType.bars : HistoricType.history,
            });
        } else {
            onClose({
                name: fieldName,
                formula,
                type: parserResult.root.dataType!,
                sourceTable: undefined,
                key: applyToSpecificSymbol ? (specificSymbol ?? undefined) : undefined,
                marketSession,
                historic: HistoricType.none
            });
        }
    }, [anyBars, anyHistoric, applyToSpecificSymbol, fieldName, formula, functionName, marketSession, onClose, parserResult, specificSymbol, timeframe]);

    const onCancel = useCallback(() => onClose && onClose(), [onClose]);
    const nameExists = useMemo(() => formulas.some(x => x.name === fieldName), [fieldName, formulas]);

    const fieldUsers: ReadonlyMap<CustomFormula, CustomFormula[]> = useMemo(() => new Map(formulas.map(formula => [formula, formulas.filter(x => x.formula.match(`([^0-9a-zA-Z_%]|^)${formula.name}([^0-9a-zA-Z_%]|$)`))])), [formulas]);
    const fieldDeleters: ReadonlyMap<CustomFormula, () => void> = useMemo(() => new Map(formulas.map(formula => [formula, () => onDelete!(formula)])), [formulas, onDelete]);

    return <Dialog PaperComponent={DraggablePaper} className="data-builder" open={!!onClose} onClose={onCancel} maxWidth="lg" fullWidth={true}>
        <DialogActions> 
            <Button onClick={toggleLoadMenu} hidden={formulas.length === 0} variant="outlined" ref={loadMenuRef}>Load Existing&nbsp;<i className="fa fa-caret-down"/></Button>
            <Tooltip title={validFieldName ? undefined : 'Column names must begin with a letter, _, or % and contain only letters, numbers, %, or _.'}>
                <TextField
                    className={classNames({valid: validFieldName, invalid: !validFieldName})}
                    label="Column Name"
                    required
                    spellCheck={false}
                    value={fieldName}
                    onChange={onFieldNameChanged}
                    variant="outlined"
                />
            </Tooltip>
            <Menu open={loadMenuShown && formulas.length > 0} anchorOrigin={{horizontal: 'left', vertical: 'top'}} onClose={hideLoadMenu} anchorEl={loadMenuRef.current!}>
                {formulas.map(f => <MenuItem onClick={formulaLoaders.get(f.name)} key={f.name} value={f.name}>
                    {f.name}
                    {onDelete && <>&nbsp;
                        <Tooltip
                            disableFocusListener={fieldUsers.get(f)!.length <= 0}
                            disableHoverListener={fieldUsers.get(f)!.length <= 0}
                            disableTouchListener={fieldUsers.get(f)!.length <= 0}
                            title={fieldUsers.get(f)!.length <= 0
                                ? undefined
                                : <>
                                    <div>Cannot delete field <code>{f.name}</code> because the following other fields use it as part of their definitions:</div>
                                    <code><ul>
                                        {fieldUsers.get(f)!.map(fu => <li key={fu.name}>{fu.name}</li>)}
                                    </ul></code>
                                </>
                            }
                        >
                            <span><IconButton className="delete" size="small" disabled={fieldUsers.get(f)!.length > 0} onClick={fieldDeleters.get(f)!}><i className="fas fa-trash-alt"/></IconButton></span>
                        </Tooltip>
                    </>}
                </MenuItem>)}
            </Menu>
            <Button className="save" onClick={save} disabled={!valid}>{nameExists ? <>Update column</> : <>Create column</>}</Button>
            <Button className="cancel" variant="outlined" onClick={cancel}>Cancel</Button>
        </DialogActions>
        <DialogContent>
            <div className="options">
                <div className="name-section">
                    <div className="section-title">Column Builder</div>
                    <div>
                        <div className="configure">
                            <div className="expression-editor">
                                <FilterEditor noPlaceholderPromotion placeholder="Data Element or Formula" fields={fields} value={formula} onValueChange={setFormula} onParserResult={onParserResult} />
                            </div>
                            <Typography variant="caption" display="block" hidden={!formula || anyHistoric}><i className="fal fa-info-circle"/>&nbsp;You must specify at least one historic data element above to use historic controls.</Typography>
                            <TextField variant="outlined" label="Function" disabled={!anyHistoric} select value={functionName} onChange={onFunctionChanged}>
                                <MenuItem value="short_hist_at">Single Point</MenuItem>
                                <MenuItem value="count" disabled={!anyBars}>Count</MenuItem>
                                <MenuItem value="sum" disabled={!anyBars}>Sum</MenuItem>
                                <MenuItem value="min" disabled={!anyBars}>Min</MenuItem>
                                <MenuItem value="max" disabled={!anyBars}>Max</MenuItem>
                                <MenuItem value="avg" disabled={!anyBars}>Average</MenuItem>
                                <MenuItem value="short_hist_avg">Periodic Average</MenuItem>
                                <MenuItem value="stddev" disabled={!anyBars}>Standard Deviation</MenuItem>
                                <MenuItem value="short_hist_change">Change</MenuItem>
                                <MenuItem value="short_hist_pct_change">Percent Change</MenuItem>
                            </TextField>
                            <div>
                                <FormControlLabel control={
                                    <Checkbox color="primary" value="checkedA" checked={applyToSpecificSymbol} onChange={onApplyToSpecificSymbolChanged} />
                                } label="Apply To Specific Symbol" />
                                <TextField label="Symbol" variant="outlined" value={specificSymbol} onChange={onSpecificSymbolChanged} hidden={!applyToSpecificSymbol} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classNames({disabled: !anyHistoric, 'history-section': true})}>
                    <div className="section-title">History &amp; Time Settings </div>
                    <div>
                        <div className="history">
                            <TimeframeComponent
                                disabled={!anyHistoric}
                                value={timeframe}
                                onChange={setTimeframe}
                                range={functionName !== 'short_hist_at' && functionName !== 'short_hist_avg'}
                                bars={anyBars}
                            />
                        </div>
                        <div className="market-switches">
                            <FormControlLabel disabled={!anyHistoric} control={
                                <Checkbox color="primary" checked={premarket} onChange={onPremarketChanged} />
                            } label="Include Premarket" />
                            <FormControlLabel disabled={!anyHistoric} control={
                                <Checkbox color="primary" checked={postmarket} onChange={onPostmarketChanged} />
                            } label="Include Postmarket" />
                        </div>
                    </div>
                </div>
            </div>
        </DialogContent>
    </Dialog>;
};

export const DataBuilderDemo: React.FC = ({}) => {
    const onClosed = useCallback((col: CustomFormula) => {
        // eslint-disable-next-line no-console
        // console.log('Custom column generated:', col);
    }, []);

    return <DataBuilder onClose={onClosed} fields={allStrategyFields} />;
};

export function descriptorToField(desc: FieldDescriptor) {
    return {
        name: desc.name,
        description: desc.description,
        historic: HistoricType.none,
        type: CategoryForFieldType.get(desc.type)!,
        sourceTable: undefined
    };
}