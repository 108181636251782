import React, {useCallback, useState, useMemo, useRef} from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
// import { Store } from 'reactive-state';
// import { ActionMap, connect } from 'reactive-state/react';

import { Tab, Tabs, AppBar, Toolbar, IconButton, Typography, Button, Avatar, Menu, MenuItem } from '@material-ui/core';
import {LinkButton} from '../components/linkButton';

import './link-bar.scss';
import { useToggleState } from '../hooks/useToggleState';

interface Props {
    onMenu?: () => void;
}

const LinkBar: React.FunctionComponent<RouteComponentProps & Props> = ({onMenu, location, history, children}) => {
    const [userMenuShown, , showUserMenu, hideUserMenu, toggleUserMenu] = useToggleState(false);
    const userMenuTriggerRef = useRef<HTMLButtonElement>(null);

    const logout = useCallback(() => history.push('/logout'), [history]);

    return <>
        <AppBar position="static" color="primary">
            <Toolbar variant="dense" className="thirds">
                <div className="start">
                    {onMenu && <IconButton edge="start" onClick={onMenu}><i className="fas fa-bars"/></IconButton>}
                    <Typography>Think&nbsp;Alpha</Typography>
                </div>

                {/* <IconButton>
                    <Avatar alt="ThinkAlpha" src={require('../assets/favicon/mstile-150x150.png')} />
                </IconButton> */}
                <div className="middle">
                    {/* <LinkButton to="/dashboard" color="inherit">Home</LinkButton>
                    <LinkButton to="/if-then" color="inherit">If/Then Strategy Builder</LinkButton>
                    <LinkButton to="/wizard" color="inherit">Strategy Builder Wizard</LinkButton>
                    <LinkButton to="/risk" color="inherit">Risk</LinkButton> */}
                    {children}
                </div>

                <div className="end">
                    <IconButton edge="end" ref={userMenuTriggerRef} onClick={toggleUserMenu}><i className="fas fa-user-circle"/></IconButton>
                </div>
            </Toolbar>
        </AppBar>
        <Menu anchorEl={userMenuTriggerRef.current} onClose={hideUserMenu} open={userMenuShown} anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
            <MenuItem onClick={logout}>Logout</MenuItem>
        </Menu>
    </>;
};

const routed = withRouter(LinkBar);
export {routed as LinkBar};
export default routed;