import React, { useCallback, useState } from 'react';

import {  Dialog, DialogContent, DialogActions, Button, DialogTitle, CircularProgress, Table, TableHead, TableCell, TableRow, TableBody } from '@material-ui/core';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

import './backtest-report.scss';

interface Props {
    onClose: () => void;
    report: any;
}

const colorStyle = {color: '#FFFFFF'};

const legendOptions: Highcharts.Options = {
    legend: {
        layout: 'vertical',
        align: 'right',
        x: 20,
        verticalAlign: 'top',
        y: 20,
        floating: true,
        backgroundColor: 'rgba(255,255,255,0.25)',
        itemStyle: colorStyle
    }
};

const buildChartOptions = (height, width?) => ({
    chart: {
        height,
        width
    },
});


const buildTitleText = (text) => ({
    title: {
        text,
        style: colorStyle
    },
});

const buildAxisPercentageFormat = (): Highcharts.YAxisOptions => ({
    labels: {
        format: '{value} %'
    }
});

const buildPercentageTooltip = () => ({
    tooltip: {
        valueSuffix: '%',
        valueDecimals: 2,
    }
});

const tooltipProps: Highcharts.Options = {
    tooltip: {
        style: colorStyle,
        shared: true
    },
};

const dateTimeAxis: Highcharts.XAxisOptions = {
    type: 'datetime',
    dateTimeLabelFormats: { 
        year: '%Y'
    },
    tickInterval: Date.UTC(2010, 0, 1) - Date.UTC(2009, 0, 1)
};

const buildMRRAPEOptions = (report): Highcharts.Options => {
    return {
        ...buildTitleText('Market Relative Returns After Position Entry'),
        xAxis: [{
            crosshair: true,
            ...buildTitleText('Trading Days After Position Entry'),
        }],
        yAxis: [{
            ...buildTitleText('Avg Market Relative Position Return'),
            ...buildAxisPercentageFormat()
        }, {
            ...buildTitleText('Std Devs of Returns'),
            ...buildAxisPercentageFormat(),
            opposite: true
        }],
        ...tooltipProps,
        series: [{
            name: 'Average Position Return (u)',
            type: 'line',
            yAxis: 0,
            data: report.allaround1.series1,
            ...buildPercentageTooltip()
        }, {
            name: 'Standard Deviation of Returns (σ)',
            type: 'line',
            data: report.allaround1.series2,
            yAxis: 1,
            ...buildPercentageTooltip()
        },  {
            name: 'Downside Standard Deviation (↓σ)',
            type: 'line',
            data: report.allaround1.series3,
            yAxis: 1,
            ...buildPercentageTooltip()
        }],
        ...tooltipProps,
        ...legendOptions,
        ...buildChartOptions(400)
    };
};

const buildMRRAPEAOptions = (report): Highcharts.Options => {
    return {
        ...buildTitleText('Market Relative Returns After Position Entry Adjusted for Downside Risk'),
        xAxis: [{
            crosshair: true,
            ...buildTitleText('Trading Days After Position Entry'),
        }],
        yAxis: [{
            ...buildTitleText('Avg Risk adjusted return'),
        }, {
            ...buildTitleText('T Statistic'),
            opposite: true
        }],
        ...tooltipProps,
        series: [{
            name: 'Optimal Horizon',
            type: 'line',
            yAxis: 0,
            data: report.allaround2.series1,
        }, {
            name: 'Risk Adjusted Average Return = u ÷ ↓σ',
            type: 'line',
            data: report.allaround2.series2,
            yAxis: 1
        }],
        ...legendOptions,
        ...buildChartOptions(400)
    };
};

const buildMRHWLROptions = (report): Highcharts.Options => {
    return {
        ...buildTitleText('Market Relative Hit rates & Win-Loss Ratios Afater position entry'),
        xAxis: [{
            crosshair: true,
            ...buildTitleText('Trading Days After Position Entry'),
        }],
        yAxis: [{
            ...buildTitleText('Hit Entry'),
            ...buildAxisPercentageFormat()
        }, {
            ...buildTitleText('Win-Loss ratios'),
            opposite: true
        }],
        series: [{
            name: 'Hit rates',
            type: 'line',
            yAxis: 0,
            data: report.allaround3.series1,
            ...buildPercentageTooltip()
        }, {
            name: 'Win-loss rates',
            type: 'line',
            data: report.allaround3.series2,
            yAxis: 1
        }],
        ...tooltipProps,
        ...legendOptions,
        ...buildChartOptions(400)
    };
};

const buildMRKROptions = (report): Highcharts.Options => {
    return {
        ...buildTitleText('Market Relative Kelly Ratios = Hit Rate - (1-Hit Rate ) ÷ Win-Loss Ratio'),
        xAxis: [{
            crosshair: true,
            ...buildTitleText('Trading Days After Position Entry'),
        }],
        yAxis: [{
            ...buildTitleText('Kelly ratio'),
            ...buildAxisPercentageFormat()
        }],
        series: [{
            name: 'Hit rates',
            type: 'line',
            data: report.allaround4.series1,
            ...buildPercentageTooltip()
        }],
        ...tooltipProps,
        ...legendOptions,
        ...buildChartOptions(400)
    };
};

const buildMRKRAOptions = (report): Highcharts.Options => {
    return {
        ...buildTitleText('Market Relative Returns After Position Entry Adjusted for Downside Risk ( u ÷ ↓σ)'),
        xAxis: [{
            crosshair: true,
            ...buildTitleText('Trading Days After Position Entry'),
        }],
        yAxis: [{
            ...buildTitleText('Avg risk adjusted ratio'),
        }],
        series: [{
            name: 'In-Sample Positions',
            type: 'line',
            data: report.allaround5.series1,
        }, {
            name: 'Out-of-Sample Positions',
            type: 'line',
            data: report.allaround5.series2,
        }],
        ...tooltipProps,
        ...legendOptions,
        ...buildChartOptions(400)
    };
};


const buildMRROptions = (report): Highcharts.Options => {
    return {
        ...buildTitleText('Market Relative Returns After Position Entry'),
        xAxis: [{
            crosshair: true,
            ...buildTitleText('Trading Days After Position Entry'),
        }],
        yAxis: [{
            ...buildTitleText('Average Market RelativeReturn'),
            ...buildAxisPercentageFormat()
        }, {
            ...buildTitleText('Risk Adjusted Return'),
            opposite: true
        }],
        series: [{
            name: 'Average Position Retuns (μ)',
            type: 'line',
            yAxis: 0,
            data: report.allaround6.series1,
            ...buildPercentageTooltip()
        }, {
            name: 'Risk Adjusted Returns  (μ/↓σ)',
            type: 'line',
            data: report.allaround6.series2,
            yAxis: 1
        }],
        ...tooltipProps,
        ...legendOptions,
        ...buildChartOptions(400)
    };
};

const pm4TableRowNames = [
    'Average Market Relative Position Return',
    'Market Relative Return Adjusted for Downside Risk',
    'Market Relative Hit Rate',
    'Market Relative Win-Loss Ratio',
    'Market Relative Kelly Ratio',
    'Percentage of Up Years (Out of 12)',
    'T Stat for the 12 Annual Risk-Adjusted Returns (μ/↓σ)',
    'T Statistic for the 12 Annual Kelly Ratios',
];

const PM4Table = ({rows}) => <Table className="backtest-table">
    <TableHead>
        <TableRow>
            <TableCell>Performance Metrics 4 Trading Days After Position Entry</TableCell>
            <TableCell>All Positions</TableCell>
            <TableCell>In-Sample Positions</TableCell>
            <TableCell>Out-of-Sample Positions</TableCell>
            <TableCell>Difference Out - In</TableCell>
            <TableCell>% Difference (Out-In)/ In</TableCell>
        </TableRow>
    </TableHead>
    {rows.map((row, i) => <TableRow key={i}>
        <TableCell className="init">{pm4TableRowNames[i]}</TableCell>
        {row.map((cell, j) => <TableCell key={j}>{
            [0, 2, 4, 5].includes(i) || j === 4 ? `${(Number(cell)*100).toFixed(2)}%` : Number(cell).toFixed(2)
        }</TableCell>)}
    </TableRow>)}
</Table>;

const pmTableRowNames = [
    'Holding Period (Trading Days)',
    'Total Number of Positions',
    'Average Number of New Positions Per Year',
    'Average Market Relative Position Return',
    'Average Annualized Market Relative Position Return',
    'Market Relative Return Adjusted for Downside Risk',
    'Market Relative Kelly Ratio',
    'Percentage of Up Years (Out of 12)',
    'T Stat for the 12 Annual Risk-Adjusted Returns (μ/↓σ)',
    'T Statistic for the 12 Annual Kelly Ratios'
];

const PMTable = ({rows}) => <Table className="backtest-table">
    <TableHead>
        <TableRow>
            <TableCell>Performance Metric</TableCell>
            <TableCell>Optimized Horizon</TableCell>
            <TableCell>Specified Horizon</TableCell>
            <TableCell>Difference</TableCell>
        </TableRow>
    </TableHead>
    {rows.map((row, i) => <TableRow key={i}>
        <TableCell className="init">{pmTableRowNames[i]}</TableCell>
        {row.map((cell, j) => <TableCell key={j}>{
            [3, 4, 6, 7].includes(i) ? `${(Number(cell)*100).toFixed(2)}%` : Number(cell).toFixed(2)
        }</TableCell>)}
    </TableRow>)}
</Table>;

const buildSimpleTooltip = (pre: string, percentage?: boolean) => {
    return {
        tooltip: {
            pointFormatter: function() {
                return `${pre}: ${((this as any).y).toFixed(3)}${percentage ? '%' : ''}`;
            },
        },

    };
};

const buildRAMaxOptions = (report): Highcharts.Options => {
    return {
        ...buildTitleText('Number of Trading Days When theRisk-Adjusted  Return Reaches a Maximum'),
        xAxis: [{
            crosshair: true,
        }],
        yAxis: [{
            ...buildTitleText('Number of Trading Days'),
        }],
        series: [{
            // name: 'In-Sample Positions',
            type: 'column',
            data: report.yearper1.series1,
            ...buildSimpleTooltip('Count'),
            showInLegend: false
        }],
        ...tooltipProps,
        ...legendOptions,
        ...buildChartOptions(350)
    };
};

const buildRAROptions = (report): Highcharts.Options => {
    return {
        ...buildTitleText('Risk Adjusted Average Return: Value at 4 Trading Days Versus Value at Optimized Horizon'),
        xAxis: [{
            crosshair: true,
        }],
        yAxis: [{
            ...buildTitleText('Risk Adjusted Return'),
        }],
        series: [{
            name: '4 Trading Days',
            type: 'column',
            data: report.yearper2.series1,
        }, {
            name: 'Optimized Horizon',
            type: 'column',
            data: report.yearper2.series2,
        }],
        ...tooltipProps,
        ...legendOptions,
        ...buildChartOptions(350)
    };
};

const buildKRMaxOptions = (report): Highcharts.Options => {
    return {
        ...buildTitleText('Number of Trading Days When the Kelly Ratio Reaches a Maximum'),
        xAxis: [{
            crosshair: true,
        }],
        yAxis: [{
            ...buildTitleText('Number of Trading Days'),
        }],
        series: [{
            type: 'column',
            data: report.yearper3.series1,
            ...buildSimpleTooltip('Count'),
            showInLegend: false
        }],
        ...tooltipProps,
        ...legendOptions,
        ...buildChartOptions(350)
    };
};

const buildKROptions = (report): Highcharts.Options => {
    return {
        ...buildTitleText('Kelly Ratio Value at 4 Trading Days Versus Value at Optimized Horizon'),
        xAxis: [{
            crosshair: true,
        }],
        yAxis: [{
            ...buildTitleText('Risk Adjusted Return'),
        }],
        series: [{
            name: '4 Trading Days',
            type: 'column',
            data: report.yearper4.series1,
        }, {
            name: 'Optimized Horizon',
            type: 'column',
            data: report.yearper4.series2,
        }],
        ...tooltipProps,
        ...legendOptions,
        ...buildChartOptions(350)
    };
};

const buildPETOptions = (report): Highcharts.Options => {
    return {
        chart: {
            type: 'scatter',
            zoomType: 'xy'
        },    
        ...buildTitleText('Position Entry Trades Across Time'),
        xAxis: [dateTimeAxis],
        yAxis: [{
            ...buildTitleText('Market Relative Return 4 Trading Days After Position Entry'),
        }],
        series: [{
            name: 'Trade',
            type: 'scatter',
            data: report.numtable1.series1,
            showInLegend: false,
        }],
        plotOptions: {
            scatter: {
                marker: {
                    radius: 3,
                },
            }
        },
    };
};

const buildSPYOptions = (report): Highcharts.Options => {
    return {
        ...buildTitleText('Number of Signals and Positions Generated Each Year'),
        xAxis: [{
            crosshair: true,
        }],
        yAxis: [{
        }],
        series: [{
            name: 'Number of Signals',
            type: 'column',
            data: report.yearper5.series1,
        }, {
            name: 'Number of Positions',
            type: 'column',
            data: report.yearper5.series2,
        }],
        ...tooltipProps,
        ...legendOptions,
        ...buildChartOptions(400)
    };
};

const buildAMRROptions = (report): Highcharts.Options => {
    return {
        ...buildTitleText('Average Market Relative Return on All Positions4 Days After Position Entry  ( μ )'),
        xAxis: [{
            crosshair: true,
        }],
        yAxis: [{
            ...buildTitleText('Market Relative Return'),
            ...buildAxisPercentageFormat()
        }],
        series: [{
            type: 'column',
            data: report.yearper6.series1,
            ...buildSimpleTooltip('Return', true),
            showInLegend: false
        }],
        ...tooltipProps,
        ...buildChartOptions(350)
    };
};

const buildSDOROptions = (report): Highcharts.Options => {
    return {
        ...buildTitleText('Standard Deviation of Returns4 Trading Days After Position Entry  (σ,↓σ )'),
        xAxis: [{
            crosshair: true,
        }],
        yAxis: [{
            ...buildTitleText('Standard Deviations'),
        }],
        series: [{
            name: 'Standard Deviation (σ)',
            type: 'column',
            data: report.yearper7.series1,
        }, {
            name: 'Semi-Std Deviation (↓σ)',
            type: 'column',
            data: report.yearper7.series2,
        }],
        ...tooltipProps,
        ...legendOptions,
        ...buildChartOptions(350)
    };
};

const buildAMRPROptions = (report): Highcharts.Options => {
    return {
        ...buildTitleText('Average Market Relative Position ReturnsAdjusted  for Downside Risk'),
        xAxis: [{
            crosshair: true,
        }],
        yAxis: [{
            ...buildTitleText('Risk-Adjusted Return'),
        }],
        series: [{
            type: 'column',
            data: report.yearper8.series1,
            ...buildSimpleTooltip('Risk-Adjusted Return'),
            showInLegend: false
        }],
        ...tooltipProps,
        ...buildChartOptions(350)
    };
};

const buildTSOptions = (report): Highcharts.Options => {
    return {
        ...buildTitleText('T Statistics for the Risk-Adjusted  Return( √N x  μ / ↓σ )'),
        xAxis: [{
            crosshair: true,
        }],
        yAxis: [{
            ...buildTitleText('Risk-Adjusted Return'),
        }],
        series: [{
            name: '4 Trading Days',
            type: 'column',
            data: report.yearper9.series1,
            ...buildSimpleTooltip('Risk-Adjusted Return'),
            showInLegend: false
        }],
        ...tooltipProps,
        ...buildChartOptions(350)
    };
};

const buildMNPOptions = (report): Highcharts.Options => {
    return {
        ...buildTitleText('Market Neutral Portfolio (β≈0)'),
        xAxis: [{
            crosshair: true,
            ...dateTimeAxis
        }],
        yAxis: [{
            ...buildTitleText('Total Cumulative Return  (%)'),
            ...buildAxisPercentageFormat()
        }, {
            opposite: true,
            min: 0,
            max: 1
        }],
        ...tooltipProps,
        series: [{
            name: 'Market Neutral Portfolio',
            type: 'line',
            data: report.timesers1.series1,
            yAxis: 0,
            ...buildPercentageTooltip(),
            zIndex: 2,
        }, {
            name: 'Market Neutral Benchmark (SPX)',
            type: 'line',
            data: report.timesers1.series2,
            yAxis: 0,
            ...buildPercentageTooltip(),
            zIndex: 2
        }, {
            name: 'Open Positions > 5',
            type: 'column',
            data: report.timesers1.series3,
            yAxis: 1,
            zIndex: 1,
            enableMouseTracking: false,
            opacity: 0.2
        }],
        ...tooltipProps,
        ...legendOptions,
        ...buildChartOptions(400)
    };
};

const buildNAPOptions = (report): Highcharts.Options => {
    return {
        ...buildTitleText('Number of Open Active Positions'),
        xAxis: [{
            crosshair: true,
            ...dateTimeAxis
        }],
        yAxis: [{
            ...buildTitleText('Open positions'),
        }, {
            opposite: true,
            min: 0,
            max: 1,
            labels: {
                enabled: false
            }
        }],
        ...tooltipProps,
        series: [{
            name: 'Market Neutral Portfolio',
            type: 'column',
            data: report.timesers2.series1,
            yAxis: 0,
            zIndex: 2,
        }, {
            name: 'Market Neutral Benchmark (SPX)',
            type: 'column',
            data: report.timesers2.series2,
            yAxis: 0,
            zIndex: 2
        }, {
            name: 'Open Positions > 5',
            type: 'column',
            data: report.timesers2.series3,
            yAxis: 1,
            zIndex: 1,
            enableMouseTracking: false,
            opacity: 0.2
        }],
        ...tooltipProps,
        ...legendOptions,
        ...buildChartOptions(400)
    };
};

const buildACTPORT1Options = (report): Highcharts.Options => {
    return {
        ...buildTitleText('Market Neutral Portfolio (β≈0)'),
        xAxis: [{
            crosshair: true,
        }],
        yAxis: [{
            ...buildTitleText('Total Cumulative Return  (%)'),
            ...buildAxisPercentageFormat()
        }],
        ...tooltipProps,
        series: [{
            name: 'Market Neutral Portfolio',
            type: 'line',
            data: report.actport1.series1,
            ...buildPercentageTooltip(),
        }, {
            name: 'Market Neutral Benchmark (SPX)',
            type: 'line',
            data: report.actport1.series2,
            ...buildPercentageTooltip(),
        }],
        ...tooltipProps,
        ...legendOptions,
        ...buildChartOptions(400)
    };
};

const buildACTPORT2Options = (report): Highcharts.Options => {
    return {
        ...buildTitleText('Number of Open Active Positions'),
        xAxis: [{
            crosshair: true,
        }],
        yAxis: [{
            ...buildTitleText('Open positions'),
        }],
        ...tooltipProps,
        series: [{
            name: 'Market Neutral Portfolio',
            type: 'column',
            data: report.actport2.series1,
        }, {
            name: 'Market Neutral Benchmark (SPX)',
            type: 'column',
            data: report.actport2.series2,
        }],
        ...tooltipProps,
        ...legendOptions,
        ...buildChartOptions(400)
    };
};

const BasicTable = ({rows, columns, name}) => <Table className={`backtest-table ${name}`}>
    <TableHead>
        <TableRow>
            {columns.map(column=> <TableCell key={column}>{column}</TableCell>)}
        </TableRow>
    </TableHead>
    {rows.map((row, i) => <TableRow key={i}>
        {row.map((cell, j) => <TableCell key={j}>{cell}</TableCell>)}
    </TableRow>)}
</Table>;

const InputTablePair = ({name, value}) => <TableCell className="pair">
    <div>{name}</div>
    <div>{value}</div>
</TableCell>;

const InputTable = () => <Table className={`backtest-table input`}>
    <TableHead>
        <TableRow>
            <TableCell>Series 18912 Quintile 5 with VIX Band: Daily Trading</TableCell>
        </TableRow>
    </TableHead>
    <TableRow className="body-row">
        <InputTablePair name='Type of Strategy:' value='Avatar Long Strategies' />
        <InputTablePair name='Number of Conditions: 11' value='11' />
        <InputTablePair name='Investable Universe:' value='us_sp1500' />
    </TableRow>
    <TableRow className="body-row">
        <InputTablePair name='Model Direction:' value='Long' />
        <InputTablePair name='Num ber of Variables:' value='7' />
        <InputTablePair name='Size of Universe:' value='1501' />
    </TableRow>
    <TableRow className="body-row">
        <InputTablePair name='Holding Period:' value='4 Trading Days' />
        <InputTablePair name='Number of Constraints:' value='11' />
        <InputTablePair name='Evaluation Period:' value='04/28/208 - 12/30/2019' />
    </TableRow>
    <TableRow className="body-row">
        <InputTablePair name='Signal Frequency:' value='552 Per Year' />
        <InputTablePair name='Types of Conditions:' value='Intersections Only' />
        <InputTablePair name='Length of Backtest:' value='11.7 Years' />
    </TableRow>
</Table>;

const roundFunc = (n: number) => Number(Number(n).toFixed(2));
const percentageFunc = (n: number) => `${roundFunc(n*100)}%`;

const propRefTableHelper = [
    [{name: 'Annualized Return', transform: percentageFunc}, {name: 'Annualized Return Relative to the SPX', transform: percentageFunc}, {name: 'Annualized Volatility', transform: percentageFunc}], 
    [{name: 'Weekdays Per Year Portfolio Was Active', transform: roundFunc}, {name: 'Daily Average Number of Open Positions', transform: roundFunc}, {name: 'Percentage of Months With Positive Returns', transform: percentageFunc}],
    [{name: 'Max Drawdown', transform: percentageFunc}, {name: 'Sharpe Ratio', transform: roundFunc}, {name: 'Sortino Ratio', transform: roundFunc}],
];

const PropTablePair = ({name, value}) => <div className="pair">
    <div>{name}</div>
    <div>{value}</div>
</div>;

const PropRefTable = ({rows}) => <div className='prop-table'>
    {rows.map((row, i) => <div key={i} className="prop-column">
        {row.map((field, j) => <PropTablePair key={j} name={propRefTableHelper[i][j].name} value={propRefTableHelper[i][j].transform(field)} />)}
    </div>)}
</div>;

const buildCNMSOptions = (report): Highcharts.Options => {
    return {
        chart: {
            height: 400,
            type: 'area'
        },
        ...buildTitleText('Number of Open Active Positions'),
        xAxis: [{
            crosshair: true,
            ...dateTimeAxis
        }],
        yAxis: [{
            ...buildTitleText('Number of signals'),
        }],
        ...tooltipProps,
        series: [{
            type: 'area',
            data: report.timesers3.series1,
            showInLegend: false,
            
            tooltip: {
                headerFormat: '',
                pointFormatter: function() {
                    return `Date: ${new Date(this.x).toISOString().substr(0, 10)} Count: ${this.y}`;
                }
            },
        }],
        // ...buildChartOptions(400)
    };
};

const buildNOSOptions = (report): Highcharts.Options => {
    return {
        ...buildTitleText('Numberof Signals Generated Each Year'),
        xAxis: [{
            crosshair: true,
        }],
        yAxis: [{
            ...buildTitleText('Number of singals'),
        }],
        series: [{
            name: 'In-sample',
            type: 'column',
            data: report.yearper10.series1,
        }, {
            name: 'Out-of-sample',
            type: 'column',
            data: report.yearper10.series2,
        }],
        plotOptions: {
            column: {
                stacking: 'normal',
            }
        },
        ...tooltipProps,
        ...legendOptions,
        ...buildChartOptions(400)
    };
};

const buildBefore1Options = (report): Highcharts.Options => {
    return {
        ...buildTitleText('Market Relative Returns Before Position Entry'),
        xAxis: [{
            crosshair: true,
            ...buildTitleText('Trading Days After Position Entry'),
            reversed: true
        }],
        yAxis: [{
            ...buildAxisPercentageFormat(),
            title: {
                text: ''
            }
        }],
        series: [{
            name: 'Average Position Return (u)',
            type: 'line',
            data: report.before1.series1.map(pair => [-pair[0], pair[1]]),
            ...buildPercentageTooltip(),
            showInLegend: false
        }],
        ...tooltipProps,
        ...legendOptions,
        ...buildChartOptions(400)
    };
};

const buildBefore2Options = (report): Highcharts.Options => {
    return {
        ...buildTitleText(`Daily Volume (σ's Above Trailing 90 Day Average)`),
        xAxis: [{
            crosshair: true,
            ...buildTitleText('Trading Days After Position Entry'),
            reversed: true
        }],
        yAxis: [{
            title: {
                text: ''
            }
        }],
        series: [{
            name: 'Daily volume',
            type: 'area',
            data: report.before2.series1.map(pair => [-pair[0], pair[1]]),
            showInLegend: false
        }],
        ...tooltipProps,
        ...legendOptions,
        ...buildChartOptions(400)
    };
};

const buildCalendarYearReturnOptions = (report, n, year): Highcharts.Options => {
    return {
        ...buildTitleText(`Calendar Year ${year} Average Market Relative Return After Position Entry`),
        xAxis: [{
            crosshair: true,
            ...buildTitleText('Trading Days After Position Entry'),
        }],
        yAxis: [{
            ...buildTitleText('Avg Position Return'),
            ...buildAxisPercentageFormat()
        }, {
            ...buildTitleText('Risk adjusted return'),
            opposite: true
        }],
        ...tooltipProps,
        series: [{
            name: 'Avg Position Return',
            type: 'line',
            yAxis: 0,
            data: report[`allaround${n}`].series1,
            ...buildPercentageTooltip()
        }, {
            name: 'Risk Adjusted Return',
            type: 'line',
            data: report[`allaround${n}`].series2,
            yAxis: 1,
        }],
        ...tooltipProps,
        ...legendOptions,
        ...buildChartOptions(350)
    };
};

const BacktestReport: React.FC<Props> = ({ onClose, report }) => {

    return <Dialog className="backtest-settings" onClose={onClose} open={true}>

        <DialogTitle className="header">
            Backtest Report
        </DialogTitle>
        <DialogContent>
            <InputTable />
            <div className="table-row">
                <BasicTable rows={report.conditions} name="conditions" columns={['Condition Number', 'Condition 1 Type', 'Description of the Condition', 'Condition Statement']} />
                <BasicTable rows={report.constants} name="constants" columns={['Constant lablel', 'Value']} />
            </div>
            <BasicTable rows={report.variables} name="variables" columns={['Variable lablel', 'Description of the variable']} />

            <HighchartsReact highcharts={Highcharts} options={buildCNMSOptions(report)} />
            <HighchartsReact highcharts={Highcharts} options={buildNOSOptions(report)} />

            <HighchartsReact highcharts={Highcharts} options={buildBefore1Options(report)} />
            <HighchartsReact highcharts={Highcharts} options={buildBefore2Options(report)} />
            
            <HighchartsReact highcharts={Highcharts} options={buildMRRAPEOptions(report)} />
            <HighchartsReact highcharts={Highcharts} options={buildMRRAPEAOptions(report)} />
            <HighchartsReact highcharts={Highcharts} options={buildMRHWLROptions(report)} />
            <HighchartsReact highcharts={Highcharts} options={buildMRKROptions(report)} />
            <HighchartsReact highcharts={Highcharts} options={buildMRKRAOptions(report)} />
            <PM4Table rows={report.pm4} />
            <HighchartsReact highcharts={Highcharts} options={buildMRROptions(report)} />
            <PMTable rows={report.pm} />
            <div className="quatro">
                <div className="row">
                    <HighchartsReact highcharts={Highcharts} options={buildRAMaxOptions(report)} />
                    <HighchartsReact highcharts={Highcharts} options={buildRAROptions(report)} />
                </div>
                <div className="row">
                    <HighchartsReact highcharts={Highcharts} options={buildKRMaxOptions(report)} />
                    <HighchartsReact highcharts={Highcharts} options={buildKROptions(report)} />
                </div>
            </div>
            <HighchartsReact highcharts={Highcharts} options={buildPETOptions(report)} />

            <HighchartsReact highcharts={Highcharts} options={buildSPYOptions(report)} />
            <div className="quatro">
                <div className="row">
                    <HighchartsReact highcharts={Highcharts} options={buildAMRROptions(report)} />
                    <HighchartsReact highcharts={Highcharts} options={buildSDOROptions(report)} />
                </div>
                <div className="row">
                    <HighchartsReact highcharts={Highcharts} options={buildAMRPROptions(report)} />
                    <HighchartsReact highcharts={Highcharts} options={buildTSOptions(report)} />
                </div>
            </div>
            <HighchartsReact highcharts={Highcharts} options={buildMNPOptions(report)} />
            <HighchartsReact highcharts={Highcharts} options={buildNAPOptions(report)} />

            <PropRefTable rows={report.porperf1} />

            <HighchartsReact highcharts={Highcharts} options={buildACTPORT1Options(report)} />
            <HighchartsReact highcharts={Highcharts} options={buildACTPORT2Options(report)} />
            
            <PropRefTable rows={report.porperf2} />

            <div className="quatro">
                <div className="row">
                    <HighchartsReact highcharts={Highcharts} options={buildCalendarYearReturnOptions(report, 7, 2019)} />
                    <HighchartsReact highcharts={Highcharts} options={buildCalendarYearReturnOptions(report, 8, 2018)} />
                </div>
                <div className="row">
                    <HighchartsReact highcharts={Highcharts} options={buildCalendarYearReturnOptions(report, 9, 2017)} />
                    <HighchartsReact highcharts={Highcharts} options={buildCalendarYearReturnOptions(report, 10, 2016)} />
                </div>
            </div>

            <div className="quatro">
                <div className="row">
                    <HighchartsReact highcharts={Highcharts} options={buildCalendarYearReturnOptions(report, 11, 2015)} />
                    <HighchartsReact highcharts={Highcharts} options={buildCalendarYearReturnOptions(report, 12, 2014)} />
                </div>
                <div className="row">
                    <HighchartsReact highcharts={Highcharts} options={buildCalendarYearReturnOptions(report, 13, 2013)} />
                    <HighchartsReact highcharts={Highcharts} options={buildCalendarYearReturnOptions(report, 14, 2012)} />
                </div>
            </div>

        </DialogContent>
        {/* <DialogActions>
            <Button color="primary" onClick={save}>Save</Button>
            <Button color="secondary" onClick={cancel}>Cancel</Button>
        </DialogActions> */}
    </Dialog>;
};

export default BacktestReport;
