import './actions-menu.scss';

import loglevel from 'loglevel';
const log = loglevel.getLogger('strategy-line');

import React from 'react';


import { MenuItem, Menu, Button } from '@material-ui/core';


type Props = {
    onCopy: () => void;
    onPaste: () => void;
};

export const ActionsMenu: React.FC<Props> = ({onCopy, onPaste}) => {
    const [actionMenuAnchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseActionsMenu = () => {
        setAnchorEl(null);
    };

    const onCopyHandler = () => {
        handleCloseActionsMenu();
        onCopy();
    };

    const onPasteHandler = () => {
        handleCloseActionsMenu();
        onPaste();
    };


    return (
        <div id="if">
            <div className="text">If</div>
            <Button onClick={handleClick}><i className="fal fa-bars"/></Button>
            <Menu
                id="simple-menu"
                anchorEl={actionMenuAnchorEl}
                keepMounted
                open={Boolean(actionMenuAnchorEl)}
                onClose={handleCloseActionsMenu}
            >
                <MenuItem onClick={onCopyHandler}>Copy</MenuItem>
                {/* Implement disabled as prop */}
                <MenuItem disabled={false} onClick={onPasteHandler}>Paste</MenuItem>
            </Menu>
        </div>
    );
};

export default ActionsMenu;