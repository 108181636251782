import { FieldTypeCategory, Key } from '@thinkalpha/table-client';
import { CustomFormula } from '../components/data-builder/model';
import { LogicalOperator, ComparisonOperator, BinaryLogicalOperator } from '@thinkalpha/language-services';

export type Operand = string;

export interface IfThenPart {
    enabled: boolean;
    id: string;    
}

export const DRAG_MODEL_TYPE = 'condition';

export interface DragModel {
    model: IfThenGroupModel | IfThenLineDraftModel;
    type: string;
    onDragLeave: () => void;
    onDragDone: () => void;
    height?: number;
    dropZoneId?: string;
    depth: number;
    clearDragPlaceholder: () => void;
}

export type DropPosition = 'top' | 'bottom';

export interface DragPreview {
    lineId: string;
    position: DropPosition;
    height: number;
}

export interface DragProps {
    onDragLeave: (id: string) => void;
    onDragDone: (id: string) => void;
    onDragStart: (preview: DragPreview) => void;
    clearDragPlaceholder: () => void;
}

export interface IfThenLineModel extends IfThenPart {
    type: 'line';
    operand1: Operand;
    operator: ComparisonOperator;
    operand2: Operand;
}

export interface IfThenLineDraftModel extends IfThenPart {
    type: 'line';
    operand1?: Operand;
    operator?: ComparisonOperator;
    operand2?: Operand;
}

export interface IfThenLinePlaceholder extends IfThenPart {
    type: 'line';
}

export interface IfThenGroupModel extends IfThenPart {
    type: 'group';
    collapsed: boolean;
    operator: BinaryLogicalOperator;
    lines: (IfThenLineModel | IfThenGroupModel | IfThenConditionModel | IfThenLinePlaceholder)[];
}

export interface IfThenConditionModel extends IfThenGroupModel {
    name?: string;
}

export interface IfThenRootModel extends IfThenGroupModel {
    formulas: CustomFormula[];
}