import React, { useState } from 'react';

// import { FormControlLabel, Switch } from '@material-ui/core';

// import { useCheckedInputState } from '../../hooks/useInputState';
// import { Timeframe, TimeframeRange, timeframeToSubscript } from './timeframe';

export const DemoTimeframe: React.FC = () => {
    // const [value, setValue] = useState<Timeframe | TimeframeRange>();
    // const [ranged, setRanged, onRangedChanged] = useCheckedInputState(false);
    return <div>
        {/* <FormControlLabel label="Ranged" control={<Checkbox checked={ranged} onChange={onRangedChanged} />}/>
        <hr/>
        {ranged && <Timeframe value={value as TimeframeRange} range onChange={setValue} />}
        {!ranged && <Timeframe value={value as Timeframe} onChange={setValue} />}
        <hr/>
        <h4>Model:</h4>
        <pre>{JSON.stringify(value, undefined, 2)}</pre>
        <h4>Subscript:</h4>
        {ranged && value && <pre><strong>Start:</strong> {timeframeToSubscript((value as TimeframeRange).start)}<br/><strong>End:</strong> {timeframeToSubscript((value as TimeframeRange).end)}</pre>}
        {!ranged && value && <pre>{timeframeToSubscript((value as Timeframe))}</pre>} */}
    </div>;
};