import React, { useCallback, useState, useEffect } from 'react';
import './settings.scss';

import {  Dialog, DialogContent, DialogActions, Button, DialogTitle, Select, MenuItem, TextField, Checkbox, FormControlLabel, FormControl } from '@material-ui/core';
import { GraphType, BarChartSettings, KnownGraphSettings, PieChartSettings, LineOrScatterChartSettings } from '../../model';
import { useInputState, useCheckedInputState } from '../../../hooks/useInputState';
import { FieldDescriptor, FieldTypeCategoryNames, FieldTypeCategory, SortModel } from '@thinkalpha/table-client';
import { FieldSelector, MultipleFieldSelector } from '../../../components/field-selector/field-selector';
import { useModelCommitter } from '../../../hooks/useModelCommitter';
import { SortModelInput } from './sortModelInput';

interface Props {
    open: boolean;
    onClose: () => void;
    onSave: (settings: KnownGraphSettings, sort: SortModel) => void;
    fields: FieldDescriptor[];
    settings?: KnownGraphSettings;
    sort: SortModel;
}

interface ChartSettingsProps<T> {
    fields: FieldDescriptor[];
    type: GraphType;
    onChange: (model: T) => void;
    settings?: KnownGraphSettings;
}

const BarChartSettingsComponent: React.FC<ChartSettingsProps<BarChartSettings>> = ({fields, onChange, settings }) => {
    const model = settings as BarChartSettings;
    const [categoryDimension, setCategoryDimension] = useState<string | undefined>(model?.categoryDimension);
    const [magnitudeDimension, setMagnitudeDimension] = useState<string | undefined>(model?.magnitudeDimension);
    const [widthDimension, setWidthDimension] = useState<string | undefined>(model?.widthDimension);

    useModelCommitter(
        model,
        onChange,
        {categoryDimension, magnitudeDimension, widthDimension},
        ({categoryDimension, magnitudeDimension, widthDimension}) => {
            if (!categoryDimension || !magnitudeDimension) return false;

            const model: BarChartSettings = {
                graphType: GraphType.bar,
                categoryDimension,
                magnitudeDimension,
                widthDimension,
            };
            return model;
        },
        model => {
            if (model) {
                setCategoryDimension(model.categoryDimension);
                setMagnitudeDimension(model.magnitudeDimension);
                setWidthDimension(model.widthDimension);
            }
        }
    );

    return <>
        <FieldSelector required label="Category dimension" allowedTypes={[FieldTypeCategory.String]} fields={fields} value={categoryDimension || ''} onChange={setCategoryDimension} />
        <FieldSelector required label="Magnitude dimension" allowedTypes={[FieldTypeCategory.Double]} fields={fields} value={magnitudeDimension || ''} onChange={setMagnitudeDimension} />
        {/* <FieldSelector label="Width dimension" allowedTypes={[FieldTypeCategory.Double]} fields={fields} value={widthDimension || ''} onChange={setWidthDimension} />  */}
    </>;
};

const PieChartSettingsComponent: React.FC<ChartSettingsProps<PieChartSettings>> = ({fields, onChange, settings}) => {
    const model = settings as PieChartSettings;
    const [categoryDimension, setCategoryDimension] = useState<string | undefined>(model?.categoryDimension);
    const [magnitudeDimension, setMagnitudeDimension] = useState<string | undefined>(model?.magnitudeDimension);

    useModelCommitter(
        model,
        onChange,
        {categoryDimension, magnitudeDimension},
        ({categoryDimension, magnitudeDimension}) => {
            if (!categoryDimension || !magnitudeDimension) return false;

            const model: PieChartSettings = {
                graphType: GraphType.pie,
                categoryDimension,
                magnitudeDimension,
            };
            return model;
        },
        model => {
            if (model) {
                setCategoryDimension(model.categoryDimension);
                setMagnitudeDimension(model.magnitudeDimension);
            }
        }
    );

    return <>
        <FieldSelector required label="Category dimension" allowedTypes={[FieldTypeCategory.String]} fields={fields} value={categoryDimension || ''} onChange={setCategoryDimension} />
        <FieldSelector required label="Magnitude dimension" allowedTypes={[FieldTypeCategory.Double]} fields={fields} value={magnitudeDimension || ''} onChange={setMagnitudeDimension} />
    </>;
};

const LineOrScatterChartModelSettingsComponent: React.FC<ChartSettingsProps<LineOrScatterChartSettings>> = ({type, fields, onChange, settings}) => {
    const model = settings as LineOrScatterChartSettings;
    const [xDimension, setXDimension] = useState<string | undefined>(model?.xDimension);
    const [yDimensions, setYDimensions] = useState<string[]>((model?.yDimensions) || []);
    const [showBellCurve, setShowBellCurve, onShowBellCurve] = useCheckedInputState((model?.showBellCurve) || false);

    useModelCommitter(
        model,
        onChange,
        {xDimension, yDimensions, showBellCurve},
        ({xDimension, yDimensions, showBellCurve}) => {
            if (!xDimension || !yDimensions.length) return false;

            const model: LineOrScatterChartSettings = {
                graphType: type as (GraphType.scatter | GraphType.line),
                xDimension,
                yDimensions,
                showBellCurve
            };
            return model;
        },
        model => {
            if (model) {
                setXDimension(model.xDimension);
                setYDimensions(model.yDimensions);
                setShowBellCurve(model.showBellCurve);
            }
        }
    );

    return <>
        <FieldSelector required label="X dimension" allowedTypes={[FieldTypeCategory.String, FieldTypeCategory.Double, FieldTypeCategory.Timestamp]} fields={fields} value={xDimension || ''} onChange={setXDimension} />
        <MultipleFieldSelector required label="Y dimension(s)" allowedTypes={[FieldTypeCategory.Double, FieldTypeCategory.Timestamp]} fields={fields} value={yDimensions} onChange={setYDimensions} />
        <FormControl className="bell-curve">
            <FormControlLabel control={
                <Checkbox color="primary" checked={showBellCurve} onChange={onShowBellCurve} />
            } label="Show bell curve" />
        </FormControl>
    </>;
};

export const ChartSettings: React.FC<Props> = ({onClose, onSave, open, fields, settings: model, sort: sortModel}) => {
    const [graphType, setGraphType] = useState<GraphType | undefined>(model?.graphType);
    const [settings, setSettings] = useState<KnownGraphSettings | undefined>(model);
    const [sort, setSort] = useState<SortModel>(sortModel);

    useModelCommitter(
        model,
        setSettings,
        {settings},
        ({settings}) => {
            if (!settings) return false;

            return settings;
        },
        model => {
            if (model) {
                setSettings(model);
            }
        }
    );

    useModelCommitter(
        sortModel,
        setSort,
        {sort},
        ({sort}) => {
            if (!sort) return false;

            return sort;
        },
        model => {
            if (model) {
                setSort(model);
            }
        }
    );

    const onGraphTypeChanged = useCallback(e => {
        const type = e.target.value;
        setGraphType(type);
        setSettings(undefined);
    }, []);

    const cancel = useCallback(() => {
        if (onClose) onClose();
    }, [onClose]);

    const save = useCallback(() => {
        onSave(settings!, sort);
    }, [settings, sort, onSave]);

    return <Dialog className="chart-settings" open={open} onClose={cancel}>
        <DialogTitle>Chart settings</DialogTitle>
        <DialogContent>
            <TextField
                select 
                variant="outlined" 
                label="Chart type"  
                className="type-select" 
                onChange={onGraphTypeChanged}
                value={graphType || ''}>
                {Object.keys(GraphType).map(type => <MenuItem key={type} value={type}>{type}</MenuItem>)}
            </TextField>
            {graphType === GraphType.bar && <BarChartSettingsComponent
                type={graphType}
                fields={fields} 
                onChange={setSettings}
                settings={settings}
            />}
            {graphType === GraphType.pie && <PieChartSettingsComponent
                type={graphType}
                fields={fields}
                onChange={setSettings}
                settings={settings}
            />}
            {[GraphType.line, GraphType.scatter].includes(graphType!) && <LineOrScatterChartModelSettingsComponent
                type={graphType!}
                fields={fields}
                onChange={setSettings}
                settings={settings}
            />}

            <SortModelInput fields={fields} sort={sort} onChange={setSort}/>
        </DialogContent>
        <DialogActions>
            <Button color="primary" onClick={save} disabled={!settings}>Save</Button>
            <Button color="secondary" onClick={cancel}>Cancel</Button>
        </DialogActions>
    </Dialog>;
};
