import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { Key } from '@thinkalpha/table-client';
import { ColumnPreference, TableUserData } from '../components/table-view/model';
import { randomString } from '../util/randomString';
import RiskTable, { RiskTableSaveData } from './risk-table';

import loglevel from 'loglevel';
const logger = loglevel.getLogger('risk-popout');

import './risk-popout.scss';

type State = {
    key: Key;
    saveData: RiskTableSaveData;
    columnPreferences: ColumnPreference[];
    title: string;
    filter: string;
};

class RiskPopout extends React.Component<RouteComponentProps<{}>, State> {
    
    componentDidMount() {
        const search = new URLSearchParams(this.props.location.search);
        const saveData = search.get('saveData');
        const filter = search.get('filter');
        const key = search.get('key');
        const title = search.get('title');
        const columnPreferences = search.get('columnPreferences');

        if (!key || !saveData || !columnPreferences || !title) {
            throw new Error('Invalid incoming URL for risk popout');
        }

        this.setState({
            key: JSON.parse(decodeURIComponent(key)),
            saveData: JSON.parse(decodeURIComponent(saveData)),
            filter: filter && JSON.parse(decodeURIComponent(filter)),
            title,
            columnPreferences: JSON.parse(decodeURIComponent(columnPreferences))
        });
    }

    render() {
        if (!this.state) return null;

        const {columnPreferences, saveData, key, title, filter} = this.state;

        return (
            <div id="risk-popout">
                <RiskTable
                    columnPreferences={columnPreferences}
                    tableKey={key}
                    saveData={saveData}
                    filter={filter}
                    title={title}
                    onSaveDataChanged={x => this.setState({saveData: x})}
                    hideExpand={true}
                />
            </div>
        );
    }
}

export default withRouter(RiskPopout);

export function open(key: Key, columnPreferences: readonly (ColumnPreference | string)[], saveData: RiskTableSaveData | undefined, filter: string, title: string, position?: {width: number, height: number, top: number, left: number}) {
    const baseUri = window.location.href.replace(window.location.pathname, '/risk');
    const childState = {...saveData, viewName: randomString(), indexName: randomString()};
    const args = new URLSearchParams();
    args.append('key', JSON.stringify(key));
    if (filter) args.append('filter', JSON.stringify(filter));
    args.append('columnPreferences', JSON.stringify(columnPreferences));
    args.append('saveData', JSON.stringify(childState));
    args.append('title', title);
    const uri = `${baseUri}/popout?${args.toString()}`;
    logger.info('opening popout', uri);
    window.open(uri, '_blank', `height=${position && position.height},width=${position && position.width},left=${position && position.left},top=${position && position.top},status=no,titlebar=no,toolbar=no,menubar=no,location=no`);
}