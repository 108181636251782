import React, { useCallback, useState, useRef } from 'react';
import {FieldDescriptor, FieldTypeCategory, CategoryForFieldType} from '@thinkalpha/table-client';
import {Select, MenuItem, InputLabel, FormControl} from '@material-ui/core';
import {useInputCallback, useMultipleSelectCallback} from '../../hooks/useInputState';
import {randomString} from '../../util/randomString';

export const FieldSelector: React.FC<{fields: FieldDescriptor[], allowedTypes?: FieldTypeCategory[], label?: string, value: string | undefined | null, onChange: (value: string | undefined) => void, required?: boolean}> = ({fields, value, onChange, label, allowedTypes, required}) => {
    const onChanged = useInputCallback(onChange);
    const idRef = useRef(randomString());
    const id = idRef.current;

    const filteredFields = allowedTypes ? fields.filter(field => allowedTypes.includes(CategoryForFieldType.get(field.type)!)) : fields;

    return <FormControl className="field-selector" required={required} error={required && !value}>
        {label && <InputLabel shrink htmlFor={id}>{label}</InputLabel>}
        <Select variant="outlined" id={id} value={value} onChange={onChanged}>
            {filteredFields.map(field => <MenuItem key={field.name} value={field.name}>{field.name}</MenuItem>)}
        </Select>
    </FormControl>;
};

export const MultipleFieldSelector: React.FC<{fields: FieldDescriptor[], allowedTypes?: FieldTypeCategory[], label?: string, value: string[], onChange: (value: string[]) => void, required?: boolean}> = ({fields, value, onChange, label, allowedTypes, required}) => {
    const onChanged = useMultipleSelectCallback(onChange);
    const idRef = useRef(randomString());
    const id = idRef.current;

    const filteredFields = allowedTypes ? fields.filter(field => allowedTypes.includes(CategoryForFieldType.get(field.type)!)) : fields;

    return <FormControl className="field-selector multi" required={required} error={required && !value.length}>
        {label && <InputLabel shrink htmlFor={id}>{label}</InputLabel>}
        <Select multiple variant="outlined" id={id} value={value} onChange={onChanged}>
            {filteredFields.map(field => <MenuItem key={field.name} value={field.name}>{field.name}</MenuItem>)}
        </Select>
    </FormControl>;
};