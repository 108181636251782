import loglevel from 'loglevel';
const logger = loglevel.getLogger('table-service');

import { ConnectionStatus, Key, RawClient, TableClient, TimurClient } from '@thinkalpha/table-client';
import { Subject, fromEvent, ReplaySubject, concat, defer, empty } from 'rxjs';
import { filter, first, publishReplay, startWith, skipUntil, skipWhile } from 'rxjs/operators';

import config from '../config/appConfig';
import {store} from '../state';
import { accessToken$ } from './auth';
import io from 'socket.io-client';
import { GaussProgram, Job, JobResult, GaussJobStatus } from '../contracts/gauss';

const socket = io('wss://ps2.thinkalpha.io:63552');

// tslint:disable-next-line: no-string-literal
window['gauss'] = {socket, createJob};

const jobUpdates$ = fromEvent<Job>(socket, 'gauss.job');
const jobResults$ = fromEvent<JobResult>(socket, 'gauss.job-result');

export async function createJob(prg: GaussProgram, description?: string) {
    const job$ = jobUpdates$;
    const jobPromise = job$.pipe(first()).toPromise();
    socket.emit('gauss.create.job', prg, description);
    const job = await jobPromise;
    const thisJobUpdates$ = new ReplaySubject<Job>();
    const sub = jobUpdates$.pipe(
        startWith(job),
        filter(x => x.id === job.id),
    ).subscribe(job => {
        thisJobUpdates$.next(job);
        if (job.status === GaussJobStatus.done || job.status === GaussJobStatus.failed || job.status === GaussJobStatus.timeOut) {
            sub.unsubscribe();
            thisJobUpdates$.complete();
        }
    }, thisJobUpdates$.error.bind(thisJobUpdates$), thisJobUpdates$.complete.bind(thisJobUpdates$));
    socket.emit('gauss.subscribe.job-status', job.id);
    const jobResult$ = concat(
        thisJobUpdates$.pipe(skipWhile(() => true)),
        defer(() => {
            socket.emit('gauss.get.job-result', job.id);
            return empty();
        }),
        jobResults$.pipe(
            filter(x => x.id === job.id),
            first()
        )
    );
    return {job, job$: thisJobUpdates$, result$: jobResult$};
}

store.watch(x => ({accessToken: x.accessToken})).pipe(
    filter(x => !!x.accessToken)
).subscribe(({accessToken}) => {
    logger.info('About to log in using token', accessToken);
    socket.emit('session.login', accessToken, {});
});