import './widget.scss';
import _ from 'lodash';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'reactive-state/react';
import { IconButton } from '@material-ui/core';

import {ComponentWithModel} from '../../util/componentWithModel';

export interface WidgetProps<TModel extends {} = {}> {
    model: TModel | undefined;
    onChange: (model: TModel | undefined) => void;
    onClose?: () => void;
}

export interface WidgetContainerProps {
    onClose?: () => void;
    name?: string;
    header?: React.ReactNode;
}

export type WidgetComponent<TProps extends {} = {}, TModel extends {} = {}> = React.ComponentType<WidgetProps<TModel> & TProps>;

export const WidgetContainer: React.FC<WidgetContainerProps> = ({name, header, onClose, children}) => {
    return <div className="widget">
        <header className="name-close-row">
            <h3 className="name">{name}</h3>
            {header && <div className="external-header">{header}</div>}
            <div className="end"><div className="expand"/>{onClose && <IconButton edge="end" size="small" onClick={onClose} id="close"><i className="fa fa-times" /></IconButton>}</div>
        </header>
        <div className="contents">
            {children}
        </div>
    </div>;
};