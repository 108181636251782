import './wizard.scss';

import loglevel from 'loglevel';
const log = loglevel.getLogger('wizard');

import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Store } from 'reactive-state';
import { ActionMap, connect } from 'reactive-state/react';

import {useStrategySteps} from '../../strategy/wizard-steps/define-strategy-steps';
import {useUniverseSteps} from '../../universe/wizard-steps/universe-steps';

import { Button, Checkbox, FormControlLabel, FormGroup, Radio, RadioGroup, Step, StepContent, StepLabel, Stepper, Typography } from '@material-ui/core';
import { useInputCallback, useInputState } from '../../hooks/useInputState';

import {ConcreteStrategy, KnownStrategyTemplate} from '../../strategy/model';
import {Universe} from '../../universe/model';

import { AppState, currentStrategy$, currentUniverse$ } from '../../state';

import { setSkipItem } from '../../services/skips';
import { saveStrategy } from '../../services/strategies';
import { saveUniverse } from '../../services/universes';
import { SkipItem } from '../../services/userStoredConfig';

import { LinkBar } from '../link-bar';

import { WIZARD_WELCOME, WIZARD_CONFIRMATION } from '../../text/wizard-steps-text';

enum ExitType {
    preview = 'preview',
    exit = 'exit',
    universe = 'universe',
    strategy = 'strategy',
}

export interface StepRecord {
    title: string;
    valid: boolean;
    content: React.ReactNode;
}

const WizardPage: React.FunctionComponent<{
    strategy?: ConcreteStrategy;
    setStrategy: (strategy?: ConcreteStrategy) => void;
    universe?: Universe;
    setUniverse: (universe?: Universe) => void;
    skipFirstStep: boolean;
    setSkipFirstStep: (val: boolean) => void;
} & RouteComponentProps<{}>>
    = ({strategy, setStrategy, history, skipFirstStep, setSkipFirstStep}) => {
    
        const [universe, setUniverse] = useState<Universe>();
        const [strategyTemplate, setStrategyTemplate] = useState<KnownStrategyTemplate>();
        const [stepIndex, setStep] = useState(0);
        const [exitType, setExitType, onExitTypeChanged] = useInputState<ExitType>(ExitType.preview);
        // const [universe, setUniverse] = useState<KnownUniverse>(undefined);
        // const [strategy, setStrategy] = useState<KnownStrategy>(undefined);
    
        /* eslint-disable prefer-const */
        let steps: StepRecord[] | undefined;
        let universeSteps: StepRecord[] | undefined;
        let strategySteps: StepRecord[] | undefined;
        let confirmStep: StepRecord | undefined;
        /* eslint-enable prefer-const */

        const prev = useCallback(() => setStep(step => step - 1), []);
        const next = useCallback(() => setStep(step => step + 1), []);

        const onSkipChange = e => {
            setSkipFirstStep(e.target.checked);
        };

        useEffect(() => {
            if (skipFirstStep) {
                setStep(1);
            }
        }, [skipFirstStep]);

        useEffect(() => {
            const universe = strategy && strategy.universe; // this is the inbound universe

            // if universe exists, skip to first strategy step
            if (universe && (!strategy || !strategy.strategy)) setStep(steps!.indexOf(strategySteps![0]));

            // if strategy exists, skip to confirm
            if (universe && strategy && strategy.strategy) setStep(steps!.indexOf(confirmStep!));

        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [strategy]);

        const cancel = useCallback(() => {
            history.push('/dashboard');
        }, [history]);

        const onUniverseNameChanged = useInputCallback(name => {
            setUniverse(universe => ({...universe!, name: name || undefined}));
        }, [setUniverse]);

        const onStrategyNameChanged = useInputCallback(name => {
            setStrategyTemplate(strategyTemplate => ({...strategyTemplate!, name: name || undefined}));
        }, [setStrategyTemplate]);

        const finish = useCallback(async () => {
            switch (exitType) {
                case ExitType.preview: {
                    setStrategy({...strategy!, strategy: strategyTemplate!});
                    history.push('/demo/preview');
                    break;
                }
                case ExitType.exit: {
                    await Promise.all([saveUniverse(universe).toPromise(), saveStrategy({...strategy!, strategy: strategyTemplate!, universe}).toPromise()]);
                    history.push('/dashboard');
                    break;
                }
                case ExitType.strategy: {
                    await Promise.all([saveUniverse(universe).toPromise(), saveStrategy({...strategy!, strategy: strategyTemplate!, universe}).toPromise()]);
                    setStrategy(undefined);
                    setStep(steps!.indexOf(strategySteps![0]));
                    break;
                }
                case ExitType.universe: {
                    await Promise.all([saveUniverse(universe).toPromise(), saveStrategy({...strategy!, ...strategyTemplate!, universe}).toPromise()]);
                    setUniverse(undefined);
                    setStrategy(undefined);
                    setStep(steps!.indexOf(universeSteps![0]));
                    break;
                }
            }
        }, [exitType, setStrategy, strategy, strategyTemplate, history, universe, steps, strategySteps, universeSteps]);

        // these steps fetchers must be done up here because they call hooks
        universeSteps = useUniverseSteps(universe, x => (log.debug('Got universe / universe definition', x), setUniverse(x)));
        strategySteps = useStrategySteps(strategy && strategy.strategy, x => (log.debug('Got strategy definition', x), setStrategyTemplate(x)));

        const introStep: StepRecord = {
            title: 'Before You Begin',
            valid: true,
            content: <>
                <Typography>{WIZARD_WELCOME}</Typography>
                <FormControlLabel label="Skip This Step Next Time" control={<Checkbox checked={skipFirstStep} onChange={onSkipChange}/>} />
            </>
        };

        const filterStep: StepRecord = {
            title: 'Filter Universe',
            valid: true,
            content: <>
                <Typography>Bacon ipsum dolor amet short ribs brisket venison rump drumstick pig sausage prosciutto chicken spare ribs salami picanha doner. Kevin capicola sausage, buffalo bresaola venison turkey shoulder picanha ham pork tri-tip meatball meatloaf ribeye. Doner spare ribs andouille bacon sausage. Ground round jerky brisket pastrami shank.</Typography>
                <div>Step filter component goes here</div>
            </>
        };

        confirmStep = {
            title: 'Confirmation',
            valid: exitType !== undefined,
            content: <>
                <Typography>{WIZARD_CONFIRMATION}</Typography>
                <FormGroup>
                    { universe && !universe.id && (
                        <FormControlLabel className="labeled-input"
                            value={universe.name} 
                            onChange={onUniverseNameChanged} 
                            label="Name Universe (optional)" 
                            control={<input />} 
                        />
                    ) }
                    { strategyTemplate && (!strategy || !strategy.id) && (
                        <FormControlLabel className="labeled-input"
                            value={strategyTemplate.name} 
                            onChange={onStrategyNameChanged} 
                            label="Name Strategy (optional)" 
                            control={<input />} 
                        />
                    ) }
                </FormGroup>
                <RadioGroup value={exitType} onChange={onExitTypeChanged}>
                    <FormControlLabel value={ExitType.preview} label="Go to strategy preview." control={<Radio/>}/>
                    <FormControlLabel disabled={!strategyTemplate || (universe && !universe.name) || !strategyTemplate.name} value={ExitType.exit} label="Save and return to dashboard." control={<Radio/>}/>
                    <FormControlLabel disabled={!strategyTemplate || (universe && !universe.name) || !strategyTemplate.name} value={ExitType.universe} label="Save and create another universe." control={<Radio/>}/>
                    <FormControlLabel disabled={!strategyTemplate || (universe && !universe.name) || !strategyTemplate.name} value={ExitType.strategy} label="Save and create another strategy with the same universe." control={<Radio/>}/>
                </RadioGroup>
            </>
        };
    
        steps = [
            introStep,
            ...universeSteps,
            // filterStep,
            ...strategySteps,
            confirmStep
        ];

        const stepJumpers = useMemo(() => !steps ? [] : steps.map((step, i) => () => setStep(i)), [steps]);

        const currentStep = steps[stepIndex];

        let allValid = true;
        const stepClickable = steps.map((step, i) => {
            if (i < stepIndex) return true;
            if (!allValid) return false;
            if (!step.valid) {
                allValid = false;
                return true;
            }
            return true;
        });

        return <div id="wizard-page">
            <LinkBar />
            <div className="wizard-stepper">
                <Stepper activeStep={stepIndex} orientation="vertical">
                    {steps.map((step, i) => <Step key={step.title}>
                        <StepLabel><a className={classNames({clickable: stepClickable[i]})} onClick={stepClickable[i] ? stepJumpers[i] : undefined}>{step.title}</a></StepLabel>
                        <StepContent>
                            {step.content}
                        </StepContent>
                    </Step>)}
                </Stepper>
            </div>
            <footer>
                <Button disabled={stepIndex === 0} onClick={prev} tabIndex={-1}>Back</Button>
                <Button variant="contained" color="primary" disabled={!currentStep.valid} hidden={stepIndex >= steps.length - 1} onClick={next}>Next</Button>
                <Button variant="contained" color="primary" disabled={!currentStep.valid} hidden={currentStep !== confirmStep} onClick={finish}>
                    {exitType === ExitType.exit ? 'Finish' : 'Save and Continue'}
                </Button>
                <Button variant="contained" color="secondary" onClick={cancel} className="cancel">Cancel</Button>
            </footer>
        </div>;
    };

const connected = connect(WizardPage, (store: Store<AppState>) => {
    const props = store.watch(state => {
        return {
            strategy: state.currentStrategy,
            universe: state.currentUniverse,
            skipFirstStep: state.storedUserConfig!.skipItems.includes(SkipItem.StrategyBuilderWizardBeforeYouBegin)
        };
    });
    const actionMap: ActionMap<typeof WizardPage> = {
        setStrategy: currentStrategy$,
        setUniverse: currentUniverse$,
        setSkipFirstStep: (val: boolean) => setSkipItem(SkipItem.StrategyBuilderWizardBeforeYouBegin, val)
    };
    return {props, actionMap};
});
export {connected as WizardPage};