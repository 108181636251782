import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { connect } from 'reactive-state/react';

import classNames from 'classnames';

// import { AgGridReact } from 'ag-grid-react';
import {GridWrapper} from './grid-wrapper';

import logger from 'loglevel';
const log = logger.getLogger('table-view');

import {escapeRegExp} from 'lodash';

import { compareKey, FieldDescriptor, FieldType, FieldTypeCategory, FieldTypeNames, Key, ObjectDefinition, RawClient, Row, SimpleTableClient, SortModel, SortOrder, TableClient, TypesInTypeCategory, withLatestFromConflated } from '@thinkalpha/table-client';

type SerializedNumberFilter = any;
type SerializedTextFilter = any;

import {AllModules} from '@ag-grid-enterprise/all-modules';
import { CellClassParams, DragStoppedEvent, ColDef, ColumnApi, ColumnResizedEvent, FilterChangedEvent, GetContextMenuItems, GetContextMenuItemsParams, GridApi, GridReadyEvent, ICellRendererParams, IViewportDatasource, IViewportDatasourceParams, SelectionChangedEvent, SortChangedEvent, ViewportChangedEvent, ColumnVisibleEvent, ColumnMovedEvent, ColumnPinnedEvent } from '@ag-grid-enterprise/all-modules';
import {ColumnState} from '@ag-grid-community/core/dist/cjs/columnController/columnController';

import { isEqual, isNumber, max } from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import { Store } from 'reactive-state';
import { BehaviorSubject, combineLatest, concat, from, Observable, of, Subject, Subscription } from 'rxjs';
import { bufferTime, debounceTime, distinctUntilChanged, filter, first, map, publishBehavior, refCount, skip, switchMap, tap, throttleTime, startWith, auditTime } from 'rxjs/operators';
import { AppState } from '../../state';
import { randomString } from '../../util/randomString';
import {useLazyRef} from '../../hooks/useLazyRef';

import './table-view.scss';
import { Dialog, DialogContent, DialogActions } from '@material-ui/core';
import { smartFilterToTableFilter } from '../smart-filter';

import {ColumnPreference, ContextMenuItemsCreator, TableUserData, TableVisualData, Pin, ColumnPinDictionary, ColumnOrdering, ColumnWidthDictionary, ColumnVisibilityDictionary} from './model';
import { useModelCommitter } from '../../hooks/useModelCommitter';
import _ from 'lodash';
import { useDeepEffect } from '../../hooks/useDeepEffect';
import { useCallbackRef } from '../../hooks/useCallbackRef';

import {getFieldClass, getFieldStyle, isNumericField, mapFilterModelToFilterText} from './utils';
import {renderer, Renderer} from './renderer';

type EventEmitter<T = void> = (event: T) => void;

const columnRankByIndexOffset = 100;
const defaultWidth = 40;

export type TableViewProps = {
    client: RawClient;

    tableKey: Key;
    smartFilter: string;
    filter: string;
    selectable: 'single' | 'multiple' | undefined;

    columnPreferences: readonly (ColumnPreference | string)[];
    columnRankByIndex?: boolean;
    columnPreference?: (field: FieldDescriptor, hasExplicit: boolean) => ColumnPreference | undefined;
    defaultColumnPreference?: Omit<ColumnPreference, 'name'>;
    
    contextMenuItemsCreator?: ContextMenuItemsCreator;
    showErrors: boolean;
    theme: string;
    tableState: TableUserData;

    onRowSelected: EventEmitter<Row[]>;
    onRowCountChanged: EventEmitter<number>;

    onRebind: EventEmitter;
    onFields: EventEmitter<FieldDescriptor[]>;
    onError: EventEmitter<string | undefined>;
    onTableClientChanged: EventEmitter<TableClient>;
    onStateChanged: EventEmitter<TableUserData>;
};

const TableView: React.FC<TableViewProps> = function TableView({
    client,
    tableKey,
    
    theme = 'ag-theme-balham-dark',
    selectable,
    contextMenuItemsCreator,

    filter: inboundFilter,
    smartFilter: inboundSmartFilter,

    tableState = {columnWidths: {}} as TableUserData,

    columnPreference,
    columnPreferences = [],
    columnRankByIndex = false,
    defaultColumnPreference,

    onFields,
    onTableClientChanged,
    onRowSelected,
    onStateChanged,
    onRowCountChanged,
}) {
    const [status, setStatus] = useState<string | undefined>('Initializing...');
    const [columnDefs, setColumnDefs] = useState<ColDef[]>();
    const [totalsColumnDefs, setTotalsColumnDefs] = useState<ColDef[]>();

    const [columnWidths, setColumnWidths] = useState<ColumnWidthDictionary>(tableState && tableState.columnWidths || {});
    const [columnOrder, setColumnOrder] = useState<ColumnOrdering>(tableState && tableState.columnOrder);
    const [pinnedColumns, setPinnedColumns] = useState<ColumnPinDictionary>(tableState && tableState.pinnedColumns);
    const [columnVisibility, setColumnVisibility] = useState<ColumnVisibilityDictionary>(tableState && tableState.columnVisibility || {});

    const [filterModel, setFilterModel] = useState<any>(tableState && tableState.filterModel);
    const [sortModel, setSortModel] = useState<any>(tableState && tableState.sortModel);
    const [firstRow, setFirstRow] = useState<number | undefined>(tableState && tableState.firstRow);

    const columnWidthsRef = useRef(columnWidths);
    useEffect(() => { columnWidthsRef.current = columnWidths; }, [columnWidths]);

    const tableClientRef = useLazyRef<SimpleTableClient>(() => new SimpleTableClient(client));
    const tc = tableClientRef.current;
    const fieldsRef = useRef<FieldDescriptor[]>();
    const sortable = tableKey && tableKey.ex !== 'M' && tableKey.ex !== 'X';
    
    const gridReady$Ref = useRef(new BehaviorSubject(false));
    const gridApiRef = useRef<GridApi>();
    const gridColumnApiRef = useRef<ColumnApi>();
    const totalsGridReady$Ref = useRef(new BehaviorSubject(false));
    const totalsApiRef = useRef<GridApi>();
    const totalsColumnApiRef = useRef<ColumnApi>();

    /**
     * Effect to announce the table client to external users.
     */
    useEffect(() => {
        if (onTableClientChanged) onTableClientChanged(tc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tc]);

    /**
     * Initial effect to set up all pipelines.
     */
    useEffect(() => {
        const gridReady$ = gridReady$Ref.current;
        const totalsGridReady$ = totalsGridReady$Ref.current;
        const subscriptions = new Subscription();

        // set initial bounds on the client, so that we get descriptors
        tc.bounds = {firstRow: 0, windowSize: 0};

        // apply incoming descriptors to the grid
        subscriptions.add(gridReady$.pipe(
            filter(x => x), // when the grid is ready (true)
            switchMap(() => tc.descriptor$),
            map(xs => xs.filter(x => !x.name.startsWith('__'))),
            distinctUntilChanged(isEqual)
        ).subscribe((fields: FieldDescriptor[]) => {
            fields = fields.filter(x => {
                const pref = getColumnPreference(x.name);
                return !pref || !pref.disabled;
            });
            fieldsRef.current = fields;

            if (onFields) onFields(fields);
            applyDescriptorsRef.current();
        }));
        
        // apply row count to grid viewport
        subscriptions.add(gridReady$.pipe(
            filter(x => x), // when the grid is ready (true)
            switchMap(() => tc.rowCount$),
            auditTime(1000) // 1000ms delay added to help with grid flash bug, but this might not actually be needed
        ).subscribe(rc => {
            // log.debug(props.tableKey, tableClient!.subscription, 'received row count', rc);
            viewportParamsRef.current!.setRowCount(rc);
            if (onRowCountChanged) onRowCountChanged(rc);
        }));

        // the first time we get a row count, move to the saved row in the table state
        subscriptions.add(gridReady$.pipe(
            filter(x => x), // when the grid is ready (true)
            switchMap(() => tc.rowCount$),
            first(),
            debounceTime(50)
        ).subscribe(rc => {
            if (!tableState) return;
            if (tableState.firstRow === undefined) return;
            if (rc <= tableState.firstRow) {
                log.warn(`Saved first row is outside of bounds 0:${rc} (row count)`);
                return;
            }

            // the first time we get a row count, move to the saved row in the table state
            gridApiRef.current!.ensureIndexVisible(tableState.firstRow, 'top');
            log.info('ensuring first row based on saved table state', tableState.firstRow);
        }));

        const columnRenderers$ = tc.descriptor$.pipe(map((descs): ReadonlyMap<string, Renderer | undefined> => {
            const map = new Map(descs.map(field => {
                const pref = getColumnPreference(field);
                return [field.name, renderer(field, pref)];
            }));
            Object.freeze(map);
            return map;
        }));

        // once the grid is available, apply row updates being careful to handle the fact that these are conflated objects
        subscriptions.add(gridReady$.pipe(
            filter(x => x), // when the grid is ready (true)
            // switchMap(() => tc.definition$.pipe(first())),
            switchMap(() => tc.update$.pipe(withLatestFromConflated(columnRenderers$))),
            // tap(x => x.row === 0 && console.log('updating row in tap', x))
        ).subscribe(([update, renderers]) => {
            const rowIndex = update.row;
            
            // if (rowIndex === 0) {
            //     console.log('updating row in table view', rowIndex);
            // }
            const rowNode = viewportParamsRef.current!.getRow(rowIndex);
            if (!rowNode || !rowNode.data || rowNode.data.rowId !== update.rowId) { // either there's no row, no row data, or the row is a complete replacement
                // dupe the update since this data will become the row node's .data object, but the incoming object is conflated and will be reused
                const newRow = _(update).mapValues((val, field) => {
                    if (field === 'row' || field === 'rowId') return val;
                    const renderer = renderers.get(field);
                    if (!renderer) return val;
                    return renderer(val);
                }).value();
                viewportParamsRef.current!.setRowData({[rowIndex]: newRow});
            } else {
                // apply the update to the existing row data, field by field
                for (const field in update) {
                    const renderer = renderers.get(field);
                    const rendered = renderer ? renderer(update[field]) : update[field];
                    if (rowNode.data[field] !== rendered) {
                        rowNode.setDataValue(field, rendered);
                    }
                }
            }
        }, err => log.error('Update subscription produced an error', err)));
        
        const totalColumnsRenderers$ = tc.totalsDescriptor$.pipe(map((descs): ReadonlyMap<string, Renderer | undefined> => {
            const map = new Map(descs.map(field => {
                const pref = getColumnPreference(field);
                return [field.name, renderer(field, pref)];
            }));
            Object.freeze(map);
            return map;
        }));

        // update total values via the totals grid api
        subscriptions.add(totalsGridReady$.pipe(
            filter(x => x), // when the grid is ready (true)
            switchMap(() => tc.totalsUpdate$.pipe(withLatestFromConflated(totalColumnsRenderers$))),
            // scan<Dictionary<any>, Dictionary<any>[]>((acc, x) => {acc[0] = x; return acc;}, []),
            // tap(x => log.info(x)),
        ).subscribe(([update, renderers]) => {
            totalsViewportParamsRef.current!.setRowCount(1);
            const rowNode = totalsViewportParamsRef.current!.getRow(0);
            if (!rowNode || !rowNode.data) {
                const newRow = _(update).mapValues((val, field) => {
                    const renderer = renderers.get(field);
                    if (!renderer) return val;
                    return renderer(val);
                }).value();
                totalsViewportParamsRef.current!.setRowData({[0]: newRow});
            } else {
                for (const field in update) {
                    const renderer = renderers.get(field);
                    const rendered = renderer ? renderer(update[field]) : update[field];
                    rowNode.setDataValue(field, rendered);
                }
            }
        }));

        // on first descriptors and then again on first descriptors AND first data, autosize columns
        subscriptions.add(gridReady$.pipe(
            filter(x => x), // when the grid is ready (true)
            switchMap(() => concat(of(undefined), /*reinit$*/)),
            switchMap(() => combineLatest( // todo -- possibly add back reinit
                tc.update$.pipe(first(), startWith(undefined)),
                tc.descriptor$.pipe(
                    distinctUntilChanged((a, b) => isEqual(a.map(x => x.name), b.map(x => x.name)))
                )
            ))
        ).subscribe(([, descs]) => {
            // if (!tableState || !tableState.columnState || !tableState.columnState.length) {
            //     // log.info('column state IS empty!!!', tableState);
            //     setTimeout(() => gridColumnApiRef.current && gridColumnApiRef.current.autoSizeAllColumns(), 50);
            // } else {
            //     // log.info('column state is not empty!!!', tableState);
            // }
            if (gridColumnApiRef.current) {
                const autosizeCols = descs
                    .map(x => x.name)
                    // .map(x => getColumnPreference(x))
                    // .filter((x): x is string => x !== undefined)
                    .filter(x => !Object.keys(columnWidthsRef.current).includes(x));
                    // .filter(x => !x.width || x.width === defaultWidth)
                log.debug('autosizing cols that are not explicitly sized', autosizeCols);
                gridColumnApiRef.current.autoSizeColumns(autosizeCols);
            }
            setStatus(undefined);
        }));

        // for totals: on first descriptors and then again on first descriptors + first data, autosize totals columns
        subscriptions.add(totalsGridReady$.pipe(
            filter(x => x), // when the grid is ready (true)
            switchMap(() => combineLatest( // todo -- possibly add back reinit
                tc.totalsUpdate$.pipe(first(), startWith(undefined)),
                tc.totalsDescriptor$.pipe(
                    distinctUntilChanged((a, b) => isEqual(a.map(x => x.name), b.map(x => x.name)))
                )
            ))
        ).subscribe(() => {
            if (totalsColumnApiRef) totalsColumnApiRef.current!.autoSizeAllColumns();
            log.info('autosizing totals');
        }));

        // create column defs for totals every time we receive total fields
        subscriptions.add(gridReady$.pipe(
            filter(x => x), // when the grid is ready (true)
            switchMap(() => tc.totalsDescriptor$),
            map(fields => fields.filter(x => x.name.indexOf('(') === -1 && !x.name.startsWith('__')).map(x => createColumnDef(x)))
        ).subscribe(setTotalsColumnDefs));

        // when this component goes away, unsubscribe all subscriptions and then dispose the table client
        return () => {
            subscriptions.unsubscribe();
            tc.dispose();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getColumnPreference = useCallback((field: FieldDescriptor | string): ColumnPreference => {
        const fieldName = typeof field === 'string' ? field : field.name;
        if (typeof field === 'string' && fieldsRef.current) {
            const foundField = fieldsRef.current.find(x => x.name === field);
            if (foundField) field = foundField;
        }
        const arrayPrefIdx = columnPreferences.findIndex(x => typeof x === 'string' ? (x === fieldName) : (x.name === fieldName));
        let arrayPref = arrayPrefIdx !== -1 ? columnPreferences[arrayPrefIdx] : undefined;
        if (typeof arrayPref === 'string') {
            arrayPref = {name: arrayPref};
        }
        const getteredPref = typeof field === 'object' && columnPreference ? columnPreference(field, arrayPref !== undefined) : undefined;
        const pref: ColumnPreference = {
            ...(!arrayPref ? defaultColumnPreference || {} : {}),
            rank: columnRankByIndex && arrayPrefIdx !== -1 ? arrayPrefIdx + columnRankByIndexOffset : undefined,
            name: fieldName,
            ...(getteredPref || {}),
            ...(arrayPref || {}),
        };

        // console.log('for field', typeof field === 'string' ? field : field.name, 'composite pref', pref);

        return pref;
    }, [columnPreference, columnPreferences, columnRankByIndex, defaultColumnPreference]);

    const createColumnDef = useCallback((field: FieldDescriptor): ColDef & {descriptor: FieldDescriptor} => {
        const pref = getColumnPreference(field);

        return {
            headerName: `${pref && pref.display || field.name}`,
            width: defaultWidth,
            hide: pref && pref.hidden,
            headerTooltip: [field.description, `Type: ${FieldTypeNames.get(field.type)}`].filter(x => x).join('\n'),
            // cellRenderer: renderer(field, pref),
            cellStyle: params => getFieldStyle(field, params, pref),
            cellClass: params => getFieldClass(field, params, pref),
            filter: isNumericField(field) ? 'agNumberColumnFilter' : 'agTextColumnFilter',
            filterParams: {
                caseSensitive: true
            },
            pinned: pref.pinned,
            field: field.name,
            sortable: sortable,
            resizable: true,
            enableCellChangeFlash: pref && pref.flashOnChange !== undefined ? pref.flashOnChange : false,
            descriptor: field
        };
    }, [getColumnPreference, sortable]);

    const viewportParamsRef = useRef<IViewportDatasourceParams>();
    const viewport: IViewportDatasource = useMemo(() => ({
        init: params => {
            viewportParamsRef.current = params;

            // tslint:disable-next-line:no-string-literal
            window['viewportParams'] = params;
        },
        setViewportRange: async (firstRow, lastRow) => {
            const tc = tableClientRef.current;
            let windowSize = lastRow - firstRow + 1;
            if (windowSize <= 0) windowSize = 0; // not sure why this is needed -- how can we have a last row that is before the first row??
            tc.bounds = {firstRow, windowSize};
            // console.log('setting bounds', tableClient.bounds, firstRow, lastRow);
        }
    }), [tableClientRef]);

    const totalsViewportParamsRef = useRef<IViewportDatasourceParams>();
    const totalsViewport: IViewportDatasource = useMemo(() => ({
        init: params => {
            totalsViewportParamsRef.current = params;
        },
        setViewportRange: (firstRow, lastRow) => {
            // no-op
        }
    }), []);
    
    // merge the inbound filter and inbound smart filter
    const dumbedFilter = inboundSmartFilter && smartFilterToTableFilter(inboundSmartFilter, fieldsRef.current);
    const actualFilter = [inboundFilter, dumbedFilter]
        .filter(x => !!x)
        .map(x => `(${x})`)
        .join(' and ') || undefined;

    useModelCommitter(
        tableState,
        onStateChanged,
        [filterModel, sortModel, pinnedColumns, columnVisibility, columnWidths, columnOrder, firstRow],
        ([filterModel, sortModel, pinnedColumns, columnVisibility, columnWidths, columnOrder, firstRow]) => {
            const model = {
                filterModel,
                sortModel,
                pinnedColumns,
                columnWidths,
                columnOrder,
                columnVisibility,
                firstRow
            };
            // console.log('sending model outgoing', model);
            return model;
        },
        model => {
            // console.log('applying model', model);
            if (!model) {
                setFilterModel(undefined);
                setSortModel(undefined);
                setPinnedColumns(undefined);
                setColumnVisibility({});
                setColumnWidths({});
                setColumnOrder(undefined);
                setFirstRow(undefined);
            } else {
                const {filterModel, sortModel, pinnedColumns, columnVisibility, columnWidths, columnOrder, firstRow} = model;
                setFilterModel(filterModel);
                setSortModel(sortModel);
                setColumnWidths(columnWidths || {});
                setColumnVisibility(columnVisibility || {});
                setColumnOrder(columnOrder);
                setPinnedColumns(pinnedColumns);
                setFirstRow(firstRow);
            }
        }
    );

    /**
     * Creates filters/indexes as necessary, and then adjusts the binding of the
     * internal table client's key based on new calculated values.
     */
    const bind = useCallback(() => {
        const tc = tableClientRef.current;

        const sortModel = gridApiRef.current && gridApiRef.current.getSortModel();
        const sort = sortModel ? sortModel.reduce((acc, x) => {acc[x.colId] = x.sort === 'desc' ? SortOrder.descending : SortOrder.ascending; return acc; }, {} as SortModel) : {};

        // create filters based on the grid api filter model
        const filterModel: {[x: string]: SerializedNumberFilter | SerializedTextFilter} = gridApiRef.current && gridApiRef.current.getFilterModel();
        const filterModelFilters = filterModel
            ? Object.keys(filterModel).map(field => mapFilterModelToFilterText(field, filterModel[field]))
            : [];

        // create a filter string based on the grid api filter model and our various inbound filters
        const tableFilter = [
            actualFilter,
            ...filterModelFilters
        ].filter(x => x)
            .map(x => `(${x})`)
            .join(' and ') || undefined;

        // ship these settings to the table client all at once
        tc.reconfigure(tableKey, tableFilter || '', sort);

        // viewportParams.setRowCount(0);
        // viewportParams.setRowData({[0]: {}});

        // clear selection when rebinding
        if (gridApiRef.current) {
            for (const node of gridApiRef.current.getSelectedNodes()) {
                node.setSelected(false);
            }
        }
    }, [actualFilter, tableClientRef, tableKey]);

    // if the inbound table key, table state, or filter change, we need to bind
    // (other )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useDeepEffect(() => {
        bind();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableKey, inboundFilter, inboundSmartFilter]);

    /**
     * Apply field descriptors to ag-grid, massaging the existing data rather than replacing
     * so that we don't lose column order / other user preferences.
     * This is pretty hacky, but there's no fully documented way to do it according to the ag-grid docs.
     * @param fields Updated fields
     * @param forceResize Should columns be resized regardless of whether this is the first time we're seeing data or not?
     */
    const [applyDescriptors, applyDescriptorsRef] = useCallbackRef(() => {
        const fields = fieldsRef.current;
        if (!fields) return;

        // console.log('applying descriptors');

        const gridApi = gridApiRef.current;
        const gridColumnApi = gridColumnApiRef.current;
        if (!gridApi || !gridColumnApi) return;

        const columns = gridColumnApi && gridColumnApi.getAllColumns();
        const colDefs = columns ? gridColumnApi.getAllColumns().map(x => x.getColDef()) : [];
        const backupColDefs = [...colDefs];

        const indicesToRemove = colDefs.map((col, i) => ({col, i}))
            .filter(({col}) => fields.every(field => field.name !== col.field))
            .map(({i}) => i);
        for (const i of indicesToRemove.reverse()) {
            colDefs.splice(i, 1);
        }

        const fieldsToAdd = fields.filter(field => colDefs.every(col => col.field !== field.name));
        const colsToAdd = fieldsToAdd.map(x => createColumnDef(x));
        for (const colDef of colsToAdd) {
            colDefs.push(colDef);
        }

        if (!columnOrder) {
            // user has never manually rearranged columns, so auto order
            const maxRank = max(columnPreferences.map((x, i) => (typeof x === 'string' || !columnRankByIndex) ? i + columnRankByIndexOffset : x.rank).filter(x => !!x)) || 0;
            const indexRanks = new Map(columnRankByIndex ? colDefs.map((x, i) => [x, i + columnRankByIndexOffset] as [ColDef, number]) : []);
            colDefs.sort((a, b) => {
                const aPref = getColumnPreference(a.field!);
                const bPref = getColumnPreference(b.field!);
                let aRank = aPref ? aPref.rank : indexRanks.get(a);
                if (aRank === undefined) aRank = maxRank;
                let bRank = bPref ? bPref.rank : indexRanks.get(b);
                if (bRank === undefined) bRank = maxRank;
                return aRank - bRank;
            });
        } else {
            // manual user order specified
            const maxRank = columnOrder.length;
            const colIndex = new Map(colDefs.map((x, i) => [x, columnOrder.includes(x.field!) ? columnOrder.indexOf(x.field!) : maxRank + i]));
            colDefs.sort((a, b) => {
                const aRank = colIndex.get(a)!;
                const bRank = colIndex.get(b)!;
                return aRank - bRank;
            });
        }
        
        const currentColState = gridColumnApi.getColumnState();

        let colsDirty = false;
        if (!isEqual(backupColDefs, colDefs)) {
            
            gridApi.setColumnDefs([]);
            gridApi.setColumnDefs(colDefs);
            colsDirty = true;
        }

        const sortModelToSet = sortModel || gridApi.getSortModel();
        const filterModelToSet = filterModel || gridApi.getFilterModel();
            
        const newColState: ColumnState[] = gridColumnApi.getColumnState();
        const finalColState: ColumnState[] = [...currentColState, ...newColState.filter(x => currentColState.every(y => y.colId !== x.colId))].map(x => ({...x}));
        finalColState.sort((a, b) => {
            const aRank = colDefs.findIndex(x => (x.colId || x.field) == a.colId);
            const bRank = colDefs.findIndex(x => (x.colId || x.field) == b.colId);
            return aRank - bRank;
        });

        for (const colState of finalColState) {
            const colId = colState.colId;
            if (colId in columnVisibility) colState.hide = !columnVisibility[colId];
            if (columnOrder && !columnOrder.includes(colId)) colState.hide = true; // overwrite previous if we have a column order and the column isn't in it
            if (pinnedColumns) colState.pinned = pinnedColumns[colId] || false;
            if (colId in columnWidths) colState.width = columnWidths[colId];
        }

        // console.log('col states', currentColState, finalColState);

        gridApi.setSortModel(sortModelToSet);
        gridApi.setFilterModel(filterModelToSet);
        if (colsDirty || !isEqual(currentColState, finalColState)) {
            logger.debug('setting column state', finalColState);
            gridColumnApi.setColumnState(finalColState);
        }

        // if (forceResize) {
        //     log.info('Forced autosizing all cols');
        //     gridColumnApi.autoSizeAllColumns();
        // } else {
        // const autosizeCols = colsToAdd
        //     .filter(x => !Object.keys(columnWidths).includes(x.colId!))
        //     .filter(x => !x.width || x.width === defaultWidth)
        //     .map(x => x.headerName)
        //     .filter((x): x is string => x !== undefined);
        // log.info('autosizing cols that are not explicitly sized', autosizeCols);
        // gridColumnApi.autoSizeColumns(autosizeCols);
        // }
    }, [columnOrder, columnPreferences, columnVisibility, columnWidths, createColumnDef, filterModel, getColumnPreference, pinnedColumns, sortModel]);

    useDeepEffect(() => {
        applyDescriptors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnOrder, columnVisibility, columnWidths, filterModel, pinnedColumns, sortModel]);

    // convert the grid ready signal into an rxjs event for use in the pipeline above
    const onGridReady = useCallback((params: GridReadyEvent) => {
        gridColumnApiRef.current = params.columnApi || undefined;
        gridApiRef.current = params.api || undefined;

        gridReady$Ref.current.next(true);

        window['grid'] = {api: params.api, columnApi: params.columnApi}; // for debugging use in Chrome console
    }, []);

    const onTotalsGridReady = useCallback((params: GridReadyEvent) => {
        totalsApiRef.current = params.api!;
        totalsColumnApiRef.current = params.columnApi!;

        totalsGridReady$Ref.current.next(true);
    }, []);

    const getContextMenuItems: GetContextMenuItems = useCallback((params: GetContextMenuItemsParams) => {
        if (!contextMenuItemsCreator) {
            return params.defaultItems!;
        }

        const additionalItems = contextMenuItemsCreator(params.node.data).map(
            item => ({
                name: item.name,
                disabled: item.disabled,
                action: item.action
            })
        );

        return [
            ...additionalItems,
            'separator',
            ...(params.defaultItems || []),
        ];
    }, [contextMenuItemsCreator]);

    const onFilterChanged = useCallback(({api, columnApi}: FilterChangedEvent) => {
        const filterModel = api!.getFilterModel();
        setFilterModel(filterModel);
        bind();
    }, [bind]);

    const onSortChanged = useCallback(({api, columnApi}: SortChangedEvent) => {
        const sortModel = api!.getSortModel();
        setSortModel(sortModel);
        bind();
    }, [bind]);

    const onSelectionChanged = useCallback(({api}: SelectionChangedEvent) => {
        const selected = api!.getSelectedRows();
        if (onRowSelected) onRowSelected(selected);
    }, [onRowSelected]);

    const onViewportChanged = useCallback(({api}: ViewportChangedEvent) => {
        setFirstRow(api.getFirstDisplayedRow());
    }, []);

    const dragEndActionQueueRef = useRef<((inbound: TableVisualData) => TableVisualData)[]>([]);

    const onColumnVisible = useCallback(({api, columnApi, source, column, visible}: ColumnVisibleEvent) => {
        if (!column) return;

        const width = column.getColDef().width;
        if (visible && (!width || width === defaultWidth)) {
            columnApi.autoSizeColumn(column);
        }

        if (source === 'uiColumnDragged') {
            const order = columnApi.getColumnState().map(x => x.colId);
            dragEndActionQueueRef.current.push(data => {
                const updatedVisibility = {...data.columnVisibility || {}};
                if (visible === undefined || visible === null) {
                    delete updatedVisibility[column.getColId()];
                } else {
                    updatedVisibility[column.getColId()] = visible;
                }

                return {
                    ...data,
                    columnOrder: data.columnOrder && order,
                    columnVisibility: updatedVisibility
                };
            });
        } else { // do it now, since we're not doing it as a result of a drag
            setColumnOrder(currentOrder => {
                if (!currentOrder) return;
                return columnApi.getColumnState().map(x => x.colId);
            });
            setColumnVisibility(columnVisibility => {
                const updated = {...columnVisibility || {}};
                if (visible === undefined || visible === null) {
                    delete updated[column.getColId()];
                } else {
                    updated[column.getColId()] = visible;
                }
                return updated;
            });
        }
    }, []);

    const onColumnPinned = useCallback(({api, column, pinned, source}: ColumnPinnedEvent) => {
        if (!column) return;
        if (source === 'uiColumnDragged') {
            dragEndActionQueueRef.current.push(data => {
                const pins = data.pinnedColumns;

                let updated: typeof pins = {...pins || {}};
                if (pinned === undefined || pinned === null) {
                    delete updated[column.getColId()];
                } else {
                    updated[column.getColId()] = pinned as Pin;
                }
                if (!Object.keys(updated).length) updated = undefined;
                return {
                    ...data,
                    pinnedColumns: updated
                };
            });
        } else { // do it now, since we're not doing it as a result of a drag
            setPinnedColumns(pins => {
                let updated: typeof pins = {...pins || {}};
                if (pinned === undefined || pinned === null) {
                    delete updated[column.getColId()];
                } else {
                    updated[column.getColId()] = pinned as Pin;
                }
                if (!Object.keys(updated).length) updated = undefined;
                return updated;
            });
        }
    }, []);

    const onColumnResized = useCallback(({api, column, source, finished}: ColumnResizedEvent) => {
        if (!column) return;
        if (source !== 'uiColumnDragged' && source !== 'uiColumnResized') return; // only care about manual user resizes
        if (!finished) return; // only save once the user finishes resizing
        // const width = column.getActualWidth();
        
        dragEndActionQueueRef.current.push(data => ({
            ...data,
            columnWidths: {
                ...data.columnWidths,
                [column.getColId()]: column.getActualWidth()
            }
        }));
    }, []);

    const onColumnMoved = useCallback(({api, columnApi, column, source, toIndex}: ColumnMovedEvent) => {
        if (!column) return;
        if (toIndex === null || toIndex === undefined) return;
        if (source !== 'uiColumnDragged' && source !== 'uiColumnMoved') return; // only care when the _user_ moves something

        dragEndActionQueueRef.current.push(data => ({
            ...data,
            columnOrder: columnApi.getColumnState().map(x => x.colId)
        }));
    }, []);
    
    const onColumnMoveEnded = useCallback(({type}: DragStoppedEvent) => {
        let data: TableVisualData = {
            columnOrder,
            columnVisibility,
            columnWidths,
            pinnedColumns
        };

        log.debug('Resolving a queue of drag end actions', dragEndActionQueueRef.current);

        for (const action of dragEndActionQueueRef.current) {
            data = action(data);
        }

        // remember: order before visibility!
        if (data.columnOrder !== columnOrder) setColumnOrder(data.columnOrder);
        if (data.columnVisibility !== columnVisibility) setColumnVisibility(data.columnVisibility);
        if (data.columnWidths !== columnWidths) setColumnWidths(data.columnWidths);
        if (data.pinnedColumns !== pinnedColumns) setPinnedColumns(data.pinnedColumns);

        dragEndActionQueueRef.current = [];
    }, [columnOrder, columnVisibility, columnWidths, pinnedColumns]);

    // *** the following code is for debugging row count flashing, by randomizing the row count every second ***
    // useEffect(() => {
    //     // console.log('useEffect')
    //     setInterval(() => {
    //         const rc = 10000 + Math.round(Math.random()*1000);
    //         if (viewportParamsRef.current) {
    //             viewportParamsRef.current?.setRowCount(rc);
    //         }    
    //         onRowCountChanged?.(rc);
    //     }, 1000);
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    return <>
        <div className={classNames(theme, 'table-view')}>
            <div className="data">
                <GridWrapper
                    rowModelType="viewport"
                    rowSelection={selectable}
                    rowDeselection={true}
                    columnDefs={columnDefs}
                    suppressChangeDetection={true}
                    viewportDatasource={viewport}
                    viewportRowModelBufferSize={20}
                    viewportRowModelPageSize={20}
                    suppressColumnVirtualisation={true}
                    onGridReady={onGridReady}
                    onSortChanged={onSortChanged}
                    onSelectionChanged={onSelectionChanged}
                    onFilterChanged={onFilterChanged}
                    suppressScrollOnNewData={true}
                    popupParent={document.body}
                    getContextMenuItems={getContextMenuItems}
                    modules={AllModules}
                    sideBar={{
                        toolPanels: [
                            {
                                id: 'columns',
                                labelDefault: 'Columns',
                                labelKey: 'columns',
                                iconKey: 'columns',
                                toolPanel: 'agColumnsToolPanel',
                            },
                            {
                                id: 'filters',
                                labelDefault: 'Filters',
                                labelKey: 'filters',
                                iconKey: 'filter',
                                toolPanel: 'agFiltersToolPanel',
                            }
                        ],
                        defaultToolPanel: undefined
                    }}
            
                    onViewportChanged={onViewportChanged}
                    onColumnVisible={onColumnVisible}
                    onColumnPinned={onColumnPinned}
                    onColumnResized={onColumnResized}
                    onColumnMoved={onColumnMoved}
                    onDragStopped={onColumnMoveEnded}
                />
            </div>
            <div hidden={!totalsColumnDefs || !totalsColumnDefs.length} className="totals">
                <GridWrapper
                    columnDefs={totalsColumnDefs}
                    suppressColumnVirtualisation={true}
                    rowModelType="viewport"
                    viewportDatasource={totalsViewport}
                    onGridReady={onTotalsGridReady}
                    popupParent={document.body}
                    domLayout="autoHeight"
                />
            </div>
            <div hidden={!status} className="overlay" onClick={() => setStatus(undefined)}>
                <div className="status">
                    <img src={require('../../assets/spinner.svg')} />
                    <div>{status}...</div>
                </div>
            </div>
        </div>
    </>;
};

const connected = connect(TableView, (store: Store<AppState>) => {
    const props = store.watch(state => ({
        client: state.client
    }));
    return {props};
});
export default connected;
export {connected as TableView};