exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".filter-editor {\n  position: relative;\n  border-radius: 0.25rem;\n  border: 1.5px solid white;\n  padding: 0.25rem;\n  font-size: 1rem;\n  min-width: 8rem;\n  text-align: left;\n  color: white; }\n  .filter-editor:focus-within {\n    border: 1.5px solid #67a9ed; }\n    .filter-editor:focus-within label {\n      color: #67a9ed; }\n  .filter-editor label {\n    position: absolute;\n    overflow: hidden;\n    max-width: 100%;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    pointer-events: none;\n    font-size: 0.8rem;\n    top: 50%;\n    transform: translateY(-50%);\n    transition: font 0.25s ease 0s;\n    transition: top 0.25s ease 0s;\n    color: rgba(255, 255, 255, 0.54);\n    font-family: \"Roboto\", \"Helvetica\", \"Arial\", sans-serif;\n    font-weight: 400; }\n    .filter-editor label.shrink {\n      top: 0px;\n      font-size: 0.6rem;\n      text-shadow: -1px -1px 0 #FFF, 1px -1px 0 #FFF, -1px 1px 0 #FFF, 1px 1px 0 #FFF, -2px -2px 0 #FFF, 2px -2px 0 #FFF, -2px 2px 0 #FFF, 2px 2px 0 #FFF;\n      padding: 0 0.2rem;\n      max-width: 100%; }\n  .filter-editor .public-DraftStyleDefault-block {\n    white-space: pre;\n    overflow-x: hidden; }\n  .filter-editor .editor {\n    display: block;\n    width: 100%;\n    overflow: hidden;\n    color: white;\n    white-space: nowrap;\n    font-family: Consolas, 'Courier New', Courier, monospace;\n    min-height: 1em;\n    outline: 0;\n    border: 0; }\n  .filter-editor .error {\n    text-decoration-line: underline;\n    text-decoration-color: red; }\n    .filter-editor .error.empty {\n      content: ' '; }\n  .filter-editor .if-then-modal-trigger {\n    position: absolute;\n    right: 0.5rem;\n    top: 50%;\n    transform: translateY(-50%); }\n", ""]);

// exports
exports.locals = {
	"primary": "#67a9ed",
	"secondary": "#aeea00",
	"error": "red",
	"success": "#8bc293"
};