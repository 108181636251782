import superagent from '../api';
import localforage from 'localforage';

import {NewRiskScreen as NewRiskScreenContract, RiskScreen as RiskScreenContract, TableState as TableStateContract } from '../contracts';
import { randomString } from '../util/randomString';

// export async function upsertRiskScreen(payload: NewRiskScreenContract) {
//     try {
//         const resp = await superagent.get(`risk-screens/by-key/${key}`);
//         const existing: RiskScreenContract = resp.body;
//         const updatePayload = {...existing, ...payload};
//         await superagent.put(`risk-screens/${existing.id}`).send(updatePayload);
//     } catch (e) {
//     // it DNE, so make a new one
//         await superagent.post('risk-screens').send(payload);
//     }
// }

// export async function getRiskScreenByKey(key: string) {
//     const resp = await superagent.get(`risk-screens/by-key/${key}`);
//     const payload: RiskScreenContract = resp.body;
//     return payload;
// }

// ******* temporary work to reenable local saving ******* //

const riskScreenKey = 'risk-screens';

async function getAllScreensHack() {
    const all = await localforage.getItem<RiskScreenContract[]>(riskScreenKey) || [];
    return all;
}

async function setAllScreensHack(screens: RiskScreenContract[]) {
    await localforage.setItem(riskScreenKey, screens);
}

export async function upsertRiskScreen(payload: NewRiskScreenContract) {
    const all = await getAllScreensHack();
    const existing = all.find(x => x.key === payload.key);
    if (!existing) {
        const updated: RiskScreenContract[] = [...all, {...payload, id: randomString(), ownerLogin: 'current-user'}];
        await setAllScreensHack(updated);
    } else {
        const updated = all.map(x => x === existing ? {...x, ...payload} : x);
        await setAllScreensHack(updated);
    }
}

export async function getRiskScreenByKey(key: string): Promise<RiskScreenContract | undefined> {
    const all = await getAllScreensHack();
    return all.find(x => x.key === key);
}