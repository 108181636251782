import loglevel from 'loglevel';
const logger = loglevel.getLogger('table-service');

import { ConnectionStatus, Key, RawClient, TableClient, TimurClient } from '@thinkalpha/table-client';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import config from '../config/appConfig';
import {store} from '../state';
import { accessToken$ } from './auth';

const setClient = new Subject<RawClient>();
const setStatus = new Subject<ConnectionStatus>();
store.addReducer(setClient, (state, c) => ({...state, client: c}), 'setClient');
store.addReducer(setStatus, (state, status) => ({...state, connectionStatus: status}), 'setConnectionStatus');

const client = new TimurClient(config.proxy, undefined);

// tslint:disable-next-line: no-string-literal
setClient.next(client);

client.connectionStatus$.subscribe(setStatus);

store.watch(x => ({accessToken: x.accessToken})).pipe(
    filter(x => !!x.accessToken)
).subscribe(({accessToken}) => {
    logger.info('About to log in using token', accessToken);
    client.token = accessToken;
});

client.connectionStatus$.pipe(filter(x => x === ConnectionStatus.badToken)).subscribe(() => {
    accessToken$.next(undefined);
});

const exportedClient: RawClient = client;
export {exportedClient as client};
export default exportedClient;