import './dashboard.scss';

import {isEqual} from 'lodash';

import React, {useCallback, useState, useMemo, useRef, useEffect} from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Store } from 'reactive-state';
import { ActionMap, connect } from 'reactive-state/react';

import classnames from 'classnames';

import {LinkBar} from '../demo/link-bar';
import { AppState, currentStrategy$ } from '../state';
import {WidgetProps, WidgetComponent} from './widgets/widget';

import Measure from 'react-measure';
import GridLayout, { Layout, Layouts } from 'react-grid-layout';
import { useToggleState } from '../hooks/useToggleState';
import { Drawer, ButtonGroup, IconButton, Button, Menu, MenuItem, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import {AppDrawer} from '../app-drawer/app-drawer';

import {Workspace, Widget, WidgetType, KnownWidget, WidgetTypes, WidgetModel, WidgetTypeNames} from './model';

import {StrategyWidget} from './widgets/strategy/strategy';
import {ChartWidget} from './widgets/chart/chart';

import { randomString } from '../util/randomString';
import {useInputCallback} from '../hooks/useInputState';

import {getSavedWorkspaces, saveWorkspace, deleteWorkspace} from '../services/workspaces';
import { LinkButton } from '../components/linkButton';

const DashboardPage: React.FunctionComponent<RouteComponentProps & {}> = function DashboardPage({history}) {
    const [workspaces, setWorkspaces] = useState<Workspace[]>();
    const [selectedWorkspaceId, setSelectedWorkspaceId] = useState<string>();
    const [editingName, setEditingName, editName, stopEditingName, toggleEditingName] = useToggleState(false);
    const [workspacePendingDeletionId, setWorkspacePendingDeletionId] = useState<string>();

    // materialize workspace to save
    const prevWorkspaces = useRef(workspaces);
    useEffect(() => {
        if (!workspaces) return;
        if (!selectedWorkspaceId) return;
        if (prevWorkspaces.current) {
            const updatedWks = workspaces.filter(x => {
                const prevWk = prevWorkspaces.current!.find(y => y.id === x.id);
                return prevWk === undefined || !isEqual(prevWk, x);
            });
            const deleted = prevWorkspaces.current.filter(x => workspaces.every(y => y.id !== x.id));

            for (const updx of updatedWks) saveWorkspace(updx).subscribe();
            for (const deletex of deleted) deleteWorkspace(deletex.id).subscribe();
        }
        prevWorkspaces.current = workspaces;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workspaces]);

    // on load, retrieve saved workspaces
    useEffect(() => {
        const sub = getSavedWorkspaces().subscribe(wks => {
            setWorkspaces(wks);
            prevWorkspaces.current = wks;
            if (wks.length) setSelectedWorkspaceId(wks[0].id);
        });
        return () => sub.unsubscribe();
    }, []);

    // setup workspace selectors for when their button is clicked
    const workspaceSelectors = useMemo(() => workspaces && new Map(workspaces.map(wk => [
        wk.id,
        () => {
            if (selectedWorkspaceId === wk.id) {
                editName();
            } else {
                setSelectedWorkspaceId(wk.id);
            }
        }
    ])), [editName, selectedWorkspaceId, workspaces]);

    const [addWidgetMenuShown,, showAddWidgetMenu, hideAddWidgetMenu, toggleAddWidgetMenu] = useToggleState(false);
    const [drawerShown,, showDrawer, hideDrawer, toggleDrawer] = useToggleState(false);

    const addWidgetButtonRef = useRef<HTMLButtonElement>(null);

    const widgets = useMemo(() => workspaces && selectedWorkspaceId ? workspaces.find(x => x.id === selectedWorkspaceId)!.widgets : undefined, [selectedWorkspaceId, workspaces]);
    const setWidgets = useCallback((value: React.SetStateAction<KnownWidget[]>) => {
        setWorkspaces(wks => wks && wks.map(wk => wk.id !== selectedWorkspaceId ? wk : {...wk, widgets: typeof value === 'function' ? value(wk.widgets) : value}));
    }, [selectedWorkspaceId]);

    // make widget closers to remove widgets
    const closers = useMemo(() => widgets && new Map(widgets.map(wr => [
        wr,
        () => setWidgets(wrs => wrs!.filter(x => x !== wr))
    ])), [setWidgets, widgets]);

    // handle grid layout changes by persisting into widget layouts
    const onLayoutChanged = useCallback((layouts: Layout[]) => {
        setWidgets(widgets => widgets!.map((widget, i) => ({...widget, layout: layouts.find(lx => lx.i === widget.id) || widget.layout})));
    }, [setWidgets]);

    // make a grid layout from the widget layouts
    const layouts = widgets ? widgets.map((x, i): Layout => ({...x.layout, i: x.id})) : [];

    // make setters for widgets' models 
    const widgetModelSetters = useMemo(() => widgets && new Map(widgets.map(wr => [
        wr,
        (model?: WidgetModel) => setWidgets(wrs => wrs!.map(wrx => wrx.id !== wr.id ? wrx : {...wrx, model: model as any}))
    ])), [setWidgets, widgets]);

    const workspaceDeleters = useMemo(() => workspaces && new Map(workspaces.map(wk => [
        wk.id,
        () => setWorkspacePendingDeletionId(wk.id)
    ])), [workspaces]);

    const okDelete = useCallback(() => {
        if (!workspaces) return;
        if (!workspacePendingDeletionId) return;
        const wk = workspaces.find(x => x.id === workspacePendingDeletionId);
        if (!wk) return;

        const index = workspaces.indexOf(wk);
        let newIndex: number | undefined = undefined;
        if (workspaces.length > 1) {
            if (workspaces.length <= index) {
                newIndex = index - 1;
            } else {
                newIndex = index + 1;
            }
        }
        const newWorkspace = newIndex !== undefined ? workspaces[newIndex] : undefined;
        const newId = newWorkspace && newWorkspace.id;
        setSelectedWorkspaceId(newId);
        setWorkspaces(wks => wks && wks.filter(x => x !== wk));

        setWorkspacePendingDeletionId(undefined);
    }, [workspacePendingDeletionId, workspaces]);

    const cancelDelete = useCallback(() => {
        setWorkspacePendingDeletionId(undefined);
    }, []);

    const getWidgetFor = useCallback((wr: KnownWidget): React.ReactNode => {
        const widgetProps = {
            onClose: closers!.get(wr)!,
            model: wr.model as any,
            onChange: widgetModelSetters!.get(wr)!
        };
        switch (wr.type) {
            case WidgetType.strategy:
                return <StrategyWidget {...widgetProps} />;
            case WidgetType.graph:
                return <ChartWidget {...widgetProps} />;
            default:
                return <></>;
        }
    }, [closers, widgetModelSetters]);

    const widgetAdders = useMemo(() => new Map(WidgetTypes.map(wt => [
        wt,
        () => {
            setWidgets(widgets => [...widgets!, {
                id: randomString(),
                type: wt,
                model: undefined,
                layout: {x: 0, y: 0, w: 6, h: 3}
            }]);
            hideAddWidgetMenu();
        }
    ])), [hideAddWidgetMenu, setWidgets]);

    const addWorkspace = useCallback(() => {
        setWorkspaces(wks => wks && [...wks, {id: randomString(), name: 'New Workspace', widgets: []}]);
    }, []);

    const onNameChanged = useInputCallback(name => {
        setWorkspaces(wks => wks!.map(wk => wk.id !== selectedWorkspaceId ? wk : {...wk, name}));
    }, [selectedWorkspaceId]);

    const workspacePendingDeletion = workspacePendingDeletionId ? workspaces!.find(x => x.id === workspacePendingDeletionId) : undefined;

    return <>
        <AppDrawer onClose={hideDrawer} open={drawerShown}>

        </AppDrawer>
        <main id="dashboard-page" className={classnames('drawerable', {'shift-for-drawer': drawerShown})}>
            <LinkBar onMenu={drawerShown ? undefined : toggleDrawer}>
                {workspaces && <>
                    <Button size="small" variant="outlined" hidden={selectedWorkspaceId === undefined || !widgets} ref={addWidgetButtonRef} onClick={toggleAddWidgetMenu}>Widgets&nbsp;<i className="fas fa-caret-down"/></Button>
                    <LinkButton size="small" variant="outlined" to="/if-then">If/Then Builder</LinkButton>
                    &nbsp;
                    <ButtonGroup size="small">
                        {workspaces.map(x => <Button variant={selectedWorkspaceId === x.id ? 'contained' : 'outlined'} onClick={workspaceSelectors!.get(x.id)} color={selectedWorkspaceId === x.id ? 'primary' : 'default'} value={x.id} key={x.id}>
                            {selectedWorkspaceId === x.id && editingName
                                ? <TextField value={x.name} autoFocus onChange={onNameChanged} onBlur={stopEditingName} />
                                : x.name
                            }
                            <IconButton size="small" edge="end" className="delete" onClick={workspaceDeleters!.get(x.id)}><i className="fas fa-times"/></IconButton>
                        </Button>)}
                        <Button onClick={addWorkspace}><i className="fas fa-plus"/></Button>
                    </ButtonGroup>
                </>}
            </LinkBar>
            <Measure bounds>{({contentRect, measureRef}) =>
                <div id="grid" ref={measureRef}>
                    {contentRect.bounds && <GridLayout
                        draggableHandle="header>h3, header>.end>.expand"
                        compactType={null}
                        preventCollision={true}
                        onLayoutChange={onLayoutChanged}
                        width={contentRect.bounds.width}
                        cols={24}
                        rowHeight={50}
                        layout={layouts}
                        margin={[2, 2]}
                    >
                        {widgets && widgets.map((wr, i) => (
                            <div key={wr.id}>
                                {getWidgetFor(wr)}
                            </div>
                        ))}
                    </GridLayout>}
                </div>
            }</Measure>
        </main>
        <Menu anchorEl={addWidgetButtonRef.current} open={addWidgetMenuShown} onClose={hideAddWidgetMenu}>
            {WidgetTypes.map(wt => <MenuItem key={wt} onClick={widgetAdders.get(wt)}>Add {WidgetTypeNames.get(wt)!}</MenuItem>)}
        </Menu>
        <Dialog open={workspacePendingDeletionId !== undefined} onClose={cancelDelete}>
            <DialogTitle>Are You Sure?</DialogTitle>
            {workspacePendingDeletion && <DialogContent>
                Are you sure you want to delete workspace <code>{workspacePendingDeletion.name}</code>?
            </DialogContent>}
            <DialogActions>
                <Button color="primary" onClick={okDelete}>Delete</Button>
                <Button color="secondary" onClick={cancelDelete}>Cancel</Button>
            </DialogActions>
        </Dialog>
    </>;
};

const connected = connect(withRouter(DashboardPage), (store: Store<AppState>) => {
    const props = store.watch(state => {
        return {
            strategy: state.currentStrategy && state.currentStrategy.strategy
        };
    });
    const actionMap: ActionMap<typeof DashboardPage> = {
        setStrategy: currentStrategy$
    };
    return {props, actionMap};
});
export {connected as DashboardPage};
export default connected;