import './timeframe.scss';

import _ from 'lodash';
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import {createPortal} from 'react-dom';
import FilterEditor from '../../components/filter-editor/filter-editor.new';
import { useInputState, useNumberInputState, useStringBooleanInputState, useCheckedInputState, useInputCallback, useNumberInputCallback } from '../../hooks/useInputState';
import { TextField, MenuItem, Checkbox, FormControlLabel, Radio, Button, RadioGroup, Tabs, Tab, Box } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import {SlidingWindow, PointInTime as PointInTimeModel, AbsolutePointInTime, PeriodNameMap, PeriodNames, PeriodType, HistoryType, Timeframe, RelativePointInTime} from '../timeframe/model';
import { useDeepEffect } from '../../hooks/useDeepEffect';
import moment, { Duration, duration } from 'moment';
import {barsAvailable, barParse, barToString, barsBetween} from './bars';
import { HistoricType, Field } from '../../components/filter-editor/model';
import {FieldDescriptor, CategoryForFieldType, MarketSession} from '@thinkalpha/table-client';
import { allStrategyFields } from '../../if-then/strategy/fields.hardcoded';
import {useModelCommitter} from '../../hooks/useModelCommitter';
import { AbsolutePointInTimeComponent } from '../timeframe/absolute';
import { SlidingWindowComponent } from '../timeframe/sliding';
import classNames from 'classnames';
import { PointInTime } from './pointInTime';

type Props = {
    range?: boolean;
    bars?: boolean;
    value?: Timeframe;
    onChange: (model?: Timeframe) => void;
    disabled?: boolean;
    barsLimit?: number;
};

export const TimeframeComponent: React.FC<Props> = ({disabled = false, range = false, bars = false, barsLimit = 200, value: model, onChange}) => {
    const [bar, setBar] = useState<SlidingWindow>();

    const [start, setStart] = useState<PointInTimeModel | undefined>(model ? model.start : {periods: [{value: 5, period: PeriodType.minute}], anchor: undefined});
    const [end, setEnd] = useState<PointInTimeModel | undefined>(!range ? null : model?.end);

    const onBarChanged = useInputCallback(value => {
        setBar(barParse(value));
    });
    
    const valid = useMemo(() => {
        if (bars && !bar) return false;
        if (!start) return false;
        if (end === undefined) return false; // could be null, which is okay

        return true;
    }, [bar, bars, end, start]);

    useModelCommitter(
        model,
        onChange,
        {bar, bars, end, start, valid},
        ({bar, bars, end, start, valid}) => { // committer
            if (!valid) {
                // console.info('not valid', start, end);
                return false;
            }
            return {
                start: start!,
                end: range ? (end || null): undefined,
                barLength: bar
            };
        },
        model => { // materializer
            if (!model) {
                setBar(undefined);
                setStart(undefined);
                setEnd(range ? undefined : null);
            } else {
                setBar(model.barLength);
                setStart(model.start);
                setEnd(range ? model.end! : null);
            }
        }
    );

    const barsAllowed = useMemo(() => {
        if (!bars) return [];
        if (!range) return barsAvailable; // any bars are good for non-range
        if (start === undefined || end === undefined) return barsAvailable; // can't calculate bar availability yet
        if ((typeof start === 'number' || typeof end === 'number') && typeof start !== typeof end) return barsAvailable;
        return barsAvailable.filter(len => {
            const barCount = barsBetween({start, end, barLength: len});
            return barCount >= 1 && barCount <= barsLimit; // no fractional bars < 0 -- that's just dumb!
        });
    }, [bars, barsLimit, end, range, start]);

    useEffect(() => {
        if (!barsAllowed.includes(bar!)) {
            setBar(barsAllowed[barsAllowed.length - 1]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [barsAllowed]);

    const DataAvailable = () => <TextField variant="outlined" label="Data Available" hidden={!bars} select value={bar ? barToString(bar) : ''} onChange={onBarChanged}>
        {barsAllowed.map(bar => <MenuItem key={barToString(bar)} value={barToString(bar)}>{bar.value} {PeriodNameMap.get(bar.period)!} bars</MenuItem>)}
    </TextField>;

    return <div className={classNames({'timeframe-selector': true, disabled})}>
        <div className="dynamic">
            <div className="start">
                <PointInTime bars={bars} label={range ? 'Start Time' : 'Point in Time'} model={start} onChange={setStart} />
            </div>
            <div className="end" hidden={!range}>
                <PointInTime barsOnly={typeof start === 'number'} bars={bars && typeof start === 'number'} label="End Time" model={end} onChange={setEnd} showNow />
            </div>
            <DataAvailable />
        </div>
    </div>;
};

export function isRelativePointInTime(x: PointInTimeModel | undefined): x is RelativePointInTime {
    if (x === null) return true;
    if (typeof x !== 'object') return false;
    return ('periods' in x);
}