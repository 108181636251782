import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Store } from 'reactive-state';
import { connect, ActionMap } from 'reactive-state/react';

import { AppState, filterDictionaryOptions$, FilterDictionaryOptions } from '../../state';

import Draggable from 'react-draggable';

import './filter-dictionary.scss';
import classnames from 'classnames';
import { IconButton, Tooltip, Tabs, Tab, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Checkbox } from '@material-ui/core';
import { FunctionDef, functionDefs } from '../../syntax/functions';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import { IndicatorGrouping, Indicator, IndicatorCategory, IndicatorClassType, IndicatorPackage, IndicatorClass, IndicatorGroupingKind } from '../filter-editor/model';


import { randomString } from '../../util/randomString';
import { useToggleState } from '../../hooks/useToggleState';

import './tabs.scss';
import './indicators.scss';

interface Props {
    // groupings: IndicatorGrouping[];
    // indicators: Indicator[];
    // categories: IndicatorCategory[];
    // classTypes: IndicatorClassType[];
    // packages: IndicatorPackage[];
    // classes: IndicatorClass[];

    setFilterDictionaryOptions: (options: FilterDictionaryOptions) => void;
}

interface IndicatorFilters {
    [groupingId: string]: {
        [subGroupingId: string]: {
            categories: {[categroryId: string]: {checked: boolean, count: number}};
            checked: boolean;
        };
    };
}

interface IndicatorFilterResult {
    [groupingId: string]: {
        [categoryId: string]: Indicator[];
    };
}

const getDictionary = (array) => array.reduce((acc, cur) => ({...acc, [cur.id]: cur}), {});

const IndicatorProp = ({name, children}) => {
    return <div className="indicator-prop">
        <div className="name">{name}</div>
        <div className="content">{children}</div>
    </div>;
};

const Indicators: React.FC<Props> = ({
    setFilterDictionaryOptions, 
    // groupings, categories, packages, classTypes, classes, indicators
}) => {
    const categories = useRef<IndicatorCategory[]>([
        {name: 'Intra day Prices', id: randomString()},
        {name: 'Intra day Returns', id: randomString()},
        {name: 'Intra day Liquidity', id: randomString()},
        {name: 'Prices', id: randomString()}
    ]);

    const packages = useRef<IndicatorPackage[]>([
        {name: 'Exegy Real-Time Indicators', id: randomString()},
        {name: 'User Proprietary Indicators - Group 1', id: randomString()},
        {name: 'Technical Indicators – 101 Al phas', id: randomString()},
    ]);

    const classTypes = useRef<IndicatorClassType[]>([
        {name: 'Indicator classes (Stock related)', id: randomString()},
        {name: 'Regime related classes (Stock related)', id: randomString()},
    ]);

    const classes = useRef<IndicatorClass[]>([
        {name: 'Intra day Market Action', id: randomString()},
        {name: 'End-Of-Day Market Action', id: randomString()},
        {name: 'End-Of-Day Technicals (Prices)', id: randomString()}
    ]);

    const groupings = useRef<IndicatorGrouping[]>([
        {name: 'Indicator Packages', id: randomString(), kind: 'Package'},
        {name: 'Indicator Classes: (Stock-Related)', id: randomString(), kind: 'Class'},
        {name: 'Indicator Classes: (Regime-Related)', id: randomString(), kind: 'Class'}
    ]);

    const getRandom = array => array[Math.floor(Math.random()*array.length)];

    const generateMockIndicators = (): Indicator[] => {
        const cat1 = categories.current.find(x => x.name === 'Intra day Prices');
        const ct = classTypes.current.find(x => x.name === 'Indicator classes (Stock related)');
        const cl = classes.current.find(x => x.name === 'Intra day Market Action');
        const g = groupings.current.find(x => x.name === 'Indicator Classes: (Stock-Related)');

        return [...[...Array(9).keys()].map(x => {
            const category = getRandom(categories.current);
            const grouping = getRandom(groupings.current);
            const indicator: Indicator = {
                id: randomString(),
                name: `Indicator ${x}`,
                categoryId:  category.id,
                occurences: [
                    {kind: 'Package', name: 'Some package'},
                    {kind: 'Class', name: 'Some class'},
                ],
                classId: getRandom(classes.current).id,
                groupingId: grouping.id,
            };
            if (grouping.kind === 'Class') {
                indicator.classTypeId = getRandom(classTypes.current).id;
            } else {
                indicator.packageId = getRandom(packages.current).id;
            }

            return indicator;
        }), {
            categoryId: cat1!.id,
            classId: cl!.id,
            classTypeId: ct!.id,
            groupingId: g!.id,
            id: randomString(),
            name: 'MACD_X1',
            occurences: [],
        }];
    };

    const indicators = useRef(generateMockIndicators());

    const [filtersVisible,, showFilters, hideFilters] = useToggleState(false);
    const [filterType, setFilterType] = useState<string>();
    const [filterSubGrouping, setFilterSubGrouping] = useState<string>();
    const [filters, setFilters] = useState<IndicatorFilters>({});
    const [filterResult, setFilterResult] = useState<IndicatorFilterResult>({});
    const [selectedIndicator, setSelectedIndicator] = useState<Indicator>();

    const onSelect = useCallback(field => {
        setFilterDictionaryOptions({show: true, disabled: false, selected: field});
    }, [setFilterDictionaryOptions]);

    const categoryDictionary: {[categoryId: string]: IndicatorCategory} = getDictionary(categories.current);
    const packageDictionary: {[packageId: string]: IndicatorPackage} = getDictionary(packages.current);
    const classesDictionary: {[classTypeId: string]: IndicatorClassType} = getDictionary(classes.current);
    const groupingDictionary: {[groupingId: string]: IndicatorGrouping} = getDictionary(groupings.current);

    // TODO investigate if it will be possible to modify fields while window is open?
    useEffect(() => {
        const temp: IndicatorFilters = {};
        indicators.current.forEach(indicator => {
            const {groupingId, classId, packageId, categoryId } = indicator;
            if (!temp[groupingId]) temp[groupingId] = {};
            const subTypeId = groupingDictionary[groupingId].kind === 'Class' ? classId! : packageId!;
            if (!temp[groupingId][subTypeId]) temp[groupingId][subTypeId] = {categories: {}, checked: false};
            if (!temp[groupingId][subTypeId].categories[categoryId]) temp[groupingId][subTypeId].categories[categoryId] = {count: 0, checked: false};
            temp[groupingId][subTypeId].categories[categoryId].count++;
        });
        setFilters(temp);
    }, []);

    useEffect(() => {
        const res: IndicatorFilterResult = {};
        // First filter for indicators that satisfy criteria
        indicators.current.forEach(indicator => {
            const {groupingId, categoryId } = indicator;
            if (!res[groupingId]) res[groupingId] = {};
            if (!res[groupingId][categoryId]) res[groupingId][categoryId] = [];
            res[groupingId][categoryId] = [...res[groupingId][categoryId], indicator];
        });
        setFilterResult(res);
    }, [filters]);

    return <div className="indicators-container">
        <div className="left-pane">
            <div className="options">
                <div className="active-filters">Search</div>
                <div className="sort-by">Sort by ▾</div>
                <div className="filters-container">
                    <div onMouseEnter={showFilters} className="action">Filter ▾</div>
                    <div hidden={!filtersVisible} onMouseLeave={hideFilters} className="filters">
                        <div className="type-column">
                            <div className="column-caption">Type</div>
                            {Object.keys(filters).map(groupingKey => 
                                <div key={groupingKey}
                                    className={classnames({
                                        'filter-type': true,
                                        'active': groupingKey === filterType
                                    })}
                                    onClick={() => {
                                        setFilterSubGrouping(undefined);
                                        setFilterType(groupingKey);
                                    }} >{groupingDictionary[groupingKey].name}</div>
                            )}
                        </div>
                        <div className="subgrouping-column">
                            {filterType && Object.keys(filters[filterType]).map(subGroupingId => {
                                const grouping = groupingDictionary[filterType];
                                return <div key={subGroupingId}
                                    onClick={() => setFilterSubGrouping(subGroupingId)} 
                                    className={classnames({
                                        'filter-subgrouping': true,
                                        'active': subGroupingId === filterSubGrouping
                                    })}> 
                                    {/* TODO Hardcoded */}
                                    <Checkbox onClick={e => e.stopPropagation()} checked={true} color="default" />
                                    <div>{grouping.kind === 'Class' ? classesDictionary[subGroupingId].name : packageDictionary[subGroupingId].name}</div>
                                </div>;
                            }
                            )}
                        </div>
                        <div className="category-column">
                            <div className="column-caption">Category</div>
                            {filterSubGrouping && Object.keys(filters[filterType!][filterSubGrouping].categories).map(categoryId => {
                                return <div key={categoryId}
                                    className={classnames({
                                        'filter-category': true,
                                    })}> 
                                    {/* TODO Hardcoded */}
                                    <Checkbox onClick={e => e.stopPropagation()} checked={true} color="default" />
                                    <div>{categoryDictionary[categoryId].name}</div>
                                </div>;
                            }
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="filter-results">
                <div className="title">Filter results</div>
                {Object.keys(filterResult).map(groupingKey => {
                    const grouping = groupingDictionary[groupingKey];
                    const current = filterResult[grouping.id];
                    
                    return <ExpansionPanel defaultExpanded className="grouping-panel" key={grouping.id}>
                        <ExpansionPanelSummary className="grouping-name" expandIcon={<i className="fal fa-angle-up"></i>}>
                            {grouping.name}
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className="grouping-panel-details">
                            {Object.keys(current).map(categoryId => {
                                const indicators = current[categoryId];
                                return <ExpansionPanel key={categoryId}>
                                    <ExpansionPanelSummary expandIcon={<i className="fal fa-angle-up"></i>}>
                                        <div>{categoryDictionary[categoryId].name}</div>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className="category-panel">
                                        {indicators.map(indicator => {
                                            return <div onClick={() => setSelectedIndicator(indicator)} className="indicator" key={indicator.id}>
                                                <div>{indicator.name}</div>
                                            </div>;
                                        })}
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>;
                            })}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>;
                })}
            </div>
        </div>
        <div className="right-pane">
            {selectedIndicator && <>
                <div className="title">MACD_X1</div>
                <IndicatorProp name='Abbreviation'>Stock-Related</IndicatorProp>
                <IndicatorProp name='Short-Name'>time = crosstime(VarA, VarB)</IndicatorProp>
                <IndicatorProp name='Indicator Identifier'>6,490</IndicatorProp>
                <IndicatorProp name='Indicator Class'>Technical Indicators (EOD Prices)</IndicatorProp>
                <IndicatorProp name='Indicator Category'>MACD</IndicatorProp>
                <IndicatorProp name='Variable 1 of 12'>Spread between the short and long-term moving averages, divided by the long-term average</IndicatorProp>
                <IndicatorProp name='Units'>% of Price</IndicatorProp>
                <IndicatorProp name='# of Specifications'>9</IndicatorProp>
                <IndicatorProp name='Full Description'>Spread between Short & Long Term Moving Averages, % of Price: 1 and 10 Day Arithmetic Moving Average Cross (Total Return Indexes) (9 Day Signal Line EMA)</IndicatorProp>

                <div className="specification">Specification</div>
                <div className="spec-header">
                    <div>NAME</div>
                    <div>CURRENT VALUE</div>
                    <div>REVISED VALUE</div>
                </div>

                <div className="spec-container">
                    {[
                        ['Final Normalization', 'None' ,'None ▾'],
                        ['Periodicity', 'Daily' ,'Daily ▾'],
                        ['Periods to Lag Indicator', '0' ,'0 ▾'],
                        ['Type of Prie', 'Total Returns' ,'Total Returns ▾'],
                        ['Volume Weighting', 'None' ,'None ▾'],
                        ['Type of Moving Average', 'Arithmetic' ,'Arithmetic ▾'],
                        ['Periods in Short-Term MA', '1' ,'1 ▾'],
                        ['Periods in Long-Term MA', '10' ,'10 ▾'],
                        ['Periods in Signal Line', '9' ,'9 ▾'],
                        ['Specification #10', '' ,'None ▾'],
                    ].map((x, i) => <div key={i} className='spec-line'>
                        <div>{i}. {x[0]}</div>
                        <div>{x[0]}</div>
                        <div>{x[0]}</div>
                    </div>)}
                </div>
            </>}
        </div>
    </div>;
};

export default Indicators;