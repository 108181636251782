import {useEffect, useState, useRef} from 'react';
import { Subscription } from 'rxjs';

export function useSubscription(subscribe: () => Subscription, deps: any[] = []) {
    const subRef = useRef<Subscription>();
    useEffect(() => {
        subRef.current = subscribe();

        return () => {
            if (subRef.current) subRef.current.unsubscribe();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}