import loglevel from 'loglevel';
const log = loglevel.getLogger('completer');
log.setDefaultLevel('info');

import {Token, Range, CompletionType, CompletionOption, CompletionContext, FunctionContext, Field, HistoricType, TokenType} from './model';
import { FieldTypeCategory, FieldDescriptor, CategoryForFieldType } from '@thinkalpha/table-client';
import _ from 'lodash';
import {overlappingRange} from '../../util/overlappingRange';
import { functionDefs } from '../../syntax/functions';
import { KnownAstNode, isWhitespace, RangeType, FunctionCallNode, getRanges } from '@thinkalpha/language-services';

export function completer(
    tokenizedText: string, tokens: Token[], textPosition: number,
    parsedText: string, ast: KnownAstNode | undefined, astPosition: number,
    fields: readonly Field[],
    allowedType?: FieldTypeCategory
): CompletionContext {

    const ranges = getRanges(ast, allowedType);
    // const allNodes = flattenAst(root);
    // let currentNode: Ast.AstNode | undefined = _(allNodes).filter(x => overlappingRange(x.range, index)).minBy(x => x.range.end - x.range.start);
    // let currentNode: Ast.AstNode | undefined = _(allNodes).filter(x => overlappingRange(x.range, index)).minBy(x => x.range.end - x.range.start);
    
    const currentTokens = tokens.filter(x => overlappingRange(x.range, textPosition));
    const currentToken = currentTokens.find(x => x.type === TokenType.Name);

    const options: CompletionOption[] = [];

    let typeFilter: FieldTypeCategory | undefined;
    let textFilter: string | undefined;

    function addFunctions() {
        const matchingFunctions = _(functionDefs)
            .filter(x => x.name.toLowerCase().startsWith(textFilter ? textFilter.toLowerCase() : '') && (typeFilter ? x.returnType === typeFilter : true));
        log.debug('adding functions', matchingFunctions.value());
        options.push(...matchingFunctions.map((x): CompletionOption => ({
            text: x.name,
            displayText: x.name,
            description: x.description,
            dataType: x.returnType,
            type: CompletionType.function,
            historic: false
        })).value());
    }

    function addFields() {
        const matchingFields = _(fields)
            .filter(x => x.name.toLowerCase().startsWith(textFilter ? textFilter.toLowerCase() : '') && (typeFilter ? x.type === typeFilter : true));
        log.debug('adding fields', matchingFields.value(), 'with type filter', typeFilter);
        options.push(...matchingFields.map((x): CompletionOption => ({
            text: x.sourceTable ? `${x.name}@${x.sourceTable}` : x.name,
            displayText: x.name,
            description: x.description,
            dataType: x.type,
            source: x.sourceTable,
            type: CompletionType.field,
            historic: x.historic !== HistoricType.none
        })).value());
    }

    if (!ranges.length) {
        // there's no nodes at all, so there's no context to interpret
        typeFilter = allowedType;

        // add global completion options, such as functions and fields
        addFunctions();
        addFields();
        return {options, index: astPosition, text: tokenizedText};
    }

    // see if we're inside of a function call's argument's range
    const argumentRange = _(ranges).orderBy(x => x.depth, 'desc').find(x => x.rangeType === RangeType.arguments);
    // for (; functionNode !== undefined && functionNode.type !== Ast.AstNodeType.functionCall; functionNode = functionNode.parent);
    
    let functionContext: FunctionContext | undefined;
    if (argumentRange) {
        const functionNode = argumentRange.source as FunctionCallNode;
        const {functionName, argumentRanges, functionDef} = functionNode;

        // found a function, so set up a function context
        // const matchingArgIdx = functionDef && argumentRanges ? argumentRanges.findIndex(x => overlappingRange(x, index)) : undefined;
        const matchingParam = functionDef && functionDef.params[argumentRange.index!];

        if (matchingParam) {
            // setting type filter
            typeFilter = matchingParam.type;
            // console.log('setting type filter due to function context', typeFilter);
        }

        functionContext = {
            name: functionName,
            params: functionDef && functionDef.params.map(param => ({
                name: param.name,
                optional: param.optional || false,
                dataType: param.type,
                description: undefined
            })) || undefined,
            paramIndex: argumentRange.index!,
            returnType: functionDef && functionDef.returnType || undefined
        };
    }

    // const deepestRange = _(ranges).orderBy(x => x.depth, 'desc').first()!;
    // if (overlappingRange(deepestRange.source.range, index)) {
    //     // we're actually within a node's text, so complete or replace

    //     textFilter = currentToken ? currentToken.token : text.substring(deepestRange.source.range.start, deepestRange.source.range.end);
    //     typeFilter = deepestRange.allowedType;

    //     addFields();
    //     addFunctions();
    // } else {
    const rangeTypesCaredAbout: RangeType[] = [
        RangeType.column,
        RangeType.functionName,
        RangeType.content,
        RangeType.operand,
        RangeType.argument
    ];
    const deepestImportantRange = _(ranges)
        .filter(x => overlappingRange(x.range, astPosition))
        .orderBy(x => x.depth, 'desc')
        .find(x => rangeTypesCaredAbout.includes(x.rangeType));

    if (deepestImportantRange) {
        // const deeperExisting = _(ranges)
        //     .filter(x => overlappingRange(x.range, deepestImportantRange.range))
        //     .filter(x => x.depth > (deepestImportantRange ? deepestImportantRange.depth : -1))
        //     .value();
        // const isDeeperExisting = deeperExisting.length !== 0;

        // if (isDeeperExisting) {
        //     // We're in a range we know how to complete, but there's something deeper.
        //     // Are we on top of the deeper thing, also?

        //     const onTopOfIt = deeperExisting.find(x => overlappingRange(x.range, astPosition));

        //     console.log('isdeeperexisting', isDeeperExisting, 'ontopofit', onTopOfIt);
        // } else {
        //     // We're in a range we know how to complete, and nothing is deeper than us.
        //     // So, we're free to insert whatever we think is appropriate

        //     console.log('isdeeperexisting', isDeeperExisting);
        // }

        const rangeText = parsedText.substring(deepestImportantRange.range.start, deepestImportantRange.range.end);
        if (currentToken || isWhitespace(rangeText) || !rangeText) {
            if (currentToken) {
                textFilter = currentToken.token;
            }

            typeFilter = deepestImportantRange.allowedType;

            if (deepestImportantRange.rangeType !== RangeType.functionName) {
                // you can't downgrade a function name to a field name
                addFields();
            }
            // but you can upgrade a field name to a function name
            addFunctions();
        }
    }

    // switch (currentNode.type) {
    //     case Ast.AstNodeType.functionCall: {
    //         const {functionNameRange, functionName, argumentRanges, argumentsRange} = currentNode as Ast.FunctionCallNode;
    //         const func = functionDefs.find(x => x.name === functionName);
    //         // are we within the function name?
    //         if (overlappingRange(functionNameRange, index)) {
    //             addFunctions();
    //             break;
    //         }
    //         if (argumentRanges) {
    //             // are we within an argument's range
    //             const matchingArg = argumentRanges.findIndex(x => overlappingRange(x, index));
    //             if (matchingArg !== -1) {
    //                 // yes, we are
    //                 const param = func && func.params[matchingArg];
    //                 typeFilter = param && param.type;
    //                 // console.log('setting type filter', typeFilter);
    //                 addFunctions();
    //                 addFields();
    //                 break;
    //             }
    //         }
    //         if (argumentsRange) {
    //             // are we within the arg space at all?
    //             const allArgsRange = {start: argumentsRange.start + 1, end: argumentsRange.end - 1};
    //             if (overlappingRange(index, allArgsRange)) {
    //                 const param = func && func.params[0];
    //                 typeFilter = param && param.type;
    //                 addFunctions();
    //                 addFields();
    //                 break;
    //             }
    //         }
            
    //         break;
    //     }
    //     case Ast.AstNodeType.string: {
    //         const {isUnquoted} = currentNode as Ast.StringNode;
    //         if (!isUnquoted) break; // we can't complete a real string
    //         // this actually is a column, so fall through! vvvvv
    //     }
    //     case Ast.AstNodeType.column:
    //         addFunctions();
    //         addFields();
    //         break;
    // }

    return {functionContext, options, index: astPosition, text: tokenizedText};
}

export default completer;