import React from 'react';

import Paper, { PaperProps } from '@material-ui/core/Paper';
import Draggable from 'react-draggable';

export const DraggablePaper: React.FC<PaperProps> = (props: PaperProps) => {
    return (
        <Draggable handle=".handle" cancel={'[class*="MuiDialogContent-root"]'} enableUserSelectHack={false}>
            <div style={{position: 'relative'}}>
                <i className="fas fa-arrows-alt handle" style={{position: 'absolute', top: '3.5rem', right: '3.5rem', zIndex: 100000, cursor: 'move'}}/>
                <Paper {...props} />
            </div>
        </Draggable>
    );
};

export default DraggablePaper;