import _ from 'lodash';

import { FormControlLabel, FormGroup, Input, MenuItem, Radio, RadioGroup, Select, Checkbox, Button, TextField } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Range, RangedUniverseAspect, UniverseAspectType, RangeGroup, MarketCapSize } from '../../model';
import { RangeCreator } from './range';
import { useCheckedInputState, useInputCallback } from '../../../hooks/useInputState';
import { useModelCommitter } from '../../../hooks/useModelCommitter';

type PremadeOption = RangeGroup & {label: string};
const premadeVolumeRanges: readonly PremadeOption[] = [
    {label: 'Less than 10K', above: false, below: true, ranges: [10_000]},
    {label: 'Between 10k and 100k', above: false, below: false, ranges: [{start: 1e4, end: 1e5}] },
    {label: 'Between 100k and 1m', above: false, below: false, ranges: [{start: 1e5, end: 1e6}] },
    {label: 'Between 1m and 10m', above: false, below: false, ranges: [{start: 1e6, end: 1e7}] },
    {label: 'Greater than 10m', above: true, below: false, ranges: [1e7] }
];
const premadeMarketCapRanges: readonly PremadeOption[] = [
    { label: MarketCapSize.large, above: false, below: false, ranges: [{start: 10_000_000_000, end: 200_000_000_000}] },
    { label: MarketCapSize.mid, above: false, below: false, ranges: [{start: 2_000_000_000, end: 10_000_000_000}] },
    { label: MarketCapSize.small, above: false, below: false, ranges: [{start: 300_000_000, end: 2_000_000_000}] },
    { label: MarketCapSize.micro, above: false, below: false, ranges: [{start: 50_000_000, end: 300_000_000}] },
    { label: MarketCapSize.nano, above: false, below: false, ranges: [{start: 0, end: 50_000_000}] },
];
for (const x of premadeVolumeRanges) Object.freeze(x);
for (const x of premadeMarketCapRanges) Object.freeze(x);
Object.freeze(premadeVolumeRanges);
Object.freeze(premadeMarketCapRanges);

export type RangedUniverseType = RangedUniverseAspect['type'];

const customMoniker = 'custom';

export const RangedAspectCreator: React.FunctionComponent<{type: RangedUniverseType, aspect?: RangedUniverseAspect, onChange?: (aspect?: RangedUniverseAspect) => void}> = ({type, aspect, onChange}) => {
    const [ranges, setRanges] = useState<(Range | undefined)[]>(aspect ? aspect.ranges : [undefined]);
    const [above, setAbove, onAboveChanged] = useCheckedInputState(aspect ? aspect.above : false);
    const [below, setBelow, onBelowChanged] = useCheckedInputState(aspect ? aspect.below : false);

    useModelCommitter<RangedUniverseAspect, any>(
        aspect,
        onChange,
        {ranges, above, below},
        () => {
            if (ranges.length) {
                if (ranges.find(x => x !== undefined)) {
                    return {
                        ranges: ranges.filter((x): x is Range => x !== undefined),
                        above,
                        below,
                        type
                    };
                } else {
                    return false;
                }
            } else {
                return undefined;
            }
        },
        model => {
            if (model) {
                const {ranges, above, below} = model;
                setRanges(ranges.length ? ranges : [undefined]);
                setAbove(above);
                setBelow(below);
            } else {
                setRanges([undefined]);
                setAbove(false);
                setBelow(false);
            }
        }
    );

    const rangeChangers = useMemo(() => ranges.map((x, i) => (range: Range) => setRanges(ranges => {
        ranges = [...ranges];
        ranges[i] = range;
        return ranges;
    })), [ranges]);

    const premadeOptions = useMemo(() => {
        switch (type) {
            case UniverseAspectType.volume:
                return premadeVolumeRanges;
            case UniverseAspectType.marketCap:
                return premadeMarketCapRanges;
            case UniverseAspectType.price:
                return [];
            default:
                throw new Error(`Universe type "${type}" not implemented in ranged creator.`);
        }
    }, [type]);

    const premadeMatch = premadeOptions.find(pm => above === pm.above
        && below === pm.below
        && _.isEqual(ranges, pm.ranges));

    const onPremadeSelected = useInputCallback(label => {
        if (label === customMoniker) return;

        const premadeMatch = premadeOptions.find(pm => pm.label === label);
        if (!premadeMatch) return;
        setRanges(premadeMatch.ranges.filter(x => x));
        setAbove(premadeMatch.above);
        setBelow(premadeMatch.below);
    }, [premadeOptions]);

    // const defaultedRanges: (Range | undefined)[] = useMemo(() => ranges.length ? ranges : [undefined], [ranges]);

    const onAdd = useCallback(() => {
        setRanges(ranges => [...ranges, undefined]);
    }, []);
    
    const onRemovers = useMemo(() => ranges.map((x, i) => () => setRanges(ranges => {
        const newRanges = ranges.filter((y, j) => j !== i);
        if (newRanges.length === 0) newRanges.push(undefined);
        return newRanges;
    })), [ranges]);

    return <div className="ranged-aspect">
        {premadeOptions.length !== 0 && <TextField className="premade-options" select label="Premade Options" value={premadeMatch ? premadeMatch.label : customMoniker} onChange={onPremadeSelected}>
            {premadeOptions.map((pm, i) =>
                <MenuItem key={i} value={pm.label}>{pm.label}</MenuItem>
            )}
            <MenuItem key={customMoniker} value={customMoniker}>Custom</MenuItem>
        </TextField>}

        {ranges.map((x, i) => <div key={i} className="range-row">
            <RangeCreator value={ranges[i]} onChange={rangeChangers[i]} />
            <Button className="remove" variant="text" hidden={ranges.length === 1 && x === undefined} onClick={onRemovers[i]}><i className="fa fa-minus-circle" /></Button>
            <Button className="add" variant="text" hidden={i !== ranges.length - 1} onClick={onAdd}><i className="fa fa-plus-circle" /></Button>
        </div>)}

        <div className="tail-options">
            <FormControlLabel label="Include All Symbols Above Cap" onChange={onAboveChanged} checked={above} control={<Checkbox/>}/>
            <FormControlLabel label="Include All Symbols Below Cap" onChange={onBelowChanged} checked={below} control={<Checkbox/>}/>
        </div>
    </div>;
};