import { FilterSymbol, KnownAstNode } from '@thinkalpha/language-services';
import { FieldTypeCategory, FieldType } from '@thinkalpha/table-client';

export type Range = {start: number, end: number};

export enum HistoricType {
    none = 'none',
    bars = 'bars',
    history = 'history'
}

export enum CompletionType {
    field = 'field',
    function = 'function',
    syntax = 'syntax'
}

export interface Section {
    source?: Token;
    text: string;
    errors: string[];
    class?: string;
    range: Range;
    node: KnownAstNode | undefined;
}

export enum TokenType {
    Error = 'error',
    Symbol = 'symbol',
    String = 'string',
    Regex = 'regex',
    Name = 'name',
    Number = 'number',
    Whitespace = 'ws'
}

export interface Token {
    range: Range;
    token: string | FilterSymbol;
    type: TokenType;
    error?: string;
}

export interface Field {
    name: string;
    type: FieldTypeCategory;
    description?: string;
    key?: string;
    sourceTable: string | undefined;
    historic: HistoricType;
}

export type IndicatorGroupingKind = 'Package' | 'Class';

export interface IndicatorGrouping {
    id: string;
    name: string;
    kind: IndicatorGroupingKind;
}

export interface IndicatorPackages extends IndicatorGrouping {
    kind: 'Package';
    package: IndicatorPackage;
}

export interface IndicatorClasses extends IndicatorGrouping {
    kind: 'Class';
    class: IndicatorClass;
}

export interface IndicatorPackage {
    id: string;
    name: string;
    // TODO Much more
}

export interface IndicatorClass {
    id: string;
    name: string;
}

export interface IndicatorClassType {
    id: string;
    name: string;
}

export interface IndicatorCategory {
    id: string;
    name: string;
}

export interface Indicator {
    id: string;
    name: string;
    categoryId: string;
    groupingId: string;
    packageId?: string;
    classId: string;
    classTypeId?: string;
    occurences: IndicatorOccurences[];
}

export interface Function {
    id: string;
    name: string;
    categoryId: string;
    classTypeId: string;
}

export interface FunctionCategory {
    id: string;
    name: string;
}

export interface FunctionClassType {
    id: string;
    name: string;
}

export interface IndicatorOccurences {
    kind: IndicatorGroupingKind;
    name: string;
}

export interface CompletionOption {
    type: CompletionType;
    displayText?: string;
    text: string;
    source?: string;
    description?: string;
    dataType: FieldTypeCategory;
    historic: boolean;
};

export interface FunctionContext {
    name: string;
    params?: {
        name?: string;
        description?: string;
        dataType: FieldTypeCategory;
        optional: boolean;
    }[];
    paramIndex?: number;
    returnType?: FieldTypeCategory;
};

export interface CompletionContext {
    index: number;
    text: string;
    options: CompletionOption[];
    functionContext?: FunctionContext;
}