import './browser-table.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';

import { FieldDescriptor, Key, RawClient, stringifyKey, TableClient, FieldTypeCategory } from '@thinkalpha/table-client';

import { Store } from 'reactive-state';
import { connect } from 'reactive-state/react';
import { AppState } from '../state';

import { smartFilterToTableFilter } from '../components/smart-filter';
import TableView from '../components/table-view/table-view.new';
import { TableUserData } from '../components/table-view/model';
import { useSubscription } from '../hooks/useSubscription';
import {useToggleState} from '../hooks/useToggleState';

import FilterEditor from '../components/filter-editor/filter-editor.new';
import { descriptorToField } from '../components/data-builder/data-builder';

type Props = {
    tableKey: Key;
    onClose: () => void;
    client: RawClient;
};

const BrowserTable: React.FunctionComponent<Props> = ({client, tableKey, onClose}) => {
    const tableClientRef = useRef<TableClient>();

    useEffect(() => {
        tableClientRef.current = new TableClient(client, tableKey);
        return () => tableClientRef.current!.dispose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client]);

    useEffect(() => {
        tableClientRef.current!.key = tableKey;
    }, [tableKey]);

    const [filter, setFilter] = useState<string>();
    const [tableState, setTableState] = useState<TableUserData>();
    const [fields, setFields] = useState<FieldDescriptor[]>([]);
    const [rowCount, setRowCount] = useState<number>(0);
    const [jsonDef, setJsonDef] = useState<object>();
    const [infoShown, setInfoShown, showInfo, hideInfo] = useToggleState(false);

    useSubscription(() => tableClientRef.current!.definition$.subscribe(od => setJsonDef(od)));

    // const tableCompatibleFilter = filter && smartFilterToTableFilter(filter, fields);
    useSubscription(() => tableClientRef.current!.descriptor$.subscribe(setFields));

    const name = stringifyKey(tableKey);
    const noFilter = !tableKey || tableKey.ex === 'X' || tableKey.ex === 'V' || tableKey.ex === 'M';
    const noInfo = !tableKey || tableKey.ex === 'M';

    const convertedFields = fields.map(descriptorToField);

    return <>
        <div className="browser-table">
            <div className="name-close-row">
                <h3>{name}</h3>
                <div className="filter-wrapper" hidden={noFilter}>
                    <FilterEditor
                        equalsMode
                        dataTypeRequired={FieldTypeCategory.Boolean}
                        value={filter ?? null}
                        onValueChange={setFilter}
                        fields={convertedFields}
                    />
                </div>
                <div className="row-count"><strong>Rows:</strong>&nbsp;{rowCount}</div>
                <button onClick={showInfo} id="info" hidden={noInfo}><i className="fa fa-info" /></button>
                <button onClick={onClose} style={{flex: '0 0 auto'}} id="close"><i className="fa fa-times" /></button>
            </div>
            <TableView tableKey={tableKey} onRowCountChanged={setRowCount} smartFilter={filter} tableState={tableState} onStateChanged={setTableState} />
        </div>
        <Dialog open={infoShown} onClose={hideInfo}>
            <DialogTitle>JSON Definition</DialogTitle>
            <DialogContent>
                <div className="browser-table-info">
                    <textarea hidden={!jsonDef} spellCheck={false} value={jsonDef && JSON.stringify(jsonDef, undefined, 2)} />
                    <CircularProgress hidden={!!jsonDef}/>
                </div>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={hideInfo}>Hide</Button>
            </DialogActions>
        </Dialog>
    </>;
};

export default connect(BrowserTable, (store: Store<AppState>) => {
    const props = store.watch(state => ({
        client: state.client
    }));
    return {props};
});