import React, { useCallback, MutableRefObject, useRef, useEffect, useMemo } from 'react';
import Measure from 'react-measure';
import HighchartsReact from 'highcharts-react-official';
import { Subject } from 'rxjs';
import { auditTime } from 'rxjs/operators';

export const HighchartsReflow: React.FC<HighchartsReact.Props> = React.forwardRef<HighchartsReact, HighchartsReact.Props>(function HighchartsReflow(props, outboundRef) {
    const highchartsRef = useRef<HighchartsReact | null>(null);
    const refSetter = useCallback((el: HighchartsReact) => {
        if (typeof outboundRef === 'object') {
            (outboundRef as MutableRefObject<HighchartsReact>).current = el;
        } else {
            outboundRef(el);
        }
        highchartsRef.current = el;
    }, [outboundRef]);

    const resizeSubjectRef = useRef(new Subject<void>());
    useEffect(() => {
        const sub = resizeSubjectRef.current.pipe(
            auditTime(500)
        ).subscribe(() => {
            if (!highchartsRef.current) return;
            highchartsRef.current.chart.reflow();
        });
        return () => sub.unsubscribe();
    }, []);

    const onResize = useCallback(() => {
        resizeSubjectRef.current.next();
    }, []);

    return <Measure bounds onResize={onResize}>
        {({contentRect, measureRef}) => <div ref={measureRef} style={{width: '100%', height: '100%', flex: '1 1 auto', display: 'flex', flexDirection: 'column', overflow: 'hidden'}}>
            <HighchartsReact {...props} ref={refSetter} options={{
                ...props.options || {},
                chart: {
                    ...(props.options || {}).chart || {},
                    height: contentRect.bounds ? contentRect.bounds.height : null,
                    width: contentRect.bounds ? contentRect.bounds.width : null
                }
            }} />
        </div>}
    </Measure>;
});