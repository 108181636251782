import loglevel from 'loglevel';
const log = loglevel.getLogger('if-then-definer');

import { Operand, IfThenGroupModel, IfThenLineModel } from '../../../if-then/model';

import { UniverseCreatorResult } from '../../../universe/model';
import { IfThenStrategy, StrategyCreatorResult, StrategyType } from '../../model';

import { DefinitionBundle, FieldDescriptor, FieldType, FieldTypeCategory, ObjectDefinition, TypesInTypeCategory, Formula, TableClient, RawClient } from '@thinkalpha/table-client';
import { randomString } from '../../../util/randomString';

import { defaultColumns } from '../defaultCols';

import { parser, lexer } from '@thinkalpha/language-services';
import compiler, { HoistedColumn } from '@thinkalpha/language-services/dist/compilers/table-server';

import { StrategyDefiner } from '../../definer';
// import {strategyFields} from '../../../if-then/fields.hardcoded';
import { Field, HistoricType } from '../../../components/filter-editor/model';
import {pointInTimeToSubscript, timeframeToSubscript} from '../../../components/timeframe/pointInTime';
import { CustomFormula } from '../../../components/data-builder/model';

import {isMoment} from 'moment';
import { strategyFields, allStrategyFields } from '../../../if-then/strategy/fields.hardcoded';
import {groupToFilter} from '../../../if-then/render';
import { functionDefs } from '../../../syntax/functions';

const stringFieldTypes = [...TypesInTypeCategory.get(FieldTypeCategory.String)!, FieldTypeCategory.String];
Object.freeze(stringFieldTypes);

function parse(operand: Operand, fields: readonly Field[]) {
    const res = parser(operand, fields, functionDefs);
    return res;
}

const cleanViewMoniker = 'clean';

function hoistToFormula(hoist: HoistedColumn): Formula {
    return {
        formula: hoist.formula,
        name: hoist.name,
        desc: 'An automatically created column to hoist a function'
    };
}

function modelToFormulas(f: CustomFormula, fields: readonly Field[]): Formula[] {
    const parseResult = parse(f.formula, fields);
    const compiled = compiler(parseResult.root, {hoist: true, barLength: f.timeframe && f.timeframe.barLength});
    const functionName = f.function;
    let formulaText = `${functionName || ''}(${compiled.result})`;
    if (f.key) {
        formulaText = `bind(${formulaText}, '${f.key}')`;
    }
    const formula: Formula = {
        name: f.name,
        desc: f.description,
        subscript: f.timeframe && timeframeToSubscript(f.timeframe, functionName),
        formula: formulaText,
        match_key: {
            primary: [
                {
                    'on_left_side': 'symbol',
                    'on_right_side': 'symbol',
                    'venue': '*'
                }
            ]
        }
    };

    return [...compiled.hoistedColumns.map(hoistToFormula), formula];
}

export const createIfThenStrategy: StrategyDefiner<IfThenStrategy> = (universeTableName, strategy) => {
    let intermediateName = randomString();
    const strategyViewName = randomString();

    const name = randomString();

    const formulas: Formula[] = [];
    const fields = [...allStrategyFields];
    for (const formula of strategy.root.formulas) {
        formulas.push(...modelToFormulas(formula, fields));
        fields.push({
            description: formula.description,
            name: formula.name,
            sourceTable: formula.sourceTable,
            type: formula.type,
            historic: HistoricType.none
        });
    }

    const hoists: HoistedColumn[] = [];
    const filter = groupToFilter(strategy.root, fields, hoists);

    formulas.push(...hoists.map(hoistToFormula));

    const definitions: DefinitionBundle = [];

    const defaultBase = 'allUniverseIfThenBase';
    if (universeTableName === 'allUniverse' && !formulas.length && !hoists.length) {
        intermediateName = defaultBase;
    } else {
        log.info('Not using', defaultBase, 'because of:', universeTableName, formulas.length, hoists.length);
        const firstTableName = randomString();
        const baseDefinition = {
            table: {
                name: firstTableName,
                auto_sources: [
                    {
                        name: universeTableName,
                        columns: [
                            // 'OfficialPrice'
                        ]
                    },
                    {
                        name: 'allUniverse',
                        columns: ['.*'],
                        match_key: {
                            primary: [
                                {
                                    on_left_side: 'symbol',
                                    on_right_side: 'symbol',
                                    // venue: '*'
                                }
                            ]
                        }
                    }
                ],
                row_filter: {
                    match_by: 'fullkey',
                    pattern: '.*'
                },
                formulas: [
                    ...formulas,
                    {
                        name: 'Symbol',
                        formula: 'ticker'
                    }
                ]
            }
        };
        definitions.push(baseDefinition);

        const validDataFilter: ObjectDefinition = {
            views: [
                {
                    table: firstTableName,
                    name: cleanViewMoniker,
                    filter: 'Bid_Price > 0 and Ask_Price < 200000 and Last_Price > 0'
                }
            ]
        };
        definitions.push(validDataFilter);

        const intermediateTable: ObjectDefinition = {
            table: {
                name: intermediateName,
                auto_sources: [
                    {
                        name: firstTableName,
                        view: cleanViewMoniker,
                        columns: ['.*'],
                        match_key: {
                            primary: [
                                {
                                    on_left_side: 'symbol',
                                    on_right_side: 'symbol',
                                    // venue: '*'
                                }
                            ]
                        }
                    }
                ]
            }
        };
        definitions.push(intermediateTable);
    }

    if (!filter) return {definitions, name: intermediateName};

    definitions.push(
        { views: [{
            table: intermediateName,
            name: strategyViewName,
            filter
        }]},
        { table: {
            name,
            auto_sources: [
                {
                    name: intermediateName,
                    view: strategyViewName,
                    columns: ['.*'],
                    match_key: {
                        primary: [
                            {
                                on_left_side: 'symbol',
                                on_right_side: 'symbol',
                                // venue: '*'
                            }
                        ]
                    }
                }
            ],
            formulas: [   
            ]
        }}
    );

    return {definitions, name};
};