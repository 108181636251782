import React, { useEffect, useState } from 'react';
import { useInputState, useInputCallback } from '../hooks/useInputState';

import { Subscription } from 'rxjs';

import { getSavedUniverses, getUniverseById, UniverseRecord, saveUniverse } from '../services/universes';
import { Universe, UniverseAspectType } from './model';
import {PremadeUniverses} from './premade-universes';
import { Select } from '@material-ui/core';
import { UniverseCreatorModal } from './universe-creator';
import { randomString } from '../util/randomString';

export enum SpecialResult {
    create = 'create'
}

type Props = {
    universe: Universe | SpecialResult | undefined;
    onChange: (universe: Universe | SpecialResult | undefined) => void;
    onUniverseCreatorOpened?: () => void;
    allowCreate?: boolean;
    builtinCreate?: boolean;
    allowUndefined?: boolean;
}

export const UniverseSelector: React.FunctionComponent<Props> = ({universe, onChange, allowCreate = true, builtinCreate = true, allowUndefined = false, onUniverseCreatorOpened}) => {
    const [universeSelection, setUniverseSelection] = useInputState<string>('');
    const [myUniverses, setMyUniverses] = useState<UniverseRecord[]>();
    const [universeCreatorClosed, setUniverseCreatorClosed] = useState<(universe: Universe) => void>();

    const onUniverseSelectionChanged = useInputCallback(selection => {
        switch (selection) {
            case SpecialResult.create: {
                if (builtinCreate) {
                    if (onUniverseCreatorOpened) onUniverseCreatorOpened();
                    setUniverseCreatorClosed(() => async (universe?: Universe) => {
                        setUniverseCreatorClosed(undefined);
                        if (!universe) return;

                        universe = {...universe, id: randomString()};

                        await saveUniverse(universe).toPromise();

                        setMyUniverses(myUniverses => [...myUniverses || [], universe!]);
                        setUniverseSelection(universe.id!);
                    });
                    break;
                }
            }
            default:
                setUniverseSelection(selection);
        }
    });

    useEffect(() => {
        getSavedUniverses().subscribe(setMyUniverses);
    }, []);

    useEffect(() => {
        if (!universe) {
            if (allowUndefined) {
                setUniverseSelection('');
            } else {
                setUniverseSelection(PremadeUniverses[0].table);
            }
        } else if (universe === SpecialResult.create) {
            setUniverseSelection('create');
        } else if (universe.id) {
            setUniverseSelection(universe.id);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [universe]);

    useEffect(() => {
        let subscription: Subscription;

        if (!myUniverses) return () => {/* no op */};

        const myUniverse = myUniverses.find(ur => ur.id === universeSelection);
        const premadeUniverse = PremadeUniverses.find(x => x.table === universeSelection);
        if (premadeUniverse) {
            onChange({aspects: [premadeUniverse], inclusionList: [], exclusionList: []});
        } else if (myUniverse) {
            subscription = getUniverseById(universeSelection!).subscribe(onChange);
        } else if (universeSelection === 'create') {
            onChange(SpecialResult.create);
        } else if (!universeSelection) {
            onChange(undefined);
        } else {
            throw new Error(`Not implemented universe selection: ${universeSelection}`);
        }

        return () => subscription && subscription.unsubscribe(); // cancel previous if we have new
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [universeSelection]);

    return <>
        <Select variant="outlined" native value={universeSelection} onChange={onUniverseSelectionChanged}>
            {allowUndefined && <option value={''}>Select a Universe</option>}
            {/* <option value={'s&p500'}>Default (S&amp;P 500)</option> */}
            {allowCreate && <option value={'create'}>Create a New Universe</option>}
            {myUniverses !== undefined && myUniverses.length > 0 && <optgroup label="Your Universe Lists">
                {myUniverses.map(ur => <option key={ur.id} value={ur.id}>{ur.name}</option>)}
            </optgroup>}
            <optgroup label="Premade Universes">
                {PremadeUniverses.map(pmu => <option value={pmu.table} key={pmu.table}>{pmu.display}</option>)}
            </optgroup>
            {/* <option value={'premade'}>Select from Premade Lists</option> */}
        </Select>
        {builtinCreate && <UniverseCreatorModal onClose={universeCreatorClosed} />}
    </>;
};