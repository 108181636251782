import React, {useEffect, useState} from 'react';
import {Subscription} from 'rxjs';
import {bufferTime, map, throttleTime} from 'rxjs/operators';

import {ConnectionStatus, TableClient} from '@thinkalpha/table-client';

enum IconState {
    off = 'off',
    on = 'on',
    solid = 'solid',
    disconnected = 'disconnected'
}

export const LightBulb: React.FunctionComponent<{tableClient: TableClient}> = ({tableClient}) => {
    const [{icon, rate}, setIconState] = useState<{icon: IconState, rate: number}>({icon: IconState.disconnected, rate: 0});
    
    useEffect(() => {
        if (!tableClient) return;
        const subscriptions = new Subscription();
        subscriptions.add(tableClient.client.connectionStatus$.subscribe(status => {
            setIconState(state => {
                // tslint:disable-next-line: no-shadowed-variable
                const {icon, rate} = state;

                if (status !== ConnectionStatus.connected) {
                    return {rate, icon: IconState.disconnected};
                } else if (icon === IconState.disconnected) {
                    return {rate, icon: IconState.off};
                } else {
                    return state;
                }
            });
        }));
        subscriptions.add(tableClient.updates$.pipe(
            map(x => undefined),
            throttleTime(500)
        ).subscribe(() => {
            setIconState(state => {
                // tslint:disable-next-line: no-shadowed-variable
                const {icon, rate} = state;

                if (icon === IconState.on) {
                    return {icon: IconState.off, rate};
                } else if (icon === IconState.off) {
                    return {icon: IconState.on, rate};
                } else {
                    return state;
                }
            });
        }));

        // count updates per second
        subscriptions.add(tableClient.updates$.pipe(
            map(x => undefined),
            bufferTime(1000),
            map(x => x.length)
        ).subscribe(ups => {
            setIconState(state => {
                // tslint:disable-next-line: no-shadowed-variable
                const {icon, rate} = state;

                if (ups > 5) {
                    return {rate: ups, icon: IconState.solid};
                } else if (icon === IconState.solid) {
                    return {rate: ups, icon: IconState.off};
                } else {
                    return {rate: ups, icon};
                }
            });
        }));

        return () => subscriptions.unsubscribe();
    }, [tableClient]);

    return <div className={`lightbulb ${icon}`} title={`Rate: ${rate} update${rate !== 1 ? 's' : ''}/sec`}><i className="fa fas fa-lightbulb"></i></div>;
};