import { BehaviorSubject } from 'rxjs';

/*
 * Save/load confif per User
 * todo: move data from localforage to backend
*/

import localforage from 'localforage';

export enum SkipItem {
    StrategyBuilderWizardBeforeYouBegin = 'StrategyBuilderWizardBeforeYouBegin'
}

export interface StoredUserConfig {
    skipItems: SkipItem[];
}

const defaultConfig: StoredUserConfig = {
    skipItems: []
};

export const storedUserConfig$ = new BehaviorSubject<StoredUserConfig>(defaultConfig);

const tmpConfigKey = 'temp-config';
function loadStoredUserConfig() {
    // todo: load from backend
    localforage.getItem<StoredUserConfig>(tmpConfigKey).then(data => storedUserConfig$.next(data || defaultConfig));
}

function saveStoredUserConfig(config: StoredUserConfig) {
    // todo: save to backend
    localforage.setItem<StoredUserConfig>(tmpConfigKey, config).then(() => storedUserConfig$.next(config));
}

export function setSkipItems(items: SkipItem[]) {
    const config = storedUserConfig$.value;
    const clone = {...config};
    clone.skipItems = items;
    saveStoredUserConfig(clone);
}

export function getSkipItems() {
    return storedUserConfig$.value.skipItems;
}

loadStoredUserConfig();
