import React, { useCallback, useContext, useEffect, useMemo, useRef, useState, HTMLProps } from 'react';
import {FormControl, FormControlLabel, InputLabel, Menu, MenuItem, Select, Step, StepLabel, Stepper, Checkbox, TextField, Tabs, Tab, Modal, Dialog, DialogTitle, DialogContent, DialogActions, Toolbar, Box, Button, IconButton, Fab, Paper, ButtonGroup} from '@material-ui/core';
import {Stack} from 'immutable';
import { ExtendButtonBase } from '@material-ui/core/ButtonBase';
import { ButtonTypeMap } from '@material-ui/core/Button';
import { useInputState } from './hooks/useInputState';
import { useStateRef } from './hooks/useStateRef';

export interface ModalButton<T> extends React.ComponentProps<typeof Button> {
    label: string;
    closeValue?: any | undefined;
}

type ModalContentProps<T = void> = {
    value: T;
    setValue: (value?: T) => void;
    close: (value?: T) => void;
};

type ModalContent<T = void> = string | React.ComponentType<ModalContentProps<T>>;

export interface ModalActions {
    showConfirmationModal(args: {title?: string, content?: ModalContent<boolean>}): Promise<boolean>;
    showPromptModal(args: {title?: string, label?: string}): Promise<string | null>;
    showModal<T = void>(args: {title?: string, buttons?: ReadonlyArray<ModalButton<T>>, content?: ModalContent<T>}): Promise<T>;
}

interface ModalData<T> {
    title: string | undefined;
    content?: ModalContent<T>;
    buttons?: ReadonlyArray<ModalButton<any>>;
    onClose: (res?: T) => void;
    currentValue?: T;
};

const DialogContext = React.createContext<ModalActions>(undefined!);

export const ModalProvider: React.FC = ({children}) => {
    const [modalStack, setModalStack] = useState<Stack<ModalData<any>>>(Stack());

    const actions = useMemo((): ModalActions => {
        const result: ModalActions = {
            showModal: function showModal({title, buttons, content}) {
                const promise = new Promise<any>((res, rej) => {
                    const modalData: ModalData<any> = {
                        title,
                        buttons: buttons ?? [{ label: 'OK', onClick: () => modalData.onClose(modalData.currentValue) }],
                        content,
                        onClose: (result) => {
                            setModalStack(stack => stack.pop());
                            res(result);
                        }
                    };
                    setModalStack(stack => stack.push(modalData));
                });
                return promise;
            },
            showConfirmationModal: async function showConfirmationModal({title, content}) {
                return await result.showModal<boolean>({
                    title,
                    content,
                    buttons: [
                        { label: 'OK', closeValue: true },
                        { label: 'Cancel', closeValue: false }
                    ]
                });
            },
            showPromptModal: async function showPromptModal({title, label}) {
                return await result.showModal<string>({
                    title,
                    content: function PromptModal({value, setValue}) {
                        // const [value, setValue, onValueChanged] = useInputState();

                        return <div className="prompt">
                            <TextField label={label} placeholder={label} value={value} onChange={x => setValue(x.target.value)} />
                        </div>;
                    }
                });
            }
        };
        return result;
    }, []);

    const modal: ModalData<any> | undefined = modalStack.peek();
    const setModalValue = useCallback(value => {
        setModalStack(stack => {
            stack.peek().currentValue = value;
            return stack;
        });
    }, []);

    const onClose = modal?.onClose;
    const onClosed = useCallback(() => onClose?.(undefined), [onClose]);
    const ModalContent = modal?.content;
    const content = !ModalContent ? null : typeof ModalContent === 'string' ? modal.content : <ModalContent close={onClosed} value={modal.currentValue} setValue={setModalValue} />;

    const buttons = modal?.buttons;
    const buttonActions = useMemo(() => {
        if (!buttons) return;
        return buttons.map(b => !('closeValue' in b)
            ? b.onClick
            : (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                b.onClick?.(event);
                onClose?.(b.closeValue!);
            }
        );
    }, [buttons, onClose]);

    return <>
        <DialogContext.Provider value={actions}>
            {children}
            <Dialog open={!!modal} onClose={onClosed}>
                {modal?.title && <DialogTitle>{modal.title}</DialogTitle>}
                {content && <DialogContent>
                    {content}
                </DialogContent>}
                {buttons && <DialogActions>
                    {buttons.map((b, i) => <Button key={i} {...b} onClick={buttonActions![i]}>{b.label}</Button>)}
                </DialogActions>}
            </Dialog>
        </DialogContext.Provider>
    </>;
};

export function useModalActions() {
    return useContext(DialogContext);
}