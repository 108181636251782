import './viewer.scss';

import React, { useCallback, useEffect, useState } from 'react';

import { Redirect, RouteComponentProps, withRouter } from 'react-router';

import { Store } from 'reactive-state';
import { ActionMap, connect } from 'reactive-state/react';
import { AppState } from '../state';

import loglevel from 'loglevel';
const log = loglevel.getLogger('preview');

import classnames from 'classnames';

import { CircularProgress } from '@material-ui/core';

import { fromEvent, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { FieldDescriptor, Key, TableClient } from '@thinkalpha/table-client';

import TableView, { TableViewProps } from '../components/table-view/table-view.new';
import {
    ColumnPreference,
    ContextMenuItemsCreator,
    MenuItem,
    TableUserData
} from '../components/table-view/model';
import { playStrategy, playStrategyById, StrategyRecord } from '../services/strategies';
import { client } from '../services/table';
import { defineStrategy } from '../strategy/definer';
import { ConcreteStrategy, KnownStrategyTemplate } from '../strategy/model';
import { defineUniverse } from '../universe/definer';
import { Universe, UniverseAspectType } from '../universe/model';

export const StrategyView: React.FC<{strategy?: ConcreteStrategy | StrategyRecord | string} & Partial<TableViewProps>>
= function StrategyView({strategy: strategyOrId, ...rest}) {
    const [key, setKey] = useState<Key | undefined>(undefined);

    useEffect(() => {(async () => {
        if (!strategyOrId) return;

        // fetch strategy and universe from database (todo)
        const strategy = await playStrategy(strategyOrId).toPromise();
        if (!strategy) return;
        
        const universe = strategy.universe || {type: UniverseAspectType.premade, name: 'all'};
        log.info('Got universe', universe);
        log.info('Got strategy', strategy);

        // set key to new strategy table
        setKey({sym: strategy.tableName as string, ex: 'T'});
    })(); }, [strategyOrId]);

    // useEffect(() => {
    //     if (onRowCount) onRowCount(undefined);

    //     if (!key) return;

    //     const tc = new TableClient(client, key);
    //     const sub = tc.rowCount$.subscribe(onRowCount);
    //     // tc.rowCount$.subscribe(x => console.log(x));
    //     tc.bounds = {firstRow: 0, windowSize: 1.1};

    //     return () => {
    //         sub.unsubscribe();
    //         tc.dispose();
    //     };
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [key]);

    // if (!strategyOrId) {
    //     return null;
    // }

    return (
        <div className="strategy-view">
            {key && <TableView
                {...rest}
                tableKey={key}
            /> }
        </div>
    );
};