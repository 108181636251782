import { useRef, useEffect } from 'react';
import {useDeepEffect} from './useDeepEffect';
import _ from 'lodash';

export function useModelCommitter<TModel, TState extends {} | []>(
    model: TModel | undefined,
    onChange: ((model: TModel | undefined) => void) | undefined,
    state: TState,
    committer: (state: TState) => TModel | undefined | false,
    materializer: (model: TModel | undefined) => void
): void {
    const uncommitted = useRef(false);
    const lastCommittedModel = useRef<TModel | undefined>(model);
    
    useDeepEffect(() => {
        if (uncommitted.current) return;
        if (_.isEqual(model, lastCommittedModel.current)) return;

        materializer(model);
        // console.log('materialized', model, 'old model', lastCommittedModel.current);
    }, [model]);

    const stateDeps = state instanceof Array ? state : Object.keys(state).map(k => state[k]);
    useEffect(() => {
        const commitable = committer(state);
        if (commitable === false) {
            uncommitted.current = true;
        } else {
            uncommitted.current = false;
            // console.log('committing', commitable);
            if (!_.isEqual(model, commitable)) {
                if (onChange) onChange(commitable);
                lastCommittedModel.current = commitable;
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, stateDeps);
}