import { useRef, RefObject, SetStateAction, Dispatch, useState } from 'react';

export function useStateRef<T>(initial: T): [RefObject<T>, Dispatch<SetStateAction<T>>];
export function useStateRef<T>(initial?: T): [RefObject<T | undefined>, Dispatch<SetStateAction<T | undefined>>];

export function useStateRef<T>(initial?: T): [RefObject<T | undefined>, Dispatch<SetStateAction<T | undefined>>] {
    const ref = useRef<T | undefined>(initial);
    const [state, setState] = useState<T | undefined>(initial);

    return [ref, (value: SetStateAction<T | undefined>) => {
        setState(existing => {
            let res: T | undefined;
            if (typeof value === 'function') {
                res = (value as any)(existing);
            } else {
                res = value;
            }
            ref.current = res;
            return res;
        });
    }];
}