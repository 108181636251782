import React, { useCallback, useState } from 'react';

import {  Dialog, DialogContent, DialogActions, Button, DialogTitle } from '@material-ui/core';
import { IfThenGroupModel } from '../../if-then/model';
import IfThenGroup from '../../if-then/group';
import { Field } from './model';
import { Store } from 'reactive-state';
import { AppState, clipboard$, Clipboard } from '../../state';
import { connect, ActionMap } from 'reactive-state/react';
import IfThenComponent from '../../if-then/if-then.component';

interface Props {
    open: boolean;
    onClose: (model?: IfThenGroupModel) => void;
    model: IfThenGroupModel;
    fields: readonly Field[];
    clipboard?: Clipboard;
    setClipboard?: (clipboard: Clipboard) => void;
}

const IfThenModal: React.FC<Props> = ({onClose, open, model, fields, clipboard, setClipboard}) => {
    const [uncommittedModel, setUncommittedModel] = useState<IfThenGroupModel>(model);

    const cancel = useCallback(() => {
        onClose(undefined);
    }, [onClose]);

    const save = useCallback(() => {
        onClose(uncommittedModel);
    }, [onClose, uncommittedModel]);

    return <Dialog className="if-then-modal" open={open} onClose={cancel}>
        <DialogTitle>Edit filter</DialogTitle>
        <DialogContent>
            <IfThenGroup
                depth={0}
                fields={fields}
                model={uncommittedModel}
                committedModel={model}
                onModelChanged={setUncommittedModel}
                clipboard={clipboard!}
                setClipboard={setClipboard!}
            />
        </DialogContent>
        <DialogActions>
            <Button color="primary" onClick={save}>Save</Button>
            <Button color="secondary" onClick={cancel}>Cancel</Button>
        </DialogActions>
    </Dialog>;
};

export default connect(IfThenModal, (store: Store<AppState>) => {
    const props = store.watch(state => ({
        clipboard: state.clipboard
    }));

    const actionMap: ActionMap<typeof IfThenComponent> = {
        setClipboard: clipboard$
    };

    return {
        props,
        actionMap
    };
});
