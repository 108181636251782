import { CharacterMetadata, ContentBlock, ContentState } from 'draft-js';

/**
 * Filters entity ranges (where entities are applied on text) based on the result of
 * the callback function. Returning true keeps the entity range, false removes it.
 * Draft.js automatically removes entities if they are not applied on any text.
 */
export const filterEntityRanges = (
    filterFn: (
        content: ContentState,
        entityKey: string,
        block: ContentBlock,
    ) => boolean,
    content: ContentState,
) => {
    const blockMap = content.getBlockMap();
  
    /**
     * Removes entities from the character list if the entity isn't enabled.
     * Also removes image entities placed outside of atomic blocks, which can happen
     * on paste.
     * A better approach would probably be to split the block where the image is and
     * create an atomic block there, but that's another story. This is what Draft.js
     * does when the copy-paste is all within one editor.
     */
    const blocks = blockMap.map((block: ContentBlock) => {
        let altered = false;
  
        const chars = block.getCharacterList().map(char => {
            const entityKey = char!.getEntity();
  
            if (entityKey) {
                const shouldRemove = !filterFn(content, entityKey, block);
  
                if (shouldRemove) {
                    altered = true;
                    return CharacterMetadata.applyEntity(char!, null);
                }
            }
  
            return char;
        });
  
        return altered ? block.set('characterList', chars) : block;
    });
  
    return content.merge({
        blockMap: blockMap.merge(blocks as ContentBlock),
    });
};