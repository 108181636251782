import { Store } from 'reactive-state';
import { connect } from 'reactive-state/react';
import { AppState } from '../state';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Subject } from 'rxjs';
import { debounce, debounceTime } from 'rxjs/operators';

import { CircularProgress, Modal, Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@material-ui/core';

import { RawClient } from '@thinkalpha/table-client';

import {useInputCallback, useInputState} from '../hooks/useInputState';
import { useSubscription } from '../hooks/useSubscription';

const TableDefiner: React.FunctionComponent<{client: RawClient, shown: boolean, onHide: () => void}> = ({client, shown, onHide}) => {
    const [definition, setDefinition] = useState('');
    const [inflight, setInflight] = useState(false);
    const [error, setError] = useState<string>();
    const [jsonError, setJsonError] = useState<string>();
    const [unvalidated, setUnvalidated] = useState(true);

    const definitionSubject = useRef<Subject<string>>();
    useEffect(() => {
        definitionSubject.current = new Subject<string>();
        return () => definitionSubject.current!.complete();
    }, []);

    useSubscription(() => definitionSubject.current!.pipe(debounceTime(750)).subscribe(value => {
        try {
            setUnvalidated(false);
            JSON.parse(value);
            setJsonError(undefined);
        } catch (e) {
            setJsonError(e.message);
        }
    }));

    const onDefinitionChanged = useInputCallback(value => {
        setDefinition(value);
        setUnvalidated(true);
        definitionSubject.current!.next(value);
    }, [setDefinition, setJsonError]);

    const define = useCallback(async () => {
        setInflight(true);
        const result = await client.defineObject(false, JSON.parse(definition)).toPromise();
        setInflight(false);
        if (!result.success) {
            setError(result.comment);
        } else {
            onHide();
        }
    }, [definition, setInflight, setError, onHide, client]);

    return <Dialog open={shown} onClose={onHide}>
        <DialogTitle>Define a Table</DialogTitle>
        <DialogContent>
            <div className="browser-table-info">
                <div className="error" hidden={inflight || !error}><i className="fas fa-exclamation-triangle"/> {error}</div>
                <div className="error" hidden={inflight || !jsonError}><i className="fas fa-exclamation-triangle"/> {jsonError}</div>
                <textarea hidden={inflight} spellCheck={false} value={definition} onChange={onDefinitionChanged} />
                <CircularProgress hidden={!inflight}/>
            </div>
        </DialogContent>
        <DialogActions>
            <Button color="primary" onClick={define} disabled={jsonError !== undefined || inflight || unvalidated}>Define</Button>
            <Button color="secondary" onClick={onHide}>Cancel</Button>
        </DialogActions>
    </Dialog>;
};

export default connect(
    TableDefiner,
    (store: Store<AppState>) => {
        const props = store.watch(state => ({
            client: state.client
        }));
        return { props };
    }
);
