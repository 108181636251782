import './filter-editor.scss';

import _ from 'lodash';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import loglevel from 'loglevel';
const log = loglevel.getLogger('filter-editor');

import { FieldTypeCategory } from '@thinkalpha/table-client';

// import { ContentChangedEvent, FilterEditorDirective, Position, Range, Section, Selection } from '../../directives/filterEditor.directive';
import { CompletionOption, Range, Section, Token, TokenType, CompletionType, CompletionContext, Field, HistoricType } from './model';
import {ParserResult, lexer, parser, LogicalOperator, flattenAst} from '@thinkalpha/language-services';
import {useDeepEffect} from '../../hooks/useDeepEffect';

export type ContentChangedEvent = {value: string, range: Range, position: Position};

import classNames from 'classnames';
import { Editor, EditorState, convertFromRaw, ContentState, SelectionState, Modifier, convertToRaw, RawDraftEntityRange, RawDraftEntity, CompositeDecorator, DraftHandleValue, getDefaultKeyBinding, ContentBlock } from 'draft-js';
import generateRandomKey from 'draft-js/lib/generateRandomKey';
import annotateTokens from './section-generator';
import {CompletionList} from './completion-list';
import determinePosition, {Position} from '../../util/position';
import completer from './completer';
import { overlappingRange } from '../../util/overlappingRange';
import {Tooltip, Typography, IconButton} from '@material-ui/core';
import {filterEntityRanges} from './entity-manip';
import { connect, ActionMap } from 'reactive-state/react';
import { Store } from 'reactive-state';
import { AppState, filterDictionaryOptions$, FilterDictionaryOptions } from '../../state';
import { FunctionDef, functionDefs } from '../../syntax/functions';
import IfThenModal from './if-then-modal';
import { randomString } from '../../util/randomString';
import { IfThenGroupModel } from '../../if-then/model';
import { useToggleState } from '../../hooks/useToggleState';
import { renderGroup, renderNodeToIfThen } from '../../if-then/render';

type Props = {
    fields: readonly Field[];
    value?: string | null;
    expressionOnlyMode?: boolean;
    placeholder?: string;
    noPlaceholderPromotion?: boolean;
    equalsMode?: boolean;
    dataTypeRequired?: FieldTypeCategory;
    dictionaryVisible: boolean;
    dictionarySelection?: FunctionDef | Field;

    onValueChange?: (value: string) => void;
    onParserResult?: (result?: ParserResult) => void;
    setFilterDictionaryOptions: (options: FilterDictionaryOptions) => void;
    onValidityChange?: (valid: boolean | undefined, value: string | null) => void;
};

const nbspRegex = new RegExp(String.fromCharCode(160), 'g');

const ErrorDecorator: React.FC<{contentState: ContentState, offsetKey: string, entityKey: string}> = ({contentState, offsetKey, children, entityKey}) => {
    const {errors}: {errors: string[]} = contentState.getEntity(entityKey).getData();
    return (
        <Tooltip
            title={<React.Fragment>
                <Typography color="inherit">Syntax Error</Typography>
                {errors.map((x, i) => <div key={i}>{x}</div>)}
            </React.Fragment>}
        >
            <span data-offset-key={offsetKey} className="error">
                {children}
            </span>
        </Tooltip>
    );
};

const decorators = new CompositeDecorator([
    {
        strategy: (contentBlock, callback, contentState) => {
            contentBlock.findEntityRanges(character => {
                const entityKey = character.getEntity();
                if (entityKey === null) {
                    return false;
                }
                return contentState.getEntity(entityKey).getType() === 'error';
            },
            callback);
        },
        component: ErrorDecorator,
    },
]);

const FilterEditor: React.FunctionComponent<Props> = ({equalsMode = false, noPlaceholderPromotion = false, dataTypeRequired, fields, placeholder, value: incomingValue, onValidityChange, onValueChange, onParserResult, setFilterDictionaryOptions, dictionaryVisible, dictionarySelection}) => {
    const [tokens, setTokens] = useState<Token[] | undefined>(undefined);
    const [parserResult, setParserResult] = useState<ParserResult>();
    const value = useRef<string | null>(null); //incomingValue);
    const [editorState, setEditorState] = useState<EditorState>(EditorState.createWithContent(ContentState.createFromText(incomingValue || ''), decorators));
    const editorRef = useRef<Editor>(null);
    const positionRef = useRef<{position: number | null, fakeBlur: boolean}>({position: null, fakeBlur: false});
    const [ifThenModalOpen, , showIfThenModal, hideIfThenModal] = useToggleState(false);

    const [helperStartIndex, setHelperStartIndex] = useState<number | undefined>();
    const [helperPosition, setHelperPosition] = useState<Position | undefined>();
    const [completionContext, setCompletionContext] = useState<CompletionContext>();
    const [active, setActive] = useState<boolean>(false);

    const divRef = useRef<HTMLDivElement>(null);
    const el = divRef.current;

    const pending = !parserResult || !value;

    const broadcastValueChange = useCallback((newValue: string) => {
        if (value.current === newValue) return;

        value.current = newValue;
        if (onValueChange) onValueChange(newValue);
    }, [onValueChange]);

    const processRawValue = useCallback((incomingValue: string | null) => {
        incomingValue = incomingValue ?? '';
        if (incomingValue === (value.current ?? '')) return;
        log.info('editor got new incoming value different from internal value... incoming:', incomingValue, 'internal:', value.current);
        // value.current = incomingValue ?? '';
        
        let targetValue = incomingValue ?? '';
        if (equalsMode) {
            if (!targetValue.startsWith('=')) {
                // string filter
                setTokens([{type: TokenType.String, token: 'value', range: {start: 0, end: targetValue.length}}]);
                setParserResult(undefined);
                setEditorState(EditorState.createWithContent(ContentState.createFromText(targetValue), decorators));
                return;
            }

            targetValue = targetValue.substring(1);
        }

        const tokens = lexer(targetValue);
        setTokens(tokens);
        // console.log('set editor state due to incoming value change from', value.current, 'to', incomingValueOrInternalValue);
        setEditorState(EditorState.createWithContent(ContentState.createFromText(incomingValue || ''), decorators));
    }, [equalsMode]);

    useEffect(() => {
        if (incomingValue === undefined) return;

        processRawValue(incomingValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [incomingValue]);

    useDeepEffect(() => {
        if (!tokens) return;
        if (equalsMode && !value.current?.startsWith('=')) return; // equals mode shouldn't run through validator when there's no equals prefix
        setParserResult(undefined);

        const handle = setTimeout(() => {
            if (!tokens) return;
            // console.log('about to parse tokens', tokens);
            
            const res = parser(value.current ?? '', fields, functionDefs);
            setParserResult(res);

            // console.log('setting editor state due to parser result');
            setEditorState(editorState => {
                let content = editorState.getCurrentContent();
                if (content.getPlainText('') !== value.current) {
                    log.debug('Bailing out due to value change, from', value.current, 'to', content.getPlainText(''));
                    return editorState;
                }

                const selection = editorState.getSelection();

                const blockKey: string = generateRandomKey();

                const sections = annotateTokens(tokens!, res, dataTypeRequired);
                const annotations = sections.filter(x => x.errors && x.errors.length).map<{range: RawDraftEntityRange, key: string, entity: RawDraftEntity}>(sect => {
                    const key: string = generateRandomKey();
                    return {
                        key,
                        range: {
                            key: key as any,
                            offset: sect.range.start,
                            length: sect.range.end - sect.range.start + 1
                        },
                        entity: {
                            data: { errors: sect.errors },
                            mutability: 'MUTABLE',
                            type: 'error'
                        }
                    };
                });

                content = convertFromRaw({
                    blocks: [
                        {
                            text: value.current,
                            type: 'unstyled',
                            entityRanges: annotations.map(x => x.range),
                            key: blockKey,
                            data: undefined,
                            depth: 0,
                            inlineStyleRanges: []
                        }
                    ],
                    entityMap: annotations.reduce((acc, x) => {acc[x.key] = x.entity; return acc;}, {})
                });
                
                let newState = EditorState.push(editorState, content, 'apply-entity');

                if (selection.getHasFocus()) {
                    const newSelection = selection.merge({focusKey: blockKey, anchorKey: blockKey}) as SelectionState;
                    newState = EditorState.forceSelection(newState, newSelection);
                    // log.debug('Forcing selection from', selection.toObject(), 'to', newSelection.toObject());
                } else {
                    // log.debug('Not forcing selection due to lack of focus.');
                }

                return newState;
            });

            setHelperNeeded(true);
        }, 500);
        return () => clearTimeout(handle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokens]);

    useEffect(() => {
        // console.log('calling onparserresult with', parserResult);
        if (onParserResult) onParserResult(parserResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parserResult, onParserResult]);

    const valid = useMemo(() => {
        if (equalsMode && (!value.current || value.current[0] !== '=')) return true;

        if (!parserResult) return false;
        if (!parserResult.root) return false;
        if (!parserResult.valid) return false;
        const flat = flattenAst(parserResult.root);
        if (!flat.every(x => x.valid)) return false;
        if (dataTypeRequired !== undefined && parserResult.root.dataType !== dataTypeRequired) return false;

        return true;
    }, [dataTypeRequired, equalsMode, parserResult]);

    // if (document.activeElement && divRef.current && (document.activeElement === divRef.current || divRef.current.contains(document.activeElement))) {
    //     console.log('on this render, selection is', editorState.getSelection().toObject(), 'and text is', editorState.getCurrentContent().getPlainText(''));
    // }

    useEffect(() => {
        if (onValidityChange) onValidityChange(pending ? undefined : valid, value.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valid, pending]);

    const [helperNeeded, setHelperNeeded] = useState(false); // hack to make this happen on next render

    const onEditorChanged = useCallback((state: EditorState) => {
        // console.log('setting editor state due to editor change');
        let content = state.getCurrentContent();
        const newValue = content.getPlainText('');

        // if equals mode and we don't have an equals expression
        if (equalsMode && newValue[0] !== '=') {
            content = filterEntityRanges((c, ek, bl) => false, content) as ContentState;
            state = EditorState.push(state, content, 'apply-entity');
            setEditorState(state);
            onValidityChange?.(undefined, newValue);

            return;
        }

        // not equals mode, or, equals expression
        setEditorState(state);
        const targetValue = equalsMode ? newValue.substring(1) : newValue;
        setTokens(lexer(targetValue));
    }, [equalsMode, onValidityChange]);

    useEffect(() => {
        const content = editorState.getCurrentContent();
        const newValue = content.getPlainText('');
        broadcastValueChange(newValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editorState]);

    const onEditorChangedRef = useRef(onEditorChanged);
    useEffect(() => { onEditorChangedRef.current = onEditorChanged; }, [onEditorChanged]);
    const onEditorChangedWrapper = (state: EditorState) => onEditorChangedRef.current(state);

    const focused = document.activeElement && el && (document.activeElement === el || el.contains(document.activeElement)) || false;
    const shrink = focused || !!value.current;
    
    const closeHelper = useCallback(() => {
        setHelperPosition(undefined);
        setHelperStartIndex(undefined);
    }, [setHelperPosition]);

    const onCompletionSelected = useCallback((option: CompletionOption) => {
        if (!tokens) return;
        if (!option) return;

        const lastToken = tokens[tokens.length - 1];
        let content = editorState.getCurrentContent();
        
        // const block = content.getFirstBlock();
        // const blockKey = block.getKey();
        const selection = editorState.getSelection();
        const location = selection.getHasFocus()
            ? selection.getFocusOffset()
            : (!lastToken ? 0 : positionRef.current.position || lastToken.range.end);

        // console.log('reported location', location, 'due to focus', selection.getHasFocus());
        const currentTokens = tokens.filter(tk => overlappingRange(tk.range, location));
        // console.log('current tokens', currentTokens);
        // const tki = tokens.findIndex(tk => tk.range.start < location && tk.range.end + 1 >= location);
        const currentTokenIdx = currentTokens.findIndex(x => x.type === TokenType.Name);
        const currentToken = currentTokens[currentTokenIdx];
        const nextToken = currentTokens[currentTokenIdx + 1];

        // const midToken = currentToken !== undefined && currentToken.range.end + 1 >= location;

        // possible scenarios:
        // 1: we're in the middle of a name token
        // 3: we're at the end of a token

        let text = option.text;
        let offset = text.length;
        let newSelection = selection;

        if (option.type === CompletionType.function && (!nextToken || nextToken.token !== '(')) {
            text += '()';
            offset += 1;
        }

        if (currentToken) {
            // console.log('currenttoken', currentToken, 'so replacing it');
            const tokenSelection = selection.merge({anchorOffset: currentToken.range.start, focusOffset: currentToken.range.end}) as SelectionState;
            newSelection = newSelection.merge({anchorOffset: currentToken.range.start + offset, focusOffset: currentToken.range.start + offset}) as SelectionState;
            content = Modifier.replaceText(content, tokenSelection, text);
        } else {
            // console.log('no currenttoken name so inserting at', location);
            const tokenSelection = selection.merge({anchorOffset: location, focusOffset: location}) as SelectionState;
            newSelection = newSelection.merge({anchorOffset: location + offset, focusOffset: location + offset}) as SelectionState;
            content = Modifier.insertText(content, tokenSelection, text);
        }

        let newState = EditorState.push(editorState, content, 'insert-fragment');

        newState = EditorState.forceSelection(newState, newSelection);
        // console.log('setting editor state due to completion insertion');
        setEditorState(newState);

        const newValue = content.getFirstBlock().getText();
        setTokens(lexer(newValue));
        broadcastValueChange(newValue);
        // console.log('on value change', newValue);
        setHelperPosition(undefined);
        setHelperStartIndex(undefined);  
    }, [tokens, editorState, broadcastValueChange]);

    const openHelper = useCallback(() => {
        if (equalsMode && (!value.current || !value.current.startsWith('='))) return;
        if (!document.activeElement || !divRef.current || (document.activeElement !== divRef.current && !divRef.current.contains(document.activeElement))) {
            return;
        }

        const position = determinePosition();
        const index = editorState.getSelection().getFocusOffset();

        setHelperPosition(position);
        setHelperStartIndex(index);
    }, [editorState, equalsMode]);

    useEffect(() => {
        if (!helperNeeded) return;

        setHelperNeeded(false);
        openHelper();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [helperNeeded]);

    const keyCommand = useCallback((command: string, editorState: EditorState, eventTimestamp: number): DraftHandleValue => {
        if (command === 'helper-requested') {
            setHelperNeeded(true);
            return 'handled';
        }
        return 'not-handled';
    }, []);

    const keyBinding = useCallback((evt: React.KeyboardEvent): string | null => {
        if (evt.ctrlKey && (evt.key === 'Space' || evt.key === ' ')) {
            return 'helper-requested';
        }
        if (evt.key === 'Enter') {
            evt.preventDefault();
            return 'enter';
        }
        return getDefaultKeyBinding(evt);
    }, []);

    useEffect(() => {
        if (!parserResult) return;
        if (helperStartIndex === undefined) return;
        if (!tokens) return;

        const currentSelection = editorState.getSelection();
        const currentPosition = currentSelection.getFocusOffset();

        // const currentTokens = tokens && tokens.filter(tk => overlappingRange(tk.range, currentPosition));
        // const currentToken = currentTokens && currentTokens[currentTokens.length - 1];
        const context = completer(value.current || '', tokens, currentPosition,
            parserResult.text, parserResult.root, helperStartIndex,
            fields, dataTypeRequired);
        setCompletionContext(context);
    }, [tokens, fields, parserResult, editorState, helperStartIndex, dataTypeRequired]);

    useEffect(() => {
        if (!active || !dictionarySelection) return;

        const isFunctionDef = (ds: Field | FunctionDef): ds is FunctionDef  => (ds as FunctionDef).hoist !== undefined;

        let option;
        if (isFunctionDef(dictionarySelection)) {
            option = {
                text: dictionarySelection.name,
                displayText: dictionarySelection.name,
                description: dictionarySelection.description,
                dataType: dictionarySelection.returnType,
                type: CompletionType.function,
                historic: false
            };
        } else {
            option = {
                text: dictionarySelection.sourceTable ? `${dictionarySelection.name}@${dictionarySelection.sourceTable}` : dictionarySelection.name,
                displayText: dictionarySelection.name,
                description: dictionarySelection.description,
                dataType: dictionarySelection.type,
                source: dictionarySelection.sourceTable,
                type: CompletionType.field,
                historic: dictionarySelection.historic !== HistoricType.none
            };
        }
        
        onCompletionSelected(option);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active, dictionarySelection]);

    const onFocus = useCallback((evt: React.FocusEvent) => {

        setTimeout(() => {
            // breathing room to allow acquisition of current position
            
            if (dictionaryVisible) {
                setFilterDictionaryOptions({
                    show: true,
                    disabled: false,
                    selected: undefined
                });
            } else {
                setHelperNeeded(true);
            }
            setActive(true);
        }, 0);
    }, [dictionaryVisible, setFilterDictionaryOptions]);

    const completionListRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const selection = editorState.getSelection();
        if (!positionRef.current.fakeBlur) {
            positionRef.current.position = selection.getHasFocus() ? selection.getFocusOffset() : null;
        } else {
            positionRef.current.fakeBlur = false;
        }
    }, [editorState]);

    const onBlur = useCallback((evt: React.FocusEvent) => {
        const relatedTarget: HTMLElement | null = evt.relatedTarget as HTMLElement;


        let dictionaryRelated = false;

        const dict = document.querySelector('.filter-dictionary');
        if (dict) {
            dictionaryRelated = relatedTarget && relatedTarget.classList.contains('filter-dictionary-marker') || dict.contains(relatedTarget);
        }
        
        if ((relatedTarget && completionListRef.current && completionListRef.current.contains(relatedTarget)) || dictionaryRelated) {
            positionRef.current.fakeBlur = true;
            // hack to not hide completions if we are clicking an option

            // editorRef.current!.focus();
            evt.preventDefault();
            return;
        }

        setActive(false);
        setHelperPosition(undefined);
        if (dictionaryVisible) {
            setTimeout(() => setFilterDictionaryOptions({
                show: true,
                disabled: true
            }), 0);
        }
    }, [setFilterDictionaryOptions, dictionaryVisible]);

    const onDictionaryShowClick = useCallback(() => {
        setHelperPosition(undefined);
        setFilterDictionaryOptions({
            show: true,
            disabled: false
        });
    }, [setFilterDictionaryOptions]);
    

    const onIfThenModalSave = useCallback((model?: IfThenGroupModel) => {
        hideIfThenModal();
        if (!model) return;

        const rendered = renderGroup(model);
        if (!rendered) {
            return;
        } else if (equalsMode) {
            processRawValue(`=${rendered}`);
            broadcastValueChange(`=${rendered}`);
        } else {
            processRawValue(rendered);
            broadcastValueChange(rendered);
        }
    }, [broadcastValueChange, equalsMode, hideIfThenModal, processRawValue]);

    const ifThenModal = useMemo((): IfThenGroupModel | undefined => {
        if (!ifThenModalOpen || !!value.current) return {
            type: 'group',
            collapsed: false,
            id: randomString(),
            enabled: true,
            lines: [{id: randomString(), type: 'line', enabled: true}],
            operator: LogicalOperator.and
        };

        const root = parserResult?.root;
        if (!root) return;

        const rendered = renderNodeToIfThen(root);
        if (rendered.type === 'line') {
            return {
                type: 'group',
                collapsed: false,
                id: randomString(),
                enabled: true,
                lines: [rendered],
                operator: LogicalOperator.and
            };
        } else {
            return rendered;
        }
    }, [ifThenModalOpen, parserResult]);

    const ifThenDisabled = (equalsMode && !!value.current?.length && !value.current?.startsWith('=')) || (!!value.current?.length && (!parserResult || !parserResult.valid));

    return (<>
        <div ref={divRef} className={classNames({'filter-editor': true, valid: !pending && valid, invalid: !pending && !valid, pending})}>
            {placeholder && <label hidden={shrink && noPlaceholderPromotion} className={classNames({shrink})}>{placeholder}</label>}
            <Editor
                ref={editorRef}
                editorState={editorState}
                onChange={onEditorChangedWrapper}
                handleKeyCommand={keyCommand}
                keyBindingFn={keyBinding}
                onFocus={onFocus}
                onBlur={onBlur}
            />

            {dataTypeRequired === FieldTypeCategory.Boolean && <IconButton disabled={ifThenDisabled} size="small" className="if-then-modal-trigger" onClick={showIfThenModal} >
                <i className="fal fa-pen"/>
            </IconButton>}
        </div>
        <CompletionList
            ref={completionListRef}
            context={completionContext}
            position={helperPosition}
            onSelect={onCompletionSelected}
            onClose={closeHelper}
            onDictionaryShowClick={onDictionaryShowClick}
        />
        <IfThenModal 
            model={ifThenModal}
            onClose={onIfThenModalSave}
            fields={fields}
            open={ifThenModalOpen}
        />
    </>);
};

const connected = connect(FilterEditor, (store: Store<AppState>) => {
    const props = store.watch(state => ({
        dictionaryVisible: state.filterDictionaryOptions.show,
        dictionarySelection: state.filterDictionaryOptions.selected
    }));

    const actionMap: ActionMap<typeof FilterEditor> = {
        setFilterDictionaryOptions: filterDictionaryOptions$
    };

    return {
        props,
        actionMap
    };
});

export {connected as FilterDictionary};
export default connected;