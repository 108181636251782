import React, {useCallback, useState} from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import './login.scss';

import {ajax} from 'rxjs/ajax';
import {map} from 'rxjs/operators';

import appConfig from '../config';
import { accessToken$ } from '../services/auth';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useInputState } from '../hooks/useInputState';

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const noProdUsers = new Set<string>(['bkehoe', 'msamue', 'pfiede']);

const LoginPage: React.FC<RouteComponentProps> = ({history, location}) => {
    const [username, setUsername, onUsernameChanged] = useInputState();
    const [password, setPassword, onPasswordChanged] = useInputState();
    const [error, setError] = useState<string>();

    const classes = useStyles();

    const login = useCallback(async (evt: React.FormEvent) => {
        evt.preventDefault();
        if (appConfig.environmentName === 'prod' && noProdUsers.has(username.toLowerCase())) return;

        const params = new URLSearchParams(location.search);

        try {
            const res = await ajax({
                url: appConfig.login,
                method: 'post',
                body: {login: username, password}
            }).pipe(
                map(x => x.response)
            ).toPromise();
            
            accessToken$.next(res.token);
            await history.replace(params.get('returnTo') || '/');
        } catch (e) {
            const res = e;
            setError(res.response || 'Invalid username/password.');
        }
    }, [history, location.search, password, username]);

    return <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
            <Avatar className={classes.avatar}>
                <i className="far fa-lock-alt" />
            </Avatar>
            <Typography component="h1" variant="h5">Sign in</Typography>
            <form className={classes.form} noValidate onSubmit={login}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="Username"
                    name="username"
                    autoComplete="username"
                    value={username}
                    onChange={onUsernameChanged}
                    autoFocus
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    value={password}
                    onChange={onPasswordChanged}
                    autoComplete="current-password"
                />
                <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                />
                <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                    Sign In
                </Button>
                <Grid container>
                    {error && <Grid item>
                        {error}
                    </Grid>}
                    {/* <Grid item xs>
                        <Link href="#" variant="body2">
                            Forgot password?
                        </Link>
                    </Grid> */}
                    {/* <Grid item>
                        <Link href="#" variant="body2">
                            Don&apos;t have an account? Sign Up
                        </Link>
                    </Grid> */}
                </Grid>
            </form>
        </div>
        <Box mt={8}>
            <Copyright />
        </Box>
    </Container>;
        
    // <form id="login-page">
    //     <h1>Login</h1>
    //     <div id="error">{error}</div>
    //     <label htmlFor="username">Username</label>
    //     <input type="text" name="username" onChange={e => this.setState({username: e.target.value})} value={username} />
    //     <label htmlFor="password">Password</label>
    //     <input type="password" name="password" onChange={e => this.setState({password: e.target.value})} value={password} />
    //     <button type="submit" onClick={e => { e.preventDefault(); this.login(); }}>Login</button>
    // </form>;
};

export const Copyright: React.FC = () => {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            {new Date().getFullYear()}&nbsp;
            <Link color="inherit" href="https://www.thinkalpha.io/">
                Think Alpha
            </Link>{' '}
        </Typography>
    );
};

export default withRouter(LoginPage);