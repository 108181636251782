export type Range = {start: number, end: number};

export function overlappingRange(a: Range | number, b: Range | number) {
    if (typeof a === 'number') a = {start: a, end: a};
    if (typeof b === 'number') b = {start: b, end: b};

    return (a.start >= b.start && a.start <= b.end) ||
        (a.end >= b.start && a.end <= b.end) ||
        (b.start >= a.start && b.start <= a.end) ||
        (b.end >= a.start && b.end <= a.end);
}

window['overlappingRange'] = overlappingRange;