import { Observable, ObservableInput, Operator, OperatorFunction, Subscriber, ObservedValueOf, SchedulerLike, from } from 'rxjs';

export function combineLatestConflated<O1 extends ObservableInput<any>>(sources: [O1]): Observable<[ObservedValueOf<O1>]>;
export function combineLatestConflated<O1 extends ObservableInput<any>, O2 extends ObservableInput<any>>(sources: [O1, O2]): Observable<[ObservedValueOf<O1>, ObservedValueOf<O2>]>;
export function combineLatestConflated<O1 extends ObservableInput<any>, O2 extends ObservableInput<any>, O3 extends ObservableInput<any>>(sources: [O1, O2, O3]): Observable<[ObservedValueOf<O1>, ObservedValueOf<O2>, ObservedValueOf<O3>]>;
export function combineLatestConflated<O1 extends ObservableInput<any>, O2 extends ObservableInput<any>, O3 extends ObservableInput<any>, O4 extends ObservableInput<any>>(sources: [O1, O2, O3, O4]): Observable<[ObservedValueOf<O1>, ObservedValueOf<O2>, ObservedValueOf<O3>, ObservedValueOf<O4>]>;
export function combineLatestConflated<O1 extends ObservableInput<any>, O2 extends ObservableInput<any>, O3 extends ObservableInput<any>, O4 extends ObservableInput<any>, O5 extends ObservableInput<any>>(sources: [O1, O2, O3, O4, O5]): Observable<[ObservedValueOf<O1>, ObservedValueOf<O2>, ObservedValueOf<O3>, ObservedValueOf<O4>, ObservedValueOf<O5>]>;
export function combineLatestConflated<O1 extends ObservableInput<any>, O2 extends ObservableInput<any>, O3 extends ObservableInput<any>, O4 extends ObservableInput<any>, O5 extends ObservableInput<any>, O6 extends ObservableInput<any>>(sources: [O1, O2, O3, O4, O5, O6]): Observable<[ObservedValueOf<O1>, ObservedValueOf<O2>, ObservedValueOf<O3>, ObservedValueOf<O4>, ObservedValueOf<O5>, ObservedValueOf<O6>]>;
export function combineLatestConflated<O extends ObservableInput<any>>(sources: O[]): Observable<ObservedValueOf<O>[]>;

export function combineLatestConflated(sources: ObservableInput<any>[]): Observable<any[]> {
    // return combineLatest(sources, scheduler); // uncomment this line and comment everything else when you don't trust this code and need to debug other stuff

    return new Observable(subscriber => {
        const values: any[] = new Array(sources.length);
        const subscriptions = sources.map((obs, i) => from(obs).subscribe(x => {
            values[i] = x;
            subscriber.next(values);
        }, subscriber.error.bind(subscriber)));

        return () => {
            for (const sub of subscriptions) {
                sub.unsubscribe();
            }
        };
    });
}