import './preview.scss';

import React, { useCallback, useEffect, useState } from 'react';

import { Redirect, RouteComponentProps, withRouter } from 'react-router';

import { Store } from 'reactive-state';
import { ActionMap, connect } from 'reactive-state/react';
import { AppState } from '../../state';

import loglevel from 'loglevel';
const log = loglevel.getLogger('preview');

import classnames from 'classnames';

import { CircularProgress } from '@material-ui/core';

import { fromEvent, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { FieldDescriptor, Key, TableClient } from '@thinkalpha/table-client';

import TableView from '../../components/table-view/table-view.new';
import {
    ColumnPreference,
    ContextMenuItemsCreator,
    MenuItem,
    TableUserData
} from '../../components/table-view/model';
import { playStrategy, playStrategyById } from '../../services/strategies';
import { client } from '../../services/table';
import { defineStrategy } from '../../strategy/definer';
import { ConcreteStrategy, KnownStrategyTemplate } from '../../strategy/model';
import { defineUniverse } from '../../universe/definer';
import { Universe, UniverseAspectType } from '../../universe/model';
import { StrategyView } from '../../strategy/viewer';

const PreviewPage: React.FunctionComponent<{currentStrategy?: ConcreteStrategy} & RouteComponentProps<{strategyId?: string}>>
= function PreviewPage({currentStrategy, match: {params: {strategyId: matchStrategyId}}, history}) {
    
    const [rowCount, setRowCount] = useState<number>();
    const strategyOrId = matchStrategyId || currentStrategy;

    const gotoDashboard = useCallback(() => {
        history.push('/dashboard');
    }, [history]);

    const gotoWizard = useCallback(() => {
        history.push('/demo/wizard');
    }, [history]);

    if (!strategyOrId) {
        // return <Redirect to="/" />;
        return null;
    }

    return (
        <div id="preview-page">
            <div className="button-bar">
                <button onClick={gotoDashboard}>Back to Dashboard</button>
                <button onClick={gotoWizard}>Edit in Wizard</button>
                <div className="row-count"># of results: {rowCount !== undefined ? rowCount : null}<CircularProgress hidden={rowCount !== undefined} /></div>
            </div>
            <StrategyView
                columnPreferences={[
                    // { name: 'PositionPnLKey', hidden: true },
                    // { name: 'PositionKey', hidden: true },
                    // { name: 'AggunitPositionKey', hidden: true },
                    // { name: 'AccountPropertyKey', hidden: true },
                    // { name: 'Time', flashOnChange: false },
                    // { name: 'Volume', flashOnChange: false },
                    // { name: 'Account', rank: 0 },
                    // { name: 'Symbol', rank: 1 }
                ]}
                strategy={strategyOrId}
                onRowCountChanged={setRowCount}
            />
        </div>
    );
};

const connected = connect(withRouter(PreviewPage), (store: Store<AppState>) => {
    const props = store.watch(state => ({
        currentStrategy: state.currentStrategy,
    }));

    const actionMap: ActionMap<typeof PreviewPage> = {
        // addStrategy: addStrategy$,
        // deleteStrategy: deleteStrategy$
    };

    return {
        props,
        actionMap
    };
});

export default connected;
export {connected as PreviewPage};