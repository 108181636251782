import React, { useState, useCallback, useMemo } from 'react';
import { useInputState } from '../../hooks/useInputState';

import './list-creator.scss';
import { Button, TextField, Select, MenuItem, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core';

export const ListCreator: React.FunctionComponent<{
    name: string; 
    placeholder?: string; 
    value: readonly string[];
    onChange: (list: readonly string[]) => void;
    unique?: boolean;
}> = ({name, placeholder, value, onChange, unique = false}) => {
    const [newItem, setNewItem, onNewItemChanged] = useInputState('');

    const addItem = useCallback(() => {
        if (!newItem) return;
        if (unique && value.includes(newItem)) {
            setNewItem('');
            return;
        }
        onChange([...value, newItem]);
        setNewItem('');
    }, [newItem, onChange, setNewItem, unique, value]);

    const onKeyPress = useCallback((evt: React.KeyboardEvent<HTMLInputElement>) => {
        if (evt.key !== 'Enter') return;
        addItem();
    }, [addItem]);

    const deleters = useMemo(() => value.map((_, io) => () => onChange(value.filter((_, ii) => ii !== io))), [onChange, value]);

    return (
        <div className="list-creator">
            <div className="list-creator__header">
                <label>{name}</label>
            </div>
            <div className="list-creator__insert">
                <TextField
                    variant="outlined"
                    className="list-creator__input"
                    placeholder={placeholder}
                    value={newItem}
                    onKeyPress={onKeyPress}
                    onChange={onNewItemChanged}
                />
                <Button onClick={addItem} disabled={!newItem}><i className="fa fa-plus"/></Button>
            </div>
            <List dense className="list-creator__list">
                {value.map((symbol, i) => <ListItem key={i} value={i}>
                    <ListItemText>{symbol}</ListItemText>
                    <ListItemSecondaryAction>
                        <IconButton edge="end" size="small" onClick={deleters[i]} aria-label="delete">
                            <i className="fas fa-trash" />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>)}
            </List>
        </div>
    );
};
