import React, { useCallback } from 'react';

import { Duration, duration } from 'moment';

import { useNumberInputCallback } from '../../hooks/useInputState';
import { Slider } from '@material-ui/core';

export const Time: React.FunctionComponent<{value: Duration, onChange: (value: Duration) => void}> = ({value = duration(), onChange}) => {
    const days = Math.floor(value.asDays());
    const minutes = Math.floor(value.asMinutes() % (60 * 24));

    const onDaysChange = useCallback((value: number) => {
        onChange(duration(value, 'days').add(minutes, 'minutes'));
    }, [minutes, onChange]);
    const onDaysInputChange = useNumberInputCallback(onDaysChange);

    const onMinutesChange = useCallback((value: number) => {
        onChange(duration(days, 'days').add(value, 'minutes'));
    }, [days, onChange]);
    const onMinutesInputChange = useNumberInputCallback(onMinutesChange);

    return <>
        <div className="days">
            <label>Days</label>
            <Slider min={0} max={365} step={1} value={days} onChange={onDaysInputChange} />
            <input type="number" step={1} min={0} value={days} onChange={onDaysInputChange} />
        </div>
        <div className="minutes">
            <label>Minutes</label>
            <Slider min={0} max={390} step={1} value={minutes} onChange={onMinutesInputChange} />
            <input type="number" step={1} min={0} value={minutes} onChange={onMinutesInputChange} />
        </div>
    </>;
};