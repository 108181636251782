import React from 'react';

import {BrowserRouter, Redirect, Route, RouteProps, Switch} from 'react-router-dom';

import { AppState } from './state';
import { Store } from 'reactive-state';
import { connect } from 'reactive-state/react';

export const PrivateRoute = connect(React.memo(function PrivateRoute({ path, accessToken, ...rest }: RouteProps & Pick<AppState, 'accessToken'>) {
    if (accessToken) {
        return <Route {...rest} />;
    } else {
        return <Route path={path} render={props => <Redirect to={`/login?returnTo=${props.location.pathname}`} />}/>;
    }
}), (store: Store<AppState>) => {
    const props = store.watch(state => {
        return {
            accessToken: state.accessToken,
            client: state.client,
        };
    });
    return {props};
});