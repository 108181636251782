exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"primary": "#67a9ed",
	"secondary": "#aeea00",
	"error": "red",
	"success": "#8bc293"
};