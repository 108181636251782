import React, { useState } from 'react';
import {UniverseCreator} from './universe-creator';
import { Universe } from './model';

// import { FormControlLabel, Switch } from '@material-ui/core';

// import { useCheckedInputState } from '../../hooks/useInputState';
// import { Timeframe, TimeframeRange, timeframeToSubscript } from './timeframe';

export const DemoUniverses: React.FC = () => {
    const [value, setValue] = useState<Universe>();

    return <div>
        <UniverseCreator model={value} onChange={setValue} />
    </div>;
};