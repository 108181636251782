import {useEffect, DependencyList, useRef, useState} from 'react';
import {isEqual} from 'lodash';

export type DeepEffectCallback<T> = (deps: T, cachedDeps: T | undefined) => (void | (() => void | undefined));

export function useDeepEffect<T extends DependencyList>(effect: DeepEffectCallback<T>, equals: (a: T, b: T) => boolean, deps: T);
export function useDeepEffect<T extends DependencyList>(effect: DeepEffectCallback<T>, deps: T);

export function useDeepEffect<T extends DependencyList>(effect: DeepEffectCallback<T>, arg1: T | ((a: T, b: T) => boolean), arg2?: T) {
    const equals = typeof arg1 === 'function' ? arg1 : isEqual;
    const deps: T = typeof arg1 === 'function' ? arg2! : arg1;

    const [cachedDeps, setCachedDeps] = useState<T>();
    useEffect(() => {
        if (cachedDeps && equals(deps, cachedDeps)) return;
        // console.log('unequal deps and cached deps', deps, cachedDeps);
        setCachedDeps(deps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => effect(deps, cachedDeps), [cachedDeps]);
}