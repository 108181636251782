import loglevel from 'loglevel';
const logger = loglevel.getLogger('table-entry');

import { Subscription } from 'rxjs';

import React from 'react';

import classNames from 'classnames';

import { Store } from 'reactive-state';
import { connect } from 'reactive-state/react';
import { AppState } from '../state';

import { FieldDescriptor, Key, RawClient } from '@thinkalpha/table-client';
import { stringifyKey } from '@thinkalpha/table-client';
import { FieldTypeNames, TableClient } from '@thinkalpha/table-client';
import { boundMethod } from 'autobind-decorator';
import { filter, map, publishBehavior, refCount, switchMap } from 'rxjs/operators';

import './key-tree.scss';

type TableEntryState = {
    expanded: boolean;
    fields?: {name: string, type: string}[];
};
class TableEntry extends React.Component<{tableKey: Key, client: RawClient, onOpen?: (key: Key) => void}, TableEntryState> {
    state: TableEntryState = {
        expanded: false
    };

    private tableClient?: TableClient;

    private fieldsSub = new Subscription();

    @boundMethod
    toggle() {
        const expanded = !this.state.expanded;
        this.setState({expanded});
        if (expanded) {
            logger.debug('subscribing to descriptors for', this.props.tableKey);
            this.tableClient = new TableClient(this.props.client, this.props.tableKey);
            this.fieldsSub.add(this.tableClient.descriptor$.pipe(
                filter(x => !!x),
                map(descs => descs.map(desc => ({
                    name: desc.name,
                    type: FieldTypeNames.get(desc.type)!
                })
                ))).subscribe(fields => this.setState({fields})));
        } else {
            this.fieldsSub.unsubscribe();
            if (this.tableClient) this.tableClient.dispose();
            this.tableClient = undefined;
        }
    }

    @boundMethod
    open() {
        if (this.props.onOpen) this.props.onOpen(this.props.tableKey);
    }

    componentWillUnmount() {
        this.fieldsSub.unsubscribe();
        if (this.tableClient) this.tableClient.dispose();
    }
        
    get name() {
        return stringifyKey(this.props.tableKey);
    }

    render() {
        const {expanded, fields} = this.state;

        return <>
            <div className="table-option">
                <span className="table-name">
                    <i className={classNames({'fa': true, 'fa-plus-square': !expanded, 'fa-minus-square': expanded})} onClick={this.toggle}/>
                    &nbsp;<span onDoubleClick={this.open}>{this.name}</span>
                </span>
            </div>
            { expanded && fields && <div className="fields">
                {fields.map(field => <div key={field.name} className="field">
                    <i className="fa fa-list"/><span className="field-name">{field.name}</span>
                    <span className="field-type">{field.type}</span>
                </div>)}
            </div> }
        </>;
    }
}

const connectedTableEntry = connect(TableEntry, (store: Store<AppState>) => {
    const props = store.watch(state => ({
        client: state.client
    }));
    return {props};
});
export {connectedTableEntry as TableEntry};

// @Component({
//     selector: 'key-tree',
//     template: `<div *ngFor="let key of keys$ | async">
//         <table-entry [key]="key" (onOpened)="onOpened(key)"></table-entry>
//     </div>`,
//     styleUrls: ['./key-tree.component.scss']
// })
// export class KeyTreeComponent {
//     keys$: Observable<Key[]>;
//     client: RawClient;
//     @Output() onOpened = new EventEmitter<Key>();

//     private async init(clientService: TableClientService) {
//         this.client = await clientService.getClient();
//         this.keys$ = this.client.keys$;
//         this.cdr.detectChanges();
//     }
//     constructor(clientService: TableClientService, private cdr: ChangeDetectorRef) {
//         this.init(clientService);
//     }
// }