import './if-then.scss';

import loglevel from 'loglevel';

import _ from 'lodash';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import {useInputCallback} from '../hooks/useInputState';
import { useToggleState } from '../hooks/useToggleState';

import {MenuItem, Tab, Toolbar, Box, IconButton} from '@material-ui/core';

import { Store } from 'reactive-state';
import { connect } from 'reactive-state/react';

import { FieldDescriptor, Key, parseKey, RawClient, stringifyKey, TableClient, FieldTypeCategory } from '@thinkalpha/table-client';

import TableView from '../components/table-view/table-view.new';
import { AppState } from '../state';
import { ConcreteStrategy, IfThenStrategy, StrategyType } from '../strategy/model';
import {appConfig} from '../config/appConfig';

import IfThenStrategyComponent from './strategy/if-then-strategy.component';

import classnames from 'classnames';

import { defineStrategy } from '../strategy/definer';
import { defineUniverse } from '../universe/definer';

import { LinkBar } from '../demo/link-bar';
import { StrategyRecord } from '../services/strategies';
import { descriptorToField } from '../components/data-builder/data-builder';
import { useDeepEffect } from '../hooks/useDeepEffect';
import FilterEditor from '../components/filter-editor/filter-editor.new';
import ifThenHardcodedColumnPreferences from './strategy/preferences.hardcoded';
import { ColumnPreference, TableUserData } from '../components/table-view/model';
import { AppDrawer } from '../app-drawer/app-drawer';
import { FilterDictionary } from '../components/filter-dictionary/filter-dictionary';
import {SmartFilterBox} from '../components/smart-filter';
import {StrategyIfThenActionsBar} from './strategy/actions-bar';
import { randomString } from '../util/randomString';
import { IndicatorGrouping, Indicator, IndicatorGroupingKind, IndicatorCategory, IndicatorPackage, IndicatorClass, IndicatorClassType } from 'src/components/filter-editor/model';
import { LogicalOperator } from '@thinkalpha/language-services';

type Props = {
    tableKey?: Key;
    // columnPreference?: (field: FieldDescriptor) => ColumnPreference | undefined;
    // columnPreferences: ColumnPreference[];
};

type Tab = 'builder' | 'preview' | 'script' | 'model';

function defaultStrategy(): ConcreteStrategy<IfThenStrategy> {
    const strategy: ConcreteStrategy<IfThenStrategy> = {
        universe: undefined,
        strategy: {
            type: StrategyType.ifThen,
            root: {
                id: randomString(),
                type: 'group',
                enabled: true,
                lines: [{type: 'group', id: randomString(), enabled: true, lines: [{type: 'line', enabled: true, id: randomString()}], operator: LogicalOperator.and, collapsed: false}],
                operator: LogicalOperator.or,
                collapsed: false,
                formulas: [],
            },
        }
    };
    Object.freeze(strategy);
    return strategy;
}

export const IfThenBuilder: React.FC<Props & RouteComponentProps<{key?: string}>> = ({tableKey, match}) => {
    const [strategy, setStrategy] = useState<ConcreteStrategy<IfThenStrategy>>(defaultStrategy());

    const [fields, setFields] = useState<FieldDescriptor[]>([]);
    const [activeTab, setActiveTab] = useState<Tab>('builder');
    const [rowCount, setRowCount] = useState<number>();
    const [collapseBuilder,, , , toggleCollapseBuilder ] = useToggleState(false);
    const [tableState, setTableState] = useState<TableUserData>();
    const [filter, setFilter] = useState<string | null>(null);
    const [drawerShown,,, hideDrawer, toggleDrawer] = useToggleState(false);

    const columnPreferences: readonly ColumnPreference[] = useMemo(() => [
        ...ifThenHardcodedColumnPreferences.map(cp => ({
            ...cp,
            rank: cp.rank !== undefined ? cp.rank + 1 : undefined
        })),
        ...strategy?.strategy?.root?.formulas?.map?.(f => ({
            name: f.name,
            rank: 0
        })) ?? []
    ], [strategy]);

    const onStrategyChanged = useCallback((strategy?: ConcreteStrategy<IfThenStrategy>) => {
        setStrategy(strategy ?? defaultStrategy());
    }, []);

    const convertedFields = fields.map(descriptorToField);
    const key: Key = {sym: strategy?.tableName ?? 'allUniverseIfThenBase', ex: 'T'};

    const buildTab = <div id="build-tab">
        <div id="builder" className={classnames({collapsed: collapseBuilder})}>
            <IfThenStrategyComponent
                tableKey={key}
                model={strategy}
                onModelChanged={setStrategy}
            />
        </div>
        <div id="preview">
            <div id="heading">
                <div className="global-filter">
                    <SmartFilterBox
                        fields={convertedFields}
                        value={filter}
                        onChange={setFilter}
                    />
                </div>
                <div className="expand"/>
                {rowCount !== undefined && <div className="row-count"><strong>Results:</strong>&nbsp;&nbsp;{rowCount}</div>}
                <IconButton edge="end" size="small" onClick={toggleCollapseBuilder}>
                    <i className={classnames({fas: true, 'fa-expand-arrows': !collapseBuilder, 'fa-compress-arrows-alt': collapseBuilder})}/>
                </IconButton>
            </div>
            {activeTab === 'builder' && <TableView
                onFields={setFields}
                onRowCountChanged={setRowCount}
                theme="ag-theme-balham-dark"
                tableKey={key}
                columnPreferences={columnPreferences}
                tableState={tableState}
                onStateChanged={setTableState}
                smartFilter={filter ?? undefined}
            />}
        </div>
    </div>;

    // const modelTab = <div id="model-tab">
    //     {uncommittedChanges ? <h3>Uncommitted Model <button onClick={refreshPreview}>Commit</button></h3> : <h3>Committed Model</h3>}
    //     {uncommittedChanges ? <textarea rows={25} style={{width: '100%'}} value={JSON.stringify(strategy, undefined, 2)} onChange={onModelEdit} /> : <pre>{JSON.stringify(strategy, undefined, 2)}</pre>}
    //     {uncommittedChanges && <>
    //         <h3>Committed Model</h3>
    //         <pre>{JSON.stringify(committedModel, undefined, 2)}</pre>
    //     </>}
    // </div>;

    // const universeDef = defineUniverse(strategy.universe || {aspects: [], inclusionList: [], exclusionList: []});
    // const strategyDef = defineStrategy(universeDef.name, strategy);
    // const scriptTab = <div id="script-tab">
    //     <pre>{JSON.stringify([...universeDef.definitions, ...strategyDef.definitions], undefined, 2)}</pre>
    // </div>;

    const mainPage =
        <div className="main-page">
            <Box display="flex" alignItems="center" width="100%">
                <Box flexGrow={0} flexShrink={0} flexBasis="auto">
                    <Toolbar hidden={activeTab !== 'preview'}>
                    </Toolbar>
                </Box>
            </Box>
            <div className="tab-content">
                <div hidden={activeTab !== 'builder'}>
                    {buildTab}
                </div>
                <div hidden={activeTab !== 'preview'}>
                    <div id="view-tab">
                        {activeTab === 'preview' && key && <TableView
                            theme="ag-theme-balham-dark"
                            tableKey={key}
                            columnPreferences={columnPreferences}
                            tableState={tableState}
                            onStateChanged={setTableState}
                            filter={filter ?? undefined}
                        />}
                    </div>
                </div>
                {/* <div hidden={activeTab !== 'model'}>
                    {modelTab}
                </div>
                <div hidden={activeTab !== 'script'}>
                    {scriptTab}
                </div> */}
            </div>
        </div>;

        
    return <>
        <AppDrawer onClose={hideDrawer} open={drawerShown}>
            {/* <MenuItem onClick={switchToBuilder}>Builder</MenuItem>
            <MenuItem onClick={switchToModel}>Model</MenuItem>
            <MenuItem onClick={switchToScript} divider>Script</MenuItem> */}
                
            <MenuItem disabled>Table:&nbsp;<code>{stringifyKey(key)}</code></MenuItem>
            {appConfig.commit && <MenuItem disabled>Version:&nbsp;<code>{appConfig.commit.substr(0, 6)}</code></MenuItem>}
        </AppDrawer>
        <main id="if-then-page" className={classnames('drawerable', {'shift-for-drawer': drawerShown})} style={{position: 'relative'}}>
            <LinkBar onMenu={drawerShown ? undefined : toggleDrawer}>
                <StrategyIfThenActionsBar
                    model={strategy}
                    setModel={onStrategyChanged}
                    uncommittedChanges={false} // todo
                />
            </LinkBar>
            <div className="page">
                {mainPage}
            </div>
            <FilterDictionary 
                // indicators={} categories={categories} packages={packages} classTypes={classTypes} classes={classes} 
            />
        </main>
    </>;
};

export default withRouter(IfThenBuilder);

// export default connect(withRouter(IfThenBuilder), (store: Store<AppState>) => {
//     const props = store.watch(state => ({
//         client: state.client,
//     }));

//     return {
//         props,
//     };
// });