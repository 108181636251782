import loglevel from 'loglevel';
const log = loglevel.getLogger('if-then');

import _ from 'lodash';
import './if-then-strategy.component.scss';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import {useInputCallback} from '../../hooks/useInputState';
import { useToggleState } from '../../hooks/useToggleState';

import {Menu, MenuItem, Tab, Dialog, DialogTitle, DialogContent, DialogActions, Toolbar, Box, Button, IconButton, ButtonGroup} from '@material-ui/core';

import { Store } from 'reactive-state';
import { ActionMap, connect } from 'reactive-state/react';

import { FieldDescriptor, Key, parseKey, RawClient, stringifyKey, TableClient, FieldTypeCategory } from '@thinkalpha/table-client';
import {SaveNameDialog} from './save-name.dialog';
import TableView from '../../components/table-view/table-view.new';
import { AppState, clipboard$, Clipboard } from '../../state';
import { ConcreteStrategy, IfThenStrategy, MaterializedStrategy, StrategyType } from '../../strategy/model';
import { randomString } from '../../util/randomString';
import { IfThenRootModel } from '../model';

import IfThenGroup from '../group';
import IfThenComponent from '../if-then.component';

import {createJob} from '../../services/gauss';
import {createIfThenBacktest} from '../../strategy/creators/if-then/backtest';

import { Universe } from '../../universe/model';

import classnames from 'classnames';

import { defineStrategy } from '../../strategy/definer';
import { defineUniverse } from '../../universe/definer';
import {SpecialResult, UniverseSelector} from '../../universe/universe-selector';

import { filter, map } from 'rxjs/operators';
import { getSavedStrategies, getStrategyById, playTransientStrategy, saveStrategy, StrategyRecord } from '../../services/strategies';
import { useDeepEffect } from '../../hooks/useDeepEffect';
import BacktestSettings from './backtest-settings';

type Props = {
    tableKey?: Key;
    // savedStrategies: IfThenGroupModel[];
    onModelChanged?: (strategy: ConcreteStrategy<IfThenStrategy>) => void;
    model: ConcreteStrategy<IfThenStrategy>;
    // addStrategy: (strategy: IfThenGroupModel) => void;
    // deleteStrategy: (strategy: {name: string}) => void;
};

export const IfThenStrategyComponent: React.FC<Props> = ({
    tableKey, onModelChanged, model
}) => {
    const [strategy, setStrategy] = useState<ConcreteStrategy<IfThenStrategy>>(model);
    const [key, setKey] = useState<Key | undefined>(tableKey);
    const [backtestSettingsVisible, , showBacktestSettings, hideBacktestSettings] = useToggleState(false);
    const [backtestReport, setBacktestReport] = useState<any>(null);
    const [backtestReportLoading, setBacktestReportLoading] = useState<any>(false);

    useDeepEffect(() => {
        // console.log('setting strategy from model', old, newer);
        setStrategy(model);
    }, [model]);
    
    useEffect(() => {
        let key: Key;
        if (tableKey) {
            key = tableKey;
        } else {
            // key = {sym: 'MktDataBase', ex: 'T'};
            return;
        }

        setKey(key);
    }, [tableKey]);

    useDeepEffect((newerDeps, oldDeps) => {
        const newer = newerDeps[0];
        const old = oldDeps?.[0];

        log.info('refreshing running strategy', old, newer);

        const universe = strategy.universe;

        playTransientStrategy(universe, strategy.strategy).subscribe(playedStrategy => {
            if (!playedStrategy) return;
            onModelChanged?.(playedStrategy as ConcreteStrategy<IfThenStrategy>);
            // console.log('outputing strategy to model', playedStrategy);
            setKey({sym: playedStrategy.tableName!, ex: 'T'});
        }, err => log.warn('Got error defining strategy', err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    ([old], [newer]) => old?.id === newer.id && _.isEqual(old?.strategy, newer.strategy) && _.isEqual(old?.universe, newer.universe),
    [strategy]);

    const onRootModelUpdated = useCallback((newRoot: IfThenRootModel) => {
        log.debug('strategy updated with new root model', newRoot);
        setStrategy(model => ({
            ...model,
            strategy: {
                ...model.strategy,
                root: newRoot
            },
            tableName: undefined
        }));
    }, []);

    const onUniverseChanged = useCallback((newUniverse: Universe | SpecialResult | undefined) => {
        if (typeof newUniverse === 'object') {
            setStrategy(strat => ({...strat, universe: newUniverse}));
        } else if (newUniverse === undefined) {
            setStrategy(strat => ({...strat, universe: undefined}));
        } else if (newUniverse === SpecialResult.create) {
            
        } else {
            // todo: special result such as create
        }
    }, []);

    
    const parseResult = useCallback((res) => {
        const getSeriesPairs = (index, x, y, isPercentage?) =>  res.result[index].value.map((val) => [Number(val[x]), Number(val[y])*(isPercentage ? 100 : 1)]).filter(x => !isNaN(x[0]) && !isNaN(x[0]));
        const getDateSeriesPairs = (index, x, y, isPercentage?) =>  res.result[index].value.map((val) => [new Date(val[x]), Number(val[y])*(isPercentage ? 100 : 1)]).filter(x => !isNaN(x[0]) && !isNaN(x[0]));
        const getSeriesPairsZero = (index, y,  isPercentage?) =>  getSeriesPairs(index, 0, y, isPercentage);
        const splitArray = (array, pieces, elements) =>  [...Array(pieces).keys()].map(n => array.slice(n*elements, n*elements + elements));

        const r: any = {
            allaround1: {},
            allaround2: {},
            allaround3: {},
            allaround4: {},
            allaround5: {},
            allaround6: {},

            allaround7: {},
            allaround8: {},
            allaround9: {},
            allaround10: {},
            allaround11: {},
            allaround12: {},
            allaround13: {},
            allaround14: {},

            yearper1: {},
            yearper2: {},
            yearper3: {},
            yearper4: {},
            yearper5: {},
            yearper6: {},
            yearper7: {},
            yearper8: {},
            yearper9: {},
            yearper10: {},
            numtable1: {},
            timesers1: {},
            timesers2: {},
            timesers3: {},
            actport1: {},
            actport2: {},
            conditions: {},
            constants: {},
            variables: {},
            before1: {},
            before2: {},
        };
        r.allaround1.series1 = getSeriesPairsZero(12, 1, true);
        r.allaround1.series2 = getSeriesPairsZero(12, 8, true);
        r.allaround1.series3 = getSeriesPairsZero(12, 9, true);

        r.allaround2.series1 = getSeriesPairsZero(12, 2);
        r.allaround2.series2 = getSeriesPairsZero(12, 7);

        r.allaround3.series1 = getSeriesPairsZero(12, 3, true);
        r.allaround3.series2 = getSeriesPairsZero(12, 4);

        r.allaround4.series1 = getSeriesPairsZero(12, 5, true);

        r.allaround5.series1 = getSeriesPairsZero(12, 2);
        r.allaround5.series2 = getSeriesPairsZero(12, 13);

        r.allaround6.series1 = getSeriesPairsZero(12, 23, true);
        r.allaround6.series2 = getSeriesPairsZero(12, 24);

        [7, 8, 9, 10, 11, 12, 13, 14].forEach((n, i)=> {
            r[`allaround${n}`].series1 = getSeriesPairsZero(12, 37 + i, true);
            r[`allaround${n}`].series2 = getSeriesPairsZero(12, 49 + i);
        });

        r.pm4 = res.result[3].value;
        r.pm = res.result[4].value;

        r.yearper1.series1 = getSeriesPairsZero(14, 58);

        r.yearper2.series1 = getSeriesPairsZero(14, 2);
        r.yearper2.series2 = getSeriesPairsZero(14, 63);

        r.yearper3.series1 = getSeriesPairsZero(14, 61);

        r.yearper4.series1 = getSeriesPairsZero(14, 5, true);
        r.yearper4.series2 = getSeriesPairsZero(14, 66, true);

        r.yearper5.series1 = getSeriesPairsZero(14, 38);
        r.yearper5.series2 = getSeriesPairsZero(14, 39);

        r.yearper6.series1 = getSeriesPairsZero(14, 43, true);

        r.yearper7.series1 = getSeriesPairsZero(14, 50, true);
        r.yearper7.series2 = getSeriesPairsZero(14, 51, true);

        r.yearper8.series1 = getSeriesPairsZero(14, 44);

        r.yearper9.series1 = getSeriesPairsZero(14, 49);

        r.yearper10.series1 = getSeriesPairsZero(14, 10);
        r.yearper10.series2 = getSeriesPairsZero(14, 24);

        r.numtable1.series1 = getDateSeriesPairs(6, 1,  5, true);

        r.timesers1.series1 = getDateSeriesPairs(9, 0, 1, true);
        r.timesers1.series2 = getDateSeriesPairs(9, 0, 4, true);
        r.timesers1.series3 = getDateSeriesPairs(9, 0, 6);

        r.timesers2.series1 = getDateSeriesPairs(9, 0, 13);
        r.timesers2.series2 = getDateSeriesPairs(9, 0, 14);
        r.timesers2.series3 = getDateSeriesPairs(9, 0, 6);

        r.actport1.series1 = getSeriesPairsZero(10, 2, true);
        r.actport1.series2 = getSeriesPairsZero(10, 3, true);

        r.actport2.series1= getSeriesPairsZero(10, 8);
        r.actport2.series2 = getSeriesPairsZero(10, 9);

        r.before1.series1 = getSeriesPairsZero(11, 1, true);
        r.before2.series1 = getSeriesPairsZero(11, 3);

        r.conditions = res.result[0].value.map((x, i) => [i, x[0], x[1], x[8]]);
        r.constants = res.result[1].value;
        r.variables = res.result[2].value;

        r.timesers3.series1 = getDateSeriesPairs(9, 0, 10);

        const porperf = res.result[13].value;
        r.porperf1 = splitArray(porperf.map(x => x[0]), 3, 3);
        r.porperf2 = splitArray(porperf.map(x => x[1]), 3, 3);

        setBacktestReport(r);
    }, []);

    const backtest = useCallback(async () => {
        const strat = strategy.strategy;
        const specs = createIfThenBacktest(strat);
        setBacktestReportLoading(true);
        const job = await createJob(specs);
        console.log('job', job);
        // TODO Should be shown bellow result
        const result = await job.result$.toPromise();
        console.log('job res', result);
        parseResult(result);
        setBacktestReportLoading(false);
    }, [strategy.strategy, parseResult]);

    return <div className="if-then-strategy">
        <div className="button-container">
            <div className="universe"><label>Universe:</label><UniverseSelector universe={strategy.universe} onChange={onUniverseChanged} allowUndefined={false} /></div>
            <Button onClick={showBacktestSettings}>Launch backtest</Button>
        </div>
        {/* <Button onClick={backtest}>Backtest</Button> */}
        <IfThenComponent
            tableKey={key}
            model={strategy.strategy.root}
            onModelChanged={onRootModelUpdated}
        />
        <BacktestSettings 
            run={backtest} 
            open={backtestSettingsVisible}
            report={backtestReport}
            reportLoading={backtestReportLoading}
            onReportClose={() => setBacktestReport(null)}
            onClose={hideBacktestSettings} 
        />
    </div>;
};

export default IfThenStrategyComponent;