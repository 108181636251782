import loglevel from 'loglevel';
const log = loglevel.getLogger('if-then-definer');

import { Operand, IfThenGroupModel, IfThenLineModel, IfThenLinePlaceholder } from '../../../if-then/model';

import { UniverseCreatorResult } from '../../../universe/model';
import { IfThenStrategy, StrategyCreatorResult, StrategyType } from '../../model';

import { DefinitionBundle, FieldDescriptor, FieldType, FieldTypeCategory, ObjectDefinition, TypesInTypeCategory, Formula, TableClient, RawClient } from '@thinkalpha/table-client';
import { randomString } from '../../../util/randomString';

import { defaultColumns } from '../defaultCols';

import { LogicalOperator, KnownAstNode, parser, lexer } from '@thinkalpha/language-services';
import compiler, { CompilationOptions, gaussOperators } from '@thinkalpha/language-services/dist/compilers/gauss';

import { StrategyDefiner } from '../../definer';
// import {strategyFields} from '../../../if-then/fields.hardcoded';
import { Field, HistoricType } from '../../../components/filter-editor/model';
import {pointInTimeToSubscript, timeframeToSubscript} from '../../../components/timeframe/pointInTime';
import { CustomFormula } from '../../../components/data-builder/model';

import {isMoment} from 'moment';
import { strategyFields, allStrategyFields } from '../../../if-then/strategy/fields.hardcoded';
import {groupToFilter} from '../../../if-then/render';
import { GaussType, GaussProgram } from '../../../contracts/gauss';
import _ from 'lodash';
import wu from 'wu';
import { functionDefs } from '../../../syntax/functions';

const stringFieldTypes = [...TypesInTypeCategory.get(FieldTypeCategory.String)!, FieldTypeCategory.String];
Object.freeze(stringFieldTypes);

function parse(operand: Operand, fields: readonly Field[]) {
    const res = parser(operand, fields, functionDefs);
    return res;
}

function handleLine(line: IfThenLineModel, fields: readonly Field[], options: CompilationOptions): string {
    const operand1 = compiler(parse(line.operand1, fields).root, options);
    const operand2 = compiler(parse(line.operand2, fields).root, options);

    const op = gaussOperators.get(line.operator)!;
    return `${operand1} ${op} ${operand2}`;
}

function handleGroup(group: IfThenGroupModel, fields: readonly Field[], options: CompilationOptions): string | undefined {
    const lines = _(group.lines).filter((x): x is (IfThenLineModel | IfThenGroupModel) => 'operator' in x).filter(x => x.enabled).value();
    if (!lines.length) return;
    const compiledLines = _(lines)
        .map(line => handleGroupOrLine(line, fields, options))
        .filter(x => !!x)
        .value();
    return `${compiledLines.map(line => `(${line})`).join(` ${gaussOperators.get(group.operator)!} `)}`;
}

function handleGroupOrLine(line: IfThenGroupModel | IfThenLineModel | IfThenLinePlaceholder, fields: readonly Field[], options: CompilationOptions): string | undefined {
    if (line.type === 'group') {
        return handleGroup(line, fields, options);
    } else if (line.type === 'line') {
        if (!('operator' in line)) return;
        return handleLine(line, fields, options);
    } else {
        throw new Error('Unexpected line type');
    }
}

export const createIfThenBacktest = (strategy: IfThenStrategy): GaussProgram => {
    let conditions = strategy.root.lines;
    if (strategy.root.operator !== LogicalOperator.and && strategy.root.lines.length > 1) {
        // this is a hack to make the top level always an AND
        conditions = [{id: randomString(), collapsed: false, operator: LogicalOperator.and, lines: conditions, enabled: true, type: 'group'}];
    }

    const fields = new Map<string, KnownAstNode>();
    const simulatedFields: Field[] = [...allStrategyFields];
    for (const formulaEntry of strategy.root.formulas) {
        const {formula, name, historic} = formulaEntry;
        if (historic) {
            throw new Error('Historic columns are not currently supported.');
        }

        const parserResult = parse(formula, simulatedFields);
        fields.set(name, parserResult.root!);
        simulatedFields.push(formulaEntry);
    }

    const entryModel = '';
    const exitModel = '';
    const entryConds: [string, string, ][] = []; // cond name, cond
    const exitConds = [];

    const symbols = new Map<string, string>();
    const functions = new Map<string, string>();
    const constants: string[] = [];
    for (const condition of conditions) {
        const compiled = handleGroupOrLine(condition, allStrategyFields, {symbols, functions, constants, fields});
        if (!compiled) continue;
        
        entryConds.push([(('name' in condition) ? condition.name! : condition.id) ?? randomString(), compiled!]);
    }
    console.debug(constants, functions, symbols);
    const outConstants = constants.map((x, i) => [`K${i}`, x]);
    const modelFuncs: [string, string][] = wu(functions).map<[string, string]>(([key, value]) => [value, key]).toArray();
    const modelVars: [string, string, ''][] = wu(symbols).map<[string, string, '']>(([key, value]) => [value, key, '']).toArray();

    const settings = [
        /*
            15x1 String vector containing the 15 user-defined settings for the backtest. 
            These settings are taken from the Backtest Settings section of the Strategy 
            Settings table. After a strategy has been selected in AlphaEdge, one of the
            menu options is 'Strategy Settings.'  This option lets users see and revise
            their strategy settings, including those used for backtesting.
            :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
            Row 01  Username
            Row 02  Strategy Identifier
            Row 03  Strategy Description
            Row 04  Identifier for the strategy's eligible universe
            Row 05  Identifier for the strategy's position entry model
            Row 06  Identifier for the strategy's position exit model  (null if not specified)
            Row 07  Periodicity of the strategy's position entry model  (e.g. 10 seconds, 5 days, etc.)
            Row 08  Type of Bar in the backtest  (Time, Number of Trades, or Shares Traded)
            Row 09  Number of periods in the backtest                
            Row 10  Starting date and time for the backtest (Date and time of the first observation in the lookback)
            Row 11  Ending date and time for the backtest   (Date and time of the last observation in the lookback)            
            Row 12  Model Direction (Long, Short. or null; if null, the backtest engine will determine the optimal direction for the positon entry model)
            Row 13  Holding Period  (Number of periods the position should remain open before closing; if null, the backtest engine will determine the optimal holding peirod)
            Row 14  Symbol for the market benchmark to use to calculate market relative returns
            Row 15  Reserved for future setting
        */
        'username',
        strategy.name ?? 'no name',
        'strategy desc',
        'universe id',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
    ];

    return {
        program: {name: 'avatar_backtest.prg'},
        inputs: [
            {
                symbol: 'entrymodel',
                type: GaussType.string,
                value: entryModel
            }, {
                symbol: 'exitmodel',
                type: GaussType.string,
                value: exitModel
            }, {
                symbol: 'entryconds',
                type: GaussType.stringArray,
                value: entryConds
            }, {
                symbol: 'exitconds',
                type: GaussType.stringArray,
                value: exitConds
            }, {
                symbol: 'modelfuncs',
                type: GaussType.stringArray,
                value: modelFuncs
            }, {
                symbol: 'modelvars',
                type: GaussType.stringArray,
                value: modelVars
            }, {
                symbol: 'constants',
                type: GaussType.stringArray,
                value: outConstants
            }, {
                symbol: 'settings',
                type: GaussType.stringArray,
                value: settings
            }
        ],
        outputs: [
            {
                symbol: 'showcond1',
                type: GaussType.stringArray,
                columns: 9
            }, {
                symbol: 'showcond2',
                type: GaussType.stringArray,
                columns: 2
            }, {
                symbol: 'showmvar',
                type: GaussType.stringArray,
                columns: 2
            }, {
                symbol: 'invout',
                type: GaussType.stringArray,
                columns: 5
            }, {
                symbol: 'horzns',
                type: GaussType.stringArray,
                columns: 3
            }, {
                symbol: 'postable',
                type: GaussType.stringArray,
                columns: 9
            }, {
                symbol: 'numtable',
                type: GaussType.stringArray,
                columns: 20
            }, {
                symbol: 'condvarx',
                type: GaussType.stringArray,
                columns: 47
            }, {
                symbol: 'leadspec',
                type: GaussType.stringArray,
                columns: 34
            }, {
                symbol: 'timesers',
                type: GaussType.stringArray,
                columns: 53
            }, {
                symbol: 'actport',
                type: GaussType.stringArray,
                columns: 10
            }, {
                symbol: 'before',
                type: GaussType.stringArray,
                columns: 22
            }, {
                symbol: 'allaround',
                type: GaussType.stringArray,
                columns: 73
            }, {
                symbol: 'porperf',
                type: GaussType.stringArray,
                columns: 3
            }, {
                symbol: 'yearper',
                type: GaussType.stringArray,
                columns: 69
            }, {
                symbol: 'sumtabs',
                type: GaussType.stringArray,
                columns: 67
            }, {
                symbol: 'finsets',
                type: GaussType.stringArray,
                columns: 1
            }
        ]
    };
};