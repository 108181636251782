import React, { useCallback, useEffect, useState } from 'react';

import { Button, FormControl, FormControlLabel, InputLabel, Radio, RadioGroup, Select, Step, StepContent, StepLabel, Stepper, Typography, Slider } from '@material-ui/core';
import moment, { duration, Duration } from 'moment';

import { isEqual } from 'lodash';
import {Time} from '../../../components/time/time';
import {useCheckedInputState, useInputCallback, useNumberInputCallback} from '../../../hooks/useInputState';
import { MovingAverageBenchmark } from '../../model';

import './moving-average.scss';

const premadeBenchmarks: {name: string, benchmark: MovingAverageBenchmark}[] = [
    {name: '20-day EMA', benchmark: {
        duration: duration(20, 'days'),
        intervals: 1,
        exponential: true
    }},
    {name: '30-day SMA', benchmark: {
        duration: duration(30, 'days'),
        intervals: 1,
        exponential: false
    }},
    {name: '90-day EMA', benchmark: {
        duration: duration(90, 'days'),
        intervals: 1,
        exponential: true
    }}
];

const customMoniker = 'custom';

export function areBenchmarksEqual(a: MovingAverageBenchmark | undefined, b: MovingAverageBenchmark | undefined) {
    if (a === undefined || b === undefined) return a === undefined && b === undefined;
    return a.exponential === b.exponential && a.intervals === b.intervals && a.duration.toISOString() === b.duration.toISOString();
}

export const MovingAverageBenchmarkComponent: React.FunctionComponent<{value: MovingAverageBenchmark | undefined, onChange: (value?: MovingAverageBenchmark) => void}> = ({value, onChange}) => {
    // const [internalValue, setInternalValue] = useState<MovingAverageBenchmark>();
    const [showCustom, setShowCustom] = useState<boolean>();
    const defaultValue: MovingAverageBenchmark = {duration: duration(), exponential: false, intervals: 0};

    const match = premadeBenchmarks.find(x => areBenchmarksEqual(x.benchmark, value));
    const radioValue = !value
        ? ''
        : match
            ? match.name
            : customMoniker;

    const onLevelChange = useCallback(({target: {value}}: React.ChangeEvent<HTMLInputElement>) => {
        if (value === customMoniker) {
            setShowCustom(true);
        } else {
            const match = premadeBenchmarks.find(x => x.name === value);
            if (onChange) onChange(match ? match.benchmark : premadeBenchmarks[0].benchmark);
            setShowCustom(false);
        }
    }, [onChange]);

    useEffect(() => {
        if (value && !match) {
            setShowCustom(true);
        }
    }, [value, match, setShowCustom]);

    const valueOrDefault = value || defaultValue;

    const onDurationChange = useCallback((value: Duration) => {
        onChange({...valueOrDefault, duration: value});
    }, [valueOrDefault, onChange]);

    const onIntervalsChange = useCallback((value: number) => {
        onChange({...valueOrDefault, intervals: value});
    }, [valueOrDefault, onChange]);
    const onIntervalsInputChange = useNumberInputCallback(onIntervalsChange);

    const onExponentialChanged = useInputCallback(value => onChange({...valueOrDefault, exponential: value === 'true'}), [valueOrDefault, onChange]);

    return <div className="moving-average-benchmark">
        <RadioGroup value={showCustom ? customMoniker : radioValue} onChange={onLevelChange}>
            {premadeBenchmarks.map((x, i) => <FormControlLabel key={x.name} label={x.name} value={x.name} control={<Radio/>}/>)}
            <FormControlLabel label="Custom" value={customMoniker} control={<Radio/>} />
        </RadioGroup>
        <div hidden={!showCustom} className="custom">
            <div className="dials">
                <Time value={valueOrDefault.duration} onChange={onDurationChange} />
                <div className="count">
                    <label>Number of Intervals</label>
                    <Slider min={0} max={390} step={1} value={valueOrDefault.intervals} onChange={onIntervalsInputChange} />
                    <input type="number" step={1} min={1} value={valueOrDefault.intervals} onChange={onIntervalsInputChange} />
                </div>
            </div>
            <div className="options">
                <RadioGroup value={valueOrDefault.exponential.toString()} onChange={onExponentialChanged}>
                    <FormControlLabel label="Simple Moving Average (SMA)" value={false.toString()} control={<Radio/>} />
                    <FormControlLabel label="Exponential Moving Average (EMA)" value={true.toString()} control={<Radio/>} />
                </RadioGroup>
            </div>
        </div>
    </div>;
};