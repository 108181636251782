import React, { useCallback, useState, useEffect, useMemo, useRef } from 'react';
import { ButtonGroup, Button, MenuItem, Menu } from '@material-ui/core';
import { ConcreteStrategy, IfThenStrategy, StrategyType, MaterializedStrategy } from '../../strategy/model';
// import { useIfThenActions } from '../useIfThenActions';
import { useModalActions } from '../../modals';
import { saveStrategy, StrategyRecord, getSavedStrategies, getStrategyById } from '../../services/strategies';
import { map, filter, tap } from 'rxjs/operators';
import { Togglers, useToggleState } from '../../hooks/useToggleState';
import _ from 'lodash';

type Props = {
    setModel: (model?: ConcreteStrategy<IfThenStrategy>) => void;
    model?: ConcreteStrategy<IfThenStrategy>;

    uncommittedChanges: boolean;
};

export const StrategyIfThenActionsBar: React.FC<Props> = ({
    model, setModel,
    uncommittedChanges
}) => {
    const {showConfirmationModal, showPromptModal} = useModalActions();
    const [loadOptions, setLoadOptions] = useState<StrategyRecord[] | undefined>(undefined);
    const [saved, setSaved] = useState<ConcreteStrategy<IfThenStrategy> | undefined>(model);
    const [loadMenuShown, , showLoadMenu, hideLoadMenu, toggleLoadMenu] = useToggleState(false);
    const loadButtonRef = useRef<HTMLButtonElement>(null);

    const refreshSaveList = useCallback(() => 
        getSavedStrategies().pipe(
            // tap(x => console.log('strategies', x)),
            map(x => x.filter(s => s.type === StrategyType.ifThen && s.name))
        ).subscribe(setLoadOptions)
    , [setLoadOptions]);

    useEffect(() => {
        refreshSaveList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const clear = useCallback(async () => {
        if (uncommittedChanges) {
            const goahead = await showConfirmationModal({
                title: 'Pending Changes',
                content: 'Are you sure you want to clear the editor? You have unsaved changes.'
            });
            if (!goahead) return;
        }

        setModel(undefined);
    }, [setModel, showConfirmationModal, uncommittedChanges]);

    const openSave = useCallback(async () => {
        if (!model) return;
        
        const name = await showPromptModal({
            title: 'Choose a name',
            label: 'Name'
        });
        if (!name) return;

        const newModel: ConcreteStrategy<IfThenStrategy> = {
            ...model,
            strategy: {
                ...model.strategy,
                name
            }
        };

        setModel(newModel);
        await saveStrategy(newModel).toPromise();
        setSaved(newModel);
        refreshSaveList();
    }, [model, refreshSaveList, setModel, showPromptModal]);

    const resave = useCallback(async () => {
        await saveStrategy(model!).toPromise();
        setSaved(model);
        refreshSaveList();
    }, [model, refreshSaveList]);

    const unsavedChanges = useMemo(() => _.isEqual(model, saved), [model, saved]);

    const load = useCallback((saved: StrategyRecord) => {
        hideLoadMenu();
        getStrategyById(saved.id).pipe(
            filter((x): x is MaterializedStrategy<IfThenStrategy> => !x ? false : x.strategy.type === StrategyType.ifThen)
        ).subscribe(strategy => {
            setModel(strategy);
            // setAutocommit(true);
        });
    }, [hideLoadMenu, setModel]);

    const loadCallbacks = useMemo(() => loadOptions ? loadOptions.map(save => () => load(save)) : [], [load, loadOptions]);
    const loadMenu =
        <Menu 
            open={loadMenuShown} 
            id="load-save-menu" 
            anchorEl={loadButtonRef.current}
            getContentAnchorEl={null}
            onClose={hideLoadMenu}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
        >
            {loadOptions && loadOptions.map((save, i) => <MenuItem key={save.name} onClick={loadCallbacks[i]}>{save.name}</MenuItem>)}
        </Menu>;

    return <div className="control-buttons">
        <ButtonGroup variant="outlined">
            <Button onClick={clear} disabled={!model}>New</Button>
            <Button onClick={openSave} disabled={!model}>Save As</Button>
            <Button onClick={resave} disabled={!model || !model.strategy.name || !unsavedChanges}>Save</Button>
            <Button disabled={!loadOptions || !loadOptions.length} onClick={toggleLoadMenu} ref={loadButtonRef}>Load&nbsp;&nbsp;<i className="fas fa-caret-down"/></Button>
        </ButtonGroup>
        {loadMenu}
    </div>;
};