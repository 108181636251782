import React, { useCallback, useState } from 'react';

import {  Dialog, DialogContent, DialogActions, Button, DialogTitle, CircularProgress, TextField, MenuItem, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import BacktestReport from './backtest-report';

import './backtest-settings.scss';

interface Props {
    open: boolean;
    reportLoading: boolean;
    onClose: () => void;
    onReportClose: () => void;
    run: () => void;
    report: any;
}

const SettingsSection = ({children, title}) => <div className="section">
    <div className="title">{title}</div>
    <div>{children}</div>
</div>;

const SettingsOption = ({value, title}) => <div className="settings-option">
    <div className="option-title">{title}</div>
    <TextField select disabled value={1} >
        <MenuItem value={1}>
            {value}
        </MenuItem>
    </TextField>
</div>;

const Stats = ({name, value}) => <div className="stats-container">
    <div>{name}</div>
    <div>{value}</div>
</div>;

const RangeTable = ({columns, rows, caption}) => <div className="range-table-wrapper">
    <div className="caption">{caption}</div>
    <div className="container">
        <Table className="range-table" stickyHeader>
            <TableHead>
                <TableRow>
                    {columns.map(column => (
                        <TableCell
                            align="center"
                            key={column.id}
                            style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
                        >
                            {column.name}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map(row => {
                    return (
                        <TableRow hover  key={row.id}>
                            {columns.map(column => {
                                const value = row[column.key];
                                return (
                                    <TableCell align="center" key={column.id}>
                                        {value}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    </div>
</div>;


const BacktestSettings: React.FC<Props> = ({open, onClose, run, report, reportLoading, onReportClose}) => {

    return <Dialog className="backtest-report" open={open} onClose={onClose}>

        <DialogTitle className="header">
            <div>Backtest Settings for</div>
            <Button color="primary" disabled={reportLoading} variant="contained" size="large"  className="run" onClick={run}>
                Run backtest
                {reportLoading && <CircularProgress className="progress" size={20} />}
            </Button>
        </DialogTitle>
        <DialogContent>
     
            <div className="settings">
                <div className="options">
                    <SettingsSection title='Position entry'>
                        <SettingsOption title='Position Entry Model' value='upcross_52W' />
                        <SettingsOption title='Periodicity of the Position Entry Model' value='Daily' />
                        <SettingsOption title='Eligible Universe' value='U.S. Institutional 1' />
                        <SettingsOption title='Model Direction' value='Long' />
                        <SettingsOption title='Execution Plan for Position Entry Orders' value='VWAP 60 Minutes' />
                        <SettingsOption title='Start Time for Daily Trading' value='9:30am' />
                        <SettingsOption title='End Time for Daily Trading' value='8:00pm (After Hours)' />
                    </SettingsSection>
                    <SettingsSection title='Position exit'>
                        <SettingsOption title='Position Exit Model (Null if Not Specified)' value='None' />
                        <SettingsOption title='Calendar Stop (Periods to Hold Position)' value='3 Days' />
                        <SettingsOption title='Stop Loss Level' value='-1.50% Absolute' />
                        <SettingsOption title='Stop-Loss Duration' value='15 Minutes' />
                        <SettingsOption title='Take Profit Level' value='2.50% Market Relative' />
                        <SettingsOption title='Trailing Stop Activation Level' value='1.00% Market Relative' />
                        <SettingsOption title='Trailing Stop Execution Level' value='-0.50% Market Relative' />
                    </SettingsSection>
                    <SettingsSection title='Backtest'>
                        <SettingsOption title='Type of Bar in the Backtest' value='Time' />
                        <SettingsOption title='Number of Periods in the Backtest ' value='1,000 Days' />
                        <SettingsOption title='Starting Date and Time for the Backtest' value='9:30am 01/03/2017' />
                        <SettingsOption title='Ending Date and Time for the Backtest ' value='4:00 pm 01/02.2020' />
                        <SettingsOption title='Symbol for the Market Benchmark' value='SPX' />
                    </SettingsSection>
                </div>
                <div className="ranges">
                    <RangeTable caption="Set ranges to test: Indicator parameters" rows={[{
                        symbol: 'V01',
                        id: 'Zprices_last_0',
                        parametersCount: 1,
                        parameterCombinations: 1
                    }, {
                        symbol: 'V02',
                        id: 'prices_high_0',
                        parametersCount: 1,
                        parameterCombinations: 1
                    }, {
                        symbol: 'V03',
                        id: 'prices_high52_0',
                        parametersCount: 1,
                        parameterCombinations: 1
                    }, {
                        symbol: 'V04',
                        id: 'prices_last_0',
                        parametersCount: 1,
                        parameterCombinations: 1
                    }, {
                        symbol: 'V05',
                        id: 'volume_vlmshs01_0',
                        parametersCount: 1,
                        parameterCombinations: 1
                    }, {
                        symbol: 'V06',
                        id: 'liquidity_dv20_0',
                        parametersCount: 1,
                        parameterCombinations: 1
                    }, {
                        symbol: 'V07',
                        id: 'marketcap_marketcap_0',
                        parametersCount: 1,
                        parameterCombinations: 1
                    }, {
                        symbol: 'V08',
                        id: 'prices_high52_1',
                        parametersCount: 1,
                        parameterCombinations: 1
                    }, {
                        symbol: 'V09',
                        id: 'prices_high52_2',
                        parametersCount: 1,
                        parameterCombinations: 1
                    }, 
                    ]} columns={[{
                        name: 'Model Symbol',
                        key: 'symbol',
                        minWidth: 100,
                        maxWidth: 100
                    }, {
                        name: 'Indicator identifier',
                        key: 'id',
                        minWidth: 80,
                    }, {
                        name: '# OF Parameters',
                        key: 'parametersCount',
                        minWidth: 80,
                    }, {
                        name: 'Parameters combination to test',
                        key: 'parameterCombinations',
                        minWidth: 80,
                    }]} />
                    <RangeTable caption="Set Ranges to Test: Constants" rows={[{
                        symbol: 'KO1',
                        value: 1,
                        constantsCount: 1
                    }, {
                        symbol: 'KO2',
                        value: 1,
                        constantsCount: 1
                    }, {
                        symbol: 'KO3',
                        value: 1,
                        constantsCount: 1
                    }, {
                        symbol: 'KO4',
                        value: 1,
                        constantsCount: 1
                    }, {
                        symbol: 'KO5',
                        value: 1,
                        constantsCount: 1
                    }, {
                        symbol: 'KO6',
                        value: 1,
                        constantsCount: 1
                    }, {
                        symbol: 'KO7',
                        value: 1,
                        constantsCount: 1
                    }, {
                        symbol: 'KO8',
                        value: 1,
                        constantsCount: 1
                    }, {
                        symbol: 'KO9',
                        value: 1,
                        constantsCount: 1
                    }, 
                    ]} columns={[{
                        name: 'Model Symbol',
                        key: 'symbol',
                        minWidth: 100,
                        maxWidth: 100
                    }, {
                        name: 'Current Value',
                        key: 'value',
                        minWidth: 250,
                    }, {
                        name: 'Number of Constants To Test',
                        key: 'constantsCount',
                        minWidth: 80,
                    }]} />
                    <div>
                        <Stats name='Number of Position Entry Models to Backtest' value={1} />
                        <Stats name='Number of Position Exit Models to Backtest' value={1} />
                        <Stats name='Total Number of Strategies to Backtest' value={1} />
                    </div>
                </div>
            </div>
            {report && <BacktestReport report={report} onClose={onReportClose} />}
        </DialogContent>
        {/* <DialogActions>
            <Button color="primary" onClick={save}>Save</Button>
            <Button color="secondary" onClick={cancel}>Cancel</Button>
        </DialogActions> */}
    </Dialog>;
};

export default BacktestSettings;
