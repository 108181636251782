import './risk-table.scss';

/* eslint-disable react/no-string-refs */
import React, { useRef, useCallback, useState } from 'react';
import { Store } from 'reactive-state';
import { connect } from 'reactive-state/react';

import loglevel from 'loglevel';
const log = loglevel.getLogger('risk-table');

import moment from 'moment';
import numeral from 'numeral';
import { Observable, Subject, Subscription } from 'rxjs';

import { ConnectionStatus, FieldDescriptor, FieldType, FieldTypeCategory, Key, RawClient, Row, TableClient, TypesInTypeCategory } from '@thinkalpha/table-client';
import { boundMethod } from 'autobind-decorator';
import { Dictionary, isNumber } from 'lodash';
import {LightBulb} from '../components/lightbulb/lightbulb';
import TableView from '../components/table-view/table-view.new';
import { AppState } from '../state';
import RiskPopout, {open as openRiskPopout} from './risk-popout';

import FilterEditor from '../components/filter-editor/filter-editor.new';
import { descriptorToField } from '../components/data-builder/data-builder';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { ColumnPreference, ContextMenuItemsCreator, TableUserData } from '../components/table-view/model';

type EventEmitter<T = void> = (event: T) => void;

type Props = {
    client: RawClient;

    columnPreferences: readonly (ColumnPreference | string)[];
    columnPreference?: (field: FieldDescriptor | string) => ColumnPreference | undefined;
    columnRankByIndex?: boolean;
    defaultColumnPreference?: Omit<ColumnPreference, 'name'>;

    tableKey: Key;
    linked?: boolean;
    hideExpand?: boolean;
    filter?: string;
    saveData: RiskTableSaveData;
    title: string;
    header?: React.ReactElement;
    contextMenuItemsCreator?: ContextMenuItemsCreator;
    hideTitle?: boolean;

    onSaveDataChanged: EventEmitter<RiskTableSaveData>;
    onRowSelected: EventEmitter<Row[]>;
    onLinkedChanged: EventEmitter<boolean>;
    onRebind: EventEmitter;
};

export interface RiskTableSaveData extends TableUserData {
    filter: string | undefined;
}

const RiskTable: React.FC<Props> = ({
    onRebind, onRowSelected, client, tableKey,
    columnPreference, columnPreferences, filter: inboundFilter,
    title, saveData, onSaveDataChanged,
    onLinkedChanged: onLinkedChange,
    columnRankByIndex, defaultColumnPreference,
    hideTitle, header, contextMenuItemsCreator, hideExpand,
    linked
}) => {
    const fieldsRef = useRef<FieldDescriptor[]>([]);
    const tableClientRef = useRef<TableClient>();
    const [filterError, setFilterError] = useState<string>();
    const tableRef = useRef<HTMLDivElement>(null);
    const [rowCount, setRowCount] = useState<number>();

    const onStateChanged = useCallback((state: TableUserData) => {
        onSaveDataChanged({...saveData, ...state});
    }, [onSaveDataChanged, saveData]);

    const onFields = useCallback((fields: FieldDescriptor[]) => {
        fieldsRef.current = fields;
    }, []);

    const internalFilter = saveData && saveData.filter;

    const onFilterChanged = useCallback((text: string) => {
        // log.debug('onFilterChanged', text);
        onSaveDataChanged({...saveData, filter: text || undefined});
    }, [onSaveDataChanged, saveData]);

    const onExpand = useCallback(() => {
        const table = tableRef.current;
        const {width, height, top, left} = table!.getBoundingClientRect();
        const {screenTop, screenLeft} = window;

        openRiskPopout(
            tableKey,
            columnPreferences,
            saveData,
            inboundFilter || '',
            title,
            {width, height, left: left + screenLeft, top: top + screenTop}
        );
    }, [columnPreferences, inboundFilter, saveData, tableKey, title]);

    const onLinkedChanged = useCallback(({target: {checked}}: React.ChangeEvent<HTMLInputElement>) => {
        onLinkedChange(checked);
    }, [onLinkedChange]);

    const columnPreferenceGetter = useCallback((field: FieldDescriptor): ColumnPreference | undefined => {
        let columnPref: ColumnPreference | undefined;
        if (columnPreference) {
            columnPref = columnPreference(field);
        }
        return {
            ...columnPref || {},
            renderer: columnPref && columnPref.renderer ? columnPref.renderer : renderer(field),
            name: field.name
        };
    }, [columnPreference]);

    const setTableClient = useCallback((tc: TableClient) => tableClientRef.current = tc, []);

    if (!client) return null;

    const fields = fieldsRef.current.map(descriptorToField);

    return <div className="risk-table" ref={tableRef}>
        <header>
            {header || (hideTitle ? null : <h1>{title}</h1>)}
            <div id="buttons" className="button-bar">
                <div className="filter">
                    <FilterEditor
                        equalsMode
                        value={saveData && saveData.filter || ''}
                        onValueChange={onFilterChanged}
                        fields={fields}
                        dataTypeRequired={FieldTypeCategory.Boolean}
                    />
                </div>
                <div className="row-count"><strong>Rows:</strong>&nbsp;{numeral(rowCount).format('0,0')}</div>
                {linked !== undefined && <FormControlLabel control={<Checkbox />} label="Linked" checked={linked} onChange={onLinkedChanged} />}
                {tableClientRef.current && <LightBulb tableClient={tableClientRef.current} />}
                {!hideExpand && <div className="expand" onClick={onExpand}><i className="fa fa-expand-arrows-alt"></i></div>}
            </div>
        </header>
        <TableView
            columnPreferences={columnPreferences}
            columnPreference={columnPreferenceGetter}
            columnRankByIndex={columnRankByIndex}
            defaultColumnPreference={defaultColumnPreference}

            contextMenuItemsCreator={contextMenuItemsCreator}
            tableKey={tableKey}
            smartFilter={internalFilter} // the textbox
            filter={inboundFilter} // incoming filter from outside

            onRowSelected={onRowSelected}
            onRebind={onRebind}
            onFields={onFields}
            onError={setFilterError}
    
            onRowCountChanged={setRowCount}
            selectable="multiple"
            tableState={saveData}
            onStateChanged={onStateChanged}
            onTableClientChanged={setTableClient}
        />
    </div>;
};

export default connect(RiskTable, (store: Store<AppState>) => {
    const props = store.watch(state => ({
        client: state.client
    }));

    return {props};
});

function renderer(field: FieldDescriptor): ((value: any) => string) | undefined {
    const smallBecomesZero = (value: number) => value < 1e-6 && value > -1e-6 ? 0 : value;
    const priceRenderer = (value: number) => {
        if (value === undefined) return '';
        const res = numeral(smallBecomesZero(value)).format('$0,0.00[00]');
        if (res === 'NaN') log.warn('got NaN', value, smallBecomesZero(value), res, field);
        return res;
    };
    // if (field.name.toLowerCase().endsWith('px') || field.name.toLowerCase().endsWith('price')) {
    //     return priceRenderer;
    // }
    switch (field.type) {
        case FieldType.Price:
            return priceRenderer;
        case FieldType.Capital:
        case FieldType.Sequence:
        case FieldType.Volume:
        case FieldType.Float:
        case FieldType.Integral:
        case FieldType.Count:
        case FieldType.Context:
        case FieldType.Quantity:
        case FieldType.Bitmask:
        case FieldType.Char:
        case FieldType.Index:
            return value => {
                const res = typeof value === 'number' ? numeral(smallBecomesZero(value)).format('0,0') : value;
                if (res === 'NaN') log.warn('got NaN', value, smallBecomesZero(value), res, field);
                return res;
            };
    }
    return undefined;
}