export type Position = {x: number, y: number};

/**
 * Identifies the x/y pixel coordinates for the current selection.
 */
export function determinePosition(): Position | undefined {
    const sel = window.getSelection();
    if (!sel) return;
    if (!sel.rangeCount) return;
    const range = sel.getRangeAt(0);
    if (!range) return;
    const rect = (range.endOffset || range.endContainer instanceof Text) ? range.getBoundingClientRect() : (range.endContainer as Element).getBoundingClientRect();
    // // range.deleteContents();
    // const tempDiv = document.createElement('div')!;
    // const frag = document.createDocumentFragment();
    // frag.appendChild(tempDiv);
    // tempDiv.style.display = 'inline-block';
    // tempDiv.className = 'sample-text';
    // tempDiv.innerText = 'M';
    // tempDiv.style.visibility = 'hidden';
    // // tempDiv.style.backgroundColor = '#ff0000';
    // range.insertNode(frag);
    // const rect = tempDiv.getBoundingClientRect();
    const left = rect.left + window.scrollX;
    const bottom = rect.bottom + window.scrollY;// rect.top + tempDiv.offsetHeight;
    // tempDiv.parentElement!.removeChild(tempDiv);
    return {x: left, y: bottom};
}

export default determinePosition;