import React from 'react';
import {BrowserRouter, Redirect, Route, RouteProps, Switch} from 'react-router-dom';

import IfThenPage from '../if-then/if-then';
import RiskStrategyPage from '../if-then/risk-strategy';
import {PreviewPage} from '../demo/preview/preview';
import {WizardPage} from '../demo/wizard/wizard';
import RiskPage from '../risk-view/risk';
import RiskPopout from '../risk-view/risk-popout';
import DashboardPage from '../dashboard/dashboard';

import {PrivateRoute} from '../private-route';

export const StrategyBuilder: React.FC = ({children}) => {
    return <>
        <Switch>
            <PrivateRoute path="/dashboard" exact component={DashboardPage} />

            <PrivateRoute path="/risk" exact component={RiskPage} />
            <PrivateRoute path="/risk/popout" exact component={RiskPopout} />
            <PrivateRoute path="/risk/:key" exact component={RiskPage} />

            <PrivateRoute path="/if-then" exact component={IfThenPage} />
            <PrivateRoute path="/if-then/risk" exact component={RiskStrategyPage} />
            <PrivateRoute path="/if-then/risk/platforms" exact render={() => <IfThenPage tableKey={{sym: 'AllPlatforms', ex: 'T'}} />} />
            <PrivateRoute path="/if-then/risk/accounts" exact render={() => <IfThenPage tableKey={{sym: 'AllAccounts', ex: 'T'}} />} />
            <PrivateRoute path="/if-then/risk/symbols" exact render={() => <IfThenPage tableKey={{sym: 'AllSymbols', ex: 'T'}} />} />
            <PrivateRoute path="/if-then/risk/positions" exact render={() => <IfThenPage tableKey={{sym: 'AllPositions', ex: 'T'}} />} />
            <PrivateRoute path="/if-then/:key" exact component={IfThenPage} />

            <PrivateRoute path="/wizard" exact component={WizardPage} />
            <PrivateRoute path="/preview" exact component={PreviewPage} />
            <PrivateRoute path="/preview/:universeId/:strategyId" exact component={PreviewPage} />
        </Switch>
    </>;
};