import React, { useCallback, useEffect, useState } from 'react';
import { Store } from 'reactive-state';
import { connect } from 'reactive-state/react';
import { Modal, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';

import {RawClient} from '@thinkalpha/table-client';

import { AppState } from '../state';

const TableErrorModal: React.FC<{client: RawClient}> = ({client}) => {
    const [errors, setErrors] = useState<string[]>([]);

    useEffect(() => {
        if (!client) return;

        const sub = client.objectDefinitionResult$.subscribe(res => {
            if (!res.success) {
                setErrors(errors => [...errors, res.comment]);
            }
        });

        return () => sub.unsubscribe();
    }, [client]);

    const ok = useCallback(() => {
        setErrors(errors => errors.slice(1));
    }, [setErrors]);

    return <Dialog open={errors.length !== 0} onClose={ok}>
        <DialogTitle>Table Communication Error</DialogTitle>
        <DialogContent>
            <code>{errors[0]}</code>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" color="primary" onClick={ok}>OK</Button>
        </DialogActions>
    </Dialog>;
};

const connected = connect(TableErrorModal, (store: Store<AppState>) => {
    const props = store.watch(state => ({
        savedStrategies: [],
        client: state.client
    }));

    return {
        props
    };
});

export {connected as TableErrorModal};
export default connected;