import loglevel from 'loglevel';
const log = loglevel.getLogger('workspace-service');

import localforage from 'localforage';
import {combineLatest, from, Observable, of} from 'rxjs';
import {filter, flatMap, map, switchMap, withLatestFrom} from 'rxjs/operators';

import {Workspace} from '../dashboard/model';

import { randomString } from '../util/randomString';

const itemMoniker = 'workspaces';
function getHackSavedWorkspaces(): Observable<Workspace[]> {
    return from(localforage.getItem<Workspace[]>(itemMoniker)).pipe(
        map(loadedWks => loadedWks || [])
    );
}

export function getSavedWorkspaces(): Observable<Workspace[]> {
    return getHackSavedWorkspaces().pipe(
        map(xs => xs.map<Workspace>(x => ({
            ...x
        })))
    );
}

export function saveWorkspace(workspace: Workspace)/*: Observable<Workspace>*/ {
    const newWorkspace = {id: workspace.id || randomString(), ...workspace};

    return getHackSavedWorkspaces().pipe(
        switchMap(xs => {
            const replaced = [...xs.filter(x => x.id !== workspace.id), newWorkspace];
            return from(localforage.setItem(itemMoniker, replaced));
        }),
        // switchMap(() => of(newWorkspace))
    );
}

export function deleteWorkspace(id: string)/*: Observable<void>*/ {
    return getHackSavedWorkspaces().pipe(
        switchMap(xs => {
            const filtered = xs.filter(x => x.id !== id);
            return from(localforage.setItem(itemMoniker, filtered));
        }),
        // switchMap(() => of())
    );
}