import { DefinitionBundle, Table } from '@thinkalpha/table-client';
import React from 'react';

export interface Universe {
    id?: string;
    name?: string;
    tableName?: string;
    aspects: KnownUniverseAspect[];
    inclusionList: readonly string[];
    exclusionList: readonly string[];
}

export enum UniverseAspectType {
    premade = 'premade',
    volume = 'volume',
    price = 'price',
    marketCap = 'marketCap',
    etf = 'etfs',
    currentRatio = 'currentRatio',
    peRatio = 'peRatio',
    sector = 'sector',
    adr = 'adrs',
}

export const universeAspectTypeNames: ReadonlyMap<UniverseAspectType, string> = new Map<UniverseAspectType, string>([
    [UniverseAspectType.premade, 'Premade'],
    // [UniverseAspectType.list, 'List of Symbols'],
    [UniverseAspectType.volume, 'Average Daily Volume'],
    [UniverseAspectType.price, 'Price'],
    [UniverseAspectType.marketCap, 'Market Cap'],
    [UniverseAspectType.etf, 'ETFs'],
    [UniverseAspectType.currentRatio, 'Current Ratio'],
    [UniverseAspectType.peRatio, 'PE Ratio'],
    [UniverseAspectType.sector, 'Sector'],
    [UniverseAspectType.adr, 'ADRs'],
]);

export interface UniverseAspect {
    type: UniverseAspectType;
}

export enum MarketCapSize {
    large = 'Large',
    mid = 'Mid',
    small = 'Small',
    micro = 'Micro',
    nano = 'Nano'
}

export type SimpleRange = {start: number, end: number};
export type Range = number | SimpleRange;

export interface RangeGroup {
    ranges: Range[];
    above: boolean;
    below: boolean;
}

export interface RangedUniverseAspect extends UniverseAspect, RangeGroup {
    type: UniverseAspectType.price | UniverseAspectType.volume | UniverseAspectType.currentRatio | UniverseAspectType.peRatio | UniverseAspectType.marketCap;
}

export interface PremadeUniverseAspect extends UniverseAspect {
    type: UniverseAspectType.premade;
    table: string;
}

export type KnownUniverseAspect =
    | PremadeUniverseAspect
    | RangedUniverseAspect
;

export interface UniverseCreatorResult {
    definitions: DefinitionBundle;
    name: string;
}

export type UniverseFilter = string;

export type UniverseAspectApplierResult = { newDefinitions: DefinitionBundle, filter: UniverseFilter };
export type UniverseAspectApplier<T extends UniverseAspect = KnownUniverseAspect> = (aspect: T, primaryTable: Table) => UniverseAspectApplierResult;
export type UniverseAspectCreatorComponent<T extends UniverseAspect> = React.FunctionComponent<{aspect?: T | undefined, onChange?: (aspect?: T) => void}>;