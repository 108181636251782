import log from 'loglevel';

import { map, scan, switchMap, tap } from 'rxjs/operators';
import { appConfig } from '../config/appConfig';
import { AppState, store } from '../state';

import jwt_decode from 'jwt-decode';

import * as Sentry from '@sentry/browser';
import { empty } from 'rxjs';

Sentry.init({
    dsn: 'https://063fce66780d4aaaa54bf032e0e53682@sentry.io/1310262',
    environment: appConfig.environmentName,
    integrations: integrations => {
        // console.log('integrations', integrations);
        const breadcrumbIntegration = new Sentry.Integrations.Breadcrumbs({
            console: appConfig.environmentName !== 'local'
        });
        return [...integrations.filter(x => !(x instanceof Sentry.Integrations.Breadcrumbs)), breadcrumbIntegration];
    },
    release: appConfig.appVersion
});

let cachedState: AppState;
store.watch().subscribe(newState => cachedState = newState);

Sentry.configureScope(scope => {
    scope.addEventProcessor(async (event, hint) => {
        // event = normalizeData(event);

        event.extra = {
            ...(event.extra || {}),
            state: {
                ...cachedState,
                nav: null,
            }
        };

        return event;
    });
});

store.watch(x => x.accessToken).subscribe(jwt => {
    // console.info('jwt', user);
    const user: any = jwt && jwt_decode(jwt);
    Sentry.configureScope(scope => {
        scope.setUser(user && {
            id: user.tokenId,
            username: user.login
        });
    });
});

store.watch(x => x.client).pipe(switchMap(client => {
    if (!client) return empty();

    return client.objectDefinitionResult$.pipe(tap(res => {
        if (!res.success) {
            Sentry.captureEvent({level: Sentry.Severity.Error, message: res.comment});
        }
    }));
})).subscribe();

// window.addEventListener('unhandledrejection', (event: PromiseRejectionEvent) => {
//     Sentry.captureException(event.reason);
// });

export function trackError(error: Error, errorInfo: any) {
    return Sentry.captureException(error/*, {extra: errorInfo}*/);
}

export function trackMessage(message: string, severity: Sentry.Severity, data?: {}) {
    return Sentry.captureEvent({
        message,
        extra: data,
        level: severity
    });    
}

// tslint:disable-next-line:no-string-literal
window['trackError'] = trackError;