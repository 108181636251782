import {escapeRegExp} from 'lodash';

import { FieldDescriptor, FieldTypeCategory, TypesInTypeCategory } from '@thinkalpha/table-client';

type SerializedNumberFilter = any;
type SerializedTextFilter = any;

import { CellClassParams } from '@ag-grid-enterprise/all-modules';

import {ColumnPreference} from './model';

export function mapFilterModelToFilterText(field: string, filterModel: SerializedNumberFilter | SerializedTextFilter) {
    if (filterModel.filterType === 'number') {
        const numberFilter = filterModel as SerializedNumberFilter;
        switch (numberFilter.type) {
            case 'greaterThan':
                return `${field} > ${numberFilter.filter}`;
            case 'greaterThanOrEqual':
                return `${field} >= ${numberFilter.filter}`;
            case 'lessThan':
                return `${field} < ${numberFilter.filter}`;
            case 'lessThanOrEqual':
                return `${field} <= ${numberFilter.filter}`;
            case 'equals':
                return `${field} == ${numberFilter.filter}`;
            case 'notEqual':
                return `${field} != ${numberFilter.filter}`;
            case 'inRange':
                return `${field} >= ${numberFilter.filter} && ${field} <= ${numberFilter.filterTo}`;
        }
    } else if (filterModel.filterType === 'text') {
        const textFilter = filterModel as SerializedTextFilter;
        switch (textFilter.type) {
            case 'contains':
                return `str_match(${field}, '%.*${escapeRegExp(textFilter.filter)}.*')`;
            case 'notContains':
                return `!str_match(${field}, '%.*${escapeRegExp(textFilter.filter)}.*')`;
            case 'equals':
                return `str_cmp(${field}, '$${textFilter.filter}') == 0`;
            case 'notEqual':
                return `str_cmp(${field}, '$${textFilter.filter}') != 0`;
            case 'startsWith':
                return `str_match(${field}, '%${escapeRegExp(textFilter.filter)}.*')`;
            case 'endsWith':
                return `str_match(${field}, '%.*${escapeRegExp(textFilter.filter)}')`;
        }
    }
}

export function isNumericField(field: FieldDescriptor) {
    if (field.parsedValues && field.parsedValues.length) {
        return false;
    }
    return TypesInTypeCategory.get(FieldTypeCategory.Double)!.indexOf(field.type) !== -1;
}

export function getFieldStyle(field: FieldDescriptor, params: CellClassParams, pref?: ColumnPreference): {} {
    const style: any = {...(pref && pref.style || {})};
    if (isNumericField(field)) {
        style['text-align'] = 'right';
    }
    if (field.name.indexOf('PnL') !== -1 ||
        field.name.endsWith('Change') ||
        field.name.endsWith('%Chg') ||
        field.name.endsWith('Change_Open') ||
        field.name.endsWith('%Chg_Open')
    ) {
        if (params.value > 0) {
            style.color = 'limegreen';
        } else if (params.value < 0) {
            style.color = 'red';
        }
    }
    return style;
}

export function getFieldClass(field: FieldDescriptor, params: CellClassParams, pref?: ColumnPreference): string | string[] {
    let classes: string[];
    let prefClass = pref && pref.className;
    if (prefClass) {
        if (typeof prefClass === 'function') {
            prefClass = prefClass(params.value);
        }

        if (typeof prefClass === 'string') {
            classes = [prefClass];
        } else if (Array.isArray(prefClass)) {
            classes = prefClass;
        } else {
            throw new Error('Not sure how we ended up here.');
        }
    } else {
        classes = [];
    }

    return classes;
}