import React, { useState, useCallback, useEffect, useMemo } from 'react';

import { boundMethod } from 'autobind-decorator';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'reactive-state/react';

import {NewRiskScreen as NewRiskScreenContract, RiskScreen as RiskScreenContract, TableState as TableStateContract, RiskScreenStates } from '../contracts';

import Split from 'react-split';

import loglevel from 'loglevel';
const logger = loglevel.getLogger('risk-view');

import classnames from 'classnames';
import numeral from 'numeral';

import { fromEvent, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { FieldDescriptor, Key } from '@thinkalpha/table-client';
import {upsertRiskScreen, getRiskScreenByKey} from '../services/risk-screens';
import queryString from 'query-string';
import {Map} from 'immutable';

import jwt_decode from 'jwt-decode';

import {
    ColumnPreference,
    ContextMenuItemsCreator,
    MenuItem,
    TableUserData
} from '../components/table-view/model';
import RiskPopout, {open as openPopout} from './risk-popout';
import RiskTable, { RiskTableSaveData } from './risk-table';

import {columnPreferences} from './preferences';

import { Dictionary } from '../util/dictionary';
import './risk.scss';
import { Tabs, Tab } from '@material-ui/core';
import { Store } from 'reactive-state';
import { AppState } from '../state';
import { useToggleState } from '../hooks/useToggleState';
import { isNumber } from 'lodash';
import {RiskScreenType, RiskScreenTypeNames} from './model';

type Props = {
    showAdamHack: boolean;
    username: string;
    prefix?: string;
};

const userToKeyPrefix = Map<string, string>([
    ['bkehoe', 'L1'],
    ['jshtul', 'L1'],
    ['msamue', 'L2'],
    ['pfiede', 'L1'],
]);

export function globalPreferences(field: FieldDescriptor): ColumnPreference {
    let pref: ColumnPreference | undefined;
    if (field.name.indexOf('Key') !== -1) {
        pref = { name: field.name, ...pref, hidden: true };
    }
    // if (field.name.endsWith('Exposure')) {
    //     pref = { name: field.name, ...pref, flashOnChange: false };
    // }
    if (field.name.startsWith('Total')) {
        pref = {
            name: field.name,
            ...pref,
            display: field.name.substr('Total'.length)
        };
    }
    pref = {
        name: field.name,
        ...pref,
        flashOnChange: false
    };
    return pref;
}

const columnPreferenceFuncs = Map<RiskScreenType, (field: FieldDescriptor) => ColumnPreference>([
    [RiskScreenType.platforms, field => {
        return {
            ...globalPreferences(field)!,
            flashOnChange: false
        };
    }],
    [RiskScreenType.accounts, field => {
        let pref = globalPreferences(field);
        if (field.name.endsWith('PnL')) {
            pref = { name: field.name, ...pref, flashOnChange: false };
        }
        return pref;
    }],
    [RiskScreenType.symbols, globalPreferences],
    [RiskScreenType.traders, globalPreferences],
    [RiskScreenType.positions, field => {
        let pref = globalPreferences(field);
        if (field.name.endsWith('Volume')) {
            pref = { name: field.name, ...pref, flashOnChange: false };
        }
        return pref;
    }]
]);

const RiskPage: React.FC<Props & RouteComponentProps<{key: string}>> = ({match, history, showAdamHack, username, prefix = 'All'}) => {
    const [tableStates, setTableStates] = useState<Map<RiskScreenType, RiskTableSaveData>>(Map());
    const [filters, setFilters] = useState<Map<RiskScreenType, string>>(Map());
    const [links, setLinks] = useState<Map<RiskScreenType, boolean>>(Map());

    const tableStateSetters = useMemo(() => Map<RiskScreenType, (state: RiskTableSaveData) => void>(Object.keys(RiskScreenType).map(x => RiskScreenType[x] as RiskScreenType).map(type => [type, state => setTableStates(states => states.set(type, state))])), []);
    const filtersSetters = useMemo(() => Map<RiskScreenType, (state: RiskTableSaveData) => void>(Object.keys(RiskScreenType).map(x => RiskScreenType[x] as RiskScreenType).map(type => [type, filter => setFilters(filters => filters.set(type, filter))])), []);
    const linkSetters = useMemo(() => Map<RiskScreenType, (linked: boolean) => void>(Object.keys(RiskScreenType).map(x => RiskScreenType[x] as RiskScreenType).map(type => [type, link => setLinks(links => links.set(type, link))])), []);

    const [sizes, setSizes] = useState([20, 40, 40]);

    const keyPrefix = userToKeyPrefix.get(username.toLowerCase()) || prefix;
    const restrictedUser = userToKeyPrefix.has(username.toLowerCase());
    
    const [currentAccountsTab, setCurrentAccountsTab] = useState(RiskScreenType.accounts);
    const [currentPositionsTab, setCurrentPositionsTab] = useState(RiskScreenType.positions);

    // useEffect(() => { if (!linkedPlatforms) setAccountsFilter(undefined); }, [linkedPlatforms]);
    // useEffect(() => { if (!linkedAccounts) setPositionsFilter(undefined); }, [linkedAccounts]);
    // useEffect(() => { if (!linkedSymbols) setPositionsFilter(undefined); }, [linkedSymbols]);
    // useEffect(() => { if (!linkedTraders) setPositionsFilter(undefined); }, [linkedTraders]);

    const contextMenuItemsCreator = useCallback((table: string): ContextMenuItemsCreator => {
        return row => {
            const filters: string[] = [];
            switch (table) {
                case 'positions':
                case 'symbols':
                    filters.push(`(str_cmp(Symbol, '$${row.Symbol}') == 0)`);
                case 'accounts':
                    filters.push(`(str_cmp(Account, '$${row.Account}') == 0)`);
                case 'platforms':
                    filters.push(`(str_cmp(Platform, '$${row.Platform}') == 0)`);
            }
            const filterText = filters.join(' and ');
            return [
                {
                    name: 'Fills',
                    action: () => {
                        openPopout(
                            { sym: 'AllExecutions', ex: 'T' },
                            [],
                            undefined,
                            filterText,
                            'Fills'
                        );
                    }
                },
                {
                    name: 'Open Orders',
                    action: () => {
                        openPopout(
                            { sym: 'AllOrders', ex: 'T' },
                            [],
                            undefined,
                            `(${filterText}) and (RemainingShares > 0)`,
                            'Open Orders'
                        );
                    }
                }
            ];
        };
    }, []);

    // function clearAccountsFilter() {
    //     setAccountsFilter(undefined);
    // }
    // function clearPositionsFilter() {
    //     setPositionsFilter(undefined);
    // }

    const switchAccountsTab = useCallback((_, tab: RiskScreenType) => {
        setCurrentAccountsTab(tab);
    }, []);
    const switchPositionsTab = useCallback((_, tab: RiskScreenType) => {
        setCurrentPositionsTab(tab);
    }, []);

    const save = useCallback(async () => {
        const key = prompt(
            'What URL key should be used?',
            match.params.key
        );
        if (!key) return;

        const mapState = (state: RiskTableSaveData): TableStateContract => (state && {
            sort: state.sortModel,
            filter: state.filter,
            columnOrder: state.columnOrder,
            columnVisibility: state.columnVisibility,
            columnWidths: state.columnWidths,
            firstRow: state.firstRow,
            pinnedColumns: state.pinnedColumns
        });

        const payload: NewRiskScreenContract = {
            key,
            shared: false,
            sizes,
            states: tableStates.map(mapState).toObject() as RiskScreenStates
        };
        await upsertRiskScreen(payload);

        logger.debug('saved state to', key, payload);

        if (key !== match.params.key) {
            history.push(`/risk/${key}`);
        }
    }, [history, match.params.key, sizes, tableStates]);

    useEffect(() => {
        const keydown$ = fromEvent<KeyboardEvent>(window, 'keydown');
        const sub = keydown$.pipe(filter(x => x.ctrlKey && x.key === 's'), tap(x => x.preventDefault())).subscribe(() => save());
        return () => sub.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [save]);

    useEffect(() => {
        const key = match.params.key;
        if (key) {
            (async () => {
                const payload = await getRiskScreenByKey(key);
                if (payload) {
                    const mapState = (state: TableStateContract | undefined): RiskTableSaveData | undefined => (state && {
                        filter: state.filter,
                        filterModel: undefined,
                        firstRow: undefined,
                        columnOrder: state.columnOrder,
                        columnVisibility: state.columnVisibility,
                        columnWidths: state.columnWidths,
                        pinnedColumns: state.pinnedColumns,
                        sortModel: state.sort
                    });

                    setTableStates(Map(Object.keys(payload.states).map((type: RiskScreenType) => [type, mapState(payload.states[type])])));
                    
                    setSizes(payload.sizes);

                    logger.debug('loaded state from', key, payload);
                }
            })();
        }
    }, [match.params.key]);

    const selectHandlers = useMemo(() => Map<RiskScreenType, (rows: Dictionary[]) => void>([
        [RiskScreenType.platforms, rows => {
            if (!(links.get(RiskScreenType.platforms) ?? true)) return;
            setFilters(filters => filters.set(RiskScreenType.accounts, rows.map(row => `(PositionKey like '%.*:${row.PositionKey}')`).join(' or ') || undefined));
        }],
        [RiskScreenType.accounts, rows => {
            if (!(links.get(RiskScreenType.accounts) ?? true)) return;
            setFilters(filters => filters.set(RiskScreenType.positions, rows
                .map(row => {
                    const [account, platform] = row.PositionKey.split(':');
                    return `(PositionKey like '%${account}-.*:${platform}')`;
                })
                .join(' or ') || undefined
            ));
        }],
        [RiskScreenType.symbols, rows => {
            if (!(links.get(RiskScreenType.symbols) ?? true)) return;
            setFilters(filters => filters.set(RiskScreenType.positions, rows
                .map(row => `(str_cmp(Symbol, '$${row.Symbol}') == 0)`)
                .join(' or ') || undefined));
        }],
        [RiskScreenType.traders, rows => {
            if (!(links.get(RiskScreenType.traders) ?? true)) return;
            setFilters(filters => filters.set(RiskScreenType.positions, rows
                .map(row => `(str_cmp(OwnerID, '$${row.TraderID}') == 0)`)
                .join(' or ') || undefined));
        }]
    ]), [links]);

    const accountsNav = <Tabs value={currentAccountsTab} onChange={switchAccountsTab}>
        <Tab value={RiskScreenType.accounts} label={RiskScreenTypeNames.get(RiskScreenType.accounts)}/>
        {showAdamHack && !restrictedUser && <Tab value={RiskScreenType.joinedAccounts} label={RiskScreenTypeNames.get(RiskScreenType.joinedAccounts)}/>}
        <Tab value={RiskScreenType.symbols} label={RiskScreenTypeNames.get(RiskScreenType.symbols)}/>
        <Tab value={RiskScreenType.traders} label={RiskScreenTypeNames.get(RiskScreenType.traders)}/>
    </Tabs>;

    const positionsNav = <Tabs value={currentPositionsTab} onChange={switchPositionsTab}>
        <Tab value={RiskScreenType.positions} label={RiskScreenTypeNames.get(RiskScreenType.positions)}/>
        {showAdamHack && !restrictedUser && <Tab value={RiskScreenType.joinedPositions} label={RiskScreenTypeNames.get(RiskScreenType.joinedPositions)}/>}
    </Tabs>;

    const tableKeys = useMemo(() => Map<RiskScreenType, Key>([
        [RiskScreenType.platforms, {sym: `${keyPrefix}Platforms`, ex: 'T'}],
        [RiskScreenType.accounts, {sym: `${keyPrefix}Accounts`, ex: 'T'}],
        [RiskScreenType.positions, {sym: `${keyPrefix}Positions`, ex: 'T'}],
        [RiskScreenType.symbols, {sym: 'AllSymbols', ex: 'T'}],
        [RiskScreenType.traders, {sym: 'AllTraders', ex: 'T'}],
        [RiskScreenType.joinedAccounts, {sym: 'AdamAccounts', ex: 'T'}],
        [RiskScreenType.joinedPositions, {sym: 'AdamPositions', ex: 'T'}],
    ]), [keyPrefix]);

    const smallBecomesZero = (value: number) => value < 1e-6 && value > -1e-6 ? 0 : value;

    const renderTable = useCallback(function(screen: RiskScreenType, header?: React.ReactElement) {
        return <div id={screen}>
            <RiskTable
                header={header}
                columnPreferences={columnPreferences.get(screen)}
                defaultColumnPreference={{hidden: true}}
                columnPreference={columnPreferenceFuncs.get(screen)}
                columnRankByIndex={true}
                tableKey={tableKeys.get(screen)}
                onRowSelected={selectHandlers.get(screen)}
                contextMenuItemsCreator={restrictedUser ? undefined : contextMenuItemsCreator(screen)}
                saveData={tableStates.get(screen)}
                onSaveDataChanged={tableStateSetters.get(screen)}
                filter={filters.get(screen)}
                linked={links.get(screen) ?? true}
                onLinkedChanged={linkSetters.get(screen)}
                // onRebind={clearAccountsFilter}
                title={RiskScreenTypeNames.get(screen)!}
            />
        </div>;
    }, [contextMenuItemsCreator, filters, linkSetters, links, restrictedUser, selectHandlers, tableKeys, tableStateSetters, tableStates]);

    const platforms = useMemo(() => renderTable(RiskScreenType.platforms), [renderTable]);

    const accounts = useMemo(() => renderTable(RiskScreenType.accounts, restrictedUser ? undefined : accountsNav), [accountsNav, renderTable, restrictedUser]);
    const joinedAccounts = useMemo(() => renderTable(RiskScreenType.joinedAccounts, restrictedUser ? undefined : accountsNav), [accountsNav, renderTable, restrictedUser]);
    const symbols = useMemo(() => renderTable(RiskScreenType.symbols, restrictedUser ? undefined : accountsNav), [accountsNav, renderTable, restrictedUser]);
    const traders = useMemo(() => renderTable(RiskScreenType.traders, restrictedUser ? undefined : accountsNav), [accountsNav, renderTable, restrictedUser]);

    const positions = useMemo(() => renderTable(RiskScreenType.positions, restrictedUser ? undefined : positionsNav), [positionsNav, renderTable, restrictedUser]);
    const joinedPositions = useMemo(() => renderTable(RiskScreenType.joinedPositions, restrictedUser ? undefined : positionsNav), [positionsNav, renderTable, restrictedUser]);

    return (
        <div id="risk-page">
            <Split direction="vertical" gutterStyle={() => ({})} onDragEnd={setSizes} sizes={sizes}>
                <div className="table-area">{!restrictedUser && platforms}</div>
                <div className="tabs" id="accounts-symbols">
                    <div className="table-area" hidden={currentAccountsTab !== RiskScreenType.accounts}>
                        {accounts}
                    </div>
                    <div className="table-area" hidden={currentAccountsTab !== RiskScreenType.joinedAccounts}>
                        {joinedAccounts}
                    </div>
                    <div className="table-area" hidden={currentAccountsTab !== RiskScreenType.symbols}>
                        {symbols}
                    </div>
                    <div className="table-area" hidden={currentAccountsTab !== RiskScreenType.traders}>
                        {traders}
                    </div>
                </div>
                <div className="tabs" id="positions-tabs">
                    <div className="table-area" hidden={currentPositionsTab !== RiskScreenType.positions}>
                        {positions}
                    </div>
                    <div className="table-area" hidden={currentPositionsTab !== RiskScreenType.joinedPositions}>
                        {joinedPositions}
                    </div>
                </div>
            </Split>
        </div>
    );
};

const hackUsernames = ['adam', 'jchin', 'jeevaka', 'bytenik', 'david', 'dpfeff', 'vgoldf', 'alewen', 'jdassa', 'jfrase'];

export default connect(withRouter(RiskPage), (store: Store<AppState>) => {
    const props = store.watch(state => {
        const user: any = state.accessToken && jwt_decode(state.accessToken);
        let username: string | undefined = user && user.login.toLowerCase();
        const values = queryString.parse(window.location.search);
        if (values.masq) username = values.masq as string;

        return {
            showAdamHack: username ? hackUsernames.includes(username) : undefined,
            username
        };
    });
    return {props};
});