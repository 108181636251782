import loglevel from 'loglevel';
const logger = loglevel.getLogger('risk-view');

import React from 'react';

import Measure from 'react-measure';

import { Store } from 'reactive-state';
import { connect } from 'reactive-state/react';
import { AppState } from '../state';

import {sum, uniqBy} from 'lodash';

import GridLayout, { Layout, Layouts } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';

import numeral from 'numeral';

import {
    compareKey,
    Key,
    RawClient,
    stringifyKey
} from '@thinkalpha/table-client';
import { boundMethod } from 'autobind-decorator';
import { Subscription, timer } from 'rxjs';
import { bufferTime, count, flatMap, map, publish, reduce, refCount } from 'rxjs/operators';

import './browser.scss';
import { TableEntry } from './key-tree';

import BrowserTable from './browser-table';
import TableDefiner from './table-definer';
import { Button } from '@material-ui/core';

type State = {
    snapshotsPerSecond: number;
    rowsPerSecond: number;
    kbps: number;
    keys?: Key[];
    layouts: (Layout & { key: Key })[];
    showDefine: boolean;
};

class BrowserPage extends React.Component<{ client: RawClient }, State> {
    state: State = {
        rowsPerSecond: 0,
        snapshotsPerSecond: 0,
        kbps: 0,
        layouts: [],
        showDefine: false
    };

    @boundMethod
    onLayoutChange(layouts: Layout[]) {
    // console.log('layout change', layouts);
    // this.setState({layouts});
    }

    private subscriptions = new Subscription();

    componentWillUnmount() {
        this.subscriptions.unsubscribe();
    }

    async componentDidMount() {
        const { client } = this.props;
        this.subscriptions.add(
            client.updates$
                .pipe(
                    map(() => 1),
                    bufferTime(5000),
                    map(x => (x.length === 0 ? 0 : sum(x))),
                    publish(),
                    refCount()
                ).subscribe(x => this.setState({ snapshotsPerSecond: x / 5 }))
        );

        this.subscriptions.add(
            client.updates$
                .pipe(
                    flatMap(x => x.updates.pipe(map(y => y.payload.length), reduce((acc, y) => acc + y, 0))),
                    bufferTime(5000),
                    map(x => (x.length === 0 ? 0 : sum(x))),
                    publish(),
                    refCount()
                ).subscribe(x => this.setState({ kbps: x / 5 }))
        );

        this.subscriptions.add(
            client.updates$
                .pipe(
                    flatMap(x => x.updates.pipe(count())),
                    bufferTime(5000),
                    map(x => (x.length === 0 ? 0 : sum(x))),
                    publish(),
                    refCount()
                ).subscribe(x => this.setState({ rowsPerSecond: x / 5 }))
        );

        this.subscriptions.add(
            client.keys$.subscribe(keys => this.setState({ keys: uniqBy(keys, x => stringifyKey(x)) }))
        );
    }

    @boundMethod
    closeTable(key: Key) {
        this.setState(state => ({
            layouts: state.layouts.filter(x => !compareKey(x.key, key))
        }));
    }

    @boundMethod
    openTable(key: Key) {
        const lastTable = this.state.layouts[this.state.layouts.length - 1];
        const newLayout: Layout & { key: Key } = {
            x: lastTable && lastTable.x >= 10 ? 0
                : lastTable ? lastTable.x + lastTable.w : 0,
            y: lastTable && lastTable.x >= 10 ? lastTable.y + 5 : 0,
            key,
            h: 5,
            w: 10,
            i: lastTable ? `${parseInt(lastTable.i!, 10) + 1}` : '0'
        };
        this.setState(state => ({ layouts: [...state.layouts, newLayout] }));
    }

    render() {
        const {
            rowsPerSecond: updatedRowsPerSecond,
            snapshotsPerSecond: updatesPerSecond,
            kbps,
            keys,
            showDefine,
            layouts
        } = this.state;

        return <>
            <div id="browser-page">
                <div className="layout">
                    <div id="sidebar">
                        {keys && keys.map(key => (
                            <TableEntry
                                tableKey={key}
                                key={stringifyKey(key)}
                                onOpen={this.openTable}
                            />
                        ))}
                    </div>
                    <Measure bounds>{({contentRect, measureRef}) =>
                        <div id="grid" ref={measureRef}>
                            {contentRect.bounds && <GridLayout draggableHandle=".name-close-row h3" compactType="vertical" onLayoutChange={this.onLayoutChange} width={contentRect.bounds.width} cols={10} rowHeight={100} layout={layouts}>
                                {layouts.map((item, i) => (
                                    <div key={i}>
                                        <BrowserTable tableKey={item.key} onClose={() => this.closeTable(item.key)} />
                                    </div>
                                ))}
                            </GridLayout>}
                        </div>
                    }</Measure>
                </div>
                <footer>
                    <div>
                        <label>Updates Per Second:</label>
                        {numeral(updatesPerSecond).format('0,0.00')}
                    </div>
                    <div>
                        <label>Updated Rows Per Second:</label>
                        {numeral(updatedRowsPerSecond).format('0,0.00')}
                    </div>
                    <div>
                        <label>Update kiB/s:</label>
                        {numeral(kbps / 1024).format('0,0.00')}
                    </div>
                    <div className="actions">
                        <Button variant="contained" onClick={() => this.setState({showDefine: true})}>Define Object</Button>
                    </div>
                </footer>
            </div>
            <TableDefiner shown={showDefine} onHide={() => this.setState({showDefine: false})} />
        </>;
    }
}

export default connect(
    BrowserPage,
    (store: Store<AppState>) => {
        const props = store.watch(state => ({
            client: state.client
        }));
        return { props };
    }
);
