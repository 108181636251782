import {LicenseManager} from '@ag-grid-enterprise/all-modules';
LicenseManager.setLicenseKey('Alpha_Trading_Systems_Alpha_Single_Application_2_Devs__9_December_2020_[v2]_MTYwNzQ3MjAwMDAwMA==b427f8844460990c3b5fe3e638b00dd7');
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';

import { AgGridReact, AgGridReactProps } from '@ag-grid-community/react';

import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import _ from 'lodash';

export const GridWrapper: React.FC<AgGridReactProps> = (props) => {
    const inboundFuncsRef = useRef<Map<string, Function>>(new Map());
    const outboundFuncsRef = useRef<Map<string, Function>>(new Map());

    for (const prop in props) {
        const propValue = props[prop];
        if (typeof propValue !== 'function') {
            // not a function, so don't include in inbound OR outbound

            inboundFuncsRef.current.delete(prop);
            outboundFuncsRef.current.delete(prop);
            continue;
        }

        inboundFuncsRef.current.set(prop, propValue);

        if (outboundFuncsRef.current.has(prop)) continue; // never change an existing outgoing func -- that's the whole point of this
        const persistentFunc = (...args) => inboundFuncsRef.current.get(prop)!(...args); // since we do a lookup in the inboundFuncsRef, this function never needs to change
        outboundFuncsRef.current.set(prop, persistentFunc); // and add it to the outbound functions
    }

    const newProps = {...props};
    for (const [key, value] of outboundFuncsRef.current.entries()) {
        newProps[key] = value;
    }

    // const prevPropsRef = useRef<AgGridReactProps | undefined>();
    // console.log('are equal', _.isEqual(prevPropsRef.current, newProps), 'prev props', prevPropsRef.current, 'new props', newProps);
    // prevPropsRef.current = newProps;

    return <MemoedAgGrid {...newProps} />;
};

export const MemoedAgGrid = React.memo(AgGridReact);