import { DefinitionBundle } from '@thinkalpha/table-client';
import { Duration } from 'moment';

import { IfThenGroupModel, IfThenRootModel } from '../if-then/model';
import { Universe } from '../universe/model';
import { CustomFormula } from '../components/data-builder/model';
import { PointInTime } from 'src/components/timeframe/model';

export enum StrategyType {
    priceChange = 'pxchng',
    priceRelativeChange = 'pxrelchng',
    exact = 'exact',
    fundamental = 'fundamental',
    technical = 'technical',
    filter = 'filter',
    ifThen = 'ifThen'
}

export interface StrategyTemplate {
    type: StrategyType;
    name?: string;
}

export interface ConcreteStrategy<T extends KnownStrategyTemplate = KnownStrategyTemplate> {
    id?: string;
    tableName?: string;
    universe: Universe | undefined;
    strategy: T;
}

export interface MaterializedStrategy<T extends KnownStrategyTemplate = KnownStrategyTemplate> extends ConcreteStrategy<T> {
    id: string;
}

export interface PriceChangeStrategy extends StrategyTemplate {
    type: StrategyType.priceChange;
    amount: number;
    lookback: PointInTime;
}

export enum PriceRelativeChangeBenchmark {
    sp100 = 'S&P100',
    sp500 = 'S&P500',
    djia = 'DJIA',
    rus3000 = 'RUS3000',
    symbol = 'Symbol',
    etfsector = 'ETF Sector',
    index = 'Index',
    portfolio = 'A Portfolio',
    custom = 'Custom Basket',
}

export interface Benchmark {
}

export interface BasketBenchmark extends Benchmark {
}

export interface NamedBenchmark extends Benchmark {
}

export type BasketRef = string;

export interface PriceRelativeChangeStrategy extends StrategyTemplate {
    type: StrategyType.priceRelativeChange;
    amount: number;
    benchmark: BasketRef;
    lookback: PointInTime;
}

export interface MovingAverageBenchmark {
    duration: Duration;
    intervals: number;
    exponential: boolean;
}

export enum MovingAverageIndicatorType {
    absolute = 'abs',
    percent = 'pct',
    time = 'time'
}

export interface StrategyCreatorResult {
    definitions: DefinitionBundle;
    name: string;
}

export interface FilterStrategy extends StrategyTemplate {
    type: StrategyType.filter;
    filter: string;
}

export type MovingAverageIndicator = { value: number, type: MovingAverageIndicatorType.absolute | MovingAverageIndicatorType.percent } | { value: Duration, type: MovingAverageIndicatorType.time };

export interface IfThenStrategy extends StrategyTemplate {
    type: StrategyType.ifThen;
    root: IfThenRootModel;
}

export type KnownStrategyTemplate =
    | PriceChangeStrategy
    | PriceRelativeChangeStrategy
    | IfThenStrategy
    | FilterStrategy
;