import { MutableRefObject, RefObject, useEffect, useRef } from 'react';

// export function useLazyRef<T>(initialValueFactory: () => T | null): RefObject<T>;
export function useLazyRef<T>(initialValueFactory: () => T): MutableRefObject<T>;

export function useLazyRef<T>(initialValueFactory: () => T): RefObject<T> {
    const ref = useRef<T>();
    const initializedRef = useRef(false);
    if (!initializedRef.current) {
        ref.current = initialValueFactory();
        initializedRef.current = true;
    }
    return ref as RefObject<T>;
}

export default useLazyRef;