import React, { useState, useCallback } from 'react';

import { FieldDescriptor } from '@thinkalpha/table-client';
import { ColumnPreference } from '../components/table-view/model';
import {globalPreferences} from '../risk-view/risk';

import StrategyBuilder from './if-then';
import { Tabs, Tab } from '@material-ui/core';

type Tab = 'Platforms' | 'Accounts' | 'Symbols' | 'Positions';

const RiskStrategy: React.FC = ({}) => {
    const [activeTab, setActiveTab] = useState<Tab>('Platforms');
    
    const preferences = useCallback((field: FieldDescriptor): ColumnPreference | undefined => globalPreferences(field), []);

    const switchToTab = useCallback((_, tabName: Tab) => {
        setActiveTab(tabName);
    }, []);

    return (
        <div id="risk-strategy-page">
            <Tabs onChange={switchToTab} value={activeTab}>
                <Tab value={'Platforms'} label="Platforms"/>
                <Tab value={'Accounts'} label="Accounts"/>
                <Tab value={'Symbols'} label="Symbols"/>
                <Tab value={'Positions'} label="Positions"/>
            </Tabs>
            <div hidden={activeTab !== 'Platforms'}>
                {/* <StrategyBuilder
                    tableKey={{sym: 'AllPlatforms', ex: 'T'}}
                    showPreviewTab={false}
                    columnPreference={preferences}
                    columnPreferences={[
                        {name: 'PositionKey', hidden: true},
                        {name: 'Platform', rank: 0},
                        {name: 'PnL', flashOnChange: false},
                        {name: 'OpenPnL', flashOnChange: false},
                        {name: 'MarketValue', flashOnChange: false}
                    ]}
                /> */}
            </div>
            <div hidden={activeTab !== 'Accounts'}>
                {/* <StrategyBuilder
                    tableKey={{sym: 'AllAccounts', ex: 'T'}}
                    showPreviewTab={false}
                    columnPreference={preferences}
                    columnPreferences={[
                        {name: 'PositionKey', hidden: true},
                        {name: 'AccountPropertyKey', hidden: true},
                        {name: 'OwnerID', hidden: true},
                        {name: 'Platform', rank: 0},
                        {name: 'Account', rank: 1},
                        {name: 'TotalPnL', flashOnChange: false},
                        {name: 'TotalOpenPnL', flashOnChange: false},
                        {name: 'TotalMarketValue', flashOnChange: false}
                    ]}
                /> */}
            </div>
            <div hidden={activeTab !== 'Symbols'}>
                {/* <StrategyBuilder
                    tableKey={{sym: 'AllSymbols', ex: 'T'}}
                    showPreviewTab={false}
                    columnPreference={preferences}
                /> */}
            </div>
            <div hidden={activeTab !== 'Positions'}>
                {/* <StrategyBuilder
                    tableKey={{sym: 'AllPositions', ex: 'T'}}
                    showPreviewTab={false}
                    columnPreference={preferences}
                /> */}
            </div>
        </div>
    );
};

export default RiskStrategy;