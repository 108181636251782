import React, { useCallback } from 'react';
import { Button } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { RouteComponentProps, withRouter } from 'react-router';

const LinkButton: React.FC<ButtonProps & RouteComponentProps & {to: string}> = ({children, onClick, history, to, ...rest}) => {
    const click = useCallback((...args) => {
        history.push(to);
        if (onClick) (onClick as any)(...args);
    }, [history, onClick, to]);

    return <Button {...rest} onClick={click}>{children}</Button>;
};

const routed = withRouter(LinkButton);
export default routed;
export {routed as LinkButton};