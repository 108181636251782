import './line.scss';

import loglevel from 'loglevel';
const log = loglevel.getLogger('strategy-line');

import React, { useCallback, useRef, useState, useEffect, useMemo, useContext } from 'react';

import _ from 'lodash';

import { FieldDescriptor, FieldType, FieldTypeCategory } from '@thinkalpha/table-client';
import FilterEditor from '../components/filter-editor/filter-editor.new';
import {Operand, IfThenLineModel, IfThenLinePlaceholder, IfThenLineDraftModel, DragModel, DRAG_MODEL_TYPE, DragProps} from './model';
import { useInputState, useCheckedInputState } from '../hooks/useInputState';
import { Field } from '../components/filter-editor/model';
import { ParserResult, flattenAst, NumericComparisonOperator, StringComparisonOperator, ComparisonOperator } from '@thinkalpha/language-services';
import { useDeepEffect } from '../hooks/useDeepEffect';
import { useModelCommitter } from '../hooks/useModelCommitter';
import { Select, MenuItem, TextField, Checkbox, FormControlLabel, Menu, Button } from '@material-ui/core';
import classNames from 'classnames';
import { randomString } from '../util/randomString';
import ActionsMenu from './actions-menu';

import { useDrag, useDrop } from 'react-dnd';

type OperatorRecord = {
    name: string;
    operator: ComparisonOperator;
    types?: FieldTypeCategory[];
};

type Props = {
    fields: readonly Field[];
    model: IfThenLineModel | IfThenLinePlaceholder;
    committedModel?: IfThenLineModel;
    onModelChange: (update?: IfThenLineModel | IfThenLinePlaceholder) => void;
    onValidityChange?: (valid: boolean) => void;
    onPaste: () => void;
    isOnly: boolean;
    onCopy: (draft: IfThenLineDraftModel) => void;
    depth: number;
    dragProps: DragProps;
};

const Operators: readonly OperatorRecord[] = [
    {name: 'Equals', operator: NumericComparisonOperator['==']},
    {name: 'Does Not Equal', operator: NumericComparisonOperator['!=']},
    {name: 'Is Greater Than', operator: NumericComparisonOperator['>']},
    {name: 'Is Greater Than Or Equal', operator: NumericComparisonOperator['>=']},
    {name: 'Is Less Than', operator: NumericComparisonOperator['<']},
    {name: 'Is Less Than Or Equal', operator: NumericComparisonOperator['<=']},
    {name: 'Matches Pattern', operator: StringComparisonOperator.like, types: [FieldTypeCategory.String]}
];
Object.freeze(Operators);
for (const x of Operators) Object.freeze(x);

export const IfThenLine: React.FC<Props> = ({model, fields, committedModel, onModelChange, onValidityChange, onPaste, onCopy, dragProps, isOnly, depth}) => {
    const [id, setId] = useState(model ? model.id : randomString());
    const [operand1, setOperand1] = useState<Operand | undefined>(model && 'operand1' in model ? model.operand1 : undefined);
    const [operand1ParserResult, setOperand1ParserResult] = useState<ParserResult>();
    const [operand2, setOperand2] = useState<Operand | undefined>(model && 'operand2' in model ? model.operand2 : undefined);
    const [operand2ParserResult, setOperand2ParserResult] = useState<ParserResult>();
    const [operator, setOperator, onOperatorChanged] = useInputState<ComparisonOperator>(model && 'operator' in model ? model.operator : undefined);
    const [enabled, setEnabled, onEnabledChanged] = useCheckedInputState(model ? model.enabled : true);
    const dragHelperRef = useRef<HTMLDivElement>(null);

    const handleCopyAction = () => {
        onCopy({
            ...model,
            operand1: operand1,
            operator: operator,
            operand2: operand2
        });
    };

    const handlePasteAction = () => {
        onPaste();
    };



    const committed = committedModel !== undefined
        && committedModel.operand1 === operand1
        && committedModel.operator === operator
        && committedModel.operand2 === operand2
        && committedModel.enabled === enabled;

    const operators = Operators.filter(x =>
        !x.types
        || (operand1ParserResult && operand1ParserResult.root && x.types.includes(operand1ParserResult.root.dataType!)));

    function isOperatorValid(operator: ComparisonOperator | undefined) {
        return !!operator;
    }

    function operandValid(operand: Operand | undefined, parserResult: ParserResult | undefined) {
        if (!operand) return false;
        if (!parserResult) return false;
        if (!parserResult.valid) return false;
        if (!parserResult.root) return false;
        const flat = flattenAst(parserResult.root);
        if (!flat.every(x => x.valid)) return false;

        return true;
    }

    const operand1Valid = useMemo(() => {
        const res = operandValid(operand1, operand1ParserResult);
        return res;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [operand1ParserResult]);

    const operand2Valid = useMemo(() => {
        const res = operandValid(operand2, operand2ParserResult);
        return res;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [operand2ParserResult]);

    const operatorValid = useMemo(() => {
        const res = isOperatorValid(operator);
        return res;
    }, [operator]);

    useEffect(() => {
        onValidityChange?.(operand1Valid
            && operand2Valid
            && operator
            && operand1ParserResult!.root!.dataType === operand2ParserResult!.root!.dataType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [operand1Valid, operand2Valid, operator]);

    useModelCommitter(
        model,
        onModelChange,
        { operand1, operand2, operand1Valid, operand2Valid, operator, enabled, id },
        ({operand1, operand2, operand1Valid, operand2Valid, operator, enabled, id}) => { // committer
            // console.log('committer for line due to', { operand1, operand2, operand1ParserResult, operand2ParserResult, operator });
            if (!operand1 && !operand2 && !operator) {
                // console.log('committing line as undefined!!!!');
                return {type: 'line' as 'line', id, enabled};
            }

            if (!operator) return false;
            if (!operand1Valid) return false;
            if (!operand2Valid) return false;
            if (operand1ParserResult!.root!.dataType !== operand1ParserResult!.root!.dataType) return false;

            return {type: 'line' as 'line', id, operand1, operand2, operator, enabled};
        },
        model => {
            // console.log('materializer for line due to model', model);
            if (!model) {
                setId(randomString());
                setOperand1(undefined);
                setOperand2(undefined);
                setOperator('');
            } else {
                setId(model.id);
                if ('operator' in model) {
                    setOperand1(model.operand1);
                    setOperand2(model.operand2);
                    setOperator(model.operator);
                }
            }
        }
    );

    const pending = !operand1ParserResult || !operand2ParserResult;
    // console.log(pending, 'analyzing', operand1ParserResult, operand2ParserResult);

    const [ , drag, preview] = useDrag({
        item: { type: DRAG_MODEL_TYPE, model, height: 0, depth, ...dragProps },
        begin: () => {
            const height = dragHelperRef.current!.getBoundingClientRect().height;
            setTimeout(() => dragProps.onDragStart({lineId: model.id, position: 'top', height}), 0);
            return { 
                type: DRAG_MODEL_TYPE, 
                model,
                height: height,
                depth,
                ...dragProps
            };
        },
        end: (item: DragModel) => {
            item.clearDragPlaceholder();
            item.onDragDone();
        },
    });

    preview(dragHelperRef);

    return (
        <div ref={dragHelperRef} className={classNames({
            'ifthen-line': true,
            committed,
            uncommitted: !committed,
            pending,
            valid: !pending && operator && operand1Valid && operand2Valid,
            invalid: !pending && (!operator || !operand1Valid || !operand2Valid),
        })}>
            <div className={classNames({'validity-box': true})}>
                <Checkbox checked={enabled} onChange={onEnabledChanged} />
                <div ref={!isOnly ? drag : null} >
                    <ActionsMenu onCopy={handleCopyAction} onPaste={handlePasteAction}/>
                </div>
            </div>
            <div className="operand">
                <FilterEditor
                    placeholder="Data Element or Formula"
                    noPlaceholderPromotion
                    fields={fields}
                    onValueChange={setOperand1}
                    onParserResult={setOperand1ParserResult}
                    value={operand1 || null}
                />
            </div>
            <div className={classNames({operator: true,valid: operatorValid})}>
                <Select variant="outlined" displayEmpty fullWidth value={operator ?? ''} onChange={onOperatorChanged}>
                    <MenuItem disabled value="">Operator</MenuItem>
                    {operators.map(op => <MenuItem value={op.operator} key={op.operator}>{op.name}</MenuItem>)}
                </Select>
            </div>
            <div className="operand">
                <FilterEditor
                    placeholder="Data Element or Formula"
                    noPlaceholderPromotion
                    fields={fields}
                    onValueChange={setOperand2}
                    onParserResult={setOperand2ParserResult}
                    value={operand2 || null}
                />
            </div>
        </div>
    );
};

export default IfThenLine;