import loglevel from 'loglevel';
const log = loglevel.getLogger('auth');

import localforage from 'localforage';
import { BehaviorSubject, from } from 'rxjs';

import {store} from '../state';

const AccessTokenKey = 'access_token';

export const accessToken$ = new BehaviorSubject<string | undefined>(undefined);
store.addReducer(accessToken$, (state, token) => ({...state, accessToken: token}), 'login');

function getAccessToken() {
    return localforage.getItem<string>(AccessTokenKey) || undefined;
}

function setAccessToken(accessToken?: string) {
    log.debug('Using access token', accessToken);
    return localforage.setItem(AccessTokenKey, accessToken);
}

// init from localforage
export async function init() {
    const token = await getAccessToken();
    accessToken$.next(token);
}

// save updates back to localforage
const updates = store.watch(state => ({token: state.accessToken}));
updates.subscribe(({token}) => setAccessToken(token));