import './selector.scss';
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Menu, TextField, OutlinedInput, StandardProps, MenuItem, FormControlLabel, Select } from '@material-ui/core';
import { useToggleState } from '../hooks/useToggleState';
import SelectInput, { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import { OutlinedInputClassKey } from '@material-ui/core/OutlinedInput';
import { InputBaseProps } from '@material-ui/core/InputBase';
import { getSavedStrategies, StrategyRecord } from '../services/strategies';
import { ComponentWithModel } from '../util/componentWithModel';
import { useInputCallback } from '../hooks/useInputState';

export const StrategySelector: ComponentWithModel<StrategyRecord | string> = ({model, onChange}) => {
    const [options, setOptions] = useState<StrategyRecord[]>();

    useEffect(() => {
        const strategySub = getSavedStrategies().subscribe(setOptions);
        return () => strategySub.unsubscribe();
    }, []);

    const onChanged = useInputCallback(id => {
        if (!onChange) return;

        if (!options) return;
        const option = options.find(opt => opt.id === id);
        if (option) onChange(option);
    }, [onChange, options]);
    // console.log(model, model ? (typeof model === 'object' ? model.id : model) : null);
    return <Select autoWidth variant="outlined" value={model ? (typeof model === 'object' ? model.id : model) : ''} onChange={onChanged}>
        {options && options.map(opt => <MenuItem key={opt.id} value={opt.id}>{opt.name}</MenuItem>)}
    </Select>;
};

// function Icon() {return <i className="fas fa-caret-down"/>;}

// const [menuShown,, showMenu, hideMenu] = useToggleState(false);
// const HackedOutlinedInput: React.ComponentType<Omit<Omit<StandardProps<InputBaseProps, OutlinedInputClassKey>, 'inputComponent'>, 'inputProps'> & {inputComponent: React.ComponentType<SelectInputProps>, inputProps: SelectInputProps & {classes: []}}> = OutlinedInput as any;
// const activatorRef = useRef<HTMLDivElement>(null);

// return <>
//     {/* <SelectInput classes={[]} {...{variant: 'outlined', autoWidth: false, native: false, multiple: false, open: false, onOpen: showMenu, IconComponent: function Icon() {return <i className="fas fa-caret-down"/>;}}}/> */}
//     <HackedOutlinedInput
//         ref={activatorRef}
//         inputComponent={SelectInput}
//         inputProps={{
//             variant: 'outlined',
//             autoWidth: false,
//             native: false,
//             multiple: false,
//             open: false,
//             onOpen: showMenu,
//             IconComponent: Icon,
//             classes: [],
//         }}
//         fullWidth
//     />
//     <Menu open={menuShown} onClose={hideMenu} anchorEl={activatorRef.current}>
//         <MenuItem>Test Item</MenuItem>
//     </Menu>
// </>;