import {ColumnPreference} from './model';
import { compareKey, FieldDescriptor, FieldType, FieldTypeCategory, FieldTypeNames, Key, ObjectDefinition, RawClient, Row, SimpleTableClient, SortModel, SortOrder, TableClient, TypesInTypeCategory } from '@thinkalpha/table-client';

import { ColDef, ColumnApi, ColumnResizedEvent, FilterChangedEvent, GetContextMenuItems, GetContextMenuItemsParams, GridApi, GridReadyEvent, ICellRendererParams, IViewportDatasource, IViewportDatasourceParams, SelectionChangedEvent, SortChangedEvent, ViewportChangedEvent, ColumnVisibleEvent, ColumnMovedEvent, ColumnPinnedEvent } from '@ag-grid-enterprise/all-modules';

import { isEqual, isNumber, max } from 'lodash';

import moment from 'moment';
import numeral from 'numeral';

export type Renderer = (value: any) => string;

export function renderer(field: FieldDescriptor, pref?: ColumnPreference): Renderer | undefined {
    if (pref && pref.renderer) {
        return value => pref.renderer!(value);
    }
    switch (field.type) {
        case FieldType.TimeStamp:
            return value => {
                if (!value) return '';
                const ts = moment(value);
                if (ts.clone().startOf('day').isSame(moment().startOf('day'))) {
                    return ts.format('HH:mm:ss');
                } else {
                    return ts.format('MMM D @ HH:mm');
                }
            };
        case FieldType.Price:
            return value => isNumber(value) ? numeral(value).format('$0,0.00[0000000000]') : value;
        case FieldType.Capital:
        case FieldType.Sequence:
        case FieldType.Float:
        case FieldType.Integral:
        case FieldType.Count:
        case FieldType.Context:
        case FieldType.Quantity:
        case FieldType.Bitmask:
        case FieldType.Char:
        case FieldType.Index:
            return value => isNumber(value) && !isNaN(value) ? numeral(value).format('0,0.00') : value;
        case FieldType.Volume:
            return value => isNumber(value) && !isNaN(value) ? numeral(value).format('0,0') : value;
    }
    return undefined;
}