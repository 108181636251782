import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import moment, { Duration, duration, Moment } from 'moment';

import { AbsolutePointInTime } from './model';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DateTimePicker, KeyboardDateTimePicker } from '@material-ui/pickers';

export const AbsolutePointInTimeComponent: React.FC<{model?: AbsolutePointInTime, label?: string, onChange?: (model?: AbsolutePointInTime) => void}> = ({model, label, onChange}) => {
    const onChanged = useCallback((date: Moment) => {
        if (!onChange) return;

        if (!date) {
            onChange(undefined);
        } else {
            onChange(moment(date));
        }
    }, [onChange]);

    return <KeyboardDateTimePicker
        variant="inline"
        disableFuture
        label={label}
        value={model}
        onChange={onChanged}
    />;
};