/* eslint-disable @typescript-eslint/indent */
import React from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore (doesn't understand SASS exports)
import {primary, secondary} from './styles/colors.scss';

import { Store } from 'reactive-state';
import globalStore, { AppState } from './state';
import { ActionMap, connect, StoreProvider } from 'reactive-state/react';

import { enableDevTool } from 'reactive-state/src/devtool';

import loglevel from 'loglevel';
const logger = loglevel.getLogger('app');

// tslint:disable-next-line: no-string-literal
window['logger'] = loglevel;

import {hot, setConfig} from 'react-hot-loader';

import {BrowserRouter, Redirect, Route, RouteProps, Switch} from 'react-router-dom';

import { RawClient } from '@thinkalpha/table-client';

import LoginPage from './login/login';
// import PortfolioAlertsPage from './portfolio/portfolio-alerts';
import BrowserPage from './table-browser/browser';

import {ParserDemoPage} from './syntax/parser-demo';
import {DemoTimeframe} from './components/timeframe/demo';
import {DemoUniverses} from './universe/demo';
import {DataBuilderDemo} from './components/data-builder/data-builder';

import { accessToken$ } from './services/auth';

import {TableErrorModal} from './components/table-error';

import { StylesProvider, ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {PrivateRoute} from './private-route';
import {StrategyBuilder} from './container/container';
import { createMuiTheme } from '@material-ui/core';
import Backend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import {ModalProvider} from './modals';

type Props = {accessToken?: string, client: RawClient, setAccessToken: (token?: string) => void};

class AppWrapper extends React.Component<{}, {}> {
    render() {
        return <StoreProvider store={globalStore}>
            <ConnectedApp/>
        </StoreProvider>;
    }
}

const theme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            main: primary,
        },
        secondary: {
            main: secondary,
        },
    },
});

class App extends React.Component<Props, {}> {
    render() {
        const {accessToken, client, setAccessToken} = this.props;

        return !client ? null : (
            <>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                <ThemeProvider theme={theme}>
                <StylesProvider injectFirst>
                <ModalProvider>
                <DndProvider backend={Backend}>
                    <BrowserRouter>
                        <Switch>
                            <Route path="/demo">
                                <Route path="/demo/timeframe" exact component={DemoTimeframe} />
                                <Route path="/demo/universes" exact component={DemoUniverses} />
                                <Route path="/demo/parser" exact component={ParserDemoPage} />
                                <Route path="/demo/data-builder" exact component={DataBuilderDemo} />
                            </Route>

                            <Route path="/login" exact render={() => this.props.accessToken ? <Redirect to="/" /> : <LoginPage />} />
                            <PrivateRoute path="/logout" exact render={() => {
                                setAccessToken(undefined);
                                return <Redirect to="/" />;
                            }} />

                            <PrivateRoute path="/browser" exact component={BrowserPage} />

                            <Route path="/" exact render={() => <div style={{width: '960px', height: '630px', margin: 'auto', position: 'relative', background: 'url(http://thinkalpha.io/img/bg.jpg)'}}>
                                <div style={{color: 'white', background: '#000', opacity: 0.88, position: 'absolute', top: '65px', right: '40px', width: '520px', padding: '15px'}}>
                                    <h3 style={{color: '#FDD017'}}>Think Alpha develops advanced analytical trading systems that are designed with elegance and delivered with innovation.</h3>
                                    We are an ultra-private group of hard core technologists and technology innovators. If you are interested in learning more and/or interested in employment with Think Alpha, please <a href="mailto:info@thinkalpha.io">contact us</a>.
                                </div>
                            </div> } />

                            <Route path="/"> {/* the actual strategy builder routes are within */}
                                <StrategyBuilder />
                            </Route>
                        </Switch>
                    </BrowserRouter>
                    <TableErrorModal/>
                </DndProvider>
                </ModalProvider>
                </StylesProvider>
                </ThemeProvider>
                </MuiPickersUtilsProvider>
            </>
        );
    }
}

const ConnectedApp = connect(App, (store: Store<AppState>) => {
    const props = store.watch(state => {
        return {
            accessToken: state.accessToken,
            client: state.client,
        };
    });
    const actionMap: ActionMap<App> = {
        setAccessToken: accessToken$
    };
    return {props, actionMap};
});

setConfig({pureSFC: true} as any);
const HotApp = hot(module)(AppWrapper);
export {HotApp as App};