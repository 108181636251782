import { NumericComparisonOperator, NumericMathOperator, AstNodeType, KnownAstNode, AstNode } from '@thinkalpha/language-services';
import { FieldTypeCategory } from '@thinkalpha/table-client';
import { SlidingWindow } from '../timeframe/model';
import { HistoricType } from './model';
import { barToString } from '../timeframe/bars';
import _ from 'lodash';
import { randomString } from '../../util/randomString';

export function render(node: KnownAstNode | null | undefined): string {
    if (!node) return '';
    if (!node.valid) return '';

    switch (node.type) {
        case AstNodeType.binaryOperation: {
            const {operand1, operand2, operator} = node;
            const compiledOperand1 = render(operand1);
            const compiledOperand2 = render(operand2);
            return `${compiledOperand1} ${operator} ${compiledOperand2}`;
        }
        case AstNodeType.column: {
            const {name, sourceTable, field} = node;
            return `${name}${sourceTable ? `@${sourceTable}` : ''}`;
        }
        case AstNodeType.number: {
            const {value} = node;
            return `${value}`;
        }
        case AstNodeType.string: {
            const {value} = node;
            // const prefix = isRegex ? '%' : '$';
            return value ?? '';
        }
        case AstNodeType.regex: {
            const {value} = node;
            // const prefix = isRegex ? '%' : '$';
            return value ?? '';
        }
        case AstNodeType.functionCall: {
            const {functionName, functionDef, arguments: args} = node;
            const compiledArgs = args!.map(x => render(x));
            return `${functionName}(${compiledArgs.join(', ')})`;
        }
        case AstNodeType.unaryOperation: {
            const {operator, operand} = node;
            const compiledOperand = render(operand);
            return `${operator}${compiledOperand}`;
        }
        case AstNodeType.paren: {
            const {content} = node;
            const compiledContent = render(content);
            return `(${compiledContent})`;
        }
        default:
            throw new Error(`Unknown AST node type: ${(node as AstNode).type}`);
    }
}

export default render;