import _ from 'lodash';

import { DefinitionBundle, Table } from '@thinkalpha/table-client';

import { randomString } from '../../../util/randomString';
import { MarketCapSize, RangedUniverseAspect, SimpleRange, UniverseCreatorResult, UniverseAspectType, UniverseFilter, UniverseAspectApplier, UniverseAspectApplierResult } from '../../model';

const tableDetailsForUniverseType: ReadonlyMap<UniverseAspectType, {field: string, factor: number}> = new Map<UniverseAspectType, {field: string, factor: number}>([
    [UniverseAspectType.marketCap, {field: 'MarketCap', factor: 1e6}],
    [UniverseAspectType.volume, {field: 'ADV_20d', factor: 1e3}], // todo, allow selection of source,
    [UniverseAspectType.currentRatio, {field: 'current_ratio_q0', factor: 1}],
    [UniverseAspectType.peRatio, {field: 'PE_Ratio', factor: 1}],
    [UniverseAspectType.price, {field: 'Close_adj', factor: 1}]
]);

export const rangedUniverseAspectApplier: UniverseAspectApplier<RangedUniverseAspect> = (aspect) => {
    if (!tableDetailsForUniverseType.has(aspect.type)) throw new Error(`Unsupported universe type in ranged universe definer: ${aspect.type}`);
    const {field, factor} = tableDetailsForUniverseType.get(aspect.type)!;

    const min = Math.min(...aspect.ranges.map(x => typeof x === 'number' ? x : x.start));
    const max = Math.min(...aspect.ranges.map(x => typeof x === 'number' ? x : x.end));

    const filter = [
        aspect.below && `${field} < ${min / factor}`,
        ...aspect.ranges.map(range => typeof range === 'number' ? `${field} == ${range/factor}` : `(${field} >= ${range.start / factor}) and (${field} <= ${range.end / factor})`),
        aspect.above && `${field} > ${max / factor}`,
    ].filter(x => x)
        .map(x => `(${x})`)
        .join(' or ');

    return {newDefinitions: [], filter};
};