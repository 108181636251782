import React, { useState, useEffect } from 'react';
import moment, { Moment, MomentSetObject } from 'moment';
import { SlidingWindow, PointInTime as PointInTimeModel, AbsolutePointInTime, PeriodTypes, PeriodType, Timeframe } from './model';
import { useStringBooleanInputState, useInputState, useNumberInputState } from '../../hooks/useInputState';
import { useDeepEffect } from '../../hooks/useDeepEffect';
import { RadioGroup, FormControlLabel, Radio, Select, MenuItem, TextField } from '@material-ui/core';
import { SlidingWindowComponent } from './sliding';
import DatePicker from 'react-datepicker';
import { AbsolutePointInTimeComponent } from './absolute';
import { useModelCommitter } from '../../hooks/useModelCommitter';
import {Anchor} from './anchor';

export const PointInTime: React.FC<{model?: PointInTimeModel, barsOnly?: boolean, label?: string, bars?: boolean, showNow?: boolean, onChange?: (model?: PointInTimeModel) => void}> = ({model, label, bars, barsOnly, onChange, showNow}) => {
    const [type, setType, onTypeChanged] = useInputState<'now' | 'relative' | 'anchored-relative' | 'absolute' | 'count'>(bars ? 'count' : (showNow ? 'now' : 'relative'));
    const [absolute, setAbsolute] = useState<AbsolutePointInTime>();
    const [window, setWindow] = useState<SlidingWindow | null>();
    const [count, setCount, onCountChanged] = useNumberInputState();
    const [anchor, setAnchor] = useState<MomentSetObject>();

    useEffect(() => {
        if (!bars) {
            if (type === 'count') setType(showNow ? 'now' : 'relative');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bars]);

    useModelCommitter(
        model,
        onChange,
        {type, absolute, window, anchor, count},
        ({type, absolute, window, anchor, count}) => {
            switch (type) {
                case 'now': {
                    return null;
                }
                case 'absolute': {
                    if (!absolute) {
                        return undefined;
                    } else {
                        return moment(absolute);
                    }
                }
                case 'relative':
                case 'anchored-relative': {
                    if (window === null) {
                        return null;
                    } else if (!window) {
                        return undefined;
                    } else {
                        return {periods: [window], anchor};
                    }
                }
                case 'count': {
                    if (count === undefined) {
                        return false;
                    } else {
                        return count;
                    }
                }
            }

            return false;
        },
        model => {
            switch (typeof model) {
                case 'undefined':
                    break;
                case 'number':
                    setType('count');
                    setCount(model);
                    break;
                case 'object':
                    if (model === null) {
                        setType('now');
                        setWindow(null);
                    } else if (moment.isMoment(model)) {
                        setType('absolute');
                        setAbsolute(model);
                    } else {
                        if (model.periods.length !== 1) {
                            throw new Error('Only relative timeframes with a single period are supported.');
                        }
                        setType('relative');
                        setWindow(model.periods[0]);
                    }
                    break;
            }
        }
    );

    return <div className="point-in-time">
        <div className="selection-row">
            <TextField select className="type-selector" variant="outlined" value={type} onChange={onTypeChanged} label={label}>
                <MenuItem value="now" hidden={!showNow}>Now</MenuItem>
                {!barsOnly && <MenuItem value="relative">Relative to Now</MenuItem>}
                {/* <MenuItem value="anchored-relative">Pinned Relative to Now</MenuItem> */}
                {!barsOnly && <MenuItem value="absolute">Select Date &amp; Time</MenuItem>}
                {bars && <MenuItem value="count">Bars from Now</MenuItem>}
            </TextField>
            <div className="relative" hidden={type !== 'relative' && type !== 'anchored-relative'}>
                <SlidingWindowComponent model={window} onChange={setWindow} showNow={showNow} />
                <div className="anchor-area"> {/* hidden={type !== 'anchored-relative'} */}
                    <Anchor model={anchor} onChange={setAnchor} windows={window ? [window] : []} />
                </div>
            </div>
            <div hidden={type !== 'absolute'}>
                <AbsolutePointInTimeComponent model={absolute} onChange={setAbsolute} />
            </div>
            <div className="bar-count" hidden={type !== 'count'}>
                <div className="count">
                    <TextField type="number" value={count || null} onChange={onCountChanged} inputProps={{min: 0, step: 1}} />&nbsp;bars
                </div>
            </div>
        </div>
    </div>;
};

export function pointInTimeToSubscript(pointInTime: PointInTimeModel | null): string { // , bars?: boolean | PeriodType
    // if (pointInTime === undefined) {
    //     return undefined as any; // hack to handle an undefined pointInTime, which shouldn't happen
    // } else
    if (pointInTime === null) {
        // return '0';
        return '';
    } else if (typeof pointInTime === 'number') {
        // we have a count
        return `${pointInTime}`;
    } else if (moment.isMoment(pointInTime)) {
        const formatted = pointInTime.format('YYYYMMDD-HH-mm-ss');
        return `#${formatted}`;
    } else {
        // if (pointInTime.anchor || !bars) {
        {
            const mostSpecificPeriodType = PeriodTypes[Math.min(...pointInTime.periods.filter(x => x && x.period).map(p => PeriodTypes.indexOf(p.period)))];
            const prefix = pointInTime.anchor ? '_' : '.'; // for when we want to go back to the . syntax
            const periodString = pointInTime.periods.map(x => `${prefix}${x.value}${x.period}`).join('');
            let formatString = '';
            // the following switch INTENTIONALLY lets the cases fall through
            switch (mostSpecificPeriodType) {
                case undefined:
                    formatString += 'YYYY';
                case PeriodType.year:
                    formatString += 'MM';
                case PeriodType.quarter:
                case PeriodType.month:
                    formatString += 'DD-';
                case PeriodType.week:
                case PeriodType.day:
                    formatString += 'HH-';
                case PeriodType.hour:
                    formatString += 'mm-';
                case PeriodType.minute:
                case PeriodType.second:
                    formatString += 'ss';
            }
            const formattedAnchor = pointInTime.anchor ? `?${moment().set(pointInTime.anchor).format(formatString)}` : '';
            return `${periodString}${formattedAnchor}`;
        }
        // } else {
        //     // Convert to count for seconds bars (this is a hacky replacement for eliminating the . syntax)
        //     // This won't properly handle varying length months or years
        //     const totalSeconds = _.sum(pointInTime.periods.map(x => x.value * (PeriodInSeconds.get(x.period) || 0)));
        //     const barFactor = typeof bars === 'string' ? PeriodInSeconds.get(bars)! : 1;
        //     return `${Math.round(totalSeconds / barFactor)}`;
        // }
    }
}

export function timeframeToSubscript(f: Timeframe, functionName: string | undefined) {
    if (f.start === undefined) return;
    const delimiter = ['asdf'].includes(functionName!) ? ';' : ':';
    // return `${pointInTimeToSubscript(f.start)}${f.end !== undefined ? `${delimiter}${pointInTimeToSubscript(f.end)}` : ''}`;
    return `${f.end !== undefined ? `${pointInTimeToSubscript(f.end)}${delimiter}` : ''}${pointInTimeToSubscript(f.start)}`;
}