import { Dispatch, SetStateAction, useMemo, useState, useCallback } from 'react';

export type Togglers = [boolean, Dispatch<SetStateAction<boolean>>, () => void, () => void, () => void];
// {
//     state: boolean;
//     setState: Dispatch<SetStateAction<boolean>>;
//     makeTrue: () => void;
//     makeFalse: () => void;
//     toggle: () => void;
//     [Symbol.iterator]: () => IterableIterator<boolean | Dispatch<SetStateAction<boolean>> | (() => void)>;
// }; // [boolean, Dispatch<SetStateAction<boolean>>, () => void, () => void, () => void];
export function useToggleState(initial = false): Togglers {
    const [state, setState] = useState(initial);
    const [makeTrue, makeFalse] = useMemo(() => [true, false].map(value => () => setState(value)), [setState]);
    const toggle = useCallback(() => setState(x => !x), []);
    return [state, setState, makeTrue, makeFalse, toggle];
}