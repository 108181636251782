import './if-then.scss';

import loglevel from 'loglevel';
const log = loglevel.getLogger('if-then');

import _ from 'lodash';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import {Menu, MenuItem, Tab, Dialog, DialogTitle, DialogContent, DialogActions, Toolbar, Box, Button, IconButton, ButtonGroup} from '@material-ui/core';

import { Store } from 'reactive-state';
import { ActionMap, connect } from 'reactive-state/react';

import { FieldDescriptor, Key, parseKey, RawClient, stringifyKey, TableClient, FieldTypeCategory } from '@thinkalpha/table-client';
import {SaveNameDialog} from './strategy/save-name.dialog';
import TableView from '../components/table-view/table-view.new';
import { AppState, clipboard$, Clipboard } from '../state';
import { ConcreteStrategy, IfThenStrategy, MaterializedStrategy, StrategyType } from '../strategy/model';
import { randomString } from '../util/randomString';
import { IfThenGroupModel, IfThenConditionModel, IfThenRootModel } from './model';

import {useModalActions} from '../modals';
import IfThenGroup from './group';

import classnames from 'classnames';

import { allStrategyFields } from './strategy/fields.hardcoded';
import { DataBuilder, descriptorToField } from '../components/data-builder/data-builder';
import { CustomFormula } from '../components/data-builder/model';
import useLazyRef from 'src/hooks/useLazyRef';
import { LogicalOperator } from '@thinkalpha/language-services';

type Props = {
    tableKey?: Key;
    client: RawClient;
    onModelChanged?: (model: IfThenRootModel | undefined) => void;
    model?: IfThenRootModel;
    onUncommittedChanges?: (uncommittedChanges: boolean) => void;
    clipboard: Clipboard;
    setClipboard: (clipboard: Clipboard) => void;
};

function defaultModel(): IfThenRootModel {
    // console.trace('default model');
    return {
        id: randomString(),
        type: 'group',
        enabled: true,
        lines: [{type: 'group', id: randomString(), enabled: true, lines: [{type: 'line', enabled: true, id: randomString()}], operator: LogicalOperator.and, collapsed: false}],
        operator: LogicalOperator.or,
        collapsed: false,
        formulas: []
    };
}

const IfThenComponent: React.FC<Props & RouteComponentProps<{key?: string}>> = ({
    tableKey, client, match, onModelChanged, clipboard, setClipboard, onUncommittedChanges, model: committedModel
}) => {

    const [uncommittedModel, setUncommittedModel] = useState<IfThenRootModel>(committedModel ?? defaultModel());
    const [autocommitState, setAutocommitState] = useState<false | true | 'not-lines'>(false);
    const [fields, setFields] = useState<FieldDescriptor[]>([]);
    const [validModel, setValidModel] = useState(false);

    const uncommittedChanges = useMemo(() => {
        const res = !_.isEqual(committedModel, uncommittedModel);
        return res;
    }, [committedModel, uncommittedModel]);
    
    useEffect(() => {
        onUncommittedChanges?.(uncommittedChanges);
    }, [onUncommittedChanges, uncommittedChanges]);

    useEffect(() => {
        if (!autocommitState) return;
        if (autocommitState === 'not-lines') {
            onModelChanged?.({...uncommittedModel, lines: committedModel?.lines ?? []});
        } else {
            onModelChanged?.(uncommittedModel);
        }
        setAutocommitState(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autocommitState]);

    useEffect(() => {
        // onModelChanged?.(committedModel);
        setUncommittedModel(committedModel ?? defaultModel());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [committedModel]);

    const tableClientRef = useRef<TableClient>();
    useEffect(() => {
        const tc = tableClientRef.current = new TableClient(client);
        const sub = tc.descriptor$.pipe(
            // tap(x => console.log('fields', x))
        ).subscribe(setFields);

        return () => {
            sub.unsubscribe();
            tc.dispose();
        };
    }, [client]);

    useEffect(() => {
        if (!tableKey) return;
        tableClientRef.current!.key = tableKey;
        // tableClientRef.current!.bounds = {firstRow: 0, windowSize: 0};
    }, [tableKey]);

    const [createColumnModal, setCreateColumnModal] = useState<(result?: {name: string, description?: string, formula: string}) => void>();
    
    const addFormula = useCallback(async () => {
        const columnPromise = new Promise<CustomFormula>((res) => {
            setCreateColumnModal(() => res);
        }).then(res => {
            setCreateColumnModal(undefined);
            return res;
        });

        const formula = await columnPromise;
        if (!formula) return; // cancelled

        setUncommittedModel(model => ({
            ...model,
            formulas: [...model.formulas.filter(x => x.name !== formula.name), formula]
        }));
        setAutocommitState('not-lines');
    }, []);

    const {showConfirmationModal} = useModalActions();

    const deleteFormula = useCallback(async (formula: CustomFormula) => {
        const confirmed = await showConfirmationModal({ title: 'Are you sure?' });
        if (!confirmed) return;

        setUncommittedModel(model => ({
            ...model,
            formulas: [...model.formulas.filter(x => x.name !== formula.name)]
        }));
        setAutocommitState('not-lines');
    }, [showConfirmationModal]);

    const runChanges = useCallback(() => {
        setAutocommitState(true);
    }, []);

    return <>
        <div className="if-then-editor">
            <IfThenGroup
                committedModel={committedModel}
                onValidityChanged={setValidModel}
                depth={0}
                fields={fields.map(descriptorToField)}
                model={uncommittedModel}
                onModelChanged={setUncommittedModel}
                clipboard={clipboard}
                setClipboard={setClipboard}
            />
            <div className="controls">
                <Button color="primary" className="run" variant="contained" onClick={runChanges} disabled={!uncommittedChanges || !validModel}>Run Changes&nbsp;&nbsp;<i className="fas fa-caret-right" /></Button>
                <Button color="primary" className="custom-columns" variant="outlined" onClick={addFormula}>Custom Columns&nbsp;&nbsp;<i className="fal fa-columns"/></Button>
            </div>
        </div>
        <FormulaCreator fields={fields} onClose={createColumnModal} formulas={uncommittedModel.formulas} onDelete={deleteFormula} />
    </>;
};

const FormulaCreator: React.FunctionComponent<{fields: readonly FieldDescriptor[], onDelete?: (formula: CustomFormula) => void, formulas?: readonly CustomFormula[], onClose: ((result?: CustomFormula) => void) | undefined}> = ({fields, onClose, onDelete, formulas}) => {
    const mappedTableFields = fields.map(descriptorToField);
    const dedupedFields = _.uniqWith([...mappedTableFields, ...allStrategyFields], (x, y) => x.name === y.name && x.sourceTable === y.sourceTable);

    return <DataBuilder onClose={onClose} formulas={formulas} fields={dedupedFields} onDelete={onDelete} />;
};

export default connect(withRouter(IfThenComponent), (store: Store<AppState>) => {
    const props = store.watch(state => ({
        savedStrategies: [],
        client: state.client,
        clipboard: state.clipboard
    }));

    const actionMap: ActionMap<typeof IfThenComponent> = {
        setClipboard: clipboard$
        // addStrategy: addStrategy$,
        // deleteStrategy: deleteStrategy$
    };

    return {
        props,
        actionMap
    };
});