export enum GaussType {
    stringArray = 'stringArray',
    string = 'string'
    // ...
}
export interface GaussInputValue {
    symbol: string;
    type: GaussType;
    value: any;
}
export interface GaussOutputValue {
    symbol: string;
    type: GaussType;
    columns?: number;
    value?: any;
}
export type ProgramName = {
    name: string;
};
export type ProgramCode = {
    code: string;
};
export function isProgramName(program: ProgramName | ProgramCode): program is ProgramName {
    return (program as ProgramName).name !== undefined;
}
export function isProgramCode(program: ProgramName | ProgramCode): program is ProgramCode {
    return (program as ProgramCode).code !== undefined;
}
export interface GaussProgram {
    program: ProgramName | ProgramCode;
    inputs: GaussInputValue[];
    outputs: GaussOutputValue[];
}
export type GaussProgramResult = GaussOutputValue[];

export enum GaussJobStatus {
    created = 'created',
    queued = 'queued',
    pickedUp = 'pickedUp',
    done = 'done',
    failed = 'failed',
    timeOut = 'timedOut'
}
export interface Job {
    id: string;
    status: GaussJobStatus;
    description?: string;
}
export interface JobResult extends Job {
    result: GaussProgramResult;
}