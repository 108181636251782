import React from 'react';
import { SlidingWindow, PeriodType, PeriodNames } from './model';
import { useNumberInputState, useInputState, useCheckedInputState } from '../../hooks/useInputState';
import { useModelCommitter } from '../../hooks/useModelCommitter';
import { TextField, MenuItem, FormControlLabel, Checkbox } from '@material-ui/core';

type Props = {
    model?: SlidingWindow | null;
    onChange?: (model: SlidingWindow | null | undefined) => void;
    showNow?: boolean;
    disabled?: boolean;
};

export const SlidingWindowComponent: React.FC<Props> = ({model, onChange, disabled = false, showNow = false}) => {
    const [now, setNow, onNowChanged] = useCheckedInputState(showNow);
    const [value, setValue, onValueChanged] = useNumberInputState(5);
    const [period, setPeriod, onPeriodChanged] = useInputState<PeriodType>(PeriodType.minute);

    useModelCommitter(
        model,
        onChange,
        { value, period, now },
        ({value, period, now}) => { // committer
            if (value === undefined && !period && !now) return undefined;
            
            if (now) return null;

            if (value === undefined) return false;
            if (period === undefined) return false;
            
            return {value, period};
        },
        model => {
            if (!model) {
                setValue(5);
                setPeriod(PeriodType.minute);
                setNow(showNow);
            } else {
                setValue(model.value);
                setPeriod(model.period);
                setNow(model.value === 0);
            }
        }
    );

    return <div className="sliding-window">
        {showNow && <div className="now">
            <FormControlLabel control={<Checkbox color="primary"/>} disabled={disabled} label="Now" checked={now} onChange={onNowChanged} />
            <div className="or">or</div>
        </div>}
        <TextField disabled={now || disabled} className="value" type="number" value={value} onChange={onValueChanged} />
        <TextField disabled={now || disabled} className="period" select value={period} onChange={onPeriodChanged}>
            {PeriodNames.filter(x => ![].find(y => y === PeriodType[x])).map(period => <MenuItem key={PeriodType[period]} value={PeriodType[period]}>
                {period[0].toUpperCase()}{period.substring(1)}{value !== 1 ? 's' : null}
            </MenuItem>)}
        </TextField>
        <div className="ago">ago</div>
    </div>;
};