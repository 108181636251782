import loglevel from 'loglevel';
const log = loglevel.getLogger('universe-creator');

import { FormControlLabel, FormGroup, Input, MenuItem, Radio, RadioGroup, Select, Checkbox, Dialog, DialogContent, DialogActions, TextField, Button, DialogTitle, Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';

import { switchMap, tap, debounceTime } from 'rxjs/operators';

import { TableClient, tableClientForKey } from '@thinkalpha/table-client';
import { client } from '../services/table';

import { useInputState } from '../hooks/useInputState';
import { useModelCommitter } from '../hooks/useModelCommitter';

import {RangedAspectCreator, RangedUniverseType} from './creators/ranged/creator';
import { MarketCapSize, Range, RangedUniverseAspect, Universe, UniverseAspectType, universeAspectTypeNames, KnownUniverseAspect } from './model';

import Highcharts from 'highcharts';
import Venn from 'highcharts/modules/venn';
Venn(Highcharts);

import HighchartsReact from 'highcharts-react-official';
import numeral from 'numeral';

import './universe-creator.scss';

import {defineUniverse} from './definer';
import DraggablePaper from '../components/draggable-paper';
import { saveUniverse } from '../services/universes';
import { ListCreator } from '../components/list-creator/list-creator';
import { Subject, of } from 'rxjs';
import {PremadeUniverses} from './premade-universes';

export const exposedUniverseTypes: readonly UniverseAspectType[] = [
    UniverseAspectType.volume, UniverseAspectType.price, UniverseAspectType.marketCap,
    UniverseAspectType.etf, UniverseAspectType.currentRatio, UniverseAspectType.peRatio,
    UniverseAspectType.sector, UniverseAspectType.adr
];

const enabled: ReadonlySet<UniverseAspectType> = new Set<UniverseAspectType>([
    UniverseAspectType.marketCap,
    UniverseAspectType.price,
    // UniverseType.peRatio,
    UniverseAspectType.volume,
    // UniverseType.currentRatio,
    // UniverseType.sector
]);

const nakedRangedAspects = [UniverseAspectType.price, UniverseAspectType.volume, UniverseAspectType.marketCap];

const defaultChartOptions: Partial<Highcharts.Options> = {
    credits: {enabled: false},
    title: {
        text: null as any
    },
    chart: {
        height: '100%',
        // width: '100%'
    },
    // tooltip: {
    //     pointFormat: '{series.name}: <strong>{point.percentage:.1f}%</strong>'
    // },
    plotOptions: {
        venn: {
            // allowPointSelect: true,
            cursor: 'pointer',
            // dataLabels: {
            //     enabled: true,
            //     distance: -50,
            //     format: '<strong>{point.name}</strong>: {point.percentage:.1f} %',
            //     style: {
            //         color: /*(Highcharts.theme && Highcharts.theme.contrastTextColor) || */'black'
            //     }
            // }
        }
    },
};

export const UniverseCreator: React.FunctionComponent<{model?: Universe, onChange?: (model?: Universe) => void}> = ({model, onChange}) => {
    const [wholeMarketSize, setWholeMarketSize] = useState<number>();
    const [universeSize, setUniverseSize] = useState<number>();

    const [premade, setPremade, onPremadeChanged] = useInputState<string>(PremadeUniverses[0].table);

    const [inclusionList, setInclusionList] = useState<readonly string[]>(model && model.inclusionList || []);
    const [exclusionList, setExclusionList] = useState<readonly string[]>(model && model.exclusionList || []);
    const [aspects, setAspects] = useState<KnownUniverseAspect[]>(model ? model.aspects : []);

    useModelCommitter(
        model,
        onChange,
        {premade, inclusionList, exclusionList, aspects},
        ({premade, inclusionList, exclusionList, aspects}) => {
            return {
                inclusionList,
                exclusionList,
                aspects
            };
        },
        model => {
            if (model) {
                const {inclusionList, exclusionList, aspects} = model;
                setInclusionList(inclusionList);
                setExclusionList(exclusionList);
                setAspects(aspects);
            } else {
                setInclusionList([]);
                setExclusionList([]);
                setAspects([]);
            }
        }
    );

    // subscribe to whole universe size
    useEffect(() => {
        const tc = new TableClient(client, {ex: 'T', sym: 'EquityUniverse'});
        const sub = tc.rowCount$.subscribe(rc => setWholeMarketSize(rc));
        tc.bounds = {firstRow: 0, windowSize: 0};
        return () => {
            sub.unsubscribe();
            tc.dispose();
        };
    }, []);

    // subscribe to the potential universe size, debounced
    const universeSubjectRef = useRef(new Subject<Universe>());
    useEffect(() => {
        const sub = universeSubjectRef.current.pipe(
            // tap(() => setUniverseSize(undefined)),
            debounceTime(750),
            switchMap(universe => {
                if (!universe.aspects.length && !universe.exclusionList.length && !universe.inclusionList.length) return of(undefined);
                const def = defineUniverse(universe);
                log.info('defining objects', def.definitions);
                return client.defineObject(false, def.definitions).pipe(switchMap(() => of({sym: def.name, ex: 'T'})));
            }),
            tableClientForKey(client),
            tap(tc => tc.bounds = { firstRow: 0, windowSize: 0}),
            switchMap(tc => tc.rowCount$)
        ).subscribe(rc => setUniverseSize(rc));
        return () => sub.unsubscribe();
    }, []);
    useEffect(() => {
        universeSubjectRef.current.next({aspects, exclusionList, inclusionList});
    }, [aspects, exclusionList, inclusionList]);

    const aspectSetters = useMemo(() => new Map(Object.keys(UniverseAspectType)
        .map(x => UniverseAspectType[x] as UniverseAspectType)
        .map(type => {
            const setter = (aspect: KnownUniverseAspect) => setAspects(aspects => {
                const aspectIndex = aspects.findIndex(x => x.type === type);
                aspects = [...aspects];
                if (aspectIndex !== -1) {
                    aspects[aspectIndex] = aspect;
                } else {
                    aspects.push(aspect);
                }
                return aspects;
            });
            return [type, setter];
        })
    ), []);

    return (
        <div className="universe-creator">
            <Typography>
                When creating a universe, there are a variety of filters available. Select the base universe from which to start customizations.
            </Typography>
            <TextField className="premade" select variant="outlined" label="Premade Base Universe" value={premade} onChange={onPremadeChanged}>
                <MenuItem value="all">Full Universe</MenuItem>
                <MenuItem>Premade Universe 1</MenuItem>
                <MenuItem>Premade Universe 2</MenuItem>
                <MenuItem>Premade Universe 3</MenuItem>
            </TextField>
            <div className="params-and-preview">
                <div className="parameters">
                    <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<i className="fas fa-caret-circle-down" />}>
                            Include and Exclude Specific Symbols
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <ListCreator name="Inclusion List" value={inclusionList} onChange={setInclusionList} unique/>
                            <ListCreator name="Exclusion List" value={exclusionList} onChange={setExclusionList} unique/>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    {nakedRangedAspects.map(type => <ExpansionPanel key={type}>
                        <ExpansionPanelSummary expandIcon={<i className="fas fa-caret-circle-down" />}>
                            {universeAspectTypeNames.get(type)}
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div className="aspect">
                                <RangedAspectCreator type={type as RangedUniverseType} aspect={aspects.find(x => x.type === type) as RangedUniverseAspect | undefined} onChange={aspectSetters.get(type)! as (aspect: RangedUniverseAspect) => void} />
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>)}
                </div>
                <div className="preview">
                    <HighchartsReact
                        options={{
                            ...defaultChartOptions,
                            series: [{
                                type: 'venn',
                                name: 'Universe Size',
                                data: [{
                                    sets: ['Whole Market'],
                                    value: wholeMarketSize || 1
                                },
                                ...universeSize !== undefined
                                    ? [{
                                        sets: ['Your Universe'],
                                        value: universeSize || 1
                                    }, {
                                        sets: ['Whole Market', 'Your Universe'],
                                        value: wholeMarketSize || 1,
                                    }]
                                    : []
                                ]
                            }]
                        }}
                        highcharts={Highcharts}
                    />
                    <div className="stats-container">
                        <div className="stats">
                            <div className="stat">Market: <code>{numeral(wholeMarketSize).format('0,0')}</code></div>
                            <div className="stat" hidden={universeSize === undefined}>Your Universe: <code>{universeSize === undefined ? null : numeral(universeSize).format('0,0')}</code></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const UniverseCreatorModal: React.FC<{onClose?: (universe?: Universe) => void}> = ({onClose}) => {
    const [universe, setUniverse] = useState<Universe>();
    const [name, setName, onNameChanged] = useInputState();

    useEffect(() => {
        if (onClose) return;

        setUniverse(undefined);
        setName('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onClose]);

    const cancel = useCallback(() => {
        if (onClose) onClose();
    }, [onClose]);

    const save = useCallback(() => {
        if (!onClose) return;
        if (!universe) return;

        onClose({...universe, name});
    }, [name, onClose, universe]);

    return <Dialog PaperComponent={DraggablePaper} open={!!onClose} onClose={cancel}>
        <DialogTitle>Universe Creator</DialogTitle>
        <DialogContent>
            <UniverseCreator
                model={universe}
                onChange={setUniverse}
            />
        </DialogContent>
        <DialogActions>
            <TextField label="Universe Name" required spellCheck={false} value={name} onChange={onNameChanged} variant="outlined" />
            <Button color="primary" onClick={save} disabled={!universe || !name}>Save</Button>
            <Button color="secondary" onClick={cancel}>Cancel</Button>
        </DialogActions>
    </Dialog>;
};

export const UniverseCreatorAndDefinerModal: React.FC<{onClose?: (name: string) => void}> = ({onClose}) => {
    const onClosed = useCallback(async (universe: Universe) => {
        if (!onClose) return;

        // define and save
        await saveUniverse(universe).toPromise();

        onClose(universe.name!);
    }, [onClose]);

    return <UniverseCreatorModal
        onClose={onClosed}
    />;
};