import React, { useEffect, useState, useCallback } from 'react';

import { FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import { useInputCallback, useInputState, useNumberInputState } from '../../hooks/useInputState';

import { KnownStrategyTemplate, MovingAverageBenchmark, MovingAverageIndicator, PriceRelativeChangeBenchmark, StrategyType } from '../model';

import { MovingAverageBenchmarkComponent } from '../creators/moving-average/moving-average';
import {MovingAverageIndicatorsComponent} from '../creators/moving-average/moving-average-indicators';

import { StepRecord } from '../../demo/wizard/wizard';

import {TimeframeComponent} from '../../components/timeframe/timeframe';
import './define-strategy-steps.scss';
import { WIZARD_CHOOSE_STRATEGY, WIZARD_SELECT_TECHNICAL_INDICATOR } from '../../text/wizard-steps-text';
import Rheostat from 'rheostat';
import { PointInTime, Timeframe, HistoryType } from '../../components/timeframe/model';

const benchmarkNames: ReadonlyMap<PriceRelativeChangeBenchmark, string> = new Map<PriceRelativeChangeBenchmark, string>([
    [PriceRelativeChangeBenchmark.sp100, 'S&P100'],
    [PriceRelativeChangeBenchmark.sp500, 'S&P500'],
    [PriceRelativeChangeBenchmark.djia, 'DJIA'],
    [PriceRelativeChangeBenchmark.rus3000, 'RUS3000'],
    [PriceRelativeChangeBenchmark.symbol, 'Symbol'],
    [PriceRelativeChangeBenchmark.etfsector, 'ETF Sector'],
    [PriceRelativeChangeBenchmark.index, 'Index'],
    [PriceRelativeChangeBenchmark.portfolio, 'A Portfolio'],
    [PriceRelativeChangeBenchmark.custom, 'Custom Basket'],
]);

export function useStrategySteps(strategy: KnownStrategyTemplate | undefined, onChange: (strategy?: KnownStrategyTemplate) => void): StepRecord[] {
    const [strategyType, setStrategyType] = useState<StrategyType | undefined>(undefined);

    const [lookback, setLookback] = useState<Timeframe>();
    const [benchmark, , onBenchmarkChanged] = useInputState<PriceRelativeChangeBenchmark>();

    const [value, setValue, onValueChanged] = useNumberInputState();
    
    const [technicalIndicator,, onTechnicalIndicatorChanged] = useInputState<string>();
    const [level,, onLevelChange] = useInputState<string>();

    const [shorterBenchmark, setShorterBenchmark] = useState<MovingAverageBenchmark>();
    const [longerBenchmark, setLongerBenchmark] = useState<MovingAverageBenchmark>();
    const [bullishIndicators, setBullishIndicators] = useState<MovingAverageIndicator[]>();
    const [bearishIndicators, setBearishIndicators] = useState<MovingAverageIndicator[]>();

    const onStrategyTypeChanged = useInputCallback((type: StrategyType) => {
        setStrategyType(type);
        setValue(null);
    }, [setStrategyType]);

    useEffect(() => {
        // update external strategy based on internal factors
        switch (strategyType) {
            case StrategyType.priceChange:
                if (value === null || lookback === undefined) {
                    onChange(undefined);
                } else {
                    onChange({
                        type: strategyType,
                        amount: value,
                        lookback: lookback.start
                    });
                }
                break;
            case StrategyType.priceRelativeChange:
                if (value === null || lookback === undefined) {
                    onChange(undefined);
                } else {
                    onChange({
                        type: strategyType,
                        amount: value,
                        benchmark,
                        lookback: lookback.start
                    });
                }
                break;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [strategyType, lookback, benchmark, value, technicalIndicator, level, shorterBenchmark, longerBenchmark, bullishIndicators, bearishIndicators]);

    useEffect(() => {
        // update internal factors based on external strategy

        // no-op for now
    }, [strategy]);

    const unfiltered: (StepRecord | false)[] = [
        {
            title: 'Choose Strategy',
            valid: strategyType !== undefined,
            content: <>
                <Typography>{WIZARD_CHOOSE_STRATEGY}</Typography>
                <RadioGroup value={strategyType || ''} onChange={onStrategyTypeChanged}>
                    <FormControlLabel value={StrategyType.priceChange} label="Price is Up or Down x$" control={<Radio/>}/>
                    <FormControlLabel value={StrategyType.priceRelativeChange} label="Price is Up or Down x% Relative to ..." control={<Radio/>}/>
                    <FormControlLabel disabled value={StrategyType.exact} label="Volume/Shares Traded is ..." control={<Radio/>}/>
                    <FormControlLabel disabled value={StrategyType.fundamental} label="Something fundamental is happening" control={<Radio/>}/>
                    <FormControlLabel value={StrategyType.technical} label="Something technical is happening" control={<Radio/>}/>
                </RadioGroup>
            </>
        },
        strategyType === StrategyType.priceChange && {
            title: 'Pick $ Amount',
            valid: value !== undefined,
            content: <>
                <Rheostat values={[value || 0]} onValuesUpdated={x => setValue(x.values[0])} min={0} max={500} />
                <br/>
                <div className="blue-border">$<input type="number" value={value || ''} step={0.01} min={0} onChange={onValueChanged} /></div>
            </>
        },
        strategyType === StrategyType.priceRelativeChange && {
            title: 'Pick % Amount',
            valid: value !== undefined,
            content: <>
                <Rheostat values={[value || 0]} onValuesUpdated={x => setValue(x.values[0])} min={0} max={100} />
                <br/>
                <div className="blue-border"><input type="number" value={value || ''} step={1} min={0} max={100} onChange={onValueChanged} />%</div>
            </>
        },
        (strategyType === StrategyType.priceChange || strategyType === StrategyType.priceRelativeChange) && {
            title: 'Pick Time Frame To Monitor',
            valid: lookback !== undefined,
            content: <>
                <TimeframeComponent range={false} bars={false} value={lookback} onChange={setLookback} />
            </>
        },
        strategyType === StrategyType.priceRelativeChange && {
            title: 'Pick Benchmark',
            valid: true,
            content: <>
                Relative to...
                <RadioGroup value={benchmark} onChange={onBenchmarkChanged}>
                    {Object.keys(PriceRelativeChangeBenchmark).map(k => PriceRelativeChangeBenchmark[k] as PriceRelativeChangeBenchmark).map(k =>
                        <FormControlLabel key={k} value={k} label={benchmarkNames.get(k)} control={<Radio/>}/>
                    )}
                </RadioGroup>
            </>
        },
        ...strategyType === StrategyType.technical ? [
            {
                title: 'Select Technical Indicator',
                valid: technicalIndicator !== undefined,
                content: <>
                    <Typography>{WIZARD_SELECT_TECHNICAL_INDICATOR}</Typography>
                    <RadioGroup value={technicalIndicator} onChange={onTechnicalIndicatorChanged}>
                        <FormControlLabel label="MACD Oscillator" disabled control={<Radio/>}/>
                        <FormControlLabel label="Relative Strength Index" disabled control={<Radio/>}/>
                        <FormControlLabel label="DeMark Indicator" disabled control={<Radio/>}/>
                        <FormControlLabel label="Moving Average (Trend)" value="trend" control={<Radio/>}/>
                        <FormControlLabel label="Parabolic Stop &amp; Reverse" disabled control={<Radio/>}/>
                        <FormControlLabel label="Stochastic Oscillator" disabled control={<Radio/>}/>
                        <FormControlLabel label="Commodity Channel Index" disabled control={<Radio/>}/>
                        <FormControlLabel label="Bollinger Bonds" disabled control={<Radio/>}/>
                        <FormControlLabel label="Average True Range" disabled control={<Radio/>}/>
                        <FormControlLabel label="Standard Deviation" disabled control={<Radio/>}/>
                        <FormControlLabel label="Chaiken Oscillator" disabled control={<Radio/>}/>
                        <FormControlLabel label="On-Balance Volume" disabled control={<Radio/>}/>
                        <FormControlLabel label="Volume Rate of Change" disabled control={<Radio/>}/>
                    </RadioGroup>
                </>
            },
            technicalIndicator === 'trend' && {
                title: 'Select Level',
                valid: level !== undefined,
                content: <>
                    <RadioGroup value={level} onChange={onLevelChange}>
                        <FormControlLabel label="Alpha Edge" value="standard" disabled control={<Radio/>}/>
                        <FormControlLabel label="Alpha Edge Pro" value="pro" control={<Radio/>}/>
                        <FormControlLabel label="Alpha Edge Expert" value="expert" disabled control={<Radio/>}/>
                    </RadioGroup>
                </>
            },
            (technicalIndicator === 'trend' && level === 'pro') && {
                title: 'Select Length of Shorter Moving Average Benchmark',
                valid: shorterBenchmark !== undefined && shorterBenchmark.duration.asMilliseconds() !== 0,
                content: <>
                    <MovingAverageBenchmarkComponent value={shorterBenchmark} onChange={setShorterBenchmark} />
                </>
            },
            (technicalIndicator === 'trend' && level === 'pro') && {
                title: 'Select Length of Longer Moving Average Benchmark',
                valid: longerBenchmark !== undefined && longerBenchmark.duration.asMilliseconds() !== 0,
                content: <>
                    <MovingAverageBenchmarkComponent value={longerBenchmark} onChange={setLongerBenchmark} />
                </>
            },
            (technicalIndicator === 'trend' && level === 'pro') && {
                title: 'Set Strength of Bullish Indicators',
                valid: bullishIndicators && bullishIndicators.length !== 0 || false,
                content: <>
                    <MovingAverageIndicatorsComponent value={bullishIndicators} onChange={setBullishIndicators} />
                </>
            },
            (technicalIndicator === 'trend' && level === 'pro') && {
                title: 'Set Strength of Bearish Indicators',
                valid: bearishIndicators && bearishIndicators.length !== 0 || false,
                content: <>
                    <MovingAverageIndicatorsComponent value={bearishIndicators} onChange={setBearishIndicators} />
                </>
            }
        ] : []
    ];

    return unfiltered.filter<StepRecord>((x): x is StepRecord => !!x);
}