import React, {createContext} from 'react';
import { Drawer, IconButton, Divider, List, ListItem, ListItemText } from '@material-ui/core';

import './app-drawer.scss';
import { Link } from 'react-router-dom';
import { LinkButton } from '../components/linkButton';

interface Props {
    open: boolean;
    onClose: () => void;
    // contents: React.ReactNode;
}

export interface AppDrawerContext {
    setContents: (contents: React.ReactNode) => void;
}

export const AppDrawer: React.FC<Props> = ({open, children, onClose}) => {
    return <Drawer
        className="app-drawer"
        variant="persistent"
        anchor="left"
        open={open}
    >
        <div className="drawer-header">
            <IconButton onClick={onClose}><i className="fas fa-chevron-left"/></IconButton>
        </div>
        <Divider />
        <List dense>
            <ListItem>
                <ListItemText>
                    <LinkButton to="/dashboard" color="inherit">Dashboard</LinkButton>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <LinkButton to="/if-then" color="inherit">If/Then Builder</LinkButton>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <LinkButton to="/wizard" color="inherit">Strategy Wizard</LinkButton>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <LinkButton to="/risk" color="inherit">Risk</LinkButton>
                </ListItemText>
            </ListItem>
        </List>
        {children}
    </Drawer>;
};