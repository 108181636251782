import React, { useState, useEffect, useCallback } from 'react';
import {TableView} from '../../../components/table-view/table-view.new';
import {WidgetContainer, WidgetComponent} from '../widget';
import { StrategyWidgetModel } from '../../model';
import {StrategySelector} from '../../../strategy/selector';
import { StrategyRecord } from '../../../services/strategies';
import {StrategyView} from '../../../strategy/viewer';
import { useModelCommitter } from '../../../hooks/useModelCommitter';
import FilterEditor from '../../../components/filter-editor/filter-editor.new';
import { FieldTypeCategory, FieldDescriptor } from '@thinkalpha/table-client';
import { descriptorToField } from '../../../components/data-builder/data-builder';

export const StrategyWidget: WidgetComponent<{}, StrategyWidgetModel> = ({model, onChange, ...wp}) => {
    const [strategyRecord, setStrategyRecord] = useState<StrategyRecord | string | undefined>(model && model.strategyId);
    const [filter, setFilter] = useState<string>();
    const [fields, setFields] = useState<FieldDescriptor[]>();
    const [rowCount, setRowCount] = useState<number>();

    useModelCommitter(
        model,
        onChange,
        {strategyRecord},
        ({strategyRecord}) => {
            switch (typeof strategyRecord) {
                case 'undefined':
                    return false;
                case 'string':
                    return {strategyId: strategyRecord};
                case 'object':
                    return {strategyId: strategyRecord.id};
            }
        },
        model => {
            if (model) {
                setStrategyRecord(model.strategyId);
            } else {
                setStrategyRecord(undefined);
            }
        }
    );

    const convertedFields = fields && fields.map(descriptorToField);

    const header = <>
        <StrategySelector model={strategyRecord} onChange={setStrategyRecord} />
        <FilterEditor
            placeholder="Filter"
            equalsMode
            dataTypeRequired={FieldTypeCategory.Boolean}
            fields={convertedFields || []}
            value={filter}
            onValueChange={setFilter}
            // onValidityChange={onGlobalFilterValidityChanged}
        />
        <label>Row Count:</label>
        {rowCount !== undefined ? <code>{rowCount}</code> : null}
    </>;
    // const columnPreferencer = useCallback((field: FieldDescriptor, hasExplicit: boolean) => {
    //     if (hasExplicit)
    // }, []);
    return <WidgetContainer {...wp} name="Strategy" header={header}>
        <StrategyView
            strategy={strategyRecord}
            onRowCountChanged={setRowCount}
            onFields={setFields}
            columnPreferences={[
                {name: 'Symbol', pinned: 'left'},
                'Last_Price',
                'Change',
                '%Chg',
                'Volume',
                'Open',
                'Close_adj',
                'Last_Size'
            ]}
            columnRankByIndex
            defaultColumnPreference={{hidden: true}}
        />
    </WidgetContainer>;
};