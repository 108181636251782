import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, FormControl, FormControlLabel, InputLabel, List, ListItem, ListItemSecondaryAction, ListItemText, Radio, RadioGroup, Select, Step, StepContent, StepLabel, Stepper, Typography, Slider } from '@material-ui/core';
import moment, { duration, Duration } from 'moment';

import { Time } from '../../../components/time/time';

import {useCheckedInputState, useInputCallback, useInputState, useNumberInputCallback} from '../../../hooks/useInputState';

import { MovingAverageBenchmark, MovingAverageIndicator, MovingAverageIndicatorType } from '../../model';

import './moving-average-indicators.scss';

function nameForIndicator(indicator: MovingAverageIndicator) {
    if (!indicator || !indicator.value) return 'Pending';

    const numeric = typeof indicator.value === 'number' ? indicator.value : indicator.value.asMilliseconds();
    const aboveBelow = numeric === 0 ? '' : numeric < 0 ? ' below' : ' above';

    switch (indicator.type) {
        case 'abs':
            return `$${Math.abs(indicator.value)}${aboveBelow}`;
        case 'pct':
            return `${Math.abs(indicator.value)}%${aboveBelow}`;
        case 'time':
            return `${indicator.value.humanize()}${aboveBelow}`;
    }
}

export const MovingAverageIndicatorsComponent: React.FunctionComponent<{value?: MovingAverageIndicator[], onChange: (value?: MovingAverageIndicator[]) => void}> = ({value, onChange}) => {
    const [currentIndex, setCurrentIndex] = useState<number>();
    const current = value && currentIndex !== undefined ? value[currentIndex] : undefined;

    const onTypeChanged = useInputCallback<MovingAverageIndicatorType>(type => {
        // onChange(value && value.map((x, i) => i !== currentIndex ? x : {type, value: undefined} as Partial<MovingAverageIndicator>));
    }, [onChange, value, currentIndex]);

    const onValueChanged = useCallback((newValue: Duration | number | null) => {
        onChange(value && value.map((x, i) => i !== currentIndex ? x : {...current!, value: newValue as any}));
    }, [onChange, value, currentIndex, current]);
    const onValueInputChanged = useNumberInputCallback(onValueChanged);

    const itemSelectors = useMemo(() => {
        return value && value.map((x, i) => () => setCurrentIndex(i));
    }, [value, setCurrentIndex]);

    const itemDeletors = useMemo(() => {
        return value && value.map((x, i) => () => onChange(value.filter((x1, i1) => i1 !== i)));
    }, [value, onChange]);

    const addItem = useCallback(() => {
        const newValue = [...value ? value : [], {
            type: undefined,
            value: undefined
        }];
        // onChange(newValue);
        setCurrentIndex(newValue.length - 1);
    }, [value, setCurrentIndex]);

    useEffect(() => {
        if (!currentIndex) return;
        if (!value) return;
        if (currentIndex >= value.length) setCurrentIndex(undefined);
    }, [currentIndex, value]);

    return <div className="moving-average-indicators">
        <div className="edit">
            {current && <>
                <RadioGroup value={current.type || ''} onChange={onTypeChanged}>
                    <FormControlLabel label="Absolute $" value={MovingAverageIndicatorType.absolute} control={<Radio/>} />
                    <FormControlLabel label="Relative %" value={MovingAverageIndicatorType.percent} control={<Radio/>} />
                    <FormControlLabel label="Time" value={MovingAverageIndicatorType.time} control={<Radio/>} />
                </RadioGroup>
                {current.type === 'abs' && <div className="absolute">
                    <Slider min={-365} max={365} step={1} value={current && current.value} onChange={onValueInputChanged} />
                    <input type="number" step={1} value={(current && current.value) || ''} onChange={onValueInputChanged} />
                </div>}
                {current.type === 'pct' && <div className="percent">
                    <Slider min={-100} max={100} step={1} value={current && current.value} onChange={onValueInputChanged} />
                    <input type="number" step={1} min={-100} max={100} value={(current && current.value) || ''} onChange={onValueInputChanged} />
                </div>}
                {current.type === 'time' && <div className="time">
                    <Time value={current && current.value} onChange={onValueChanged} />
                </div>}
            </>}
        </div>
        <div className="list">
            <List>
                {value && value.map((indicator, i) => <ListItem key={i} selected={currentIndex === i} onClick={itemSelectors![i]}>
                    <ListItemText>{nameForIndicator(indicator)}</ListItemText>
                    <ListItemSecondaryAction>
                        <Button onClick={itemDeletors![i]}><i className="fas fas-close"/></Button>
                    </ListItemSecondaryAction>
                </ListItem>)}
            </List>
            <ul className="buttons">
                <Button onClick={addItem}><i className="fas fa-plus"/></Button>
                <Button disabled={currentIndex === undefined} onClick={currentIndex !== undefined ? itemDeletors![currentIndex] : undefined}><i className="fas fa-minus"/></Button>
            </ul>
        </div>
    </div>;
};