import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Store } from 'reactive-state';
import { connect, ActionMap } from 'reactive-state/react';

import { AppState, filterDictionaryOptions$, FilterDictionaryOptions } from '../../state';

import Draggable from 'react-draggable';

import './filter-dictionary.scss';
import classnames from 'classnames';
import { IconButton, Tooltip, Tabs, Tab, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Checkbox } from '@material-ui/core';
import { FunctionDef, functionDefs } from '../../syntax/functions';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import { Function, FunctionCategory, FunctionClassType } from '../filter-editor/model';


import { randomString } from '../../util/randomString';
import { useToggleState } from '../../hooks/useToggleState';

import './tabs.scss';
import './functions.scss';

interface Props {
    // groupings: FunctionGrouping[];
    // Functions: Function[];
    // categories: FunctionCategory[];
    // classTypes: FunctionClassType[];
    // packages: FunctionPackage[];
    // classes: FunctionClass[];

    setFilterDictionaryOptions: (options: FilterDictionaryOptions) => void;
}

interface FunctionFilters {
    [categoryId: string]: {
        classTypes: {[classTypeId: string]: {checked: boolean, count: number}};
        checked: boolean;
    };
}

interface FunctionFilterResult {
    [categoryId: string]: {
        [classTypeId: string]: Function[];
    };
}

const getDictionary = (array) => array.reduce((acc, cur) => ({...acc, [cur.id]: cur}), {});

const FunctionProp = ({name, children}) => {
    return <div className="function-prop">
        <div className="name">{name}</div>
        <div className="content">{children}</div>
    </div>;
};

const Functions: React.FC<Props> = ({
    setFilterDictionaryOptions, 
    // groupings, categories, packages, classTypes, classes, Functions
}) => {
    const categories = useRef<FunctionCategory[]>([
        {name: 'Category', id: randomString()},
    ]);

    const classTypes = useRef<FunctionClassType[]>([
        {name: 'Custom lookback', id: randomString()},
        {name: 'Date and time', id: randomString()},
        {name: 'Financial', id: randomString()},
        {name: 'Mathematical', id: randomString()},
        {name: 'Precision control', id: randomString()},
        {name: 'Statistical', id: randomString()},
        {name: 'Relative value', id: randomString()},
    ]);

    const getRandom = array => array[Math.floor(Math.random()*array.length)];

    const generateMockFunctions = (): Function[] => {
        const cat1 = categories.current.find(x => x.name === 'Category');
        const ct = classTypes.current.find(x => x.name === 'Relative value');

        return [...[...Array(8).keys()].map(x => {
            const category = getRandom(categories.current);
            const classType = getRandom(classTypes.current);
            
            return {
                id: randomString(),
                name: `func${x}`,
                categoryId:  category.id,
                classTypeId: classType.id
            };
        }), {categoryId: cat1!.id, classTypeId: ct!.id, name: 'crosstime', id: randomString()}];
    };

    const functionsList = useRef(generateMockFunctions());

    const [filtersVisible,, showFilters, hideFilters] = useToggleState(false);
    const [filterCategory, setFilterCategory] = useState<string>();
    const [filters, setFilters] = useState<FunctionFilters>({});
    const [filterResult, setFilterResult] = useState<FunctionFilterResult>({});
    const [selectedFunction, setSelectedFunction] = useState<Function>();

    const onSelect = useCallback(field => {
        setFilterDictionaryOptions({show: true, disabled: false, selected: field});
    }, [setFilterDictionaryOptions]);

    const categoryDictionary: {[categoryId: string]: FunctionCategory} = getDictionary(categories.current);
    const classTypesDictionary: {[classTypeId: string]: FunctionClassType} = getDictionary(classTypes.current);


    // TODO investigate if it will be possible to modify fields while window is open?
    useEffect(() => {
        const temp: FunctionFilters = {};
        functionsList.current.forEach(f => {
            const { classTypeId, categoryId } = f;
            if (!temp[categoryId]) temp[categoryId] = {checked: false, classTypes: {}};
            if (!temp[categoryId].classTypes[classTypeId]) temp[categoryId].classTypes[classTypeId] = {count: 0, checked: false};
            temp[categoryId].classTypes[classTypeId].count++;
        });
        setFilters(temp);
    }, []);

    useEffect(() => {
        const res: FunctionFilterResult = {};
        // First filter for Functions that satisfy criteria
        functionsList.current.forEach(f => {
            const {categoryId, classTypeId } = f;
            if (!res[categoryId]) res[categoryId] = {};
            if (!res[categoryId][classTypeId]) res[categoryId][classTypeId] = [];
            res[categoryId][classTypeId] = [...res[categoryId][classTypeId], f];
        });
        setFilterResult(res);
    }, [filters]);

    return <div className="functions-container">
        <div className="left-pane">
            <div className="options">
                <div className="active-filters">Search</div>
                <div className="sort-by">Sort by ▾</div>
                <div className="filters-container">
                    <div onMouseEnter={showFilters} className="action">Filter ▾</div>
                    <div hidden={!filtersVisible} onMouseLeave={hideFilters} className="filters">
                        <div className="type-column">
                            <div className="column-caption">Category</div>
                            {Object.keys(filters).map(categoryId => 
                                <div key={categoryId}
                                    className={classnames({
                                        'filter-type': true,
                                        'active': categoryId === filterCategory
                                    })}
                                    onClick={() => setFilterCategory(categoryId)} >{categoryDictionary[categoryId].name}</div>
                            )}
                        </div>
                        <div className="class-type-column">
                            {filterCategory && Object.keys(filters[filterCategory].classTypes).map(classTypeId => {
                                return <div key={classTypeId}
                                    className='filter-subgrouping'> 
                                    <Checkbox onClick={e => e.stopPropagation()} checked={true} color="default" />
                                    <div>{classTypesDictionary[classTypeId].name}</div>
                                </div>;
                            }
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="filter-results">
                <div className="title">Filter results</div>
                {Object.keys(filterResult).map(categoryId => {
                    const current = filterResult[categoryId];
                    return <ExpansionPanel defaultExpanded className="grouping-panel" key={categoryId}>
                        <ExpansionPanelSummary className="grouping-name" expandIcon={<i className="fal fa-angle-up"></i>}>
                            {categoryDictionary[categoryId].name}
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className="grouping-panel-details">
                            {Object.keys(current).map(classTypeId => {
                                const functions = current[classTypeId];
                                return <ExpansionPanel key={classTypeId}>
                                    <ExpansionPanelSummary expandIcon={<i className="fal fa-angle-up"></i>}>
                                        <div>{classTypesDictionary[classTypeId].name}</div>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className="category-panel">
                                        {functions.map(f => {
                                            return <div onClick={() => setSelectedFunction(f)} className="function" key={f.id}>
                                                <div>{f.name}</div>
                                            </div>;
                                        })}
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>;
                            })}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>;
                })}
            </div>
        </div>
        <div className="right-pane">
            {selectedFunction && <>
                <div className="title">crosstime</div>
                <FunctionProp name='Purpose'>
                    Returns the number of periods that have passed since variable A crossed above (below) variable B.
                </FunctionProp>
                <FunctionProp name='Format'>
                    time = crosstime(VarA, VarB)
                </FunctionProp>
                <FunctionProp name='Inputs'>
                    <div>VarA:      The first time series in the comparision</div>
                    <div>VarB:      The second time series in the comparison</div>
                </FunctionProp>
                <FunctionProp name='Output'>
                    time:      the number of periods since varA crossed above (below) varB.
                </FunctionProp>
                <FunctionProp name='Examples'>
                    <div className="example">
                        <div className="name">crosstime (Close_Last, sma_10D ) = 1</div>
                        <div className="desc">Yesterday’s closing price has been above the 10 day moving average price for 1 day. If it’s Wednesday, that means Close_Last was above sma_10d on Tuesday, but not on Monday.</div>
                    </div>
                    <br/>
                    <div className="example">
                        <div className="name">crosstime (Close_Last, sma_10D ) &lt; 5</div>
                        <div className="desc">Yesterday`s closing price has been below the 10 day moving average price</div>
                    </div>
                </FunctionProp>
                <FunctionProp name='Remarks'>
                    <div>If VarA &gt; VarB, time will be a positive number. It tells us how long ago the upside crossover occured - that is, the number of periods since VarA moved above VarB. If VarA is less than VarB, time will be a negative number. It tells us how long ago the downside crossover occured - VarA moving below VarB. The function can be used in technical strategies to identify recent moving average crossovers and price breakouts.</div>
                    <br/>
                    <div>The preiodicity of the input variables - e.g. hours, days, etc - determines the units of time. VarA and varB will generally have the same periodicities, bu, tif they differ, time will be defined using the shortest periodicity.</div>
                </FunctionProp>
            </>}
        </div>
    </div>;
};

export default Functions;