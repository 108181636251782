import React from 'react';
import { Input } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { InputProps } from '@material-ui/core/Input';

function NumberFormatThousands(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={
                values => {
                    onChange({
                        target: {
                            value: values.value,
                            valueAsNumber: +values.value
                        }
                    });
                }
            }    
            thousandSeparator
        />
    );
}

export function NumberFormatMaterial(props: InputProps) {
    return (
        <Input {...props} inputComponent={NumberFormatThousands}/>
    );
}
