exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".indicator-prop {\n  display: flex;\n  padding: 0 1rem;\n  font-size: 0.75rem;\n  line-height: 1.2rem;\n  letter-spacing: 0.21px; }\n  .indicator-prop .name {\n    flex: 0 0 9rem;\n    font-weight: 600; }\n  .indicator-prop .content {\n    flex: 1; }\n\n.specification {\n  font-weight: 600;\n  font-size: 1rem;\n  letter-spacing: 0.15px;\n  margin: 0.75rem 1rem; }\n\n.spec-container {\n  border: 1px solid #626567;\n  margin: 0 1rem;\n  padding: 1rem; }\n\n.spec-header {\n  padding: 0 1rem;\n  font-weight: 600;\n  font-size: 0.75rem;\n  letter-spacing: 2px;\n  margin: 0.5rem 1rem;\n  display: flex; }\n  .spec-header > * {\n    flex: 1 1 0; }\n\n.spec-line {\n  display: flex; }\n  .spec-line > * {\n    flex: 1 1 0; }\n", ""]);

// exports
exports.locals = {
	"primary": "#67a9ed",
	"secondary": "#aeea00",
	"error": "red",
	"success": "#8bc293"
};