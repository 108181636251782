import { RiskScreenType } from './model';
import { ColumnPreference } from '../components/table-view/model';
import { isNumber } from 'lodash';

export const columnPreferences: ReadonlyMap<RiskScreenType, (ColumnPreference | string)[]> = new Map<RiskScreenType, (ColumnPreference | string)[]>([
    [RiskScreenType.platforms, [
        { name: 'PositionKey', hidden: true, disabled: true },
        'Platform',
        'PnL',
        'OpenPnL',
        'ClosedPnL',
        'MarketValue',
        'Exposure',
        'LongExposure',
        'ShortExposure',
        'SharesTraded',
        'OpenOrders',
        'PendingSharesBuy',
        'PendingSharesSell',
        'PendingNotionalBuy',
        'PendingNotionalSell',
    ]],
    [RiskScreenType.accounts, [
        { name: 'PositionKey', hidden: true, disabled: true },
        { name: 'AccountPropertyKey', hidden: true },
        { name: 'OwnerID', hidden: true },
        'UpdateTime',
        'Trader',
        'TotalPnL',
        'TotalavOpenPnL',
        'TotalavClosedPnL',
        'TotalMarketValue',
        'TotalExposure',
        'TotalLongExposure',
        'TotalShortExposure',
        'TotalSharesTraded',
        'TotalOpenOrders',
        'TotalPendSharesBuy',
        'TotalPendSharesSell',
        'PendingNotionalBuy',
        'PendingNotionalSell',
        'PendLongExposure',
        'PendShortExposure'
    ]],
    [RiskScreenType.symbols, [
        { name: 'PositionKey', hidden: true, disabled: true },
        'UpdateTime',
        'Symbol',
        'LastPrice',
        'TotalPnL',
        'TotalOpenShares',
        'TotalMarketValue',
        'TotalExposure',
        'TotalLongExposure',
        'TotalShortExposure',
        'TotalSharesTraded',
        'TotalOpenOrders',
        'TotalPendSharesBuy',
        'TotalPendSharesSell',
        'PendingNotionalBuy', // Not in table
        'PendingNotionalSell' // Not in table
    ]],
    [RiskScreenType.traders, [
        'LastTime',
        'Trader',
        'TraderID',
        'PnL',
        'avOpenPnL',
        'avClosedPnL',
        'OpenShares', // Not in table
        'MarketValue',
        'Exposure',
        'LongExposure', // Not in table unless 'TtlPendLongExposure'
        'ShortExposure', // Not in table unless 'TtlPendShortExposure'
        'SharesTraded',
        'OpenOrders',
        'PendSharesBuy',
        'PendSharesSell',
        'PendingNotionalBuy', // Not in table
        'PendingNotionalSell' // Not in table
    ]],
    [RiskScreenType.positions, [
        { name: 'PositionPnLKey', hidden: true },
        { name: 'PositionKey', hidden: true, disabled: true },
        'Trader',
        'Name',
        'Symbol',
        'OpenShares',
        'RiskPnL',
        'avOpenPnL',
        'avClosedPnL',
        'avAvgPx',
        'RiskPrice',
        'PendingSharesBuy',
        'PendingSharesSell&SShort',
        'MinSinceLast',
        'SharesTraded',
        'MarketValue',
        'TransactTime',
        'SODPosition',
        'ChangeQty',
        'Platform'
    ]],
    [RiskScreenType.joinedAccounts, [
        { name: 'PositionKey', hidden: true, disabled: true },
        'UpdatedTime',
        'Platform',
        'Trader',
        'account',
        'PnL',
        'AvOpenPnL',
        'AvClosedPnL',
        'Exposure',
        'MarketValue',
        'SharesTraded',
        'AbsSlippageMarketValue',
        'SlippageMarketValue',
        'SlippagePnL',
        {name: 'ratio', renderer: value => {
            const res = isNumber(value) && !isNaN(value) ? numeral(value).format('0,0.00') : value;
            return res;
        }},
        'BenchmarkAccount',
        'BenchmarkName',
        'BenchmarkPnL',
        'BenchmarkMarketValue',
        'BenchmarkSharesTraded',
        'SlippageVolume',
        '%SlippagePnL',
        '%SlippageVolume',
        '%SlippageMarketValue'
    ]],
    [RiskScreenType.joinedPositions, [
        { name: 'PositionKey', hidden: true, disabled: true },
        'BenchmarkAccount', 'Symbol', 'Ratio', 'TransactTime', 'BenchmarkPnL',
        'BenchmarkMarketValue', 'BenchmarkSharesTraded', 'Trader', 'PnL', 'avOpenPnL',
        'avClosedPnL', 'Exposure', 'MarketValue', 'SharesTraded', 'BoughtNotional',
        'SoldNotional', 'TradedNotional', 'SlippagePnL', 'SlippageVolume', '%SlippagePnL',
        '%SlippageVolume', 'SlippageMarketValue', 'AbsSlippageMarketValue', '%SlippageMarketValue'
    ]]
]);
Object.freeze(columnPreferences);